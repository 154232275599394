import { renderTags } from "./RenderTags";

export const stringToHTML = (str) => {
    var dom = document.createElement('div');
    dom.innerHTML = str;
    return dom.childNodes[0];
};

const copyToDelFragment = (node, fragment) => {
    //保留文本和标签
    if (node.nodeName === '#text' || node.nodeName === 'IMG') {
        fragment.appendChild(node.cloneNode(true));
    }
    for (let cn of node.childNodes) {
        if (cn.nodeName === "DEL") {
            for (let cn2 of cn.childNodes) {
                copyToDelFragment(cn2, fragment);
            }
        } else if (cn.nodeName === "INS") {
            //remove
        } else if (cn.nodeName == '#text') {
            // document.createTextNode()
            fragment.appendChild(cn.cloneNode(true));
        } else {
            fragment.appendChild(cn.cloneNode(true));
        }
    }
    merge(fragment);
}

const merge = (node) => {
    //http://localhost:8080/doc/20210203145124130254.tr-TR?mode=review
    //node.acceptNode
    var preNode = null;
    var deleted = [];
    for (let cn of node.childNodes) {
        if (cn.nodeName === '#text' || cn.nodeName === "IMG") {
            preNode = cn;
            continue;
        }
        if (!cn.hasChildNodes()) {
            // if ((cn.nodeName === "DEL"||cn.nodeName==="INS") && cn.textContent === '') {
            //DEL 元素直接删除
            // cn.remove();//这里移除会导致索引变化
            deleted.push(cn);
            continue;
        }
        if (preNode && preNode.nodeType == Node.ELEMENT_NODE && preNode.nodeName === cn.nodeName) {
            // 相同元素合并
            appendChildNodesTo(cn, preNode);
            deleted.push(cn);
            continue;
        }
        preNode = cn;
    }
    for (let element of deleted) {
        element.remove();
    }
}

const appendChildNodesTo = (from, node) => {
    for (let cn of from.childNodes) {
        node.appendChild(cn.cloneNode(true));
    }
}

const isTag = (text) => {
    return /^(\{[a-zA-Z\d]+:\})|(\{:[a-zA-Z\d]+\})|(\{:[a-zA-Z\d]+:\})$/ig.test(text);
}

const isImageSource = (text) => {
    return text.includes(`${process.env?.REACT_APP_ENDPOINT}tags-images/`);
}

const stringToNodes = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const fragment = document.createDocumentFragment();

    Array.from(doc.body.childNodes).forEach(node => {
        fragment.appendChild(node);
    });

    return fragment;
}

const getImageAlt = (tagSource) => {
    // Extract parameters from URL made by tagSource
    const url = new URL(tagSource);
    const params = new URLSearchParams(url.search);
    const name = params.get('name');
    const type = params.get('type').replace('/', "");

    // Determine alt value based on extracted parameters
    let altValue = '';
    if (type === '1') {
        altValue = `{${name}:}`;
    } else if (type === '2') {
        altValue = `{:${name}}`;
    } else if (type === '3') {
        altValue = `{:${name}:}`;
    }

    return altValue;
}

export const trackChanges = (evt) => {

    var selection = document.getSelection();
    if (selection.rangeCount == 0) {
        return;
    }
    var range = selection.getRangeAt(0);
    var ptag = range.startContainer.parentElement.localName;
    var rangeContents = range.cloneContents();

    if (evt.inputType === 'deleteContentForward' || evt.inputType === 'deleteContentBackward'
        || evt.inputType === 'deleteWordForward' || evt.inputType === 'deleteWordBackward'
        || evt.inputType === 'deleteByDrag' || evt.inputType === 'deleteByCut') {
        // @ts-ignore
        var range = document.getSelection().getRangeAt(0);
        // 如果用户没有选择中内容执行删除, 则设置选中
        var contents = range.cloneContents();
        if (!contents.hasChildNodes()) {
            var tr = evt.getTargetRanges()[0];
            // 在DEL标签内执行删除操作, 只移动光标
            var nn = tr.endContainer.parentElement.nodeName;
            if (nn === "DEL") {
                if (evt.inputType.endsWith("Forward")) {
                    range.setStart(tr.endContainer, tr.endOffset);
                } else if (evt.inputType.endsWith("Backward")) {
                    range.setEnd(tr.endContainer, tr.startOffset);
                }
                evt.preventDefault();
                evt.stopPropagation();
                return;
            }
            range.setStart(tr.startContainer, tr.startOffset);
            range.setEnd(tr.endContainer, tr.endOffset);
            // 在INS标签内执行删除操作, 直接删除内容
            if (nn === "INS") {
                // range.deleteContents();
                // evt.preventDefault();
                // evt.stopPropagation();
                return;
            }
        }
        var fragment = range.extractContents();
        var mark = document.createElement("MARK");//用于获取光标位置前后元素
        range.insertNode(mark);

        var delFragment = document.createDocumentFragment();
        copyToDelFragment(fragment, delFragment)

        var prev = mark.previousSibling;
        var next = mark.nextSibling;
        var currentElemnet = mark.parentElement;
        var inDelEl = mark.parentElement.nodeName === "DEL";
        var inInsEl = mark.parentElement.nodeName === "INS";

        if (evt.inputType.endsWith("Forward")) {//Del键
            if (inDelEl) {
                range.insertNode(delFragment);
                range.setStartAfter(delFragment.lastChild);
            } else if (inInsEl) {
                range.selectNode(mark);
            } else {
                if (delFragment.hasChildNodes()) {//delFragment.textContent != ''
                    var del = document.createElement("del");
                    del.setAttribute('contenteditable', 'false')
                    del.appendChild(delFragment);
                    range.insertNode(del);
                    range.setStartAfter(del);
                }
            }
        } else if (evt.inputType.endsWith("Backward")) {//Back键(删除光标左边的内容)
            if (inDelEl) {
                // console.log("删除左边的元素del")
                range.insertNode(delFragment);
                range.setStartBefore(currentElemnet.firstChild);
                range.setEndBefore(currentElemnet.firstChild);
            } else if (inInsEl) {
                range.selectNode(mark);
            } else {
                if (delFragment.hasChildNodes()) {//delFragment.textContent != ''
                    var del = document.createElement("del");
                    del.setAttribute('contenteditable', 'false')
                    del.appendChild(delFragment);
                    range.insertNode(del);
                    range.setStartBefore(del);
                    range.setEndBefore(del);
                }
            }
        } else {
            //剪切 拖拽
            if (inDelEl) {
                console.log("拖拽元素del")
            } else {
                if (delFragment.hasChildNodes()) {//delFragment.textContent != ''
                    // var del = document.createElement("del");
                    // del.setAttribute('contenteditable', 'false')
                    // del.appendChild(delFragment);
                    // range.insertNode(del);
                    // range.setStartBefore(del);
                    // range.setEndBefore(del);

                    // Instantly red line on deleted tag
                    var div = document.createElement("div");
                    var del = document.createElement("del");
                    del.setAttribute('contenteditable', 'false')
                    del.appendChild(delFragment);
                    div.appendChild(del);
                    div.className = 'delClass';
                    range.insertNode(div);
                    range.setStartBefore(div);
                    range.setEndBefore(div);

                }
            }
        }
        // var mp = mark.parentElement;
        mark.remove();
        // merge(mp);//TODO
        evt.preventDefault();
        evt.stopPropagation();
        return;
    }
    if (evt.inputType === 'insertText' || evt.inputType === 'insertFromPaste' || evt.inputType === 'insertFromDrop') {
        //insertCompositionText: 中文输入法等
        var fragment = range.extractContents();
        var mark = document.createElement("MARK");//用于获取光标位置前后元素
        range.insertNode(mark);
        if (mark.parentElement.nodeName === "DEL") {
            range.setStartAfter(mark.parentElement);//???
        }
        //删除选中的文本
        if (fragment.hasChildNodes()) {
            range.setStartAfter(mark);
            var delFragment = document.createDocumentFragment();
            copyToDelFragment(fragment, delFragment);
            if (delFragment.hasChildNodes()) {
                var del = document.createElement("del");
                del.setAttribute('contenteditable', 'false')
                del.appendChild(delFragment);
                range.insertNode(del);
                range.setStartAfter(mark);
            }
        }
        //参考: https://www.w3.org/TR/2016/WD-input-events-20161205/
        //如果拖动的是图片且仅有图片(非选中状态下), text会返回图片链接, 否则会返回alt属性中的内容
        var _data = evt.data || evt.dataTransfer.getData("text/plain");
        var inputText = document.createTextNode(_data);
        const tagSource = evt.dataTransfer?.getData("text/plain");

        // 如果在ins里面, 这直接插入文本
        if (mark.parentElement.nodeName === "INS") {
            // if tag is dropped inside <ins> as image source
            if (evt.inputType === 'insertFromDrop' && isImageSource(tagSource)) {
                const imgElement = document.createElement("img");
                imgElement.alt = getImageAlt(tagSource);
                imgElement.src = tagSource;
                range.insertNode(imgElement);
            } else if (evt.inputType === 'insertFromDrop' && isTag(tagSource)) {
                // if tag is dropped inside <ins> as alt value i.e. {1:}
                range.insertNode(stringToNodes(renderTags(tagSource)));
            } else {
                range.insertNode(inputText)
            }
        } else {
            var ins = document.createElement("ins");
            if (evt.data === " ") {
                ins.innerHTML = "&nbsp;";
                range.insertNode(ins);
                range.selectNodeContents(ins);//替死鬼
                range.collapse(false);
            } else {
                // if tag is dropped as image source
                if (evt.inputType === 'insertFromDrop' && isImageSource(tagSource)) {
                    const imgElement = document.createElement("img");
                    imgElement.alt = getImageAlt(tagSource);
                    imgElement.src = tagSource;
                    ins.appendChild(imgElement);
                    range.insertNode(ins);
                } else if (evt.inputType === 'insertFromDrop' && isTag(tagSource)) {
                    // if tag is dropped as alt value i.e. {1:}
                    ins.appendChild(stringToNodes(renderTags(tagSource)));
                    range.insertNode(ins);
                } else {
                    ins.innerText = evt.data;//撤销时会显示
                    range.insertNode(ins);
                }
                range.selectNodeContents(ins);//替死鬼
            }
        }
        // range.selectNodeContents(ins);//如果是insertText事件, 那么这部分会被删除(事件无法取消), 如果是paste事件则取消事件行为
        if (evt.inputType === 'insertFromDrop') {
            //REOSLVED: bug 如果取消默认行为, 图片就不会自动处理; 如果不取消就没法给新的文本加上ins标签
            evt.preventDefault();
        }
        // merge(mark.parentElement);//TODO
        mark.remove();
        return;
    }
    if (evt.inputType === 'insertCompositionText') {//每输入一个字符会导致range选择的内容改变
        if (ptag === 'ins') {
            // fixbug: CAT-276
            // 这里直接返回, 交给浏览器处理, 不需要自己处理中文输入法的问题
            return;
        }
        if (rangeContents.firstChild) {
            var contents = range.cloneContents();
            range.deleteContents();
            if (ptag !== 'del' && ptag !== 'ins') {
                var del = document.createElement("del");
                del.setAttribute('contenteditable', 'false')
                del.appendChild(contents);
                // console.log(del.outerHTML)
                range.insertNode(del);
                range.setStartAfter(del);
                range.setEndAfter(del);
            }
        }
        if (evt.data) {//如果有数据: 输入的内容不能再del里面
            //重点: 删除掉尾部evt.data-1个字符
            var delCount = evt.data.length - 1;
            if (delCount > 0) {
                var len = range.startContainer.textContent.length;
                if (len > delCount) {
                    range.setStart(range.startContainer, len - delCount);
                    range.setStart(range.startContainer, len);
                    range.deleteContents();
                }
            }

            if (ptag === 'del') {//如果光标在del里面, 则先移到外面去
                range.selectNode(range.startContainer.parentElement.nextSibling)
            }
            var ins = document.createElement("ins");
            ins.innerText = evt.data;//撤销时会显示
            range.insertNode(ins);
            range.selectNodeContents(ins);//替死鬼
        }
    }
}

export default function useTrackChanges() {

    return {
        trackChanges: trackChanges,
        copyToDelFragment: copyToDelFragment,
        merge: merge,
        appendChildNodesTo: appendChildNodesTo,
        stringToHTML: stringToHTML
    }

}