import React, { useEffect, useState } from "react";
import {
    Box,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    Modal,
    TextField,
    InputLabel,
    MenuItem,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./CheckBoxStyles";
import { IState } from "./ActionReducer";
import { useTranslation } from "react-i18next";
import { IAppState } from "../../interfaces/types/SegmentType";

interface IProps {
    actionState: IState;
    appState: IAppState;
    appDispatch: any;
    headerData: any;
}
export default function CheckBoxAdvance({ actionState, appState, appDispatch, headerData }: IProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [checked, setChecked]: any = useState([]);
    const [excluded, setExcluded]: any = useState([]);
    const [isAllChecked, setIsAllChecked]: any = useState(0);
    const [isAllExcluded, setIsAllExcluded]: any = useState(0);
    const [childValue, setChildValue]: any = useState({
        engine: "",
        similarity: "",
        percentage: "",
        showDiff: false,
    });

    useEffect(() => {
        let temp: any = [];
        let tempChild = childValue;
        let exclude = excluded;

        let advanceArray: any = actionState.advance;
        let excludeArray: any = actionState.excludeSegments;

        let advanceFound: any = appState?.projectSettings;
        let excludeFound: any = appState?.projectSettings;

        if (advanceFound) {
            let advance: any = JSON.parse(advanceFound);
            advance = advance.find((itm: any) => itm.value == 'advance');
            advanceArray = advance ? advance.children : [];
            actionState.advance = advanceArray;
        } else localStorage.setItem("advance", JSON.stringify(actionState.advance));


        if (excludeFound) {
            let exclude: any = JSON.parse(excludeFound);
            exclude = exclude.find((itm: any) => itm.value == 'excludeSegments');

            excludeArray = exclude ? exclude.children : [];
            actionState.excludeSegments = excludeArray;
        } else localStorage.setItem("exclude", JSON.stringify(actionState.excludeSegments));

        advanceArray.forEach((parent: any) => {
            if (parent.checked === true) {
                temp.push(parent.value);
            }
            if (parent.children) {
                parent.children.forEach((child: any) => {
                    if (child.value === "engine") {
                        tempChild.engine = child.inputValue;
                    } else if (child.value === "similarity") {
                        tempChild.similarity = child.inputValue;
                    } else if (child.value === "percentage") {
                        tempChild.percentage = child.inputValue;
                    } else if (child.value === "showDiff") {
                        tempChild.showDiff = child.checked;
                    }
                });
            }
        });

        excludeArray.forEach((item: any) => {
            if (item.checked === true) exclude.push(item.value);
        });

        // setShowDiff(tempShowDiff);

        setChecked(temp);
        setChildValue(tempChild);
        setExcluded(exclude);
    }, [appState?.projectSettings]);

    useEffect(() => {
        const check = localStorage.getItem("advance");
        const check1 = localStorage.getItem("exclude");
        if (check)
            localStorage.setItem("advance", JSON.stringify(actionState.advance));
        if (check1)
            localStorage.setItem(
                "exclude",
                JSON.stringify(actionState.excludeSegments)
            );

        if (checked.length < 1) setIsAllChecked(0);
        else setIsAllChecked(1);
        if (excluded.length < 1) setIsAllExcluded(0);
        else setIsAllExcluded(1);
    }, [checked, excluded, childValue]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        let temp = childValue;
        if (temp.similarity > 100) temp.similarity = 100;
        else if (temp.similarity < 50) temp.similarity = 50;
        if (temp.percentage > 100) temp.percentage = 100;
        else if (temp.percentage < 1) temp.percentage = 1;
        setChildValue(temp);



        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempCheck = checked;
        let temp = childValue;
        if (event.target.checked) {
            actionState.advance.forEach((parent: any) => {
                parent.checked = true;
                tempCheck.push(parent.value);
                if (parent.children) {
                    parent.children.forEach((child: any) => {
                        if (child.value === "engine") {
                            temp.engine = child.inputValue;
                        }
                        if (child.value === "similarity") {
                            temp.similarity = child.inputValue;
                        }
                        if (child.value === "percentage") {
                            temp.percentage = child.inputValue;
                        }
                    });
                }
            });
            setChecked(tempCheck);
            setChildValue(temp);
            setIsAllChecked(1);
        } else {
            actionState.advance.forEach((item: any) => {
                item.checked = false;
                if (item.children) {
                    item.children.forEach((el: any) => (el.checked = false));
                }
            });
            setChecked([]);
            setIsAllChecked(0);
            setChildValue(temp);
        }

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleCheck = (event: any, name: any) => {
        let temp = childValue;
        let flag = false;
        if (event.target.checked) {
            actionState.advance.forEach((parent: any) => {
                if (parent.value === name) {
                    parent.checked = true;
                    if (parent.children) {
                        flag = true;
                        parent.children.forEach((child: any) => {
                            if (child.value === "engine") {
                                temp.engine = child.inputValue;
                            }
                            if (child.value === "similarity") {
                                temp.similarity = child.inputValue;
                            }
                            if (child.value === "percentage") {
                                temp.percentage = child.inputValue;
                            }
                        });
                    }
                }
            });
            setChecked([...checked, name]);
            if (flag) setChildValue(temp);
        } else {
            actionState.advance.forEach((parent: any) => {
                if (parent.value === name) parent.checked = false;
            });
            setChecked(checked.filter((checkedItem: any) => checkedItem !== name));
        }

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        // appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const handleExcludeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let temp = excluded;
        if (event.target.checked) {
            actionState.excludeSegments.forEach((item: any) => {
                item.checked = true;
                temp.push(item.value);
            });
            setExcluded(temp);
            setIsAllExcluded(1);
        } else {
            actionState.excludeSegments.forEach((item: any) => (item.checked = false));
            setExcluded([]);
            setIsAllExcluded(0);
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const handleExclude = (event: any, name: any) => {
        if (event.target.checked) {
            actionState.excludeSegments.forEach((item: any) => {
                if (item.value === name) item.checked = true;
            });
            setExcluded([...excluded, name]);
        } else {
            actionState.excludeSegments.forEach((item: any) => {
                if (item.value === name) item.checked = false;
            });
            setExcluded(excluded.filter((checkedItem: any) => checkedItem !== name));
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const parseEngines = () => {
        let engineResult = headerData.engines.find((itm: any) => (itm.sl == headerData?.sl?.code && itm.tl == appState.selectedLanguage));
        let engines = engineResult ? engineResult.engines : [];
        let parsedEngines = [];

        for (let i = 0; i < engines.length; i++) {
            parsedEngines.push({ name: engines[i] });
        }
        return parsedEngines;
    };

    const translationEngine = parseEngines().map(engine => ({
        value: engine.name.replace(' Translate', ''),
        label: engine.name
    }));

    // const translationEngine = [
    //     {
    //         value: "Google",
    //         label: "Google Translate",
    //     },
    //     {
    //         value: "Microsoft",
    //         label: "Microsoft Translate",
    //     },
    //     {
    //         value: "Baidu",
    //         label: "Baidu Translate",
    //     },
    //     {
    //         value: "Pseudo",
    //         label: "Pseudo Translate",
    //     },
    // ];

    const handleEngine = (event: any) => {
        actionState.advance[1].children[0].inputValue = event.target.value;
        setChildValue({ ...childValue, engine: event.target.value });
    };
    const handleSimilarity = (event: React.ChangeEvent<HTMLInputElement>) => {
        actionState.advance[1].children[1].inputValue = event.target.value;
        setChildValue({ ...childValue, similarity: event.target.value });
    };
    const handlePercentage = (event: any) => {
        actionState.advance[1].children[2].inputValue = event.target.value;
        setChildValue({ ...childValue, percentage: event.target.value });
    };

    const { t } = useTranslation();

    const advance = (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>{t('verify.spellCheck')}</span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("spell_check") ? true : false}
                        onChange={(e) => handleCheck(e, "spell_check")}
                    />
                }
            />
            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            {t('verify.mtCheck')}
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("translation") ? true : false}
                            onChange={(e) => handleCheck(e, "translation")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={handleOpen}
                    aria-label="delete"
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
        </Box>
    );

    const handleChange = (
        event: any,
    ) => {
        let temp: any = false;
        // console.log(actionState.advance[1].expand[3], "AAAAAAAA")
        if (event.target.checked) {
            actionState.advance[1].children[3].checked = event.target.checked;
            temp = true;
        }
        else {
            actionState.advance[1].children[3].checked = event.target.checked;
            temp = false;
        }

        setChildValue({ ...childValue, showDiff: temp });


    };

    return (
        <Box>
            <Box>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            <b>{t('verify.advance')}</b>
                        </span>
                    }
                    control={
                        <Checkbox
                            checked={isAllChecked ? true : false}
                            indeterminate={
                                checked.length !== 2 && isAllChecked ? true : false
                            }
                            onChange={handleChangeAll}
                            className={classes.checkboxColor}
                        />
                    }
                />
                {advance}
            </Box>

            <Box mt={6} fontSize={"16px"} fontWeight={"bold"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            <b>{t('verify.excludeSegments')}</b>
                        </span>
                    }
                    control={
                        <Checkbox
                            checked={isAllExcluded ? true : false}
                            indeterminate={
                                excluded.length !== 4 && isAllExcluded ? true : false
                            }
                            onChange={handleExcludeAll}
                            className={classes.checkboxColor}
                        />
                    }
                />

                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={excluded.includes("locked") ? true : false}
                                onChange={(e) => handleExclude(e, "locked")}
                                className={classes.checkboxColor}
                            />
                        }
                        label={<span className={classes.checkboxLabel}>{t('verify.locked')}</span>}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={excluded.includes("100_matched") ? true : false}
                                onChange={(e) => handleExclude(e, "100_matched")}
                                className={classes.checkboxColor}
                            />
                        }
                        label={<span className={classes.checkboxLabel}>{t('verify.hundredMatch')}</span>}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={excluded.includes("context_match") ? true : false}
                                onChange={(e) => handleExclude(e, "context_match")}
                                className={classes.checkboxColor}
                            />
                        }
                        label={<span className={classes.checkboxLabel}>{t('verify.contextMatch')}</span>}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={excluded.includes("reviewed") ? true : false}
                                onChange={(e) => handleExclude(e, "reviewed")}
                                className={classes.checkboxColor}
                            />
                        }
                        label={<span className={classes.checkboxLabel}>{t('common.reviewed')}</span>}
                    />
                </Box>
            </Box>

            <Modal
                hideBackdrop={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="MuiModal-root"
            >
                <Box className={classes.modalStyles}>
                    <Box className={classes.childTitle} id="child-modal-title">
                        {t('verify.mtOpt')}
                    </Box>

                    <Box className={classes.modalBody}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box mb={0.5}>
                                <InputLabel shrink>
                                    <b>{t('verify.engine')}</b>
                                </InputLabel>
                            </Box>

                            {/*{JSON.stringify(childValue)}*/}
                            {/*e = {childValue.engine}*/}
                            {childValue.engine != "" && <TextField
                                id="outlined-select-currency"
                                select
                                variant="outlined"
                                size="small"
                                value={childValue.engine}
                                onChange={handleEngine}
                            >
                                {translationEngine.map((option: any) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <span className={classes.selectStyle}>{option.label}</span>
                                    </MenuItem>
                                ))}
                            </TextField>
                            }

                            <Box mb={0.5} mt={3}>
                                <InputLabel shrink>
                                    <b>{t('verify.similarityThreshold')}</b>
                                </InputLabel>
                            </Box>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="outlined-number"
                                type="number"
                                value={childValue.similarity}
                                onChange={handleSimilarity}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { min: 50, max: 100 } }}
                            />

                            <Box mb={0.5} mt={3}>
                                <InputLabel shrink>
                                    <b>{t('verify.samplePercentage')}</b>
                                </InputLabel>
                            </Box>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="outlined-number"
                                type="number"
                                value={childValue.percentage}
                                onChange={handlePercentage}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { min: 1, max: 100 } }}
                            />

                            <Box mt={1}>
                                {/*sf {childValue.showDiff?'1':'0'}*/}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={childValue.showDiff}
                                            onChange={(e) => handleChange(e)}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            Show diff
                                        </span>
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.childFooter}>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            {t('verify.close')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}


