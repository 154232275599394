import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerWrap: {
            '& .MuiAccordionSummary-root': {
                backgroundColor: '#ffffff',
                minHeight: 'auto',
                borderBottom: '3px solid #F77D3D',
                borderTop: '3px solid #c5c5c5',
            },
            '& .MuiAccordionSummary-content': {
                margin: 0
            },
            '& .MuiAppBar-root': {
                backgroundColor: 'transparent',
                boxShadow: 'none !important'
            },
            '& .MuiTab-root': {
                color: '#00000099'
            },
            '& .MuiTabs-indicator': {
                backgroundColor: '#fff'
            }
        },
        expandIcon: {
            color: '#00000099',
        },
        accordionSummary: {
            backgroundColor: '#A6A6A6',
            color: '#fff'
        },
        accordionDetails: {
            padding: 0
        },
        historyTable: {
            '& thead tr th': {
                '&:nth-child(1)': {
                    width: '13%'
                },
                '&:nth-child(2)': {
                    width: '30.5%'
                },
                '&:nth-child(3)': {
                    width: '30.5%'
                },
                '&:nth-child(4)': {
                    width: '13%'
                },
                '&:nth-child(5)': {
                    width: '13%'
                },
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            }
        },
        QATable: {
            '& thead tr th': {
                textAlign: 'left',
                '&:nth-child(1)': {
                    width: '8%'
                },
                '&:nth-child(2)': {
                    width: '72%'
                },
                '&:nth-child(3)': {
                    width: '20%'
                },
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            }
        },
        searchTable: {
            '& p': {
                fontSize: 13
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            }
        },
        commentsTable: {
            '& thead tr th': {
                '&:nth-child(1)': {
                    width: '8%',
                    textAlign: "left"
                },
                '&:nth-child(2)': {
                    width: '--webkit-fill-available',
                    textAlign: 'left'
                },

            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            }
        },
        infoSmIcon: {
            fontSize: '16px !important',
            color: theme.palette.grey[400],
            marginBottom: -3,
            marginRight: 2
        },
        typeRadioWrap: {
            '& .MuiFormGroup-root': {
                flexDirection: 'row',
                '& .MuiSvgIcon-root': {
                    fontSize: 18
                },
                '& .MuiTypography-root': {
                    fontSize: 13
                },
                '& .Mui-checked': {
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.main
                    },
                }
            },
            '& .MuiFormControl-root': {
                '&:nth-child(2)': {
                    width: 440,
                    marginLeft: 16
                },
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: 5.5,
                    paddingBottom: 9.5,
                    backgroundColor: 'rgba(232,232,232,0.5)',
                    borderRadius: 2,
                    '&::placeholder': {
                        color: '#0000008A',
                        fontSize: 13,
                        opacity: 1
                    }
                },
                '& fieldset': {
                    border: '1px solid rgba(232,232,232,0.5)',
                    '&.': {
                    }
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& fieldset': {
                        border: '1px solid #f77d3d'
                    }
                }
            }
        },
        searchBtn: {
            height: 34,
            minWidth: 90,
            marginLeft: 24,
            fontSize: 14,
            fontFamily: 'Roboto Medium'
        },
        btmBarStyles: {
            borderRadius: '0 !important',
        },
        loader: {
            display: "flex",
            justifyContent: "center",
            margin: "5% 0"
        },
        noRecordFoundContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '150px',
            color: '#878484',
            fontSize: '18px',
            '& svg': {
                width: '190px',
                height: '150px'
            },
            '& .ant-empty-img-1': {
                fill: '#aeb8c2',
            },
            '& .ant-empty-img-2': {
                fill: '#f5f5f7'
            },
            '& .ant-empty-img-3': {
                fill: '#dce0e6'
            },
            '& .ant-empty-img-4': {
                fill: '#fff'
            },
            '& .ant-empty-img-5': {
                fillOpacity: '0.8',
                fill: '#f5f5f5'
            }
        },
        filterSelect: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#494949',
            '& .MuiSelect-select.MuiSelect-select': {
                paddingRight: '15px',
            },
            '&.MuiInput-underline::before': {
                borderBottom: 'none !important'
            }
        },
        snackBarStyles: {
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important'
            }
        },
    }),
);

export default useStyles;