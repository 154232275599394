import { gql } from '@apollo/client';

const CONFIRM_SEGMENTS = gql`mutation ConfirmSegments($data: ConfirmSegmentsRequest){
   confirmSegments(data: $data){
      status
      error
  }
 
  }`;



export default CONFIRM_SEGMENTS;

