import React, { useEffect, useState } from "react";
import {
    Box,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    Modal,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./CheckBoxStyles";
import { IState } from "./ActionReducer";
import { useTranslation } from "react-i18next";
import { IAppState } from "../../interfaces/types/SegmentType";

interface IProps {
    actionState: IState;
    appState: IAppState;
    appDispatch: any;
}
export default function CheckBoxBasic({ actionState, appState, appDispatch }: IProps) {
    const classes = useStyles();
    const [isAllChecked, setIsAllChecked]: any = useState(0);
    const [sourceCheck, setSourceCheck] = useState(false);
    const [open, setOpen] = useState(false);
    const [checked, setChecked]: any = useState([]);
    const [childChecked, setChildChecked]: any = useState([]);

    useEffect(() => {
        let temp: any = [];
        let tempChild: any = [];
        let basicArray: any = actionState.basic;
        let basicFound: any = appState?.projectSettings;

        if (basicFound) {
            let basic: any = JSON.parse(basicFound);
            basic = basic.find((itm: any) => itm.value == 'basic');

            basicArray = basic ? basic.children : [];
            actionState.basic = basicArray;


        } else localStorage.setItem("basic", JSON.stringify(actionState.basic));

        basicArray.forEach((parent: any) => {
            if (parent.checked === true) temp.push(parent.value);
            if (parent.children)
                parent.children.forEach((child: any) => {
                    if (child.checked === true) tempChild.push(child.value);
                });
        });
        setChecked(temp);
        setChildChecked(tempChild);
    }, []);

    useEffect(() => {
        let check: any = localStorage.getItem("basic");
        if (check) localStorage.setItem("basic", JSON.stringify(actionState.basic));
        if (checked.length < 1) setIsAllChecked(0);
        else setIsAllChecked(1);
    }, [checked, childChecked]);

    const handleTargetExpand = () => {
        setOpen(!open);
        setSourceCheck(false);
    };
    const handleClose = () => {
        setOpen(!open);
    };
    const handleSourceExpand = () => {
        setSourceCheck(true);
        setOpen(!open);
    };
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        name: any
    ) => {
        if (event.target.checked) {
            actionState.basic.forEach((parent: any) => {
                // console.log(parent.expand)
                if (parent.children) {
                    parent.children.forEach((child: any) => {
                        if (child.value === name) {
                            if (parent.checked === false) {
                                parent.checked = true;
                                setChecked([...checked, parent.value]);
                            }
                            child.checked = true;
                            setChildChecked([...childChecked, child.value]);
                        }
                    });
                }
            });
        } else {
            actionState.basic.forEach((parent: any) => {
                if (parent.children) {
                    parent.children.forEach((child: any) => {
                        if (child.value === name) {
                            child.checked = false;
                            setChildChecked((childChecked: any) =>
                                childChecked.filter((checkedItem: any) => {
                                    return checkedItem !== name;
                                })
                            );
                        }
                    });
                }
            });
        }


        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
        // app
        // SET_ACTION_STATE_CHANGED
    };

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempCheck = checked;
        let tempChild = childChecked;
        if (event.target.checked) {
            actionState.basic.forEach((item: any) => {
                item.checked = true;
                tempCheck.push(item.value);
                if (item.children) {
                    item.children.forEach((child: any) => {
                        child.checked = true;
                        tempChild.push(child.value);
                    });
                }
            });
            setChecked(tempCheck);
            setChildChecked(tempChild);
            setIsAllChecked(1);
        } else {
            actionState.basic.forEach((item: any) => {
                item.checked = false;
                if (item.children) {
                    item.children.forEach((el: any) => (el.checked = false));
                }
            });
            setChecked([]);
            setIsAllChecked(0);
            setChildChecked([]);
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const handleCheck = (
        event: React.ChangeEvent<HTMLInputElement>,
        name: any
    ) => {
        let temp = childChecked;
        if (event.target.checked) {
            actionState.basic.forEach((parent: any) => {
                if (parent.value === name) {
                    parent.checked = true;
                    if (parent.children) {
                        parent.children.forEach((child: any) => {
                            child.checked = true;
                            temp.push(child.value);
                        });
                    }
                }
            });
            setIsAllChecked(1);
            setChecked([...checked, name]);
            setChildChecked(temp);
        } else {
            actionState.basic.forEach((parent: any) => {
                if (parent.value === name) {
                    parent.checked = false;
                    if (parent.children) {
                        parent.children.forEach((child: any) => {
                            child.checked = false;
                            setChildChecked((childChecked: any) =>
                                childChecked.filter((checkedItem: any) => {
                                    return checkedItem !== child.value;
                                })
                            );
                        });
                    }
                }
            });


            setChecked((checked: any) =>
                checked.filter((checkedItem: any) => {
                    return checkedItem !== name;
                })
            );
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const { t } = useTranslation()
    const basic = (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            {t('verify.inconsistencyInTarget')}
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("inconsistency_target") ? true : false}
                            onChange={(e) => handleCheck(e, "inconsistency_target")}
                        />
                    }
                />
                <IconButton className={classes.iconStyles} onClick={handleTargetExpand}>
                    <SettingsIcon />
                </IconButton>
            </Box>

            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            {t('verify.inconsistencyInSource')}
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("inconsistency_source") ? true : false}
                            onChange={(e) => handleCheck(e, "inconsistency_source")}
                        />
                    }
                />
                <IconButton className={classes.iconStyles} onClick={handleSourceExpand}>
                    <SettingsIcon />
                </IconButton>
            </Box>
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>
                    {t('verify.emptyTranslation')}
                </span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("empty_translation") ? true : false}
                        onChange={(e) => handleCheck(e, "empty_translation")}
                    />
                }
            />
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>
                        {t('common.targetSame')}
                    </span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("target_same_source") ? true : false}
                        onChange={(e) => handleCheck(e, "target_same_source")}
                    />
                }
            />
        </Box>
    );

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
                <FormControlLabel
                    className={classes.checkboxLabel}
                    label={
                        <span className={classes.checkboxLabel}>
                            <b>{t('verify.basic')}</b>
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={isAllChecked ? true : false}
                            indeterminate={
                                checked.length !== 4 && isAllChecked ? true : false
                            }
                            onChange={handleChangeAll}
                        />
                    }
                />
                {basic}
            </Box>

            <Modal
                hideBackdrop={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="MuiModal-root"
            >
                <Box className={classes.modalStyles}>
                    {!sourceCheck ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.inconsistencyTargetOpt')}
                        </Box>
                    ) : (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.inconsistencySourceOpt')}
                        </Box>
                    )}

                    {!sourceCheck ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_case_t") ? true : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_case_t")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignoreCase')}
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_tags_t") ? true : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_tags_t")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignoreTags')}
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_punctuation_t")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_punctuation_t")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignorePunctuation')}
                                        </span>
                                    }
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_case") ? true : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_case")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignoreCase')}
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_tags") ? true : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_tags")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignoreTags')}

                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                childChecked.includes("ignore_punctuation")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChange(e, "ignore_punctuation")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.ignorePunctuation')}
                                        </span>
                                    }
                                />
                            </Box>
                        </Box>
                    )}
                    <Box className={classes.childFooter}>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            {t('verify.close')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}


