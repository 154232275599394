import { gql } from '@apollo/client';

const COPY_ALL_PAGES_SEGMENTS = gql`mutation CopyAllSourceSegment($data: CopyAllSourceSegmentRequest){
    copyAllSourceSegment(data: $data){
      status
      error
  }
 
  }`;



export default COPY_ALL_PAGES_SEGMENTS;

