import { gql } from '@apollo/client';
const GET_SEGMENT_MACHINE_TRANSLATION = gql`query GetSegmentMachineTranslation($sourceSegmentId:Int!, $tl:String!, $engines: [Engine]) {
    getSegmentMachineTranslations(sourceSegmentId: $sourceSegmentId, tl: $tl, engines: $engines) {
        segmentId
        sourceText
        translatedText
        matchRate
        engine
  }
  }`;

export default GET_SEGMENT_MACHINE_TRANSLATION;