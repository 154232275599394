import { gql } from '@apollo/client';
const PROJECT_DETAIL = gql`query GetProjectDetailById($id:String!, $readonly: Int, $fileId: String, $tl: String, $service: String, $start: Int, $end: Int ) {
    getProjectDetailById(id: $id, readonly: $readonly, fileId: $fileId, tl: $tl, service: $service, start: $start, end: $end) {
      id
      sl {
        code
        name
      }
      tl {
        code
        name
      }
      services {
          code
          name
          isAssigned
      }
      files {
        filename
        url
        id
        mtConverted
      }
      settings
      mtConverted
      isMTEnabled
      engines {
          sl
          tl
          engines
      }
    }
  }`;



export default PROJECT_DETAIL;

