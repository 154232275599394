import { gql } from '@apollo/client';
const SEGMENT_HISTORY = gql`query GetSegmentHistory($sourceSegmentId:Int!, $tl:String!) {
     getSegmentHistory(sourceSegmentId: $sourceSegmentId, tl: $tl) {
    translation
    state
    user
    date
  }
  }`;



export default SEGMENT_HISTORY;

