import { gql } from '@apollo/client';

const CREATE_QUERY_LOG = gql`mutation createQueryLog($data: QueryLogRequest){
    createQueryLog(data: $data){
      status
      error
  }
 
}`;

export default CREATE_QUERY_LOG;