
const TAG = {
    START_TAG_GLOBAL: /(\{(\d+):\})/ig,
    END_TAG_GLOBAL: /(\{:(\d+)\})/ig,
    EMPTY_GLOBAL: /(\{:(\d+):\})/ig,
    ANY_TAG: /(\{[a-zA-Z\d]+:\})|(\{:[a-zA-Z\d]+\})|(\{:[a-zA-Z\d]+:\})/i,
    ANY_TAG_GLOBAL: /(\{[a-zA-Z\d]+:\})|(\{:[a-zA-Z\d]+\})|(\{:[a-zA-Z\d]+:\})/ig,
    ANY_HTML_TAG_GLOBAL: /(<.+?>)/g,
    isStartTag: (code: string) => {
        return /^(\{([a-zA-Z\d]+):\})$/ig.test(code);
    },
    isEndTag: (code: string) => {
        return /^(\{:([a-zA-Z\d]+)\})$/ig.test(code);
    },
    isEmpty: (code: string) => {
        return /^(\{:([a-zA-Z\d]+):\})$/ig.test(code);
    },
    isTag: (code: string) => {
        return /^(\{[a-zA-Z\d]+:\})|(\{:[a-zA-Z\d]+\})|(\{:[a-zA-Z\d]+:\})$/ig.test(code);
    }
}

function getLanguageDirection(text: any) {
    const rtlScriptRegex = /[\u0590-\u08FF\u200F\u202A-\u202E\uFB1D-\uFB4F\u0600-\u06FF\u0750-\u077F\u0591-\u05F4]/;
    return rtlScriptRegex.test(text) ? 'rtl' : 'ltr';
}

const handleHtmlParsedTags = (source: any) => {
    let replacedSource = source.replace(/&lbrace;/g, '{');
    replacedSource = replacedSource.replace(/&rcub;/g, '}');
    replacedSource = replacedSource.replace(/&colon;/g, ':');
    return replacedSource;
};

export const renderTags = (codeText: string) => {
    if (typeof codeText === "undefined") {
        return "";
    }
    if (codeText.length < 3) {
        return codeText;
    }

    var baseUrl = process.env.REACT_APP_ENDPOINT;
    const langDirection: any = getLanguageDirection(codeText);

    // To show spaces in source and target and restrict &nbsp; to come with <del>, <div>, and <img> tags
    codeText = codeText.replace(/(?<!<(del|div|img|mark|span)[^>]*.*?) (?![^>]*?<\/(del|div|img|mark|span)>)/g, "&nbsp;&#8203;");

    {/* To handle handle HTML Parsed Tags (&lbrace; &rcub; &colon;) */ }
    codeText = handleHtmlParsedTags(codeText);

    if (codeText.includes('{') && codeText.includes('}')) {
        if (langDirection === "ltr") {

            codeText = codeText.replace(TAG.START_TAG_GLOBAL, function (match, p1, p2) {
                // Extract the numeric part inside curly braces and keep only the first three digits
                const numericValue = p2.substring(0, 3);
                // Replace the numeric part in the match with the modified value
                const modifiedMatch = match.replace(p2, numericValue);
                return `<img alt='${modifiedMatch}' src=${baseUrl}tags-images/1?name=${numericValue}&type=1/>`;
            });

            codeText = codeText.replace(TAG.END_TAG_GLOBAL, function (match, p1, p2) {
                // Extract the numeric part inside curly braces and keep only the first three digits
                const numericValue = p2.substring(0, 3);
                // Replace the numeric part in the match with the modified value
                const modifiedMatch = match.replace(p2, numericValue);
                return `<img alt='${modifiedMatch}' src=${baseUrl}tags-images/2?name=${numericValue}&type=2/>`;
            });
        }
        else if (langDirection === "rtl") {
            codeText = codeText.replace(TAG.START_TAG_GLOBAL, function (match, p1, p2) {
                // Extract the numeric part inside curly braces and keep only the first three digits
                const numericValue = p2.substring(0, 3);
                // Replace the numeric part in the match with the modified value
                const modifiedMatch = match.replace(p2, numericValue);
                return `<img alt='${modifiedMatch}' src=${baseUrl}tags-images/2?name=${numericValue}&type=2/>`;
            });

            codeText = codeText.replace(TAG.END_TAG_GLOBAL, function (match, p1, p2) {
                // Extract the numeric part inside curly braces and keep only the first three digits
                const numericValue = p2.substring(0, 3);
                // Replace the numeric part in the match with the modified value
                const modifiedMatch = match.replace(p2, numericValue);
                return `<img alt='${modifiedMatch}' src=${baseUrl}tags-images/1?name=${numericValue}&type=1/>`;
            });
        }

        codeText = codeText.replace(TAG.EMPTY_GLOBAL, function (match, p1, p2) {
            // Extract the numeric part inside curly braces and keep only the first three digits
            const numericValue = p2.substring(0, 3);
            // Replace the numeric part in the match with the modified value
            const modifiedMatch = match.replace(p2, numericValue);
            return `<img alt='${modifiedMatch}' src=${baseUrl}tags-images/3?name=${numericValue}&type=3/>`;
        });
    }


    // if (codeText.indexOf('{') >= 0 && codeText.indexOf('}') >= 0) {

    //     codeText = codeText.replace(TAG.START_TAG_GLOBAL, "<img alt='$1' src=" + baseUrl + "/tags-images/1?name=$2&type=1/>");
    //     codeText = codeText.replace(TAG.END_TAG_GLOBAL, "<img alt='$1' src=" + baseUrl + "/tags-images/2?name=$2&type=2/>");
    //     codeText = codeText.replace(TAG.EMPTY_GLOBAL, "<img alt='$1' src=" + baseUrl + "/tags-images/3?name=$2&type=3/>");
    // }

    codeText = codeText.replace(/(<del contenteditable='false'>.*?<img .*?>)/g, (match) => {
        return `<div class='delClass'>${match}</div>`;
    })

    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = codeText;

    // Find all <del> tags with <img> tags inside
    const delTagsWithImg = tempDiv.querySelectorAll('del img');

    // Wrap each <img> with a <div class='delClass'>
    delTagsWithImg.forEach((img) => {
        const div = document.createElement('div');
        div.className = 'delClass';
        if (img.parentNode) {
            img.parentNode.insertBefore(div, img);
            div.appendChild(img);
        }
    });

    // Convert the modified DOM back to HTML
    let modifiedHTML: any = tempDiv.innerHTML;

    // Clean up the temporary div
    tempDiv.remove();

    if (codeText.indexOf('del:') >= 0) {
        modifiedHTML = modifiedHTML.replace(/\[del:]/g, "<del contenteditable='false'>").replace(/\[:del]/g, "</del>");
    }
    if (codeText.indexOf('ins:') >= 0) {
        modifiedHTML = modifiedHTML.replace(/\[ins:]/g, "<ins>").replace(/\[:ins]/g, "</ins>");
    }
    if (codeText.indexOf('em:') >= 0) {
        modifiedHTML = modifiedHTML.replace(/\[em:]/g, "<em>").replace(/\[:em]/g, "</em>");
    }
    if (codeText.indexOf('code:') >= 0) {
        modifiedHTML = modifiedHTML.replace(/\[code:]/g, "<code>").replace(/\[:code]/g, "</code>");
    }

    return modifiedHTML;
}

export default function useRenderTags() {

    return {
        renderTags: renderTags,
        TAG: TAG
    }

}