import React from 'react';
import {
    AppBar, Toolbar, Typography, Box, Button,
} from "@material-ui/core";
import * as Icons from 'src/material-ui';
import { useTranslation } from 'react-i18next';
import useStyles from './HeaderStyles';


const HomeHeader = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <AppBar className={classes.homeHeader} color="default" position="static">
                <Toolbar>

                    <Box display="flex" alignItems="center">
                        <Button color="inherit">
                            <Icons.LiveHelpIcon color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography className={classes.textStyle} component={'span'} variant="body2">{t('header.helpCenter')}</Typography>
                            </Box>
                        </Button>

                        <Button color="inherit"
                            onClick={() => {
                                const token = localStorage.getItem('access_token');
                                localStorage.removeItem('access_token');
                                let logoutUrl: any = process.env?.REACT_APP_SSO_LOGOUT_URL;
                                window.location.href = logoutUrl + token;
                            }}>
                            <Icons.SupervisedUserCircle color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography className={classes.textStyle} component={'span'} variant="body2">{t('header.logout')}</Typography>
                            </Box>
                        </Button>
                        <Box pl={3.5} ml={1.75} className={classes.logoDivider}>
                            <img src="/assets/images/Marscat.svg" alt="MarsCat" />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default React.memo(HomeHeader);