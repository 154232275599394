import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

let ThemeConfig = createTheme({

  palette: {
    type: "light",
    primary: {
      light: '#f3996b',
      main: '#F77D3D',
      dark: '#e86a28',
      contrastText: '#fff'
    },
    secondary: {
      light: '#f9d696',
      main: '#F8C567',
      dark: '#e8b453',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#ff9800'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    },
    grey: {
      100: '#fff'
    },
    text: {
      primary: '#494949',
      secondary: '#494949',
      disabled: 'rgba(0,0,0,0.38)',
      hint: 'rgba(0,0,0,0.38)'
    },
  },

  typography: {
    fontSize: 14,
    button: {
      fontSize: '0.875rem',
      textTransform: 'none',
    },
    fontFamily: [
      '"Roboto"',
      '"Arial"',
      '"Helvetica Neue"',
      '"-apple-system"',
      '"BlinkMacSystemFont"',
      '"Apple Color Emoji"',
      '"sans-serif"',

    ].join(','),
  },

  overrides: {

    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#E5E5E5'
        }
      },
    },

    MuiAppBar: {
      colorDefault: {
        color: '#494949'
      },
      root: {
        boxShadow: '0px 0px 7px rgb(0 0 0 / 15%) !important'
      },
    },

    MuiTableRow: {
      root: {
        transition: 'all .3s',
        '&:nth-child(2n+1)': {
          backgroundColor: '#FCFCFC'
        },
        '&:hover': {
          backgroundColor: '#FCF5ED',
          '& td': {
            color: '#393939'
          }
        }
      }
    },

    MuiTableHead: {
      root: {
        '& .MuiTableRow-root': {
          backgroundColor: '#E4E4E4 !important',
          '& th': {
            fontFamily: 'Roboto Medium',
            textTransform: 'uppercase'
          }
        }
      }
    },

    MuiTableCell: {
      root: {
        border: '1px solid #E0E0E0 !important',
        fontSize: 13,
        padding: '10.5px 8px',
        outline: 'none',
        wordBreak: 'break-all',
        // '&:focus':{
        //   outline: '1px solid #f77d3d !important'
        // }
      }
    },

    MuiLinearProgress: {
      determinate: {
        backgroundColor: '#f3f3f3'
      }
    },

    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#F77D3D',
          color: '#fff'
        }
      }
    },

    MuiCard: {
      root: {
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))',
        boxShadow: 'none'
      }
    },

    MuiSvgIcon: {
      root: {
        color: '#00000080'
      }
    },

    MuiFormLabel: {
      asterisk: {
        color: '#ff0000',
      },
    },

  }

});

ThemeConfig = responsiveFontSizes(ThemeConfig);

export default ThemeConfig;