import { gql } from '@apollo/client';

const DELETE_QA_REPORT = gql`mutation DeleteQAReport($id: ID){
    deleteQAReport(id: $id){
      status
      error
  }
 
  }`;

export default DELETE_QA_REPORT;