import { gql } from '@apollo/client';

const SAVE_SEGMENTS = gql`mutation SaveSegments($data:[SaveSegmentTranslationRequest]){
   saveSegmentTranslation(data: $data){
      status
      error
  }
 
  }`;



export default SAVE_SEGMENTS;

