import { createTheme, responsiveFontSizes  } from '@material-ui/core/styles';

let ThemeConfig = createTheme({
  
  palette: {
    type: "dark",
    primary: {
      light: '#f3996b',
      main: '#F77D3D',
      dark: '#e86a28',
      contrastText: '#fff'
    },
    secondary: {
      light: '#f9d696',
      main: '#F8C567',
      dark: '#e8b453',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#ff9800'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    },
    grey:{
      100:'#fff',
      50: '#212121'
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: 'rgba(0,0,0,0.38)',
      hint: 'rgba(0,0,0,0.38)'
    },
  },
  
  typography: {
    fontSize: 14,
    button: {
      fontSize: '0.875rem',
      textTransform: 'none',
    },
    fontFamily: [
      '"Roboto"',
      '"Arial"', 
      '"Helvetica Neue"',
      '"-apple-system"',
      '"BlinkMacSystemFont"',
      '"Apple Color Emoji"',
      '"sans-serif"',
    ].join(','),
  },
  
  overrides: {
    
    MuiCssBaseline: {
      '@global': {
        body:{
          backgroundColor:'#212121',
          '& .MuiTableRow-root':{
            backgroundColor: '#424242 !important',
          },
          '& .MuiTableRow-root:hover':{
            backgroundColor: '#616161 !important',
          },
          '& .MuiModal-root .MuiBox-root':{
            backgroundColor: '#424242 !important',
          },
          '& .MuiModal-root .MuiOutlinedInput-inputMarginDense':{
            backgroundColor: '#959595',
            borderRadius: '5px',
          },
          '& .MuiModal-root .MuiButton-containedPrimary .MuiButton-label':{
            color: '#ffffff'
          },
          '& .MuiModal-root .MuiButton-label':{
            color: '#212121'
          },
          '& .MuiModal-root textarea':{
            backgroundColor: '#959595',
          },
          '& .MuiTableHead-root .MuiTableRow-root':{
            backgroundColor: '#a6a6a6 !important',
          },
          '& .MuiBox-root .boxFooter':{
            backgroundColor: '#424242'
          },
          '& .MuiGrid-grid-xs-8 .customMenu':{
            backgroundColor: '#616161 !important',
          },
          '& .MuiGrid-grid-xs-8 .customMenu .menuItem':{
            color: '#ffffff !important',
          },     
          '& .MuiGrid-grid-xs-8 .customMenu .menuItem:hover':{
            backgroundColor: '#797575 !important'
          },
          '& .MuiTable-root .innerTable':{
            border: '1px solid #595959',
          },
          '& .MuiTableCell-root .selectInput .MuiSelect-root':{
            backgroundColor: '#959595 !important',
          } 
        },
        '.custom_icon':{
          color:'#fff',
          '& path':{
            fill:'#fff',
            fillOpacity:1,
          }
        },


      },
    },

    MuiButton: {
      root:{
        '& svg':{
          '& *':{
            fill: '#fff',
            fillOpacity: 1
          }
        }
      },
      text: {
        color: 'white',
        root: {
          fontSize: '1rem',
        }
      },
    },

    MuiButtonBase: {
      root:{
        '& *':{
            color:'#fff'
          }
        }
    },

    MuiAppBar:{
      colorDefault: {
        color: '#fff',
        backgroundColor: '#424242'
      },
      root:{
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)'
      },
    },

    MuiTableRow:{
      root:{
        transition: 'all .3s',
        '&:hover':{
          backgroundColor: '#616161'
        },
        '&.active':{
          backgroundColor: '#616161 !important'
        }
      }
    },

    MuiTableCell:{
      root:{
        border: '1px solid #595959 !important',
        fontSize: 13,
        padding: '10.5px 8px'
      }
    },

    MuiLinearProgress:{
      determinate:{
        backgroundColor: '#212121'
      }
    },

    MuiListItem:{
      button:{
        '&:hover':{
          backgroundColor: '#F77D3D',
          color: '#fff'
        }
      }
    },

    MuiCard:{
      root:{
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))',
        boxShadow: 'none'
      }
    },

    MuiSvgIcon:{
      root:{
        color: '#fff',
      }
    },

    MuiFormLabel: {
      asterisk: {
        color: '#ff0000',
      },
    },

  }
  
});

ThemeConfig = responsiveFontSizes(ThemeConfig);

export default ThemeConfig;