import React from 'react';
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { ScrollDialog } from 'src/material-ui';
import shortcutList from './ShortcutList';
import useStyles from './HeaderStyles';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean,
    setOpen: Function
}

interface IShortcut {
    name: string,
    key: string
}

const ShortcutModal = ({ open, setOpen }: IProps) => {
    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <ScrollDialog title={t('header.shortcut')} open={open} setOpen={setOpen} hideCloseBtn>
            <Box minWidth={600} px={3} pt={3} pb={1.5}>
                <TableContainer>
                    <Table className={classes.shortcutTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('shortcuts.function')}</TableCell>
                                <TableCell align="center">{t('shortcuts.hotKey')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shortcutList.map((val: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell>{t(val.name)}</TableCell>
                                    <TableCell>
                                        {window?.navigator.userAgent.indexOf("Mac") !== -1
                                            ? val.mkey
                                            : val.wkey}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </ScrollDialog>
    )
}

export default ShortcutModal;