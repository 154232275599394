import useRenderTags from "src/helpers/custom-hooks/RenderTags";
import { ISegment } from "src/interfaces";

async function MenuData(exec: string, temp: any, targetDivContent: any, onRightClickData: string, selected: string | number, flag: any, checkPropagation: Function, appState: any, appDispatch: Function, setNotConfirmed: any) {
  //let targetDivContent1: any = document?.getElementById('custom-editor' + selected);
  // alert(onRightClickData)
  //  let temp= translationData;
  let ret: any = [];
  const { renderTags } = useRenderTags();

  async function insertAtCursor(myField: any, myValue: any) {

    let pos: any = localStorage.getItem('cursor_position');
    const getCordinates: any = localStorage.getItem("coordinates");
    const cordinates: any = JSON.parse(getCordinates);

    if (myField.selectionStart || myField.selectionStart === '0' || pos) {

      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;

      if (pos || pos === "0") {
        startPos = pos;
        endPos = pos;
      }
      if (cordinates) {
        startPos = cordinates.start;
        endPos = cordinates.end;
        if (startPos > endPos) {
          [startPos, endPos] = [endPos, startPos];
        }
      }
      // myField.textContent = myField.textContent.substring(0, startPos)
      //   + myValue
      //   + myField.textContent.substring(endPos, myField.textContent.length);

    } else {
      myField.textContent += myValue;
    }

    await myField.focus()
    let range, selection;
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(myField);
      let caret = myField.textContent.substring(0, startPos).length + myValue.length;
      range.setStart(myField.firstChild, caret);
      range.setEnd(myField.firstChild, caret)
      selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

    }
  }

  const propagatedSegStatus = (row: any) => {
    if (row.isPropagationOn === 1) {
      // Check if there's a matching row in appState.data
      const hasMatchingRow = appState.data.some((otherRow: any) => {
        return (
          appState.selectedSegments.some((item: any) => item.id === otherRow.id) &&
          otherRow.id !== row.id &&
          otherRow.source === row.source &&
          otherRow.isPropagationOn === 1
        );
      });

      if (hasMatchingRow) {
        return true;
      }
    }
    return false;
  };

  let rowsToConfirm: any = [];

  switch (exec) {

    case 'lock':
      temp.forEach((element: ISegment) => {
        // if (element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id || propagatedSegStatus(element))) {
        if (element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) {
          element.isLocked = true;
          let editor: any = document.getElementById("custom-editor" + element.id);
          editor.contentEditable = "true";
          // const isEmpty = editor.innerHTML.trim() === "";
          const isEmpty = editor.textContent.trim() === "";

          // const hasDiv = editor.innerHTML.includes('<div>');
          const isNotDisable = !editor.innerHTML.includes('<div class="disableInput">');

          if (isNotDisable) {
            if (isEmpty) {
              editor.innerHTML = '<div class="disableInput">&nbsp;</div>';
              // element.target = '<div class="disableInput">&nbsp;</div>';
            }
            else {
              editor.innerHTML = '<div class="disableInput">' + editor.innerHTML + '</div>';
            }
          }

          // if (!hasDiv && isNotDisable) {
          //   if (isEmpty) {
          //     editor.innerHTML = '<div class="disableInput">&nbsp;</div>';
          //   } else {
          //     editor.innerHTML = '<div class="disableInput">' + editor.innerHTML + '</div>';
          //   }
          // }
          // else if (hasDiv && isNotDisable) {
          //   let str = editor.innerHTML;
          //   let output = str.replace('<div>', '<div class="disableInput">');
          //   editor.innerHTML = output;
          // }
        }

      });
      ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);

      break;

    case 'unlock':
      temp.forEach((element: ISegment) => {
        if (element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) {
          element.isLocked = false;
          let editor: any = document.getElementById("custom-editor" + element.id);
          if (editor.innerHTML.includes('<div class="disableInput">')) {
            let str = editor.innerHTML;
            let output = str.replace('<div class="disableInput">', '').replace('</div>', '');
            editor.innerHTML = output;
          }
        }
      });
      ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    case 'translated':
      temp.forEach((element: ISegment) => {
        // if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false || propagatedSegStatus(element)) {
        if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false) {
          element.state = 'translated';
          rowsToConfirm.push(element);
        }
      });

      if (rowsToConfirm.length > 0) {
        appDispatch({
          type: "CONFIRM_DATA",
          payload: rowsToConfirm,
        });
      } else {
        setNotConfirmed(true);
      }
      // ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    case 'draft':
      temp.forEach((element: any) => {
        if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false) {
          element.state = 'draft';
          element.isConfirmed = false;
        }
      });
      ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    case 'merge':
      temp.forEach((element: ISegment, index: number) => {
        if (element.id === selected && element.isLocked === false) {
          if (index + 1 < temp?.length) {
            let next_t = element.target + ' ' + temp[index + 1].target;
            let next_s = element.source + ' ' + temp[index + 1].source;
            element.target = next_t;
            element.source = next_s;
            temp = temp.filter((item: any) => item.id !== temp[index + 1].id);

            if (targetDivContent?.innerHTML) {
              targetDivContent.innerHTML =
                // '<span contenteditable class="editorjs"> ' + 
                renderTags(next_t)
              // + ' </span>';

            }
            ret = temp;
            setTimeout(() => {
              appDispatch({ type: "RESET_HISTORY" })
            }, 500);
          }
        }
      });
      let time_go = setTimeout(() => {
        checkPropagation();
        clearTimeout(time_go);
      }, 800);
      break;

    // case 'extendSelection':
    //   temp.forEach((element: any) => {
    //     if (element.id === selected) {
    //       const node = document.getElementById('custom-editor' + selected);
    //       if (node) {
    //         const selection: any = window.getSelection();
    //         const range = document.createRange();
    //         range.selectNodeContents(node);
    //         selection.removeAllRanges();
    //         selection.addRange(range);
    //       }
    //     }
    //   });
    //   break;   

    case 'extendSelection':
      temp.forEach((element: any) => {
        if (element.id === selected) {
          const node: any = document.getElementById('custom-editor' + selected);
          if (node) {
            const selection: any = window.getSelection();
            if (selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const commonAncestor = range.commonAncestorContainer;
              if (node.contains(commonAncestor)) {
                const newRange = document.createRange();
                newRange.selectNodeContents(commonAncestor);
                selection.removeAllRanges();
                selection.addRange(newRange);
              }
              // else is the case when user selects extend selection from right click menu :(
              // else {
              //   let pos: any = localStorage.getItem('cursor_position');
              //   const position: any = JSON.parse(pos);
              //   const getCordinates: any = localStorage.getItem("coordinates");
              //   const cordinates: any = JSON.parse(getCordinates);
              //   let startPos = node.selectionStart;

              //   if (pos || pos === "0") {
              //     startPos = pos;
              //   }
              //   if (cordinates) {
              //     startPos = cordinates.start;
              //   }

              //   setTimeout(() => {
              //     node.focus();
              //   }, 500);

              //   let range, selection;
              //   if (document.createRange) {
              //     range = document.createRange();
              //     range.selectNodeContents(node);
              //     let caret = node.textContent.substring(0, startPos).length;
              //     range.setStart(node.firstChild, caret);
              //     range.setEnd(node.firstChild, caret)
              //     selection = window.getSelection();
              //     selection?.removeAllRanges();
              //     selection?.addRange(range);
              //   }
              // }
            }
          }
        }
      });
      break;

    case 'next_text_range':
      temp.forEach((element: any, index: number) => {
        if (element.id === selected) {
          if (index + 1 < temp?.length) {
            let ele: any = document.getElementById('custom-editor' + temp[index + 1]?.id);
            ele.focus();
            const offsetTop = ele.offsetTop;
            window.scrollTo({
              top: offsetTop,
              behavior: 'smooth'
            });
          }
          appDispatch({ type: "SET_SELECTED", payload: temp[index + 1]?.id });
        }
      });
      break;

    case 'approved':
      temp.forEach((element: any) => {
        if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false) {
          element.state = 'approved';
        }
      });
      ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    case 'rejected':
      temp.forEach((element: any) => {
        if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false) {
          element.state = 'rejected';
          element.isConfirmed = false;
        }
      });
      ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    case 'reviewed':
      temp.forEach((element: any) => {
        // if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id) || propagatedSegStatus(element)) && element.isLocked === false) {
        if ((element.id === selected || appState.selectedSegments.some((item: any) => item.id === element.id)) && element.isLocked === false) {
          element.state = 'reviewed';
          rowsToConfirm.push(element);
        }
      });

      if (rowsToConfirm.length > 0) {
        if (rowsToConfirm.length > 10) {
          appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
        }

        appDispatch({
          type: "CONFIRM_DATA",
          payload: rowsToConfirm,
        });
      } else {
        setNotConfirmed(true);
      }

      // ret = temp;
      setTimeout(() => {
        appDispatch({ type: "RESET_HISTORY" })
      }, 500);
      break;

    default:
      break;
  }
  return ret;
}

export default MenuData;