import { IAppState } from "src/interfaces";
const TargetKeyDown = (
  event: any,
  appDispatch: any,
  appState: IAppState,
  flag: any,
  menuData: Function,
  setOpen: Function,
  setToggle: Function,
  mergeSegment: any,
  getCurrentSegment: any,
  setRestrictSave: any,
  setSnackBarOpen: any,
  setNotConfirmed: any,
  headerData: any,
  setMTAlert: any,
  changeTab: Function,
  setValue: Function,
  setTargetValue: Function
) => {
  var code = event?.keyCode;

  if (code === 77 && (event?.ctrlKey || event.metaKey)) {
    event?.preventDefault();
    setRestrictSave(true);
    if (getCurrentSegment().length > 0) {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
      localStorage.setItem("current_segment", JSON.stringify(appState.selectedRow.id));
      mergeSegment();
    }
    else {
      setSnackBarOpen(true)
    }
  }

  if (code === 73 && (event?.ctrlKey || event.metaKey)) {
    event.preventDefault();
    appDispatch({
      type: "COPY",
      payload: appState,
    });

    let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
    appDispatch({
      type: "ADD_HISTORY",
      payload: {
        id: appState.selectedRow.id,
        history: rowHtml.innerHTML,
        state: appState.selectedRow.state,
        tStatusType: appState.selectedRow.tStatusType,
        tStatusValue: appState.selectedRow.tStatusValue,
        isCopy: 1
      },
    });
  }

  if (code === 68 && (event?.ctrlKey || event.metaKey)) {
    event?.preventDefault();


    let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
    let rowVal = rowHtml.innerHTML;
    let rowState = appState.selectedRow.state;

    appDispatch({
      type: "CLEAR",
      payload: appState,
    });

    appDispatch({
      type: "ADD_HISTORY",
      payload: {
        id: appState.selectedRow.id,
        history: rowVal == "" ? " " : rowVal,
        state: rowState,
        tStatusType: appState.selectedRow.tStatusType,
        tStatusValue: appState.selectedRow.tStatusValue,
        isClear: 1
      },
    });
  }

  if (code === 114) {
    event?.preventDefault();
    const text: any = window.getSelection()?.toString();
    appDispatch({
      type: "CONCORDANCE_SEARCH",
      payload: { flag, text },
    });
  }

  // Insert Tag F8
  // if (code === 119) {
  //   event?.preventDefault();
  //   appDispatch({
  //     type: "SHOW_CODE", payload: false,
  //   });
  // }

  // Tab
  if (code === 9) {
    event?.preventDefault();
    menuData("next_text_range");
  }

  // Extend Selection (Ctrl + E)
  if ((event?.ctrlKey || event.metaKey) && code === 69) {
    event?.preventDefault();
    menuData("extendSelection");
  }

  const refreshFocus = (id: any) => {
    const divToFocus = document.getElementById('custom-editor' + id);
    if (divToFocus) {
      setTimeout(() => {
        divToFocus.blur();
        divToFocus.focus();
      }, 100);
    }
  };

  // Apply Machine Transition F1
  if (code === 112) {
    event?.preventDefault();
    if (headerData.isMTEnabled) {
      menuData('machineTranslation');
    } else {
      setMTAlert(true);
    }
  }

  // Lock 
  if (code === 76 && (event?.ctrlKey || event.metaKey)) {
    event?.preventDefault();
    menuData("lock");
    refreshFocus(appState.selected)
  }

  // Unlock
  if (code === 75 && (event?.ctrlKey || event.metaKey)) {
    event?.preventDefault();
    menuData("unlock");
    refreshFocus(appState.selected)
  }

  if (code === 13 && (event?.ctrlKey || event.metaKey) && appState?.user_type?.translate === false) {
    event?.preventDefault();
    const selectedRows = appState.selectedSegments.length > 1
      ? appState.selectedSegments
      : [appState.selectedRow];

    if (appState.selectedSegments.length > 0) {
      appDispatch({
        type: "CONFIRM_DATA",
        payload: selectedRows,
      });
    } else {
      setNotConfirmed(true);
    }
  }

  if (code === 13 && (event?.altKey || event.metaKey) && appState?.user_type?.translate === false) {
    event?.preventDefault();
    menuData("reviewed");
  }

  if (code === 80 && (event?.altKey || event.metaKey)) {
    event?.preventDefault();
    menuData("rejected");
  }

  if (code === 65 && (event?.altKey || event.metaKey) && appState?.user_type?.translate === false) {
    event?.preventDefault();
    menuData("approved");
  }

  if (code === 120 && (event?.ctrlKey || event.metaKey) && appState?.user_type?.translate === false) {
    event?.preventDefault();
    appDispatch({
      type: "HANDLE_TRACK_CHANGES",
      payload: { isAccept: true, isMove: true, toAll: false, data: appState.data }
    });
  }

  if (code === 120 && (event?.altKey || event.metaKey) && appState?.user_type?.translate === false) {
    event?.preventDefault();
    appDispatch({
      type: "HANDLE_TRACK_CHANGES",
      payload: { isAccept: false, isMove: true, toAll: false, data: appState.data }
    });
  }

  if (code === 115) {
    event?.preventDefault();
    const text: any = window.getSelection()?.toString();
    appDispatch({
      type: "ADD_NEW_TERM",
      payload: { flag, text },
    });
    setToggle({ seg: "tm|tb", id: '', textInput: '' });
  }

  // Add Notes (QueryLog, Alt + M)
  if (code === 77 && (event?.altKey || event.metaKey)) {
    event?.preventDefault();
    changeTab(appState.selectedSegments.length > 1 ? 0 : appState.selectedRow.id);
    setTimeout(() => {
      document.getElementById("qlText")?.focus();
    }, 500);
  }

  // Find and Replace (Alt + S)
  if (code === 83 && (event?.altKey || event.metaKey)) {
    event?.preventDefault();
    const text: any = window.getSelection()?.toString();
    const searchField: any = document.getElementById(flag === 't' ? "searchTargetText" : "searchSourceText")
    if (searchField) {
      flag === 't' ? setTargetValue(text) : setValue(text)
      // searchField?.focus();
    }
    appDispatch({ type: "REPLACE", payload: appState.expandReplace ? false : true });
  }

  // Related Search (Alt + F)
  if (code === 70 && (event?.altKey || event.metaKey)) {
    event?.preventDefault();
    const text: any = window.getSelection()?.toString();
    const searchField: any = document.getElementById(flag === 't' ? "searchTargetText" : "searchSourceText")
    if (searchField) {
      flag === 't' ? setTargetValue(text) : setValue(text)
      // searchField?.focus();
    }
  }
};
export default TargetKeyDown;