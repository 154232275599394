import { gql } from '@apollo/client';
const GET_PUBLIC_QUERY_LOGS = gql`query GetPublicQueryLogs($projectId: String!, $segmentId: String) {
    getPublicQueryLogs(projectId: $projectId, segmentId: $segmentId)
     {
      projectName
      logs{
        id
        segmentId
        sourceText
        content
        user
        fileName
        createdAt
        answers{
            id
            answer
            username
            createdAt
        }
      }        
    }
  }`;

export default GET_PUBLIC_QUERY_LOGS;