import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Typography, Button, TextField, LinearProgress, Theme } from '@material-ui/core';
import useStyles from "../../pages/projects/ProjectStyles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { useNavigate } from 'react-router-dom';
import GET_ALL_PROJECTS from "src/helpers/queries/GetAllProjects";
import { useLazyQuery } from "@apollo/client";
import PROJECT_DETAIL from "../../helpers/queries/ProjectDetailsById";

const ProjectList = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [GETALLPROJECTS, { data, error }] = useLazyQuery(
        GET_ALL_PROJECTS
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function createData(id: string, name: string, targetLanguage: string, progress: any, createdAt: string, action: any) {
        return { id, name, targetLanguage, progress, createdAt, action };
    }

    const BorderLinearProgress = withStyles((theme: Theme) =>
        createStyles({
            root: {
                height: 10,
                borderRadius: 5,
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius: 5,
                backgroundColor: '#76d677',
            },
        }),
    )(LinearProgress);

    // const rows = [
    //     createData('056864', 'testing 001', 'English', <BorderLinearProgress variant="determinate" value={50} />, '2022-11-03 13:00', ''),
    //     createData('056864', 'testing 002', 'English', <BorderLinearProgress variant="determinate" value={50} />, '2022-11-03 13:00', ''),
    //     createData('056864', 'testing 003', 'English', <BorderLinearProgress variant="determinate" value={50} />, '2022-11-03 13:00', ''),
    //     createData('056864', 'testing 004', 'English', <BorderLinearProgress variant="determinate" value={50} />, '2022-11-03 13:00', ''),
    //     createData('056864', 'testing 005', 'English', <BorderLinearProgress variant="determinate" value={50} />, '2022-11-03 13:00', ''),
    // ];

    // Pagination

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleButtonClick() {
        navigate('/create-project');
    }

    function handleOpenProject(projectId: any) {
        navigate('/home?projectId=' + projectId);
    }

    const dateFormat = (row: any) => {
        const date = new Date(parseInt(row.date));
        const dateString = date.toDateString(); // format the date into a string
        return <div>{dateString}</div>;
    }

    useEffect(() => {
        GETALLPROJECTS({
            variables: {
                page: page + 1
            }
        });
    }, [page]);

    return (<>
        <Box className={classes.projectList}>
            <Box px={8} py={8}>

                <TableContainer component={Paper}>
                    <Typography className={classes.projectHeading}>
                        Projects
                    </Typography>

                    <Box px={'24px'} pb={'40px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                            {/*<Button onClick={handleButtonClick} className={classes.AddBtn} size="small" variant="contained" color="primary">*/}
                            {/*New*/}
                            {/*</Button>*/}

                            {/*<Button className={classes.exportBtn} size="small" variant="outlined" color="primary">*/}
                            {/*Export Records*/}
                            {/*</Button>*/}
                        </Box>

                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            <TextField
                                className={classes.projectFeilds}
                                id="outlined-size-small"
                                placeholder="Project ID"
                                variant="outlined"
                                size="small"
                            />

                            {/*<TextField*/}
                            {/*className={classes.projectFeilds}*/}
                            {/*id="outlined-size-small"*/}
                            {/*placeholder="Project Name"*/}
                            {/*variant="outlined"*/}
                            {/*size="small"*/}
                            {/*/>*/}

                            {/*<Button*/}
                            {/*aria-controls="customized-menu"*/}
                            {/*aria-haspopup="true"*/}
                            {/*variant="contained"*/}
                            {/*className={classes.projectMenu}*/}
                            {/*onClick={handleClick}*/}
                            {/*size='small'*/}
                            {/*>*/}
                            {/*Open Menu*/}
                            {/*</Button>*/}
                            {/*<Menu*/}
                            {/*id="customized-menu"*/}
                            {/*anchorEl={anchorEl}*/}
                            {/*keepMounted*/}
                            {/*open={Boolean(anchorEl)}*/}
                            {/*onClose={handleClose}*/}
                            {/*elevation={0}*/}
                            {/*getContentAnchorEl={null}*/}
                            {/*className={classes.menuStyles}*/}
                            {/*anchorOrigin={{*/}
                            {/*vertical: 'bottom',*/}
                            {/*horizontal: 'center',*/}
                            {/*}}*/}
                            {/*transformOrigin={{*/}
                            {/*vertical: 'top',*/}
                            {/*horizontal: 'center',*/}
                            {/*}}*/}
                            {/*>*/}
                            {/*<MenuItem>*/}
                            {/*<ListItemText primary="Open" />*/}
                            {/*</MenuItem>*/}

                            {/*<MenuItem>*/}
                            {/*<ListItemText primary="Closed" />*/}
                            {/*</MenuItem>*/}
                            {/*</Menu>*/}

                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Button variant="contained" className={classes.filterBtn} size="small" color="primary">
                                    Filter
                                </Button>

                                {/*<Button className={classes.queryBtn} size="small" variant="contained" color="primary">*/}
                                {/*Query*/}
                                {/*</Button>*/}
                            </Box>
                        </Box>
                    </Box>

                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>ID</TableCell>
                                <TableCell className={classes.tableCell}>Name</TableCell>
                                <TableCell className={classes.tableCell}>Source Language</TableCell>
                                <TableCell className={classes.tableCell}>Target Languages</TableCell>
                                <TableCell className={classes.tableCell}>Services</TableCell>
                                <TableCell className={classes.tableCell}>Progress</TableCell>
                                <TableCell className={classes.tableCell}>Created At</TableCell>
                                <TableCell className={classes.tableCell}>Actions</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.getAllProjects?.projects.map((row: any) => (
                                <TableRow key={row.name}>
                                    <TableCell className={classes.tableContent} component="th" scope="row">{row.id}</TableCell>
                                    <TableCell className={classes.tableContent}>{row.name}</TableCell>
                                    <TableCell className={classes.tableContent}>{row.sl.name}</TableCell>
                                    <TableCell className={classes.tableContent}>{row.tl.map((it: any) => it.name).join(', ')}</TableCell>
                                    <TableCell className={classes.tableContent}>{row.services.map((it: any) => it.name).join(', ')}</TableCell>
                                    <TableCell className={classes.tableContent}>{<BorderLinearProgress variant="determinate" value={50} />}</TableCell>
                                    <TableCell className={classes.tableContent}>{row.date}</TableCell>
                                    <TableCell className={classes.tableContent}>
                                        {row.isAssigned == 1 ?
                                            <Button
                                                size="small"
                                                onClick={() => handleOpenProject(row.id)}
                                                className={classes.resetBtn}
                                                variant="outlined"
                                                color="primary">
                                                Translate
                                            </Button>
                                            : <Button
                                                size="small"
                                                onClick={() => handleOpenProject(row.id)}
                                                className={classes.resetBtn}
                                                variant="outlined"
                                                color="primary">
                                                View
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <TablePagination
                            component="div"
                            count={data?.getAllProjects?.total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    </>);
}

export default ProjectList;