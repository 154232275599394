import React, { useState, useRef, useEffect, MouseEvent, useReducer } from 'react';
import {
    AppBar, Toolbar, Typography, Box, Button, LinearProgress, Fade, MenuItem, Menu, IconButton, Snackbar
} from "@material-ui/core";
import * as Icons from 'src/material-ui';
import { Trans, useTranslation } from 'react-i18next';
import SelectLanguage from './SelectLanguage';
import SelectDocument from './SelectDocument';
import useStyles from './HeaderStyles';
import SettingModal from './SettingModal';
import ShortcutModal from './ShortcutModal';
import { IGetProjectDetailById } from 'src/interfaces';
import { useLazyQuery, useMutation } from '@apollo/client';
import GET_FILESEGMENTS_BY_LANGUAGE from 'src/helpers/queries/GetFileSegBySrcLang';
import AppReducer, { initialState } from "../../AppReducer";
import { useNavigate } from 'react-router-dom';
import GET_CURRENT_USER_DETAILS from "src/helpers/queries/GetCurrentUserDetails";
import RefreshIcon from '@material-ui/icons/Refresh';
import GET_FILE_PROGRESS from 'src/helpers/queries/GetFileProgress';
import SUBMIT from 'src/helpers/mutations/submit';
import { Alert } from '@material-ui/lab';
import PROJECT_DETAIL from 'src/helpers/queries/ProjectDetailsById';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logout from 'src/helpers/custom-hooks/Logout';
import LOGOUT from 'src/helpers/mutations/logout';
import SubmitModal from './SubmitModal';
import { handleErrors } from 'src/helpers/errorHandler';
import CustomSnackbar from '../custom-snackbar/CustomSnackbar';
import useErrorHandler from 'src/helpers/custom-hooks/UseErrorHandler';

interface IProps {
    setThemeType: Function,
    themeType: string,
    appDispatch: Function,
    actionDispatch: Function,
    appState: any,
    headerData: IGetProjectDetailById,
    projectId: string
    currentPage: number,
    userType: any,
    rowsPerPage: any,
    verify: any,
}

const Header = ({ setThemeType, themeType, appDispatch, appState, headerData, projectId, currentPage, userType, actionDispatch, rowsPerPage, verify }: IProps) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElLogout, setAnchorElLogout] = useState<null | HTMLElement>(null);
    const [isSelectDoc, setIsSelectDoc] = useState(false);
    const [isSetLang, setIsSetLang] = useState(false);


    const { t } = useTranslation();

    const [openSetting, setOpenSetting] = useState(false);
    const [openShortcut, setOpenShortcut] = useState(false);
    const [open, setOpen] = useState(false);
    const [fileName, setFileName] = useState((headerData?.files && headerData?.files.length > 0) ? headerData?.files[0]?.filename : '');
    const [toggleProgress, setToggleProgress] = useState(false);
    const [submitAlert, setSubmitAlert] = useState(false);
    const [showSubmitError, setShowSubmitError] = useState(false);
    const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
    const [submittionConfirmed, setSubmittionConfirmed] = useState(false);
    const [forceSubmit, setForceSubmit] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const navigate = useNavigate();

    const [getCurrentUserDetails, { data: userData, error: userError }] = useLazyQuery(
        GET_CURRENT_USER_DETAILS
    );

    const [GetProjectDetailById, { data: projectSettingsData, error: peojectSettingsError }] = useLazyQuery(
        PROJECT_DETAIL,
    );

    const [logoutMutation, { data: logoutData, loading }] = useMutation(LOGOUT, {
        errorPolicy: 'all',
        variables: {
            token: localStorage.getItem("access_token")
        },
        onCompleted: (data: any) => {
            if (logoutData) {
                logout();
            }
        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const getQueryParam = (parameterName: any) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(parameterName);
    };

    const urlLangVal = getQueryParam('tl');

    const startVal = getQueryParam('start');

    const endVal = getQueryParam('end');

    const roVal = getQueryParam('ro');

    let type = getQueryParam('type');

    {/* For sending start and end URL params to SUBMIT Query*/ }

    let startParam: any = null;
    let endParam: any = null;

    if (typeof startVal != "undefined" && startVal) {
        startParam = parseInt(startVal);
    }
    if (typeof endVal != "undefined" && endVal) {
        endParam = parseInt(endVal);
    }

    const [submit, { data: submitData, loading: loadingSubmit }] = useMutation(SUBMIT, {
        errorPolicy: 'all',
        variables: {
            data: {
                projectId: headerData?.id,
                fileId: "" + appState.selectedFile.id,
                tl: appState.selectedLanguage,
                service: appState.selectedService.code,
                submittionConfirmed: submittionConfirmed,
                start: startParam,
                end: endParam,
                forceSubmit: forceSubmit
            }
        },
        onCompleted: (data: any) => {
            if (data.submit.error !== "") {
                if (data.submit.code === 2) {
                    setOpenSubmit(true);
                    setShowSubmitError(data.submit.error);
                } else {
                    setShowSubmitError(data.submit.error);
                    setSubmitAlert(true);
                }
            } else {
                if (submittionConfirmed == false) {
                    setSubmittionConfirmed(true);
                    submit();
                } else {
                    setSubmittionConfirmed(false);
                    setShowSubmitSuccess(true);
                    window.location.reload();
                }
                setForceSubmit(false);
            }

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [GetFileSegmentsByLanguage, { data, error }] = useLazyQuery(GET_FILESEGMENTS_BY_LANGUAGE, {
        fetchPolicy: 'no-cache',
        onCompleted: (result) => {
            if (appState.showCode) {
                setTimeout(() => {
                    appDispatch({
                        type: 'SHOW_CODE',
                        payload: appState.showCode,
                    })
                }, 1500);
            }
            setTimeout(() => {
                appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            }, 500);
        },
    });

    const [GetFileProgress, { data: progressData, error: progressError }] = useLazyQuery(GET_FILE_PROGRESS, { fetchPolicy: 'no-cache' });

    const navigatePtojectList = () => {
        navigate('/');
    };

    useEffect(() => {
        let themeValues: any = localStorage.getItem('themeValues');
        if (themeValues) {
            themeValues = JSON.parse(themeValues);
            const size = themeValues.size
            const panelSize = themeValues.panelSize
            setTimeout(() => {
                appDispatch({
                    type: 'SET_FONT_SIZE',
                    payload: { size, panelSize }
                })
            }, 3500);
        } else {
            localStorage.setItem('themeValues', JSON.stringify({ size: 14, panelSize: 14, mode: themeType, }));
        }
    }, []);

    useEffect(() => {
        document.body.addEventListener("click", () => {
            if (document.body.classList.contains("open")) {
                document.body.classList.remove("fixed-position");
                document.body.classList.remove("open");
            }
        });
    }, []);

    useEffect(() => {
        if (!openSetting || !openShortcut || !anchorEl || !isSelectDoc || !isSetLang || !anchorElLogout) {
            setTimeout(function () {
                document.body.removeAttribute("style");
                document.body.classList.remove("fixed-position");
                document.body.classList.remove("open")
            }, 1);
        }
        if (openSetting || openShortcut || anchorEl || isSelectDoc || isSetLang || anchorElLogout) {
            setTimeout(function () {
                document.body.removeAttribute("style");
                document.body.classList.add("fixed-position");
                document.body.classList.add("open")
            }, 1);
        }
    }, [openSetting, openShortcut, anchorEl, isSelectDoc, isSetLang, anchorElLogout]);

    let [fileId, setFileId] = useState(headerData?.files[0]?.id);

    let langVal = headerData.tl.find((item: any) => item.code == urlLangVal);
    if (!langVal || typeof langVal == "undefined") {
        langVal = headerData?.tl[0];
    }

    let role: any = getQueryParam('role');

    const [currentRole, setCurrentRole]: any = useState(role);

    useEffect(() => {
        if (role === "translator" || role === "PM") {
            localStorage.setItem("role", role);
            setCurrentRole(role);
        }
        else {
            let prevRole = localStorage.getItem("role")
            setCurrentRole(prevRole);
        }
    }, []);

    useEffect(() => {
        const urlFileVal = getQueryParam('fileId');
        if (urlFileVal) {
            setFileId(+urlFileVal);
        }

        getCurrentUserDetails({
            context: {
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": 'Bearer ' + localStorage.getItem('access_token')
                }
            }
        });

        let start = null;
        let end = null;


        if (typeof startVal != "undefined" && startVal) {
            start = parseInt(startVal);
        }
        if (typeof endVal != "undefined" && endVal) {
            end = parseInt(endVal);
        }

        GetProjectDetailById({
            variables: {
                id: headerData?.id,
                readonly: roVal == "1" ? 1 : 0,
                fileId: fileId + "",
                tl: urlLangVal ? urlLangVal : "",
                service: type?.toLowerCase(),
                start: start,
                end: end
            }
        });
    }, []);

    useEffect(() => {
        if ((headerData?.files && headerData?.files.length > 0)) {
            setFileName(headerData?.files.find((it: any) => it.id == fileId)?.filename);

            appDispatch({ type: "SELECTED_FILE", payload: headerData?.files.find((it: any) => it.id == fileId) });
        }
    }, [headerData, fileId]);

    useEffect(() => {
        appDispatch({ type: "SET_CURRENT_USER", payload: userData?.getCurrentUserDetails });
    }, [userData]);

    useEffect(() => {
        appDispatch({ type: "SET_PROJECT_SETTINGS", payload: projectSettingsData?.getProjectDetailById?.settings });
    }, [projectSettingsData]);

    const [targetLang, setTargetLang] = useState(langVal?.code);

    const [start, setStart] = useState(null);

    const [end, setEnd] = useState(null);

    useEffect(() => {
        if (fileId && targetLang) {
            if (appState.searchFilters?.queryExecute) {
                // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
                if (typeof targetLang !== "undefined") {
                    const rightLang = ['arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi', 'ar'];
                    const lang = targetLang.split("-");
                    const direction = rightLang.find((item: any) => item === lang[0]) ? "rtl" : "ltr";
                    localStorage.setItem('language', direction);
                }

                let start = null;
                let end = null;


                if (typeof startVal != "undefined" && startVal) {
                    start = parseInt(startVal);
                }
                if (typeof endVal != "undefined" && endVal) {
                    end = parseInt(endVal);
                }

                GetFileSegmentsByLanguage({
                    variables: {
                        fileId: fileId,
                        projectId: projectId,
                        tl: targetLang,
                        perPage: rowsPerPage,
                        currentPage: currentPage,
                        source: appState.searchFilters?.source,
                        target: appState.searchFilters?.target,
                        caseSensitive: appState.searchFilters?.case,
                        segmentFilters: appState.segmentFilters,
                        start: start,
                        end: end
                    }
                })
            }
        }
    }, [fileId, rowsPerPage, currentPage, targetLang, appState.searchFilters, appState.searchFilters?.queryExecute, verify]);

    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted

        const fetchFileProgress = async () => {
            if (fileId && targetLang) {
                let start = null;
                let end = null;

                if (typeof startVal !== "undefined" && startVal) {
                    start = parseInt(startVal, 10);
                }
                if (typeof endVal !== "undefined" && endVal) {
                    end = parseInt(endVal, 10);
                }

                await GetFileProgress({
                    variables: {
                        projectId: projectId,
                        fileId: fileId,
                        tl: targetLang,
                        start: start,
                        end: end
                    }
                });
            }

            if (isMounted) {
                setToggleProgress(prevToggle => !prevToggle);
            }
        };

        fetchFileProgress();

        const interval = setInterval(() => {
            if (isMounted) {
                fetchFileProgress();
            }
        }, 20000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [fileId, targetLang, startVal, endVal, projectId, appState.selectedService.code]);

    {/* submit modal useEffect */ }

    useEffect(() => {
        if (!openSubmit && forceSubmit) {
            submit();
        }
    }, [openSubmit])

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickLogout = (event: MouseEvent<HTMLElement>) => {
        setAnchorElLogout(event.currentTarget);
    };

    const handleCloseLogout = () => {
        setAnchorElLogout(null);
    };

    const urlServiceVal = getQueryParam('type');

    // const startVal = getQueryParam('start');
    // const endVal = getQueryParam('end');

    let serviceVal = headerData.services.find((item: any) => item.name.toLowerCase() == urlServiceVal);
    if (!serviceVal || typeof serviceVal == "undefined") {
        serviceVal = headerData?.services[0];
    }

    const [service, setService] = useState(serviceVal);
    const currentStatus = appState.selectedService.code === "translation" ? t('common.translated') : t('common.reviewed');
    const anchorRef = useRef<HTMLButtonElement>(null); // currentPage
    const handleToggle = () => {
        setOpen((prevOpen: any) => !prevOpen);
    };

    const handleCloseFile = (event: React.MouseEvent<EventTarget>, name: string, item: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setFileName(name);
        setFileId(item.id);

        navigate(`/home?projectId=${projectId}&tl=${langVal.code}&type=${urlServiceVal}&fileId=${item.id}&role=${currentRole}&start=${startVal}&end=${endVal}`);
    };

    const [firstTimeLoad, setFirstTimeLoad] = useState(false);

    let startV = 0;
    let endV = 0;

    if (typeof startVal != "undefined" && startVal) {
        startV = parseInt(startVal);
    }
    if (typeof endVal != "undefined" && endVal) {
        endV = parseInt(endVal);
    }

    useEffect(() => {
        appDispatch({ type: "SELECTED_SERVICE", payload: service, pagination: null });
        if (!firstTimeLoad) {
            setFirstTimeLoad(true);
        } else {
            if (startVal && endVal) {
                navigate(`/home?projectId=${projectId}&tl=${langVal.code}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}&start=${startV}&end=${endV}`);
            } else {
                navigate(`/home?projectId=${projectId}&tl=${langVal.code}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}`);
            }
        }
    }, [service, currentRole]);

    useEffect(() => {
        appDispatch({ type: "SELECTED_LANGUAGE", payload: targetLang, pagination: null });
        if (!firstTimeLoad) {
            setFirstTimeLoad(true);
        } else {
            if (startVal && endVal) {
                navigate(`/home?projectId=${projectId}&tl=${targetLang}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}&start=${startV}&end=${endV}`);
            } else {
                navigate(`/home?projectId=${projectId}&tl=${targetLang}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}`);
            }
        }
    }, [targetLang, currentRole]);


    useEffect(() => {
        if (!firstTimeLoad) {
            setFirstTimeLoad(true);
        } else {
            if (startVal && endVal) {
                navigate(`/home?projectId=${projectId}&tl=${targetLang}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}&start=${startV}&end=${endV}&ro=${(roVal == "1") ? 1 : 0}`);
            } else {
                navigate(`/home?projectId=${projectId}&tl=${targetLang}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}&ro=${(roVal == "1") ? 1 : 0}`);
            }
        }
    }, [roVal]);

    useEffect(() => {
        if (!firstTimeLoad) {
            setFirstTimeLoad(true);
        } else {
            if (startVal && endVal) {
                navigate(`/home?projectId=${projectId}&tl=${targetLang}&type=${service.name.toLowerCase()}&fileId=${fileId}&role=${currentRole}&start=${parseInt(startVal)}&end=${parseInt(endVal)}`);
            }
        }
    }, [startVal, endVal]);

    const selectService = (val: any) => {
        setService(val);
        // setTargetLang(val?.code);
        handleClose();
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const status = [
        {
            name: t('common.translated'),
            value: progressData?.getFileProgress?.totalTranslated,
            class: `${classes.translatedProgress}`
        },
        {
            name: t('common.reviewed'),
            value: progressData?.getFileProgress?.totalReviewed,
            class: `${classes.reviewProgress} ${classes.root}`
        },
        {
            name: t('filters.approved'),
            value: 180,
            class: `${classes.approvedProgess} ${classes.root}`
        },
    ];

    useEffect(() => {
        appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });
    }, [data]);

    const showProgressBar = () => {
        let index = status.findIndex((x: any) => x.name === currentStatus);
        let segmentValue = status[index].value;
        let statusClass = status[index].class;
        if (progressData) {
            let segmentPercentage = appState.selectedService.code === "translation" ? progressData.getFileProgress?.percentTranslated : progressData.getFileProgress?.percentReviewed;

            return <Box width={250} ml={5}>
                <Box className={classes.progressStatus}>
                    <Typography component={'span'} variant="body2"
                        className={classes.progressTextStyle}>{currentStatus}</Typography>
                    <Typography component={'span'} variant="body2"
                        className={classes.progressTextStyle}>
                        <IconButton onClick={() => setToggleProgress(!toggleProgress)} className={classes.refreshBtn}>
                            <RefreshIcon className={classes.refreshIcon} />
                        </IconButton>{segmentValue}/{progressData?.getFileProgress?.totalSegments} {t('common.segments')}
                    </Typography>
                </Box>
                {segmentPercentage ?
                    <LinearProgress className={statusClass} color="primary" variant="determinate" value={segmentPercentage} />
                    : segmentPercentage === 0 ?
                        < LinearProgress className={statusClass} color="primary" variant="determinate" value={segmentPercentage} />
                        : ''
                }
                {progressData?.getFileProgress?.totalSegments !== progressData?.getFileProgress?.totalSegmentsOriginal &&
                    <Box className={classes.loadingProgressStatus}>
                        <Typography component={'span'} variant="body2"
                            className={classes.loadingStyle}>Loading Segments...</Typography>
                        <Typography component={'span'} variant="body2"
                            className={classes.loadingSegStyle}>
                            {progressData?.getFileProgress?.totalSegments}/{progressData?.getFileProgress?.totalSegmentsOriginal}
                        </Typography>
                    </Box>
                }

            </Box>;
        } else {
            return <Box width={250} ml={8.5}>
                <Box className={classes.progressStatus}>
                    <Typography component={'span'} variant="body2"
                        className={classes.progressTextStyle}>{currentStatus}</Typography>
                </Box>
            </Box>;

        }

    };

    const handleProjectSubmit = () => {
        // setOpenSubmit(true);
        submit();
    };

    if (error) {
        return <div>
            <h1 style={{ color: 'red' }} >{t('common.error')} {error?.message}</h1>
        </div>
    }

    const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmitAlert(false);
        setShowSubmitSuccess(false);
    };

    return (
        <>
            <AppBar className={classes.header} color="default" position="static">
                <Toolbar>
                    <Box display="flex" alignItems="center">
                        <SelectDocument
                            data={headerData?.files}
                            chooseFile={handleCloseFile}
                            setIsSelectDoc={setIsSelectDoc}
                            role={currentRole}
                        />

                        <SelectLanguage
                            appDispatch={appDispatch}
                            languageArray={headerData?.tl}
                            data1={data}
                            targetLang={targetLang}
                            fileName={fileName}
                            setTargetLang={setTargetLang}
                            rowsPerPage={rowsPerPage}
                            setIsSetLang={setIsSetLang}
                            role={currentRole}
                        />

                        <Box ml={2}>
                            {/*{JSON.stringify(appState.currentUser)}*/}
                            <Button color="inherit" onClick={handleClick}>
                                {/*<LanguageIcon color="primary" fontSize="medium" />*/}
                                <Box ml={1}>
                                    <Typography component={'span'} variant="body2" style={{ textTransform: 'capitalize' }}>{service?.name}</Typography>
                                </Box>
                            </Button>
                            <Menu
                                id="UserMenu"
                                className={classes.headerMenu}
                                anchorEl={anchorEl}
                                keepMounted
                                open={currentRole === "PM" ? Boolean(anchorEl) : false}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                PaperProps={{
                                    style: { minWidth: 200 },
                                }}
                            >
                                {(headerData?.services && headerData?.services.length > 0) && headerData.services.map((item: any, idx: number) => {
                                    return <MenuItem key={idx} disabled={service === item?.name.toLowerCase()} onClick={() => selectService(item)}>{item?.name}</MenuItem>
                                })}
                            </Menu>

                        </Box>


                        <Box ml={4}>
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                className={classes.fileNameBtn}
                            >
                                {/* {fileName}  */}
                                {appState.selectedFile.filename}
                            </Button>

                        </Box>
                        {showProgressBar()}
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Button disabled={appState.selectedService.isAssigned == 1 ? false : true} onClick={() => handleProjectSubmit()} color="inherit">
                            <Icons.PublishIcon style={{ opacity: appState.selectedService.isAssigned == 1 ? 1 : 0.3 }} color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography component={'span'} variant="body2"><Trans i18nKey="header.submit" /></Typography>
                            </Box>
                        </Button>

                        <Button color="inherit" onClick={() => setOpenSetting(true)}>
                            <Icons.SettingsIcon color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography component={'span'} variant="body2">{t('header.settings')}</Typography>
                            </Box>
                        </Button>

                        <Button color="inherit" onClick={() => setOpenShortcut(true)}>
                            <Icons.LayersIcon color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography component={'span'} variant="body2">{t('header.shortcut')}</Typography>
                            </Box>
                        </Button>

                        <Button color="inherit">
                            <Icons.LiveHelpIcon color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>
                                <Typography component={'span'} variant="body2">{t('header.helpCenter')}</Typography>
                            </Box>
                        </Button>
                        {/* 
                        <Button color="inherit"
                            onClick={() => {
                                const token = localStorage.getItem('access_token');
                                localStorage.removeItem('access_token');
                                let logoutUrl: any = process.env?.REACT_APP_SSO_LOGOUT_URL;
                                window.location.href = logoutUrl + token;
                            }}>
                            <Icons.SupervisedUserCircle color="action" fontSize="medium" />
                            <Box ml={.5} mr={1}>

                                <Typography component={'span'} variant="body2">{userData?.getCurrentUserDetails?.name}</Typography>
                            </Box>
                        </Button> */}

                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickLogout}>
                            <Icons.SupervisedUserCircle color="action" fontSize="medium" />
                            <Box ml={0.3}>
                                {userData?.getCurrentUserDetails?.name}
                            </Box>
                        </Button>
                        <Menu
                            className={classes.headerMenu}
                            id="UserMenu"
                            anchorEl={anchorElLogout}
                            keepMounted
                            open={Boolean(anchorElLogout)}
                            onClose={handleCloseLogout}
                            TransitionComponent={Fade}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            PaperProps={{
                                style: { minWidth: 200 },
                            }}
                        >
                            <MenuItem onClick={() => logoutMutation()}>
                                <ExitToAppIcon className={classes.logoutIcon} />{t('header.logout')}</MenuItem>

                        </Menu>

                        <Box pl={3.5} ml={1.75} className={classes.logoDivider}>
                            <img src="/assets/images/Marscat.svg" className={classes.logoStyle} alt="MarsCat" onClick={navigatePtojectList} />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <SettingModal
                appDispatch={appDispatch}
                setThemeType={setThemeType}
                themeType={themeType}
                setOpen={setOpenSetting}
                open={openSetting}
            />
            <ShortcutModal
                setOpen={setOpenShortcut}
                open={openShortcut}
            />

            <SubmitModal
                openSubmit={openSubmit}
                setOpenSubmit={setOpenSubmit}
                setForceSubmit={setForceSubmit}
                showSubmitError={showSubmitError}
            />

            <Snackbar className={classes.snackBarStyles} key={t('menu.pasteShortcut')} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={submitAlert}
                autoHideDuration={4000} onClose={handleSnackBarClose}>
                <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
                    {showSubmitError}
                </Alert>
            </Snackbar>

            <Snackbar className={classes.snackBarStyles} key={t('header.submitProject')} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={showSubmitSuccess}
                autoHideDuration={4000} onClose={handleSnackBarClose}>
                <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="success" style={{ backgroundColor: "#F77D3D" }}>
                    {t('header.submitProject')}
                </Alert>
            </Snackbar>

            <CustomSnackbar
                error={gqlError}
                open={gqlErrorSnackBar}
                onClose={handleErrorSnackBarClose}
            />
        </>
    )
}

export default React.memo(Header);