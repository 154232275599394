import { gql } from '@apollo/client';

const IMPORT_KEY_TERM_MISMATCH = gql`mutation ImportKeyTermMismatch($data: ImportKeyTermMismatchRequest){
    importKeyTermMismatch(data: $data){
      status
      error
  }
 
  }`;

export default IMPORT_KEY_TERM_MISMATCH;