import { gql } from '@apollo/client';
const GET_QUERY_LOGS = gql`query GetQueryLogs($projectId: String!, $fileId: String!, $tl:String!) {
    getQueryLogs(projectId: $projectId, fileId: $fileId, tl: $tl)
     {
        id
        segmentId
        content
        user
        service
        role
        createdAt
        answers{
          id
          answer
          service
          role
          username
          createdAt
        }
    }
  
  }`;

export default GET_QUERY_LOGS;