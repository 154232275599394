import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectList: {
            backgroundColor: '#e5e5e5',
            height: 'calc(100vh - 64px)'
        },
        createProject: {
            height: '100vh',
            backgroundColor: '#e5e5e5',
        },
        logoStyles: {
            position: 'absolute',
        },
        table: {
            borderTop: '0.2em solid #f2711c',
        },
        qlHeading: {
            fontSize: '28px',
            color: '#494949',
            flex: 1, // Take up remaining space to center-align the text
            textAlign: 'center',
            fontWeight: 'bold'
        },
        projectHeading: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#494949',
            marginBottom: '18px',
            display: 'flex'
        },
        tblContainer: {
            borderRadius: '4px'
        },
        tblData: {
            padding: '11px'
        },
        projectName: {
            color: '#ec1a7c',
        },
        paper: {
            border: '1px solid #d3d4d5',
        },
        tblCellStyle: {
            fontWeight: 'bold',
            color: '#494949',
            padding: '11px'
        },
        tblRow: {
            backgroundColor: '#f9fafb'
        },
        projectFeilds: {
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#f77d3d',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#f77d3d',
                },
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#dcdcdc'
            },
        },
        rowStyles: {
            '&:hover': {
                backgroundColor: '#FCF5ED',
            }
        },
        qlSource: {
            display: 'flex',
            fontSize: '13px',
            color: '#7d7d7d',
            '& img': {
                marginRight: '1px'
            }
        },
    }),
);

export default useStyles;