import React, { useEffect } from "react";
import { Box, Typography, Avatar, Link, Accordion, AccordionSummary, AccordionDetails, IconButton } from "@material-ui/core";
import * as Icons from 'src/material-ui';
import useStyles from "./MessageBoxStyles";
import ReplyIcon from '@material-ui/icons/Reply';
import { useTranslation } from "react-i18next";
import { FaLevelDownAlt } from 'react-icons/fa';


const MessageBox = ({ id, date, message, role, segId, handleReply, isReply, reply, answers, segmentCount }: any) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [expanded, setExpanded] = React.useState(true);

    // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const toggleAccordion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <Box className={classes.boxWrap}>


            <Accordion defaultExpanded={true} className={classes.qlAccordian} expanded={expanded}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classes.commentBoxWrap}
                >
                    <Box className="boxFooter" display="flex" alignItems="center" justifyContent="space-between" p={1}>
                        <Box className={classes.qlCursor} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            {answers.length > 0 &&
                                <Box className={classes.qlAccordianIcon} display={'flex'} mr={'3px'}>
                                    {/* <Icons.ArrowDropDownIcon
                                        className={`${classes.arrowIcon} ${expanded === 'panel1' ? classes.rotateUp : ''}`}
                                        fontSize="medium"
                                        onClick={() => handleChange('panel1')}
                                    /> */}

                                    <IconButton className={classes.expandIconButton} onClick={toggleAccordion}>
                                        <Icons.ExpandMoreIcon
                                            className={`${classes.arrowIcon} ${expanded === true ? classes.rotateUp : ''}`}
                                        />
                                    </IconButton>
                                </Box>
                            }

                            <Box className={classes.qlRole} fontSize={11} mr={2} color="#858585"><Icons.PersonIcon className={classes.infoSmIcon} />
                                {role.charAt(0).toUpperCase() + role.slice(1)}
                            </Box>

                            <Box className={classes.qlRole} fontSize={11} mr={2} color="#858585"><Icons.CalendarTodayIcon className={classes.infoSmIcon} />
                                {date}
                            </Box>

                            <Box className={classes.qlCursor} display={'flex'}>
                                {segId && segmentCount != 0 ?
                                    <>
                                        <Avatar className={`${classes.textStatus} transparent`} variant="square">Seg.</Avatar>
                                        <Box className={classes.qlRole} ml={1} fontSize={'12px'} fontWeight={'bold'} display={'flex'} alignSelf={'center'} color={'#979797'}>{segmentCount}</Box>
                                    </>
                                    : ''}
                            </Box>
                        </Box>

                        <Box height={18} display={'flex'} alignItems={'center'}>
                            {isReply === 1 &&
                                <Link className={classes.replyLink} onClick={() => handleReply(id)}>
                                    <FaLevelDownAlt className={classes.transformIcon} fontSize="small" />
                                    {t('common.reply')}
                                </Link>
                            }
                        </Box>
                    </Box>

                    <Box onClick={(e: any) => { e.stopPropagation(); }} className={classes.questionBox}>
                        <Typography className="content" component={'span'} dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }} />
                    </Box>


                </AccordionSummary>

                {answers.length > 0 &&
                    <AccordionDetails className={classes.qlAccordianDetail}>

                        <Box className={classes.answerBoxWrap} height={'auto'}>
                            {/* Client Answer && Reply*/}
                            <Box className={classes.replyCellWrap} height={'auto'}>
                                {answers && answers.map((answer: any, index: any) => (
                                    <div key={answer.id}>
                                        <Box className="boxFooter" display="flex" alignItems="center" justifyContent="space-between" p={1}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Box fontSize={11} mr={2} color="#858585"><Icons.PersonIcon className={classes.infoSmIcon} />
                                                    {answer.role ? answer.role.charAt(0).toUpperCase() + answer.role.slice(1) : "Client"}
                                                </Box>

                                                <Box fontSize={11} mr={2} color="#858585"><Icons.CalendarTodayIcon className={classes.infoSmIcon} />
                                                    {answer.createdAt}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Typography style={{ whiteSpace: 'pre-wrap' }} className="content" component={'span'} dangerouslySetInnerHTML={{ __html: answer.answer.replace(/\n/g, '<br />') }} />
                                    </div>
                                ))}

                            </Box>
                        </Box>
                    </AccordionDetails>
                }

            </Accordion>

        </Box>
    );
}

export default React.memo(MessageBox);