import React, { MouseEvent, useEffect, useState, } from 'react';
import { Typography, Box, Button, MenuItem, Menu, Fade } from "@material-ui/core";
import { LanguageIcon } from 'src/material-ui';
import { ISegment } from 'src/interfaces';
import useStyles from './HeaderStyles';
import axios from 'axios';
// import axios from 'axios';


interface IProps {
    appDispatch: Function,
    languageArray: ISegment[],
    data1: any,
    targetLang: any,
    fileName: string,
    setTargetLang: Function,
    rowsPerPage: any,
    setIsSetLang: any,
    role: any
}


const SelectLanguage = ({ appDispatch, languageArray, data1, fileName, setTargetLang, targetLang, rowsPerPage, setIsSetLang, role }: IProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [language, setLanguage] = useState(targetLang ? (languageArray.find((item: any) => item.code == targetLang)) : (languageArray ? languageArray[0] : undefined));

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsSetLang(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsSetLang(false);
    };

    const selectLanguage = (val: any) => {
        setLanguage(val);
        setTargetLang(val?.code);
        handleClose();
    };

    useEffect(() => {
        if (languageArray) {
            let lang: any = languageArray[0];

            if (targetLang) {
                lang = languageArray.find((item: any) => item.code == targetLang);
            }

            setLanguage(lang);
        }
    }, [languageArray]);


    useEffect(() => {
        setSegmentsDispatch();
    }, [data1, fileName, language, targetLang]);

    const setSegmentsDispatch = () => {

        let temp: any = [];
        let segments = data1?.getFileSegmentsByLanguage?.segments;
        let pagination = data1?.getFileSegmentsByLanguage?.pagination;
        let searchCounts = data1?.getFileSegmentsByLanguage?.searchCounts;
        let currentPage = pagination?.currentPage;

        if (segments) {
            segments.forEach((segments: any, idx: number) => {
                let segmentCount = 1;
                if (currentPage === 1) {
                    segmentCount = currentPage * (idx + 1);
                } else {

                    segmentCount = ((currentPage - 1) * rowsPerPage) + (idx + 1);
                }
                let segments_data = {
                    id: segments?.sSegmentId,
                    key: segments?.key,
                    segmentCount: segmentCount,
                    isPropagationOn: segments.isPropagationOn,
                    source: segments.source,
                    target: segments.target,
                    state: segments.state,
                    //  words: segments.words,
                    isLocked: segments.isLocked,
                    tSegmentId: segments.tSegmentId,
                    sSegmentId: segments.sSegmentId,
                    tl: language?.code,
                    tStatusType: segments.tStatusType,
                    tStatusValue: (segments.tStatusValue != 'draft' && segments.tStatusValue != 'translated') ? segments.tStatusValue : '',
                }
                temp.push(segments_data)
            });
            // console.log('temp ==> ', JSON.stringify(temp, null, 4));
            appDispatch({
                type: 'CONVERT_DATA',
                payload: temp,
                pagination: [pagination],
                searchCounts: searchCounts,
                segments: segments,
            })


        } else {
            // axios.get(`/assets/json/english.json`)
            //     .then((res: any) => {
            //         appDispatch({
            //             type: 'CONVERT_DATA',
            //             payload: res.data.segments
            //         })
            //     })
        }
    }



    return (
        <Box ml={2}>
            <Button color="inherit" onClick={handleClick}>
                <LanguageIcon color="primary" fontSize="medium" />
                <Box ml={1}>
                    <Typography component={'span'} variant="body2" style={{ textTransform: 'capitalize' }}>{language?.name}</Typography>
                </Box>
            </Button>
            <Menu
                id="UserMenu"
                className={classes.headerMenu}
                anchorEl={anchorEl}
                keepMounted
                open={role === "PM" ? Boolean(anchorEl) : false}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: { minWidth: 200 },
                }}
            >
                {(languageArray && languageArray.length > 0) && languageArray.map((item: any, idx: number) => {
                    return <MenuItem key={idx} disabled={language === item?.name} onClick={() => selectLanguage(item)}>{item?.name}</MenuItem>
                })}
            </Menu>

        </Box>
    )
}

export default React.memo(SelectLanguage);