import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectList: {
            backgroundColor: '#e5e5e5',
            height: 'calc(100vh - 64px)'
        },
        createProject: {
            height: '100vh',
            backgroundColor: '#e5e5e5',
        },
        table: {
            padding: '12px 0 12px 0',
            margin: '0 25px 0 25px',
            width: '-webkit-fill-available',
            '& .MuiTableRow-root': {
                transition: 'all .3s',
                '&:nth-child': {
                    backgroundColor: '#ffffff !important'
                },
                '&:hover': {
                    backgroundColor: '#FCF5ED',
                    '& td': {
                        color: '#393939'
                    }
                }
            },
            '& .MuiTableHead-root': {
                '& .MuiTableRow-root': {
                    backgroundColor: '#ffffff !important',
                    '& th': {
                        fontSize: '0.875rem !important',
                        fontWeight: 'bold !important',
                        fontFamily: '"Roboto","Arial","Helvetica Neue","-apple-system","BlinkMacSystemFont","Apple Color Emoji","sans-serif" !important',
                        textTransform: 'none !important'
                    }
                }
            },
            '& .MuiTableCell-root': {
                display: 'table-cell !important',
                padding: '16px !important',
                fontSize: '0.875rem !important',
                borderTop: 'none !important',
                borderLeft: 'none !important',
                borderRight: 'none !important',
                borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
                outline: 'none !important',
                wordBreak: 'break-all',
            },
        },
        tableCell: {
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#494949'
        },
        projectHeading: {
            fontWeight: 'bold',
            fontSize: '20px',
            padding: '24px',
            color: '#494949'
        },
        tableContent: {
            color: '#494949'
        },
        AddBtn: {
            color: '#ffffff',
            backgroundColor: '#f77d3d',
            borderColor: '#f77d3d',
            boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            '&:hover': {
                backgroundColor: '#ffa269',
                borderColor: '#ffa269'
            },
            marginRight: '12px',
            borderRadius: '2px',
            textTransform: 'none'
        },
        exportBtn: {
            color: '#f77d3d',
            backgroundColor: '#ffffff',
            borderColor: '#f77d3d',
            boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            '&:hover': {
                backgroundColor: '#f77d3d',
                borderColor: '#f77d3d',
                color: '#ffffff'
            },
            borderRadius: '2px',
            textTransform: 'none'
        },
        filterBtn: {
            color: '#ffffff',
            backgroundColor: '#f77d3d',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#ffa269',
                borderColor: '#ffa269'
            },
        },
        resetBtn: {
            color: '#f77d3d',
            backgroundColor: '#ffffff',
            borderColor: '#f77d3d',
            boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            '&:hover': {
                backgroundColor: '#f77d3d',
                borderColor: '#f77d3d',
                color: '#ffffff'
            },
            borderRadius: '2px',
            marginRight: '12px',
            textTransform: 'none',
            minWidth: '77px'
        },
        queryBtn: {
            color: '#ffffff',
            backgroundColor: '#f77d3d',
            borderColor: '#f77d3d',
            boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            '&:hover': {
                backgroundColor: '#ffa269',
                borderColor: '#ffa269'
            },
            borderRadius: '2px',
            textTransform: 'none'
        },
        root: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
        },
        paper: {
            border: '1px solid #d3d4d5',
        },
        projectMenu: {
            color: '#ffffff',
            backgroundColor: '#f77d3d',
            borderColor: '#f77d3d',
            boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            '&:hover': {
                backgroundColor: '#ffa269',
                borderColor: '#ffa269'
            },
            borderRadius: '2px',
            marginRight: '12px',
            textTransform: 'none'
        },
        menuStyles: {
            border: '1px solid #d3d4d5',
            '& .MuiMenu-paper': {
                boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
            }
        },
        projectFeilds: {
            fontSize: '16px',
            borderRadius: '2px',
            marginRight: '12px',
            '& .MuiOutlinedInput-inputMarginDense': {
                paddingTop: '6px',
                paddingBottom: '6px'
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '2px',
                '&:hover fieldset': {
                    borderColor: '#f77d3d',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#f77d3d',
                },
                '& input#outlined-size-small::placeholder': {
                    fontSize: '14px',
                },
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#dcdcdc'
            },
        },
        addPaper: {
            backgroundColor: '#ffffff',
            padding: '30px'
        },
        feildWrap: {
            width: '-webkit-fill-available',
            padding: '20px 20px 20px 20px',
            '& .MuiDropzoneArea-root': {
                border: '1px dashed #c4c4c4',
                backgroundColor: "#fafafa"
            },
            '& .MuiDropzoneArea-text': {
                fontSize: '18px',
                color: '#494949',
            },
            '& .MuiDropzoneArea-icon': {
                color: '#f77d3d'
            }
        },
        formFeilds: {
            width: '-webkit-fill-available',
            '& .MuiOutlinedInput-root': {
                borderRadius: '2px',
                // '& fieldset': {
                //     borderColor: 'red',
                // },
                '&:hover fieldset': {
                    borderColor: '#f77d3d',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#f77d3d',
                },
            },
            '& .MuiFormControl-fullWidth': {
                width: '-webkit-fill-available',
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: '6px',
            },
            ' & .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                padding: '4.5px 4px',
            }

        },
        labelStyles: {
            marginBottom: '8px',
            color: '#494949',
            fontSize: '14px',
        },
        checkboxColor: {
            color: '#f77d3d',
            padding: '10px !important',
            marginLeft: '10px',
            '&.Mui-checked svg': {
                color: '#f77d3d !important',
                width: '20px !important',
                height: '20px !important',
            },
            '&.MuiIconButton-colorSecondary svg': {
                width: '20px !important',
                height: '20px !important',
            },
            '&.MuiCheckbox-indeterminate svg': {
                color: '#f77d3d !important',
            }
        },
        checkboxLabel: {
            fontSize: '14px',
            color: '#494949'
        },
        required: {
            '&::before': {
                content: '"*"',
                color: theme.palette.error.main,
                marginRight: '4px'
            }
        },
        dateFeild: {
            width: '-webkit-fill-available',
            padding: '9px',
            fontSize: '16px',
            borderRadius: '2px',
            border: '1px solid #c4c4c4',
            '&:hover': {
                border: '1px solid #f77d3d',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none',
                border: '2px solid #f77d3d',
            },

        },
        deadline: {
            '& .react-datepicker__triangle': {
                transform: 'translate(150px, 0px) !important'
            }
        },
        defaultPage: {
            height: '100vh',
            backgroundColor: '#e5e5e5',
        },
        // tableRowStyle: {
        //     cursor: 'pointer'
        // }
    }),
);

export default useStyles;