import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Box,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    TextField,
    Button,
    Tooltip,
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { TabPanel } from "src/material-ui";
import * as Icons from "src/material-ui";
import useStyles from "./BottomBarStyles";
import { IAppState } from "src/interfaces";
import { useTranslation } from "react-i18next";
import ConcordanceSearch from "src/helpers/queries/ConcordanceSearch";
import CONCORDANCE_SEARCH from "src/helpers/queries/ConcordanceSearch";
import { useLazyQuery } from "@apollo/client";
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderTags } from "src/helpers";

interface IProps {
    value: string;
    appState: IAppState;
    appDispatch: Function;
    headerData: any;
}

const SearchTab = ({ value, appState, appDispatch, headerData }: IProps) => {
    const classes = useStyles();
    const [type, setType] = React.useState("1");
    const [flag, setFlag] = React.useState("");
    const [text, setText] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [concordanceData, setConcordanceData] = useState([]);
    const { t } = useTranslation();

    const handleChange = (event: any) => {
        setText(event.target.value);
    };

    const handleClick = (event: any, row: any) => {
        if (row && event.detail === 2)
            appDispatch({
                type: "CONCORDANCE_REPLACE",
                payload: { row, text },
            });
    };

    const handleRadio = (event: any) => {
        if (event.target.value === "1") {
            setFlag("s");
        } else {
            setFlag("t");
        }
        setText(text);
        setType(event.target.value);
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: "#494949",
            border: "1px solid #E0E0E0",
            fontSize: 12,
            margin: "2px 0",
        },
    }))(Tooltip);

    const title = (id: any) => {
        let text = "";
        concordanceData.forEach((el: any) => {
            if (el.id === id)
                text =
                    t('segmentDataBox.origin') + ": " +
                    el.origin +
                    "\n" + t('segmentDataBox.updatedOn') + ": " +
                    el.updated_on +
                    "\n" + t('segmentDataBox.updatedBy') + ": " +
                    el.updated_by +
                    "\n" + t('segmentDataBox.createdOn') + ": " +
                    el.created_on +
                    "\n" + t('segmentDataBox.createdBy') + ": " +
                    el.created_by;
        });
        const newText = text
            .split("\n")
            .map((str) => <p style={{ margin: 0 }}>{str}</p>);
        return newText;
    };

    useEffect(() => {
        const { flag, text } = appState.concordSearch;
        setFlag(flag);
        setText(text);
        setToggleSearch(!toggleSearch)
    }, [appState.concordSearch.text, appState.concordSearch.flag]);

    useEffect(() => {
        if (text.trim() !== "") {
            getConcordanceSearch()
        } else {
            setConcordanceData([])
        }
    }, [toggleSearch])

    const [ConcordanceSearch, { data, error }] = useLazyQuery(
        CONCORDANCE_SEARCH, { fetchPolicy: 'no-cache' }
    );

    const getConcordanceSearch = () => {
        setLoading(true);
        let tl: any = appState.selectedRow?.tl;
        if (tl) {
            ConcordanceSearch({
                variables: {
                    sl: headerData?.sl?.code,
                    tl: appState.selectedLanguage,
                    searchTerm: text,
                    type: flag === "s" ? "source" : "translation",
                    projectId: headerData?.id,
                }
            }).then(response => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
                .catch(error => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                });;
        }
    }

    useEffect(() => {
        if (data) {
            let concordanceData = data.concordanceSearch;
            let dataRows: any = [];

            for (let i = 0; i < concordanceData.length; i++) {
                dataRows.push({
                    id: i + 1,
                    origin: concordanceData[i].tmName,
                    updated_on: concordanceData[i].lastModified,
                    updated_by: concordanceData[i].contributor,
                    created_on: concordanceData[i].createdAt,
                    created_by: concordanceData[i].contributor,
                    source: concordanceData[i].sourceText,
                    target: concordanceData[i].translatedText,
                    tmName: concordanceData[i].tmName
                });
            }

            if (headerData?.sl?.code.includes('zh-'))
                dataRows = dataRows.filter((item: any) => item.target.includes(text) || item.source.includes(text));
            setConcordanceData(dataRows);
            setLoading(false);
        }

    }, [data, appState.selected]);

    const CustomNoRowsOverlay = () => {
        return (
            <div className={classes.noRecordFoundContainer}>
                <svg
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>Sorry! No Record Found</Box>
            </div>
        );
    }

    return (
        <TabPanel value={value} index="search">
            <>
                <Box className={classes.typeRadioWrap} mt={2} mb={2} ml={1}>
                    <FormControl>
                        <RadioGroup
                            name="type"
                            value={type}
                            onChange={(e) => handleRadio(e)}
                        >
                            <FormControlLabel
                                value="1"
                                control={<Radio checked={flag === "s"} />}
                                label={t('translationTable.source')}
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio checked={flag === "t"} />}
                                label={t('common.translation')}
                            />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder={t('concordanceSearch.textSearch') as any}
                        value={text}
                        onChange={(e) => handleChange(e)}
                    />
                    <Button
                        className={classes.searchBtn}
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={!text}
                        onClick={() => getConcordanceSearch()}
                        disableElevation
                    >
                        {t('concordanceSearch.search')}
                    </Button>
                </Box>


                {
                    loading ?
                        <Box className={classes.loader}>
                            <CircularProgress />
                        </Box> : (
                            concordanceData && concordanceData.length > 0 ?
                                <TableContainer>
                                    <Table className={classes.searchTable}>
                                        <TableBody>
                                            {concordanceData.map((row: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.id}</TableCell>
                                                    <TableCell>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: renderTags(row.source),
                                                            }}
                                                        />
                                                        <Box mt={0.5}>
                                                            <LightTooltip
                                                                title={title(row.id)}
                                                                placement="bottom-start"
                                                            >
                                                                <Box
                                                                    display="inline-block"
                                                                    fontSize={11}
                                                                    mr={2}
                                                                    color="#858585"
                                                                >
                                                                    <Icons.PersonIcon className={classes.infoSmIcon} />{" "}
                                                                    {row.updated_by}
                                                                </Box>
                                                            </LightTooltip>
                                                            <LightTooltip
                                                                title={title(row.id)}
                                                                placement="bottom-start"
                                                            >
                                                                <Box
                                                                    display="inline-block"
                                                                    fontSize={11}
                                                                    mr={2}
                                                                    color="#858585"
                                                                >
                                                                    <Icons.CalendarTodayIcon
                                                                        className={classes.infoSmIcon}
                                                                    />{" "}
                                                                    {row.updated_on}
                                                                </Box>
                                                            </LightTooltip>
                                                        </Box>
                                                    </TableCell>

                                                    <LightTooltip
                                                        title={t('segmentDataBox.pasteMatches') as any}
                                                        placement="bottom"
                                                        arrow
                                                    >
                                                        <TableCell onClick={(e) => handleClick(e, row)}
                                                            dangerouslySetInnerHTML={{
                                                                __html: renderTags(row.target),
                                                            }} />
                                                    </LightTooltip>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <CustomNoRowsOverlay />)
                }
            </>
        </TabPanel>
    );
};

export default React.memo(SearchTab);


