import * as Icons from 'src/material-ui';
import { MdSubscript, MdSuperscript } from 'react-icons/md';
import '../../../src/App.css';
// const classes = useStyles();

const ButtonsList: any = [
    [{
        name: 'editorMenu.confirm',
        icon: <Icons.CheckIcon />,
        type: 'CONFIRM_SEGMENT',
        options: [
            { name: 'editorMenu.confirmSegment', type: 'CONFIRM' },
            { name: 'editorMenu.confirmSegOfCurPage', type: 'CONFIRM_CURRENT' },
        ],
    }],
    [{
        name: 'common.copy',
        icon: <Icons.CopyIcon />,
        type: 'COPY_TEXT',
        options: [
            { name: 'editorMenu.copySourceTargetShort', type: 'COPY' },
            { name: 'editorMenu.copyAllSource', type: 'COPY_ALL' },
            // { name: 'editorMenu.copySourceToTargetAllPages', type: 'COPY_ALL_PAGES' },//TODO need to optimize
        ]
    },
    {
        name: 'editorMenu.clear',
        icon: <Icons.FormatClearOutlinedIcon />,
        type: 'CLEAR_TEXT',
        options: [
            { name: 'editorMenu.clearTranslationShort', type: 'CLEAR' },
            { name: 'editorMenu.clearAll', type: 'CLEAR_ALL' },
        ]
    }],
    [
        { name: 'Bold', icon: <Icons.FormatBoldIcon />, type: 'BOLD' },
        { name: 'Underline', icon: <Icons.FormatUnderlinedIcon />, type: 'UNDERLINE' },
        { name: 'Square', icon: <MdSuperscript size={'19px'} color={'#00000080'} />, type: 'SUPERSCRIPT' },
        { name: 'Italic', icon: <Icons.FormatItalicIcon />, type: 'ITALIC' },
        { name: 'Line Through', icon: <Icons.StrikethroughSIcon />, type: 'STRIKE_THROUGH' },
        { name: 'Square', icon: <MdSubscript size={'19px'} color={'#00000080'} />, type: 'SUBSCRIPT' },
    ],
    [
        { name: 'editorMenu.merge', icon: <Icons.MergeTypeOutlinedIcon />, type: 'MERGE' },
        { name: 'editorMenu.split', icon: <Icons.CallSplitOutlinedIcon />, type: 'SPLIT' }
    ],
    // MCE-899
    // [{ name: 'editorMenu.insertTag', icon: <Icons.CodeIcon />, type: 'SHOW_CODE' }],
    [
        { name: 'editorMenu.undo', icon: <Icons.UndoOutlinedIcon />, type: 'UNDO' },
        { name: 'editorMenu.next', icon: <Icons.RedoOutlinedIcon />, type: 'REDO' }
    ],
    [{ name: 'editorMenu.whitespace', icon: <Icons.VisibilityIcon />, type: 'WHITE_SPACES' }],
    [{
        name: 'editorMenu.preTranslation',
        icon: <Icons.TranslateOutlinedIcon />,
        type: 'PRE_TRANSLATION',
        options: [
            { name: 'editorMenu.preTranslate', type: 'PRE_TRANSLATION' },
        ]
    },
    {
        name: 'common.machineTranslation',
        icon: <Icons.MemoryIcon />,
        type: 'MACHINE_TRANSLATION',
        options: [
            { name: 'editorMenu.translateThis', type: 'TRANSLATE_SEGMENT' },
            { name: 'editorMenu.translateAll', type: 'TRANSLATE_ALL' },
        ]
    }],
    [{
        name: 'common.trackChanges',
        icon: <Icons.TrackChangesIcon />,
        type: 'TRACK_CHANGES'
    },
    {
        name: 'editorMenu.accept',
        icon: <Icons.DoneAllIcon />,
        type: 'ACCEPT',
        options: [
            { name: 'editorMenu.acceptAndMove', type: 'ACCEPT_AND_MOVE' },
            { name: 'editorMenu.acceptChanges', type: 'ACCEPT_CHANGES' },
            { name: 'editorMenu.acceptAllChanges', type: 'ACCEPT_ALL_CHANGES' },
        ]
    },
    {
        name: 'editorMenu.reject',
        icon: <Icons.RejectChangesIcon />,
        type: 'REJECT',
        options: [
            { name: 'editorMenu.rejectAndMove', type: 'REJECT_AND_MOVE' },
            { name: 'editorMenu.rejectChanges', type: 'REJECT_CHANGES' },
            { name: 'editorMenu.rejectAllChanges', type: 'REJECT_ALL_CHANGES' },
        ]
    }],
    [{ name: 'common.replace', icon: <Icons.ListSearchIcon />, type: 'REPLACE' }],
    [{ name: 'verify.title', icon: <Icons.VerifyChangesIcon />, type: 'VERIFY' }],
    [{
        name: 'filters.allSegments',
        icon: <Icons.FilterIcon />,
        type: 'ALL_SEGMENTS',
        options: [
            { name: 'filters.allSegments', type: 'ALL_SEGMENTS' },
            { name: 'filters.empty', type: 'EMPTY' },
            { name: 'common.targetSame', type: 'TARGET_SAME_SOURCE' },
            { name: 'filters.containsTags', type: 'CONTAINS_TAGS' },
            { name: 'filters.hundredMatches', type: '100%_MATCHES' },
            { name: 'filters.fuzzyMatches', type: 'FUZZY_MATCHES' },
            { name: 'filters.contextMatches', type: 'CONTEXT_MATCHES' },
            { name: 'filters.autoTranslate', type: 'AUTO_TRANSLATION' },
            { name: 'common.translated', type: 'TRANSLATED' },
            { name: 'common.reviewed', type: 'REVIEWED' },
            { name: 'filters.rejected', type: 'REJECTED' },
            { name: 'filters.approved', type: 'APPROVED' },
            { name: 'filters.unconfirmed', type: 'UNCONFIRMED' },
            { name: 'filters.notTranslated', type: 'NOT_TRANSLATED' },
            { name: 'filters.draft', type: 'DRAFT' },
            { name: 'common.trackChanges', type: 'TRACK_CHANGES' },
            { name: 'filters.locked', type: 'LOCKED' },
            { name: 'filters.unlocked', type: 'UNLOCKED' },
            { name: 'filters.errorWarning', type: 'ERROR' },
        ]
    }],
];

export default ButtonsList;