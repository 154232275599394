import React, { useEffect, useState } from "react";
import { gql, useMutation } from '@apollo/client';
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";

const REGISTER_USER_GQL = gql`mutation registerUser($firstName: String!,$lastName: String!, $email: String, $accessToken: String){
    registerUser(firstName: $firstName,lastName: $lastName,email: $email, accessToken: $accessToken ) {
        id
    }

}`;


export const Authentication = (props: any) => {

    const [token, setToken] = useState('');

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const [registerUser, { loading: loadingSegments }] = useMutation(REGISTER_USER_GQL, {
        errorPolicy: 'all',
        onCompleted: (data: any) => {
            // setData(data);
            localStorage.setItem('access_token', token);

            // const cloudToken = getQueryParam('token');

            // if (!cloudToken) {
            const url = localStorage.getItem('referer');
            if (url && typeof url != "undefined") {
                localStorage.removeItem('referer');
                window.location.href = url;
            } else {
                window.location.href = '/';
            }
            // }
        }, onError: handleError

    });

    const getUrlParameterValue = (name: any) => {
        // eslint-disable-next-line no-empty-character-class
        name = name.replace(/[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const getQueryParam = (parameterName: any) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(parameterName);
    };

    useEffect(() => {

        const accessToken = getUrlParameterValue('accessToken');

        const cloudToken = getQueryParam('token');
        const email = getQueryParam('email');

        if (cloudToken) {
            setToken(cloudToken);
        } else {
            setToken(accessToken);
        }

        if (!accessToken && !cloudToken) {
            const token = localStorage.getItem('access_token');

            if (!token) {
                // setIsRedirecting(true);
                localStorage.setItem('referer', window.location.href);
                window.location.href = (process.env.REACT_APP_SSO_ENDPOINT + '/login?redirect_url=' + window.location.href);
            } else {
                localStorage.setItem('access_token', token);
                // dispatch(setAuth(token));
            }
        } else if (!accessToken && cloudToken) {
            if (localStorage.getItem('access_token') != cloudToken) {
                localStorage.setItem('access_token', cloudToken);
                localStorage.setItem('referer', window.location.href);
                registerUser({
                    variables: {
                        firstName: '',
                        lastName: '',
                        email: email,
                        accessToken: cloudToken
                    }
                });
            }
        } else if (accessToken || cloudToken) {
            const name = getUrlParameterValue('first_name');
            const email = getUrlParameterValue('email');
            const firstName = name;
            const lastName = getUrlParameterValue('last_name');

            const finalToken = cloudToken || accessToken; // Use cloudToken if it exists, otherwise use accessToken

            localStorage.setItem('referer', window.location.href);
            registerUser({
                variables: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    accessToken: finalToken
                }
            });

            // Save the token to localStorage
            localStorage.setItem('access_token', finalToken);
        }

        // Remove token and email from URL params
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete('accessToken');
        urlSearchParams.delete('token');
        urlSearchParams.delete('email');
        const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
        window.history.pushState({}, document.title, newUrl);
    }, []);

    return (<><CustomSnackbar
        error={gqlError}
        open={gqlErrorSnackBar}
        onClose={handleErrorSnackBarClose}
    /></>);
};