import { gql } from '@apollo/client';

const SUBMIT = gql`mutation submit($data: SubmitRequest){
    submit(data: $data){
      status
      error
      code
  }
 
  }`;


export default SUBMIT;