import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3c3c3c',
      fontWeight: 300,
      cursor: 'pointer',
      lineHeight: 1.8,
      fontSize: '13px',
      "&:hover": {
        backgroundColor: '#f5f5f5'
      },
      // pointerEvents: 'none',
      // opacity: 0.7,
      userSelect: 'none', // Disable text selection
    },

    sourceMenuDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3c3c3c',
      fontWeight: 300,
      cursor: 'pointer',
      lineHeight: 1.8,
      fontSize: '13px',
      "&:hover": {
        backgroundColor: '#f5f5f5'
      },
      pointerEvents: 'none',
      opacity: 0.7,
    },

    menuMargin: {
      margin: '10px 0',
    },

    menuItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    menuIcon: {
      display: 'flex',
      marginRight: '10px'
    },

    menuTitle: {
      marginRight: '20px',
    },

    menuInfo: {

    },

    iconStyle: {
      color: '#4d82b8',
      fontSize: '18px',
    },

    iconPrimary: {
      color: '#ee662c',
      fontSize: '18px',
    },

    iconWarning: {
      color: '#ffa500',
      fontSize: '18px',
    },

    iconSuccess: {
      color: '#008000',
      fontSize: '18px',
    },

    textStyle: {
      borderBottom: '1px solid #e0e0e0 !important'
    },
  }),
);

export default useStyles;