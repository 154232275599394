import React, { useEffect, useState } from 'react';
import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    IconButton,
    Typography,
    Box,
} from '@material-ui/core';
import useStyles from "../../components/layout/AnswerQueryLogStyles";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { useNavigate } from 'react-router-dom';
import ANSWER_PUBLIC_QUERY_LOG from 'src/helpers/mutations/answerPublicQueryLog';
import { useLazyQuery, useMutation } from '@apollo/client';
import GET_PUBLIC_QUERY_LOGS from 'src/helpers/queries/GetPublicQueryLogs';
import { FaCheck } from 'react-icons/fa';
import { renderTags } from 'src/helpers';
import useErrorHandler from 'src/helpers/custom-hooks/UseErrorHandler';
import CustomSnackbar from '../custom-snackbar/CustomSnackbar';


export default function AnswerQueryLog() {
    const classes = useStyles({});
    const [editedIndex, setEditedIndex] = useState(-1);
    const [editedAnswer, setEditedAnswer] = useState('');
    const [predefinedAnswer, setPredefinedAnswer] = useState('');
    const [id, setId] = useState('');
    const [userName, setUserName]: any = useState('');

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const [answerPublicQueryLog, { loading: loadingAnswerPublicQueryLog }] = useMutation(ANSWER_PUBLIC_QUERY_LOG, {
        errorPolicy: 'all',
        variables: {
            data: {
                questionId: id,
                answer: editedAnswer,
                type: predefinedAnswer !== '' ? "update" : "",
            }
        },
        onCompleted: (data: any) => {
            setEditedAnswer('');
        }, onError: handleError
    });

    const [queryLog, setQueryLog]: any = useState([]);

    const [GetPublicQueryLogs, { data: queryPublicLogData, error: queryPublicLogError }] = useLazyQuery(
        GET_PUBLIC_QUERY_LOGS, { fetchPolicy: 'no-cache' }
    );

    useEffect(() => {
        const currentURL = window.location.href;
        const projectId = currentURL.substring(currentURL.indexOf('=') + 1);

        if (projectId) {

            GetPublicQueryLogs({
                variables: {
                    projectId: projectId,
                    // segmentId: 'null',
                }
            });
        }
    }, []);

    useEffect(() => {
        if (queryPublicLogData) {
            let queryLog = queryPublicLogData.getPublicQueryLogs.logs;

            let dataRows = [];

            for (let i = 0; i < queryLog.length; i++) {
                dataRows.push({
                    id: queryLog[i].id,
                    segId: queryLog[i].segmentId,
                    sourceTxt: queryLog[i].sourceText,
                    question: queryLog[i].content,
                    date: queryLog[i].createdAt,
                    user: queryLog[i].user,
                    answers: queryLog[i].answers.filter((ans: any) => ans.username === "Client"),
                    fileName: queryLog[i].fileName,
                });
            }

            setQueryLog(dataRows);
        }

    }, [queryPublicLogData]);

    const handleEditClick = (id: any, userName: any, answer: any, index: any) => {
        setEditedIndex(index);
        setEditedAnswer(answer);
        setPredefinedAnswer(answer);

        setId(id);
        setUserName(userName);
    };

    const handleSaveClick = (id: any) => {
        if (editedAnswer !== "") {
            const updatedQueryLog = queryLog.map((question: any) => {
                if (question.id === id) {
                    return {
                        ...question,
                        type: null,
                        answers: [
                            ...(question.answers || []),
                            { answer: editedAnswer.replace(/\n/g, '<br />') },
                        ],
                        username: userName,
                    };
                }
                return question;
            });

            setQueryLog(updatedQueryLog);
            answerPublicQueryLog();
        }
        setEditedIndex(-1);

    };

    const navigate = useNavigate();

    const navigatePtojectList = () => {
        navigate('/');
    };

    return (
        <Box px={5} py={5}>
            <Box pb={5} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box className={classes.logoStyles}>
                    <img style={{ cursor: 'pointer', maxWidth: '180px' }} src="/assets/images/Marscat.svg" alt="MarsCat" onClick={navigatePtojectList} />
                </Box>

                <Typography className={classes.qlHeading}>
                    Query Log
                </Typography>
            </Box>

            <Typography className={classes.projectHeading}>
                Project Name: <Box ml={1} fontWeight={500} className={classes.projectName}>{queryPublicLogData && queryPublicLogData.getPublicQueryLogs.projectName}</Box>
            </Typography>
            <Paper>

                <TableContainer className={classes.tblContainer}>
                    <Table className={classes.table} aria-label="Query Log Table">
                        <TableHead>
                            <TableRow className={classes.tblRow}>
                                <TableCell className={classes.tblCellStyle}>File Name</TableCell>
                                <TableCell className={classes.tblCellStyle}>Question</TableCell>
                                <TableCell className={classes.tblCellStyle}>Answer</TableCell>
                                <TableCell className={classes.tblCellStyle}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {queryLog && queryLog.map((logEntry: any, index: any) => (
                                <TableRow key={index} className={classes.rowStyles}>
                                    <TableCell style={{ width: '25%' }} className={classes.tblData}>
                                        {logEntry.fileName}
                                    </TableCell>

                                    <TableCell style={{ width: '35%' }} className={classes.tblData}>
                                        {logEntry.sourceTxt.length !== 0 &&
                                            <div className={classes.qlSource}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: renderTags("Source: " + logEntry.sourceTxt).replace(/<meta[^>]+>/g, ""),
                                                    }} />
                                            </div>
                                        }

                                        <Typography className="content" component={'span'} dangerouslySetInnerHTML={{ __html: logEntry.question.replace(/\n/g, '<br />') }} />
                                    </TableCell>

                                    <TableCell style={{ width: '35%' }} className={classes.tblData}>
                                        {editedIndex === index ? (
                                            <TextField
                                                className={classes.projectFeilds}
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                style={{ width: "100%" }}
                                                value={editedAnswer.replace(/<br \/>/g, '\n')}
                                                onChange={(e) => setEditedAnswer(e.target.value)}
                                                onKeyDown={(e: any) => {
                                                    if (e.key === 'Enter' && e.shiftKey) {
                                                        e.preventDefault();
                                                        setEditedAnswer((prev) => prev + '\n');
                                                    } else if (e.key === 'Enter') {
                                                        handleSaveClick(logEntry.id);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <div style={{ whiteSpace: 'pre-wrap' }}
                                                dangerouslySetInnerHTML={{ __html: logEntry.answers.length ? logEntry.answers[logEntry.answers.length - 1].answer.replace(/\n/g, '<br />') : '' }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell style={{ width: '5%' }} className={classes.tblData}>
                                        {editedIndex === index ? (
                                            <IconButton size='medium' onClick={() => handleSaveClick(logEntry.id)}>
                                                <FaCheck fontSize='medium' color='#3d80c3' />
                                            </IconButton>
                                        ) : (
                                            <IconButton size='medium'
                                                onClick={() => handleEditClick(logEntry.id, logEntry.user, logEntry.answers.length ? logEntry.answers[logEntry.answers.length - 1].answer : '', index)}
                                            >
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <CustomSnackbar
                error={gqlError}
                open={gqlErrorSnackBar}
                onClose={handleErrorSnackBarClose}
            />
        </Box>

    );
}

function appDispatch(arg0: { type: string; payload: number; }) {
    throw new Error('Function not implemented.');
}
