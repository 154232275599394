import { gql } from '@apollo/client';

const LOGOUT = gql`mutation logout($token: String!){
    logout(token: $token){
      status
      error
  }
 
  }`;


export default LOGOUT;