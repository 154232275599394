import { Modal, Box, Typography, Button, TextField, Snackbar, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useStyles from './SpliSegmentStyles';
import { useMutation } from '@apollo/client';
import SPLIT_SEGMENT from 'src/helpers/mutations/splitSegment';
import { Alert } from '@material-ui/lab';
import { t } from 'i18next';
import CloseIcon from '@material-ui/icons/Close';
import { renderTags } from 'src/helpers';
import useErrorHandler from 'src/helpers/custom-hooks/UseErrorHandler';
import CustomSnackbar from '../custom-snackbar/CustomSnackbar';

export default function SplitSegment({ splitModalOpen, setSplitModalOpen, appState, appDispatch }: any) {

    const classes = useStyles();
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [textFields, setTextFields]: any = useState([{}]);

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const [splitSegment, { loading: loadingSplitSegments }] = useMutation(SPLIT_SEGMENT, {
        errorPolicy: 'all',
        variables: {
            data: {
                segmentId: appState.selectedRow.id,
                newSegments: textFields,
                tl: appState.selectedLanguage
            }
        },
        onCompleted: (data: any) => {
            // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
                appDispatch({
                    type: "QUERY_EXECUTE_FILTERS",
                    payload: 2
                });
            } else {
                appDispatch({
                    type: "QUERY_EXECUTE_FILTERS",
                    payload: 1
                });
            }
        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [snackBarState, setSnackBarState]: any = useState({
        vertical: 'bottom',
        horizontal: 'right',
    });

    const { vertical, horizontal }: any = snackBarState;

    const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const closeModal = () => {
        setSplitModalOpen(false);
    };

    const handleSplit = () => {
        if (textFields.length > 1) {
            splitSegment();
            closeModal();
        } else {
            setSnackBarOpen(true);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {

            // To remove Chunk with <br> in it.
            const splittedChunks: any = document.getElementsByClassName('chunks');
            if (splittedChunks) {
                setTimeout(() => {
                    Array.from(splittedChunks).forEach((chunk: any) => {
                        if (chunk.innerHTML.trim() === '<br>') {
                            chunk.remove();
                        }
                    });
                }, 0);
            }
        }
        else {
            e.preventDefault();
        }
    };

    const handleKeyUp = (e: any) => {

        const splittedChunks: any = document.getElementsByClassName('chunks');

        if (splittedChunks) {
            const splitArray = Array.from(splittedChunks).map((chunk: any) => {
                let innerHTML = chunk.innerHTML;

                // Create a temporary DOM element to manipulate the innerHTML
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = innerHTML;

                // Convert NodeList to an array to avoid live collection issues
                const imgTags = Array.from(tempDiv.getElementsByTagName('img'));

                // Replace all img tags with their alt values
                imgTags.forEach(img => {
                    const altText = img.alt;
                    img.replaceWith(document.createTextNode(altText));
                });

                return tempDiv.innerHTML;
            });

            setTextFields(splitArray);
        }
    }

    const handleSelection = () => {
        const selection = window.getSelection();
        if (selection && !selection.isCollapsed) {
            selection.removeAllRanges();
        }
    }

    return (
        <Box className={classes.snackbarContainer}>
            <Modal open={splitModalOpen} onClose={closeModal}>
                <Box className={classes.splitModal}>
                    <Box>
                        <Typography className={classes.splitModalTitle}>
                            Split Source Text
                        </Typography>

                        <IconButton className={classes.closeButton} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box className={classes.splitModalBody}>

                        <Typography className={classes.splitHeading}>
                            {t('editorMenu.splitSegmentHeading')}
                        </Typography>

                        <Box
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            className={classes.splitText}
                            onKeyDown={(e: any) => handleKeyDown(e)}
                            onKeyUp={(e: any) => handleKeyUp(e)}
                            onContextMenu={(e: any) => e.preventDefault()}
                            onSelect={() => handleSelection()}
                            onDrop={(e) => e.preventDefault()}
                        >
                            <div
                                className={`${classes.splittedChunks} chunks`}
                                dangerouslySetInnerHTML={{
                                    __html: renderTags(appState.selectedRow.source).replace(/<meta[^>]+>/g, ""),
                                }}
                            />
                        </Box>

                        <Snackbar className={classes.snackBarStyles} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={snackBarOpen}
                            autoHideDuration={3000} onClose={handleSnackBarClose}>
                            <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
                                {t('editorMenu.singleSplitAlert')}
                            </Alert>
                        </Snackbar>
                    </Box>

                    <Box className={classes.splitModalFooter}>
                        <Button variant="contained" color="primary" onClick={handleSplit}>
                            Split
                        </Button>
                    </Box>
                </Box>
            </Modal >

            <CustomSnackbar
                error={gqlError}
                open={gqlErrorSnackBar}
                onClose={handleErrorSnackBarClose}
            />
        </Box >
    )
}