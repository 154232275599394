const Languages = [{
    id: 0,
    lang: "Abkhaz"
}, {
    id: 1,
    lang: "Acholi"
}, {
    id: 2,
    lang: "Afar"
}, {
    id: 3,
    lang: "Afrikaans"
}, {
    id: 4,
    lang: "Albanian"
}, {
    id: 5,
    lang: "Amharic"
}, {
    id: 6,
    lang: "Arabic (Saudi Arabia)"
}, {
    id: 7,
    lang: "Arabic (Algeria)"
}, {
    id: 8,
    lang: "Arabic (Bahrain)"
}, {
    id: 9,
    lang: "Arabic (Egypt)"
}, {
    id: 10,
    lang: "Arabic (Iraq)"
}, {
    id: 11,
    lang: "Arabic (Jordan)"
}, {
    id: 12,
    lang: "Arabic (Kuwait)"
}, {
    id: 13,
    lang: "Arabic (Lebanon)"
}, {
    id: 14,
    lang: "Arabic (Libya)"
}, {
    id: 15,
    lang: "Arabic (Morocco)"
}, {
    id: 16,
    lang: "Arabic (Oman)"
}, {
    id: 17,
    lang: "Arabic (Qatar)"
}, {
    id: 18,
    lang: "Arabic (Sudan)"
}, {
    id: 19,
    lang: "Arabic (Syria)"
}, {
    id: 20,
    lang: "Arabic (Tunisia)"
}, {
    id: 21,
    lang: "Arabic (United Arab Emirates)"
}, {
    id: 22,
    lang: "Arabic (Yemen)"
}, {
    id: 23,
    lang: "Hiligaynon"
}, {
    id: 24,
    lang: "Armenian"
}, {
    id: 25,
    lang: "Assamese"
}, {
    id: 26,
    lang: "Avestan"
}, {
    id: 27,
    lang: "Aymara"
}, {
    id: 28,
    lang: "Azerbaidjani"
}, {
    id: 29,
    lang: "Azerbaijani (Cyrillic)"
}, {
    id: 30,
    lang: "Azerbaijani (Latin)"
}, {
    id: 31,
    lang: "Bashkir"
}, {
    id: 32,
    lang: "Basque"
}, {
    id: 33,
    lang: "Belarusian"
}, {
    id: 34,
    lang: "Bulgarian"
}, {
    id: 35,
    lang: "Breton (France)"
}, {
    id: 36,
    lang: "Bosnian (B. &amp; H.)"
}, {
    id: 37,
    lang: "Bosnian (Latin, B. &amp; H.)"
}, {
    id: 38,
    lang: "Bengali (Bangladesh)"
}, {
    id: 39,
    lang: "Bengali (India)"
}, {
    id: 40,
    lang: "Burmese"
}, {
    id: 41,
    lang: "Catalan (Andorra)"
}, {
    id: 42,
    lang: "Catalan (Spain)"
}, {
    id: 43,
    lang: "Catalan (Italy)"
}, {
    id: 44,
    lang: "Cebuano"
}, {
    id: 45,
    lang: "Chechen"
}, {
    id: 46,
    lang: "Chichewa"
}, {
    id: 47,
    lang: "Chinese Simplified"
}, {
    id: 48,
    lang: "Chinese Simplified (Singapore)"
}, {
    id: 49,
    lang: "Chinese Simplified (Hong Kong)"
}, {
    id: 50,
    lang: "Chinese Simplified (Macao)"
}, {
    id: 51,
    lang: "Chinese Traditional (Hong Kong)"
}, {
    id: 52,
    lang: "Chinese Traditional (Macao)"
}, {
    id: 53,
    lang: "Chinese Traditional (Taiwan)"
}, {
    id: 54,
    lang: "Cornish"
}, {
    id: 55,
    lang: "Corsican"
}, {
    id: 56,
    lang: "Croatian (Croatia)"
}, {
    id: 57,
    lang: "Croatian (B. &amp; H.)"
}, {
    id: 58,
    lang: "Czech"
}, {
    id: 59,
    lang: "Danish"
}, {
    id: 60,
    lang: "Dari"
}, {
    id: 61,
    lang: "Divehi (Maldives)"
}, {
    id: 62,
    lang: "Dutch (Netherlands)"
}, {
    id: 63,
    lang: "Dutch (Belgium)"
}, {
    id: 64,
    lang: "Faroese"
}, {
    id: 65,
    lang: "English (United States)"
}, {
    id: 66,
    lang: "English (United Kingdom)"
}, {
    id: 67,
    lang: "English (World)"
}, {
    id: 68,
    lang: "English (Europe)"
}, {
    id: 69,
    lang: "English (Australia)"
}, {
    id: 70,
    lang: "English (Canada)"
}, {
    id: 71,
    lang: "English (India)"
}, {
    id: 72,
    lang: "English (Ireland)"
}, {
    id: 73,
    lang: "English (Malta)"
}, {
    id: 74,
    lang: "English (New Zealand)"
}, {
    id: 75,
    lang: "English (Philippines)"
}, {
    id: 76,
    lang: "English (Singapore)"
}, {
    id: 77,
    lang: "English (South Africa)"
}, {
    id: 78,
    lang: "Ewe (Ghana)"
}, {
    id: 79,
    lang: "Ewe (Togo)"
}, {
    id: 80,
    lang: "Esperanto (World)"
}, {
    id: 81,
    lang: "Estonian"
}, {
    id: 82,
    lang: "French (France)"
}, {
    id: 83,
    lang: "French (Belgium)"
}, {
    id: 84,
    lang: "French (Canada)"
}, {
    id: 85,
    lang: "French (CAR)"
}, {
    id: 86,
    lang: "French (Côte d`Ivoire)"
}, {
    id: 87,
    lang: "French (Haiti)"
}, {
    id: 88,
    lang: "French (Comoros)"
}, {
    id: 89,
    lang: "French (Morocco)"
}, {
    id: 90,
    lang: "French (Monaco)"
}, {
    id: 91,
    lang: "French (Luxembourg)"
}, {
    id: 92,
    lang: "French (Niger)"
}, {
    id: 93,
    lang: "French (Switzerland)"
}, {
    id: 94,
    lang: "Finnish"
}, {
    id: 95,
    lang: "Filipino"
}, {
    id: 96,
    lang: "Galician"
}, {
    id: 97,
    lang: "Georgian"
}, {
    id: 98,
    lang: "German (Germany)"
}, {
    id: 99,
    lang: "German (Austria)"
}, {
    id: 100,
    lang: "German (Greece)"
}, {
    id: 101,
    lang: "German (Luxembourg)"
}, {
    id: 102,
    lang: "German (Switzerland)"
}, {
    id: 103,
    lang: "Greek (Greece)"
}, {
    id: 104,
    lang: "Greek (Cyprus)"
}, {
    id: 105,
    lang: "Gujarati"
}, {
    id: 106,
    lang: "Samoan"
}, {
    id: 107,
    lang: "Spanish (Spain)"
}, {
    id: 108,
    lang: "Spanish (Latin America)"
}, {
    id: 109,
    lang: "Spanish (Argentina)"
}, {
    id: 110,
    lang: "Spanish (Bolivia)"
}, {
    id: 111,
    lang: "Spanish (Chile)"
}, {
    id: 112,
    lang: "Spanish (Colombia)"
}, {
    id: 113,
    lang: "Spanish (Costa Rica)"
}, {
    id: 114,
    lang: "Spanish (Cuba)"
}, {
    id: 115,
    lang: "Spanish (Dominican Republic)"
}, {
    id: 116,
    lang: "Spanish (Ecuador)"
}, {
    id: 117,
    lang: "Spanish (El Salvador)"
}, {
    id: 118,
    lang: "Spanish (Guatemala)"
}, {
    id: 119,
    lang: "Spanish (Honduras)"
}, {
    id: 120,
    lang: "Spanish (Mexico)"
}, {
    id: 121,
    lang: "Spanish (Nicaragua)"
}, {
    id: 122,
    lang: "Spanish (Panama)"
}, {
    id: 123,
    lang: "Spanish (Paraguay)"
}, {
    id: 124,
    lang: "Spanish (Peru)"
}, {
    id: 125,
    lang: "Spanish (Puerto Rico)"
}, {
    id: 126,
    lang: "Spanish (United States)"
}, {
    id: 127,
    lang: "Spanish (Uruguay)"
}, {
    id: 128,
    lang: "Spanish (Venezuela)"
}, {
    id: 129,
    lang: "Spanish (International)"
}, {
    id: 130,
    lang: "Persian"
}, {
    id: 131,
    lang: "Hakha Chin (Lai)"
}, {
    id: 132,
    lang: "Haitian Creole"
}, {
    id: 133,
    lang: "Hausa"
}, {
    id: 134,
    lang: "Hawaiian"
}, {
    id: 135,
    lang: "Hebrew"
}, {
    id: 136,
    lang: "Hindi"
}, {
    id: 137,
    lang: "Hungarian"
}, {
    id: 138,
    lang: "Hmong"
}, {
    id: 139,
    lang: "Hmong (SDL)"
}, {
    id: 140,
    lang: "Icelandic"
}, {
    id: 141,
    lang: "Igbo"
}, {
    id: 142,
    lang: "Indonesian"
}, {
    id: 143,
    lang: "Irish"
}, {
    id: 144,
    lang: "Italian (Italy)"
}, {
    id: 145,
    lang: "Italian (Switzerland)"
}, {
    id: 146,
    lang: "Japanese"
}, {
    id: 147,
    lang: "Kabuverdianu"
}, {
    id: 148,
    lang: "Kannada"
}, {
    id: 149,
    lang: "Karen"
}, {
    id: 150,
    lang: "Kashmiri"
}, {
    id: 151,
    lang: "Kazakh"
}, {
    id: 152,
    lang: "Khmer"
}, {
    id: 153,
    lang: "Kinyarwanda"
}, {
    id: 154,
    lang: "Korean"
}, {
    id: 155,
    lang: "Kurdish"
}, {
    id: 156,
    lang: "Kyrgyz"
}, {
    id: 157,
    lang: "Lao"
}, {
    id: 158,
    lang: "Latvian"
}, {
    id: 159,
    lang: "Lithuanian"
}, {
    id: 160,
    lang: "Lingala (Congo)"
}, {
    id: 161,
    lang: "Lingala (Angola)"
}, {
    id: 162,
    lang: "Lingala (Central African Republic)"
}, {
    id: 163,
    lang: "Ilocano"
}, {
    id: 164,
    lang: "Luxembourgish"
}, {
    id: 165,
    lang: "Macedonian"
}, {
    id: 166,
    lang: "Malagasy"
}, {
    id: 167,
    lang: "Malayalam"
}, {
    id: 168,
    lang: "Malay"
}, {
    id: 169,
    lang: "Marathi"
}, {
    id: 170,
    lang: "Marshallese"
}, {
    id: 171,
    lang: "Maltese"
}, {
    id: 172,
    lang: "Maori"
}, {
    id: 173,
    lang: "Mongolian"
}, {
    id: 174,
    lang: "Nauru"
}, {
    id: 175,
    lang: "Ndebele"
}, {
    id: 176,
    lang: "Nepali"
}, {
    id: 177,
    lang: "Nepali (India)"
}, {
    id: 178,
    lang: "Ndonga"
}, {
    id: 179,
    lang: "Norwegian"
}, {
    id: 180,
    lang: "Norwegian Bokmål"
}, {
    id: 181,
    lang: "Norwegian Nynorsk"
}, {
    id: 182,
    lang: "Occitan"
}, {
    id: 183,
    lang: "Oriya"
}, {
    id: 184,
    lang: "Oromo (Ethiopia)"
}, {
    id: 185,
    lang: "Oromo (Kenya)"
}, {
    id: 186,
    lang: "Ossetic (Georgia)"
}, {
    id: 187,
    lang: "Ossetic (Russia)"
}, {
    id: 188,
    lang: "Punjabi"
}, {
    id: 189,
    lang: "Quechua (Bolivia)"
}, {
    id: 190,
    lang: "Quechua (Ecuador)"
}, {
    id: 191,
    lang: "Quechua (Peru)"
}, {
    id: 192,
    lang: "Polish"
}, {
    id: 193,
    lang: "Pashto"
}, {
    id: 194,
    lang: "Portuguese (Portugal)"
}, {
    id: 195,
    lang: "Portuguese (Brazil)"
}, {
    id: 196,
    lang: "Romansh"
}, {
    id: 197,
    lang: "Rundi"
}, {
    id: 198,
    lang: "Romanian"
}, {
    id: 199,
    lang: "Russian"
}, {
    id: 200,
    lang: "Northern Sami (Sweden)"
}, {
    id: 201,
    lang: "Northern Sami (Finland)"
}, {
    id: 202,
    lang: "Northern Sami (Norway)"
}, {
    id: 203,
    lang: "Inari Sami (Finland)"
}, {
    id: 204,
    lang: "Sanskrit"
}, {
    id: 205,
    lang: "Sardinian"
}, {
    id: 206,
    lang: "Scottish"
}, {
    id: 207,
    lang: "Sindhi"
}, {
    id: 208,
    lang: "Sinhala"
}, {
    id: 209,
    lang: "Slovak"
}, {
    id: 210,
    lang: "Slovenian"
}, {
    id: 211,
    lang: "Shona"
}, {
    id: 212,
    lang: "Somali"
}, {
    id: 213,
    lang: "Serbian"
}, {
    id: 214,
    lang: "Serbian (Cyrl, Serbia)"
}, {
    id: 215,
    lang: "Serbian (Cyrl, B. &amp; H.)"
}, {
    id: 216,
    lang: "Serbian (Cyrl, S. &amp; M.)"
}, {
    id: 217,
    lang: "Serbian (Latin, Serbia)"
}, {
    id: 218,
    lang: "Serbian (Latin, B. &amp; H.)"
}, {
    id: 219,
    lang: "Serbian (Latin, S. &amp; M.)"
}, {
    id: 220,
    lang: "Sundanese"
}, {
    id: 221,
    lang: "Swedish"
}, {
    id: 222,
    lang: "Swedish (Finland)"
}, {
    id: 223,
    lang: "Swahili (Kenya)"
}, {
    id: 224,
    lang: "Swahili (Congo - Kinshasa)"
}, {
    id: 225,
    lang: "Swahili (Tanzania)"
}, {
    id: 226,
    lang: "Swahili (Uganda)"
}, {
    id: 227,
    lang: "Syriac"
}, {
    id: 228,
    lang: "Tamil"
}, {
    id: 229,
    lang: "Tagalog"
}, {
    id: 230,
    lang: "Tagalog（SDL）"
}, {
    id: 231,
    lang: "Tahitian"
}, {
    id: 232,
    lang: "Tajik"
}, {
    id: 233,
    lang: "Tatar"
}, {
    id: 234,
    lang: "Telugu"
}, {
    id: 235,
    lang: "Thai"
}, {
    id: 236,
    lang: "Tibetan (China)"
}, {
    id: 237,
    lang: "Tigrinya (Tigrinya)"
}, {
    id: 238,
    lang: "Tigrinya (Eritrea)"
}, {
    id: 239,
    lang: "Tongan"
}, {
    id: 240,
    lang: "Turkmen"
}, {
    id: 241,
    lang: "Turkish"
}, {
    id: 242,
    lang: "Tsonga"
}, {
    id: 243,
    lang: "Tswana"
}, {
    id: 244,
    lang: "Uyghur (China)"
}, {
    id: 245,
    lang: "Urdu (Pakistan)"
}, {
    id: 246,
    lang: "Urdu (India)"
}, {
    id: 247,
    lang: "Ukrainian"
}, {
    id: 248,
    lang: "Uzbek"
}, {
    id: 249,
    lang: "Uzbek (Cyrillic)"
}, {
    id: 250,
    lang: "Uzbek (Latin)"
}, {
    id: 251,
    lang: "Vietnamese"
}, {
    id: 252,
    lang: "Walloon"
}, {
    id: 253,
    lang: "Welsh"
}, {
    id: 254,
    lang: "Western Frisian"
}, {
    id: 255,
    lang: "Wolof"
}, {
    id: 256,
    lang: "Xhosa"
}, {
    id: 257,
    lang: "Yoruba"
}, {
    id: 258,
    lang: "Yiddish"
}, {
    id: 259,
    lang: "Cantonese"
}, {
    id: 260,
    lang: "Zulu"
}]

export default Languages;