import React, { useState } from 'react';
import { Box, Typography, TextField, Checkbox, InputLabel, Button } from '@material-ui/core';
import useStyles from "../../pages/projects/ProjectStyles";

const DefaultPage = () => {
    const classes = useStyles();

    return (
        <Box className={classes.defaultPage} px={8} py={8}>
            <Box display={'flex'} justifyContent={'center'}>
                <img style={{ width: '300px', height: '300px' }} src="/assets/images/Marscat.svg" alt="MarsCat" />
            </Box>
        </Box>
    );
}

export default DefaultPage;