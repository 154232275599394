
const shortcutList = [
  { name: "shortcuts.cut", wkey: "Ctrl + X", mkey: "Cmd + X" },
  { name: "common.copy", wkey: "Ctrl + C", mkey: "Cmd + C" },
  { name: "shortcuts.paste", wkey: "Ctrl + V", mkey: "Cmd + V" },
  { name: "shortcuts.confirmSegment", wkey: "Ctrl + Enter", mkey: "Cmd + Enter" },
  { name: "common.addTerm", wkey: "F4", mkey: "Opt + T" },
  { name: "shortcuts.addNotes", wkey: "Alt + M", mkey: "Opt + M" },
  { name: "shortcuts.copySourceToTarget", wkey: "Ctrl + I", mkey: "Cmd + I" },
  { name: "shortcuts.clearTranslation", wkey: "Ctrl + D", mkey: "Cmd + D" },
  { name: "common.mergeSegments", wkey: "Ctrl + M", mkey: "Opt + M" },
  { name: "shortcuts.findReplace", wkey: "Alt + S", mkey: "Opt + S" },
  { name: "shortcuts.relatedSearch", wkey: "Alt + F", mkey: "Opt + F" },
  { name: "shortcuts.selectAll", wkey: "Ctrl + A", mkey: "Cmd + A" },
  { name: "shortcuts.nextTextRange", wkey: "Tab", mkey: "Tab" },
  { name: "shortcuts.applyMachineTranslation", wkey: "F1", mkey: "F1" },
  { name: "shortcuts.multiEngineMachineTranslation", wkey: "F2", mkey: "F2" },
  { name: "shortcuts.lock", wkey: "Ctrl + L", mkey: "Cmd + L" },
  { name: "shortcuts.unlock", wkey: "Ctrl + K", mkey: "Cmd + K" },
  { name: "shortcuts.translated", wkey: "Ctrl + Enter", mkey: "Cmd + Enter" },
  { name: "shortcuts.reviewed", wkey: "Alt + Enter", mkey: "Opt + Enter" },
  { name: "shortcuts.translationRejected", wkey: "Alt + P", mkey: "Opt + P" },
  { name: "shortcuts.translationApproved", wkey: "Alt + A", mkey: "Opt + A" },
  { name: "shortcuts.concordanceSearch", wkey: "F3", mkey: "F3" },
];
export default shortcutList; 