const MakePropArray=(array:any)=>{
    let temp=array;
    function groupBy(objectArray:any, property:any) {
        return objectArray.reduce(function (acc:any, obj:any) {
          var key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      }
      var groupedPeople = groupBy(temp, 's');
      return Object.values(groupedPeople)

   }

   export default MakePropArray;