import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "@apollo/client";
import { errorControl } from "../helpers/errorHandler";


// import { RestLink } from "apollo-link-rest";
// import { env } from "process";
// console.log('REACT_APP_ENDPOINT ',process?.env?.REACT_APP_ENDPOINT);
// http://local.marseditor.com/    local server

let reactAppEndPoint: any = process.env?.REACT_APP_ENDPOINT;

const httpLink = new HttpLink({
  // uri: 'http://localhost:8085/',

  uri: reactAppEndPoint,

  headers: {
    'Content-Type': 'application/json',
    "authorization": 'Bearer ' + localStorage.getItem('access_token')
    // 'Access-Control-Allow-Origin': '*',
    //  "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    // 'Accept': '*/*',
  },
  // useGETForQueries:true,
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorControl.concat(ApolloLink.from([httpLink])),
});

// export const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   uri: "http://me-backend.lifesciencetranslation.com:8084/"
// });