import React, { MouseEvent } from 'react';
import { Box, MenuItem, Menu, Fade, IconButton } from "@material-ui/core";
import { ShortTextIcon, ArrowBackIosIcon } from 'src/material-ui';
import useStyles from './HeaderStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    data: any;
    chooseFile: Function;
    setIsSelectDoc: any;
    role: any;
}

const SelectDocument = ({ data, chooseFile, setIsSelectDoc, role }: IProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (role === "PM") {
            setOpen(!open);
            setAnchorEl(event.currentTarget);
            setIsSelectDoc(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        setIsSelectDoc(false);
    };

    function backToTaskList() {
        navigate('/');
    }

    return (
        <Box ml={2}>
            <IconButton edge="start" onClick={handleClick}>
                <ShortTextIcon color="primary" fontSize="large" />
            </IconButton>
            <Menu
                id="UserMenu"
                className={classes.headerMenu}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        minWidth: 200,
                    },
                }}
            >
                {/* <MenuItem onClick={backToTaskList}><ArrowBackIosIcon fontSize="medium" /> {t('header.backToTaskList')}</MenuItem> */}
                {(data && data?.length > 0) && data?.map((item: any, index: number) =>
                    <MenuItem key={index}
                        // className={classes.item}
                        onClick={(e) => { chooseFile(e, item?.filename, item); handleClose(); }}
                    >{item?.filename}</MenuItem>
                )}
            </Menu>

        </Box>
    )
}

export default React.memo(SelectDocument);