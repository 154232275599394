import React, { useState } from 'react';
import { Box, Typography, TextField, Checkbox, InputLabel, Button } from '@material-ui/core';
import useStyles from "../../pages/projects/ProjectStyles";
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Languages from './languages';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

const CreateProject = () => {
    const classes = useStyles();

    // const navigate = useNavigate();

    const [allowMachineTranslation, setAllowMachineTranslation] = useState(false);

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setChecked(event.target.checked);
    // };

    const [files, setFiles] = useState<File[]>([]);

    const [startDate, setStartDate]: any = useState(new Date());

    const industry = [
        { id: 1, value: "General" },
        { id: 2, value: "Certificates" },
        { id: 3, value: "Advertising and Marketing" },
        { id: 4, value: "Automative" },
        { id: 5, value: "Business/Finance and Insurance" },
        { id: 6, value: "Chemical" },
        { id: 7, value: "Electronics and Home Appliances" },
        { id: 8, value: "Energy and Environment" },
        { id: 9, value: "Hotel and Catering" },
        { id: 10, value: "Gaming and Video Games" },
        { id: 11, value: "Medical and Healthcare" },
        { id: 12, value: "History and Archaeology" },
        { id: 13, value: "Legal and Contracts" },
        { id: 14, value: "Library and Art" },
        { id: 15, value: "Software and IT" },
        { id: 16, value: "Telecommunication" },
        { id: 17, value: "Tourism" },
        { id: 18, value: "Technical and Engineering" },
        { id: 19, value: "Education and E-learning" },
        { id: 20, value: "Patent" },
        { id: 21, value: "Film and Entertainment" },
        { id: 22, value: "Beauty and Cosmetics" },
    ];

    const workflow = [
        { id: 1, value: "Translation" },
        { id: 2, value: "Translation + Proofreading" },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    // function handleFinish() {
    //     navigate('/home');
    // }

    const initialValues = {
        name: '',
        industry: null,
        sourceLanguage: null,
        targetLanguage: null,
        deadline: startDate,
        workflow: null,
        allowMachineTranslation: false,
        files: []
    };

    const handleSubmit = (values: any) => {
        console.log("VALUES", values);
    };

    const handleDateChange = (date: any) => {
        setStartDate(date);
    };


    return (
        <Box className={classes.createProject} px={8} py={8}>
            <Box className={classes.addPaper}>
                <Typography className={classes.projectHeading}>
                    Create Project
                </Typography>

                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box className={classes.feildWrap}>
                                    <InputLabel className={`${classes.labelStyles}  ${classes.required}`}>
                                        Name:
                                    </InputLabel>
                                    <TextField
                                        name="name"
                                        value={values.name}
                                        onChange={(e) => setFieldValue('name', e.target.value)}
                                        className={classes.formFeilds}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>


                                <Box className={classes.feildWrap}>
                                    <InputLabel className={`${classes.labelStyles}  ${classes.required}`}>
                                        Industry:
                                    </InputLabel>
                                    <Autocomplete
                                        className={classes.formFeilds}
                                        id="combo-box-demo"
                                        options={industry}
                                        getOptionLabel={(option: any) => option.value}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        value={values.industry}
                                        onChange={(e, newValue) => setFieldValue('industry', newValue)}
                                    />
                                </Box>
                            </Box>

                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box className={classes.feildWrap}>
                                    <InputLabel className={`${classes.labelStyles}  ${classes.required}`}>
                                        Source Language:
                                    </InputLabel>
                                    <Autocomplete
                                        className={classes.formFeilds}
                                        id="sourceLanguage"
                                        options={Languages}
                                        getOptionLabel={(option) => option.lang}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        value={values.sourceLanguage}
                                        onChange={(e, newValue) => setFieldValue('sourceLanguage', newValue)}
                                    />
                                </Box>

                                <Box className={classes.feildWrap}>
                                    <InputLabel className={`${classes.labelStyles}  ${classes.required}`}>
                                        Target Language:
                                    </InputLabel>
                                    <Autocomplete
                                        className={classes.formFeilds}
                                        id="combo-box-demo"
                                        options={Languages}
                                        getOptionLabel={(option) => option.lang}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        value={values.targetLanguage}
                                        onChange={(e, newValue) => setFieldValue('targetLanguage', newValue)}
                                    />
                                </Box>
                            </Box>

                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box className={`${classes.feildWrap} ${classes.deadline}`}>
                                    <InputLabel className={`${classes.labelStyles} ${classes.required}`}>
                                        Deadline:
                                    </InputLabel>

                                    <DatePicker
                                        name="deadline"
                                        className={classes.dateFeild}
                                        showIcon
                                        selected={startDate}
                                        onChange={(date: any) => { setFieldValue('deadline', date); handleDateChange(date); }}
                                    // onChange={(date: any) => setStartDate(date)}
                                    />
                                </Box>

                                <Box className={classes.feildWrap}>
                                    <InputLabel className={`${classes.labelStyles}  ${classes.required}`}>
                                        Workflow:
                                    </InputLabel>

                                    <Autocomplete
                                        className={classes.formFeilds}
                                        id="combo-box-demo"
                                        options={workflow}
                                        getOptionLabel={(option) => option.value}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        value={values.workflow}
                                        onChange={(e, newValue) => setFieldValue('workflow', newValue)}
                                    />
                                </Box>
                            </Box>

                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Checkbox
                                    className={classes.checkboxColor}
                                    // onChange={handleChange}
                                    checked={allowMachineTranslation}
                                    onChange={(event) => {
                                        setAllowMachineTranslation(event.target.checked);
                                        setFieldValue('allowMachineTranslation', event.target.checked);
                                    }}
                                />

                                <Typography className={classes.checkboxLabel}>
                                    Allow machine translation
                                </Typography>
                            </Box>

                            <Box className={classes.feildWrap}>
                                <Typography className={classes.labelStyles}>
                                    Files:
                                </Typography>

                                <DropzoneArea
                                    acceptedFiles={['image/*', ".pdf", ".doc", ".xlsx", ".txt", ".html", ".zip"]}
                                    dropzoneText={'Click or drag the file to upload'}
                                    onChange={(newFiles) => {
                                        setFiles(newFiles);
                                        setFieldValue('files', newFiles);
                                    }}
                                    // onChange={(newFiles: File[]) => setFiles(newFiles)}
                                    filesLimit={1}
                                />
                            </Box>

                            <Box py={'20px'} display={'flex'} justifyContent={'center'}>
                                <Button type="submit" className={classes.AddBtn} size="medium" variant="contained" color="primary">
                                    Finish
                                </Button>
                            </Box>

                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
}

export default CreateProject;