import { useState } from 'react';
import { handleErrors } from "src/helpers/errorHandler";

const useErrorHandler = () => {
    const [gqlError, setGqlError] = useState<string>("");
    const [gqlErrorSnackBar, setGqlErrorSnackBar] = useState<boolean>(false);

    const handleErrorSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setGqlErrorSnackBar(false);
    };

    const handleError = (error: any) => {
        handleErrors({ graphQLErrors: error.graphQLErrors, networkError: error.networkError });
        setGqlError(error.message || 'An error occurred');
        setGqlErrorSnackBar(true);
    };

    return {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    };
};

export default useErrorHandler;
