import React, { useState, useEffect } from "react";
import useStyles from "./MenuStyle";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import RemoveIcon from "@material-ui/icons/Remove";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import { RiEraserFill } from 'react-icons/ri';
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import { Button, Box, Modal, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from '@apollo/client';
import MERGE_SEGMENT from "src/helpers/mutations/mergeSegment";


const Menu = ({ menuData, flag, isLocked, appDispatch, appState, anchorPoint, show, setOpen, setToggle, setRestrictSave, setLoad, mergeSegment, getCurrentSegment, setPasteAlert, headerData, setMTAlert }: any) => {
  const classes = useStyles({});
  const user_type = appState?.selectedService?.code == 'translation' ? true : false;
  const style = (): any => {
    return {
      width: "347px",
      height: "auto",
      zIndex: 2,
      backgroundColor: "#ffffff",
      borderRadius: "0px",
      color: "#353535",
      flexDirection: "column",
      padding: "0 10px",
      top: anchorPoint.y,
      left: anchorPoint.x,
      position: "absolute",
      display: show ? "flex" : "none",
      border: "1px solid #c5c5c5",
    };
  };

  const checkOs =
    window?.navigator?.userAgent?.indexOf("Mac") !== -1
      ? false
      : true;
  let text = window?.getSelection()?.toString();

  const onCut = (e: any) => {
    document.execCommand('cut');
  };

  const onCopy = (e: any) => {
    document.execCommand('copy');
  };

  const onPaste = async (e: any) => {
    setPasteAlert(true)
  };

  const onLock = (e: any) => {
    e?.preventDefault();
    menuData("lock");
  };

  const onUnLock = (e: any) => {
    e?.preventDefault();
    menuData("unlock");
    let temp = menuItem;
    temp.forEach((item: any) => {
      if (item.id === 11) {
        item.enable = 0
      }
      else {
        item.enable = 1
      }
    })
    setMenuItem(temp);
  };

  const onDraft = (e: any) => {
    menuData("draft");
  };

  const onTranslated = (e: any) => {
    setLoad(true);
    menuData("translated");
  };

  const onReviewed = (e: any) => {
    menuData("reviewed");
  };

  const onTranslationApproved = (e: any) => {
    menuData("approved");
  };

  const onTranslationRejected = (e: any) => {
    menuData("rejected");
  };

  const onApplyMachineTranslation = (e: any) => {
    if (headerData.isMTEnabled) {

      let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);

      menuData('machineTranslation');

      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: appState.selectedRow.id,
          history: rowHtml.innerHTML,
          state: appState.selectedRow.state,
          tStatusType: appState.selectedRow.tStatusType,
          tStatusValue: appState.selectedRow.tStatusValue,
          isMT: 1
        },
      });
    } else {
      setMTAlert(true);
    }
  };

  const onApplyMachineTranslationWithoutTags = (e: any) => {
    if (headerData.isMTEnabled) {

      let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);

      menuData('machineTranslationWithoutTags');

      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: appState.selectedRow.id,
          history: rowHtml.innerHTML,
          state: appState.selectedRow.state,
          tStatusType: appState.selectedRow.tStatusType,
          tStatusValue: appState.selectedRow.tStatusValue,
          isMT: 1
        },
      });
    } else {
      setMTAlert(true);
    }
  };

  const onMultiEngineMachineTranslation = (e: any) => {
    console.log("onMultiEngineMachineTranslation ", e);
  };

  const onMergeSegments = (e: any) => {
    e?.preventDefault();
    setRestrictSave(true);
    // menuData("merge");
    if (getCurrentSegment().length > 0) {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
      localStorage.setItem("current_segment", JSON.stringify(appState.selectedRow.id));
      mergeSegment();
    }
  };

  const onExtendSelection = (e: any) => {
    e?.preventDefault();
    menuData("extendSelection");
  };

  const onNextTextRange = (e: any) => {
    e?.preventDefault();
    menuData("next_text_range");
  };

  const onCopySourcetoTarget = (e: any) => {
    appDispatch({
      type: "COPY",
      payload: appState,
    });

    let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
    appDispatch({
      type: "ADD_HISTORY",
      payload: {
        id: appState.selectedRow.id,
        history: rowHtml.innerHTML,
        state: appState.selectedRow.state,
        tStatusType: appState.selectedRow.tStatusType,
        tStatusValue: appState.selectedRow.tStatusValue,
        isCopy: 1
      },
    });
  };

  const onClearTranslation = (e: any) => {

    let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
    let rowVal = rowHtml.innerHTML;
    let rowState = appState.selectedRow.state;

    e?.preventDefault();
    appDispatch({
      type: "CLEAR",
      payload: appState,
    });

    appDispatch({
      type: "ADD_HISTORY",
      payload: {
        id: appState.selectedRow.id,
        history: rowVal == "" ? " " : rowVal,
        state: rowState,
        tStatusType: appState.selectedRow.tStatusType,
        tStatusValue: appState.selectedRow.tStatusValue,
        isClear: 1,
        isRemovePrev: 1
      },
    });

  };

  const onConcordanceSearch = (e: any) => {

    appDispatch({
      type: "CONCORDANCE_SEARCH",
      payload: { flag, text },
    });
  };

  const onAddNewTerm = (e: any) => {
    // menuData("addNewTerm");
    // setOpen(true);
    appDispatch({
      type: "ADD_NEW_TERM",
      payload: { flag, text },
    });
    setToggle({ seg: "tm|tb", id: '', textInput: '' });

    if (flag === "t") {
      let tbTarget = document.getElementById("tbTarget");
      setTimeout(() => {
        tbTarget?.focus();
      }, 1000);
    }
    else {
      let tbSource = document.getElementById("tbSource");
      setTimeout(() => {
        tbSource?.focus();
      }, 1000);
    }
  };

  const [menuItem, setMenuItem]: any = useState([
    { 'id': 0, 'title': 'shortcuts.cut', 'icon': <ShuffleIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + X" : "Cmd + X", 'enable': 1, 'function': onCut },
    { 'id': 1, 'title': 'common.copy', 'icon': <FileCopyIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + C" : "Cmd + C", 'enable': 1, 'function': onCopy },
    { 'id': 2, 'title': 'shortcuts.paste', 'icon': <FileCopyIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + V" : "Cmd + V", 'enable': 1, 'function': onPaste },
    { 'id': 3, 'title': 'menu.applyMT', 'icon': <TextFormatIcon className={classes.iconStyle} fontSize="small" />, 'key': 'F1', 'enable': 1, 'function': onApplyMachineTranslation },
    { 'id': 4, 'title': 'menu.applyMTwithout', 'icon': <TextFormatIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "" : "Cmd+X", 'enable': 1, 'function': onApplyMachineTranslationWithoutTags },
    { 'id': 5, 'title': 'menu.multiEnfineMT', 'icon': <HelpOutlineIcon className={classes.iconStyle} fontSize="small" />, 'key': 'F2', 'enable': 1, 'function': onMultiEngineMachineTranslation },
    { 'id': 6, 'title': 'common.mergeSegments', 'icon': <RemoveIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "" : "", 'enable': 1, 'function': onMergeSegments },
    { 'id': 7, 'title': 'menu.extendSelection', 'icon': <SwapHorizIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + E" : "Cmd + E", 'enable': 1, 'function': onExtendSelection },
    { 'id': 8, 'title': 'menu.nextTextRange', 'icon': <ArrowDropDownCircleOutlinedIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Tab" : "Cmd + R", 'enable': 1, 'function': onNextTextRange },
    { 'id': 9, 'title': 'common.copySourceToTarget', 'icon': <DoubleArrowOutlinedIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + I" : "Cmd + I", 'enable': 1, 'function': onCopySourcetoTarget },
    { 'id': 10, 'title': 'common.clearTranslation', 'icon': <RiEraserFill className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + D" : "Cmd + D", 'enable': 1, 'function': onClearTranslation },
    { 'id': 11, 'title': 'menu.lock', 'icon': <LockIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + L" : "Cmd + L", 'enable': 1, 'function': onLock },
    { 'id': 12, 'title': 'menu.unlock', 'icon': <LockOpenIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "Ctrl + K" : "Cmd + K", 'enable': 1, 'function': onUnLock },
    { 'id': 13, 'title': 'filters.draft', 'icon': <EditIcon className={`${classes.iconStyle} ${classes.iconPrimary}`} fontSize="small" />, 'key': '', 'enable': 1, 'function': onDraft },
    { 'id': 14, 'title': 'common.translated', 'icon': <CheckIcon className={`${classes.iconStyle} ${classes.iconPrimary}`} fontSize="small" />, 'key': checkOs ? "Ctrl + Enter" : "Cmd + Enter", 'enable': 1, 'function': onTranslated },
    { 'id': 15, 'title': 'common.reviewed', 'icon': <CheckCircleIcon className={`${classes.iconStyle} ${classes.iconPrimary}`} fontSize="small" />, 'key': checkOs ? "ALT+Enter" : "Option + Enter", 'enable': 1, 'function': onReviewed },
    { 'id': 16, 'title': 'menu.translationRejected', 'icon': <ErrorIcon className={`${classes.iconStyle} ${classes.iconWarning}`} fontSize="small" />, 'key': checkOs ? "ALT + P" : "Option + P", 'enable': 1, 'function': onTranslationRejected },
    { 'id': 17, 'title': 'menu.translationApproved', 'icon': <CheckCircleIcon className={`${classes.iconStyle} ${classes.iconSuccess}`} fontSize="small" />, 'key': checkOs ? "ALT + A" : "Option + A", 'enable': 1, 'function': onTranslationApproved },
    { 'id': 18, 'title': 'menu.concordanceSearch', 'icon': <SearchIcon className={classes.iconStyle} fontSize="small" />, 'key': checkOs ? "F3" : "fn + F3", 'enable': 1, 'function': onConcordanceSearch },
    { 'id': 19, 'title': 'menu.addNewTerm', 'icon': <AddIcon className={classes.iconStyle} fontSize="small" />, 'key': 'F4', 'enable': 1, 'function': onAddNewTerm },
  ]);


  useEffect(() => {
    // let temp = menuItem;
    let tempArr: any = [];
    if (menuItem && menuItem.length > 0) {
      menuItem.forEach((item: any) => {

        if (isLocked === false || item.id === 1 || item.id === 12) {
          item.enable = 1
        } else {
          item.enable = 0
        }

        if ((item.id === 15 || item.id === 17 || item.id === 16) && user_type === true) {
          item.enable = 0
        }
        if (item.id === 14 && user_type === false) {
          item.enable = 0
        }

        if ((item.id === 6 || item.id === 8) && getCurrentSegment() === 0) {
          item.enable = 0
        }

        tempArr.push(item);
      });
    }

    setMenuItem(tempArr);

  }, [isLocked, user_type, appState.selectedRow.id]);

  const { t } = useTranslation();

  const dividerTitles = ['shortcuts.paste', 'menu.multiEnfineMT', 'common.mergeSegments', 'menu.nextTextRange', 'common.clearTranslation', isLocked === false ? 'menu.unlock' : '', appState.selectedService.isAssigned === 1 ? 'common.translated' : '', 'menu.translationApproved', 'menu.concordanceSearch'];

  return (
    <>
      {show ?
        <div style={style()}>
          {menuItem.map((item: any, index: any) =>
            item.enable === 1 ?
              <>
                <div className={flag === 't' ? `${classes.menuDiv} menuItem` : (index === 0 || index === 2 ? `${classes.sourceMenuDiv} menuItem` : `${classes.menuDiv} menuItem`)
                } onClick={item.function}>
                  <div className={classes.menuItems}>
                    <div className={classes.menuIcon}>
                      {item.icon}
                    </div>
                    <div className={classes.menuTitle}>
                      {t(item.title)}
                    </div>
                  </div>
                  <div className={classes.menuInfo}>
                    {item.key}
                  </div>
                </div>
                {dividerTitles.includes(item.title) && <Divider />} {/* Add Divider at specified indexes */}

              </>
              :
              <></>
          )}
        </div> : ''}

    </>
  );
};

export default Menu;
