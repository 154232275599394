import React, { useEffect, useReducer, useState, useRef, Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, CssBaseline } from "@material-ui/core";
import {
    ThemeConfig,
    ThemeConfigDark,
    ThemeConfigGreen,
} from "src/material-ui";
import { Header, EditorMenu, BottomBar, Layout } from "src/components";
import { useLazyQuery } from "@apollo/client";
import PROJECT_DETAIL from "src/helpers/queries/ProjectDetailsById";
import AppReducer, { initialState } from "../../AppReducer";
import "../../App.css";
import ActionReducer, { intialState } from "../../components/editor-menu/ActionReducer";

const Home = () => {
    const [themeType, setThemeType] = useState("light");
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const textInput: any = useRef(null);
    const [toggle, setToggle]: any = useState({ seg: "tm|tb", id: 0, textInput: textInput });
    const [tbRowData, setTbRowData] = useState({
        source: "",
        target: "",
        comment: ""
    });
    const [totalPages, settotalPages] = useState(6);
    const [currentPage, setcurretPage] = useState(1);
    const [rowsPerPage, setRowsPerPage]: any = React.useState(50);

    const [tempPage, setTempPage] = useState(1);
    const [actionState, actionDispatch] = useReducer(ActionReducer, intialState);
    const [scrollValue, setScrollValue]: any = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [fromEditorMenu, setFromEditorMenu] = useState(false);
    const [verify, setVerify] = useState(false);
    const [notConfirmed, setNotConfirmed] = useState(false);


    let [projectId, setProjectId] = useState("");

    let userType = state.user_type;

    const [GetProjectDetailById, { data, error }] = useLazyQuery(
        PROJECT_DETAIL
    );

    useEffect(() => {
        let id = window.location.href.split('?projectId=')[1].split('&')[0];
        if (id != "" && typeof id != "undefined") {
            setProjectId(id);
        }
    }, [window.location.href]);

    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollValue(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollValue]);

    useEffect(() => {
        if (state?.pagination && state?.pagination[0]) {
            let totalP = state?.pagination[0]?.totalPages;
            let currentP = state.pagination[0].currentPage;
            settotalPages(parseInt(totalP));
            setcurretPage(currentP);
        }
    }, [state.pagination]);

    const getQueryParam = (parameterName: any) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(parameterName);
    };

    useEffect(() => {
        // ?id=202209231663918116
        // window.location.search = 'id=' + projectId // ?.search?.split('id=')[1];

        let id = getQueryParam('id');
        let isRO = getQueryParam('ro');

        let fileId = getQueryParam('fileId');
        let tl = getQueryParam('tl');
        let type = getQueryParam('type');

        let startVal = getQueryParam('start');

        let endVal = getQueryParam('end');

        let start = null;
        let end = null;
        if (typeof startVal != "undefined" && startVal) {
            start = parseInt(startVal);
        }
        if (typeof endVal != "undefined" && endVal) {
            end = parseInt(endVal);
        }

        if (id) {
            setProjectId(id);
            GetProjectDetailById({
                variables: {
                    id: id,
                    readonly: isRO == "1" ? 1 : 0,
                    fileId: fileId + "",
                    tl: tl ? tl : "",
                    service: type?.toLowerCase(),
                    start: start,
                    end: end
                }
            });

        } else if (projectId !== "") {
            GetProjectDetailById({
                variables: {
                    id: projectId,
                    readonly: isRO == "1" ? 1 : 0,
                    fileId: fileId + "",
                    tl: tl ? tl : "",
                    service: type?.toLowerCase(),
                    start: start,
                    end: end
                }
            });
        }

        // const script2 = document.createElement("script");
        // script2.src = "assets/js/marsEditor.js";
        // script2.async = true;
        // script2.onload = () => scriptLoaded2();
        // document.body.appendChild(script2);
        // return () => {
        //     document.body.removeChild(script2);
        // };

        // You may find that it works just fine, or you may not.
        // SUPPORTED TYPESCRIPT VERSIONS: >= 3.3.1 < 4.2.0
        // YOUR TYPESCRIPT VERSION: 4.3.5

    }, [projectId]);



    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "z" && e.ctrlKey) {
            // @ts-ignore
            dispatch({ type: "UNDO" });
        } else if (e.key === "y" && e.ctrlKey) {
            // @ts-ignore
            dispatch({ type: "REDO" });
        }
    };

    if (error && error?.message) {
        return <div>
            Error {error?.message}
        </div>
    }

    const handleChangePagination = (e: any, value: number) => {
        window.scrollTo(0, 0);
        setTempPage(value);
        // setcurretPage(value);
    }

    return (
        <Suspense fallback="loading">
            <div className="App">
                <ThemeProvider
                    theme={
                        themeType === "dark"
                            ? ThemeConfigDark
                            : themeType === "light"
                                ? ThemeConfig
                                : ThemeConfigGreen
                    }
                >
                    <CssBaseline />
                    {(data && data?.getProjectDetailById && data?.getProjectDetailById?.files && projectId) ? <Header
                        setThemeType={setThemeType}
                        themeType={themeType}
                        appDispatch={dispatch}
                        appState={state}
                        actionDispatch={actionDispatch}
                        headerData={data?.getProjectDetailById}
                        projectId={projectId}
                        currentPage={currentPage}
                        userType={userType}
                        rowsPerPage={rowsPerPage}
                        verify={verify}
                    /> : <div></div>}

                    <Box className={expanded ? "handleBottom" : ''} onKeyDown={handleKeyDown}>
                        <>
                            <EditorMenu
                                scrollValue={scrollValue}
                                appState={state}
                                appDispatch={dispatch}
                                actionDispatch={actionDispatch}
                                actionState={actionState}
                                headerData={data?.getProjectDetailById}
                                verify={verify}
                                setVerify={setVerify}
                                fromEditorMenu={fromEditorMenu}
                                setFromEditorMenu={setFromEditorMenu}
                                setNotConfirmed={setNotConfirmed}
                            />
                            <Layout
                                toggle={toggle}
                                setToggle={setToggle}
                                appState={state}
                                appDispatch={dispatch}
                                actionDispatch={actionDispatch}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setcurretPage={setcurretPage}
                                tempPage={tempPage}
                                setTempPage={setTempPage}
                                handlePage={handleChangePagination}
                                tbRowData={tbRowData}
                                setTbRowData={setTbRowData}
                                scrollValue={scrollValue}
                                headerData={data?.getProjectDetailById}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                projectId={projectId}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                actionState={actionState}
                                verify={verify}
                                fromEditorMenu={fromEditorMenu}
                                setFromEditorMenu={setFromEditorMenu}
                                notConfirmed={notConfirmed}
                                setNotConfirmed={setNotConfirmed}
                            />
                        </>
                    </Box>
                </ThemeProvider>
            </div>
        </Suspense>
    );
};

export default Home;