// import React from "react";
import { Avatar } from "@material-ui/core";
import * as Icons from 'src/material-ui';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from "@material-ui/icons/Lock";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const GetIconRandom = (classes: any, state: string, tValue: string) => {

    let val = tValue ? JSON?.parse(tValue) : {};

    let value: any = '';
    // if (typeof val?.matchRate != "undefined") {
    //     value = parseInt((val?.matchRate + "")?.replace('%', ''));
    // }

    let matchRate: string | undefined = '';

    if (typeof val?.matchRate !== "undefined") {
        value = parseInt((val?.matchRate + "")?.replace('%', '')) || '';
        matchRate = val?.matchRate || '';
    }

    //
    // console.log('val',val);
    // console.log('value',value);

    const icons = [ // Translated

        { 'state': 'initial', 'icon': <div className={classes.statusIcon} ></div> },
        { 'state': 'draft', 'icon': <EditIcon className={classes.statusIcon} /> },
        { 'state': 'TM', 'icon': <Avatar className={`${classes.textStatus} ${value > 94 ? 'tm_primary' : 'tm_secondary'}`} variant="square"> {typeof matchRate !== "number" && matchRate?.includes("%") ? value : matchRate}</Avatar> },
        { 'state': 'cTM', 'icon': <Avatar className={`${classes.textStatus} transparent`} variant="square"> {value}</Avatar> },
        { 'state': 'TB', 'icon': <Avatar className={`${classes.textStatus} ${value > 94 ? 'tm_primary' : 'tm_secondary'}`} variant="square"> {value}</Avatar> },
        { 'state': 'cTB', 'icon': <Avatar className={`${classes.textStatus} transparent`} variant="square"> {value}</Avatar> },
        { 'state': 'AT', 'icon': <Avatar className={`${classes.textStatus} at_primary`} variant="square">AT</Avatar> },
        { 'state': 'cAT', 'icon': <Avatar className={`${classes.textStatus} transparent`} variant="square">AT</Avatar> },
        { 'state': 'MT', 'icon': <Avatar className={`${classes.textStatus} at_primary`} variant="square">MT</Avatar> },
        { 'state': 'cMT', 'icon': <Avatar className={`${classes.textStatus} transparent`} variant="square">MT</Avatar> },
        { 'state': 'match100', 'icon': <Avatar className={`${classes.textStatus} bg_success`} variant="square">100</Avatar> },
        { 'state': 'HM', 'icon': <Avatar className={`${classes.textStatus} bg_success`} variant="square">100</Avatar> },
        { 'state': 'match92', 'icon': <Avatar className={`${classes.textStatus} transparent`} variant="square">92</Avatar> },
        { 'state': 'CM', 'icon': <Avatar className={`${classes.textStatus} bg_success`} variant="square" >CM</Avatar > },
        { 'state': 'FM', 'icon': <Avatar className={`${classes.textStatus} bg_success`} variant="square" > {value}</Avatar > },
        { 'state': 'AP', 'icon': <Avatar className={`${classes.textStatus} bg_secondary`} variant="square">AP</Avatar> },
        { 'state': 'check_success', 'icon': <Icons.CheckCircleIcon className="color_success" /> },
        { 'state': 'check_primery', 'icon': <Icons.CheckCircleIcon color="primary" /> },
        //   { 'state': 'lock', 'icon': <Icons.LockIcon className={classes.statusIcon} />, },
        { 'state': 'unlock', 'icon': <LockOpenIcon className={classes.statusIcon} />, },
        { 'state': 'error_secondary', 'icon': <Icons.ErrorIcon color="secondary" /> },
        { 'state': 'lock', 'icon': <LockIcon className={classes.iconStyle} fontSize="small" />, },
        { 'state': 'translated', 'icon': <CheckIcon className={`${classes.iconStyle} ${classes.iconPrimary}`} />, },
        { 'state': 'reviewed', 'icon': <CheckCircleIcon className={`${classes.iconStyle} ${classes.iconPrimary}`} fontSize="small" />, },
        { 'state': 'rejected', 'icon': <ErrorIcon className={`${classes.iconStyle} ${classes.iconWarning}`} fontSize="small" />, },
        { 'state': 'approved', 'icon': <CheckCircleIcon className={`${classes.iconStyle} ${classes.iconSuccess}`} fontSize="small" />, },
    ];
    // console.log(' icon name ==> ', state);
    let res = icons.filter(item => item.state === state)
    return res[0]?.icon;



};

export default GetIconRandom;





