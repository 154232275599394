import React, { useEffect, useState } from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    Link,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    TextField,
    TextareaAutosize,
    Tooltip,
    Typography
} from "@material-ui/core";
import * as Icons from "src/material-ui";
import { a11yProps, TabPanel } from "src/material-ui";
import useStyles from "../../pages/home/HomeStyles";
import { MessageBox } from "src/components";
import { ISegment } from "src/interfaces";
import { Theme, withStyles } from "@material-ui/core/styles";
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MessageIcon from '@material-ui/icons/Message';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLazyQuery, useMutation } from "@apollo/client";
import SEGMENT_TRANSLATIONS from "src/helpers/queries/GetSegmentTranslations";
import { useTranslation } from "react-i18next";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useRenderTags } from "src/helpers";
import { ClassSharp } from "@material-ui/icons";
import CREATE_SINGLE_TERM_BASE from "../../helpers/mutations/createSingleTermBase";
import CREATE_QUERY_LOG from "src/helpers/mutations/createQueryLog";
import GET_QUERY_LOGS from "src/helpers/queries/GetQueryLogs";
import TranslateIcon from '@material-ui/icons/Translate';
import GET_SEGMENT_MACHINE_TRANSLATION from "src/helpers/queries/GetSegmentMachineTranslations";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DELETE_TERM_BASE_DETAIL from "src/helpers/mutations/deleteTermBaseDetail";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import ReplyIcon from '@material-ui/icons/Reply';
import ANSWER_QUERY_LOG from "src/helpers/mutations/answerQueryLog";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";


interface IProps {
    selectedRow: ISegment;
    appDispatch: Function;
    appState: any;
    toggle: any;
    setToggle: Function;
    tbRowData: any;
    headerData: any;
    scrollValue: any;
    expand: any;
    actionState: any;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SegmentDataBox = ({ headerData, selectedRow, appDispatch, appState, toggle, setToggle, tbRowData, scrollValue, expand, actionState }: IProps) => {

    const classes: any = useStyles();
    const [value, setValue] = useState("tm|tb");
    const [qlInput, setQlInput] = useState("");
    const [translationData, setTranslationData]: any = useState([]);
    const [translationDataFlag, setTranslationDataFlag]: any = useState(false);
    const { t } = useTranslation();
    const [expanded, setExpanded]: any = useState(false);
    const { renderTags } = useRenderTags();
    const [qlData, setQlData]: any = useState([]);
    const [engine, setEngine]: any = useState("");
    const [loading, setLoading]: any = useState(false);
    const [hidden, setHidden]: any = useState([]);
    const [recall, setRecall]: any = useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [removeTbId, setRemoveTbId]: any = useState(null);
    const [queryLogRecall, setQueryLogRecall]: any = useState(false);
    const [tbSource, setTbSource]: any = useState("");
    const [tbTarget, setTbTarget]: any = useState("");
    const [tbComment, setTbComment]: any = useState("");
    const [selectedValue, setselectedValue] = React.useState('all');
    const [isEdit, setEdit] = React.useState(0);
    const [commentId, setCommentId] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [selectedService, setSelectedService] = useState('');
    const [state, setState]: any = useState({
        vertical: 'top',
        horizontal: 'center',
    });

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const { vertical, horizontal }: any = state;
    const [tbSourceMismatch, setTbSourceMismatch]: any = useState(false);


    const [GetSegmentTranslations, { data, loading: translationDataLoading, error }] = useLazyQuery(
        SEGMENT_TRANSLATIONS,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                if (data) {
                    let temp = translationData;
                    let id = selectedRow?.id;
                    let d = data?.getSegmentTranslations;
                    let qaReport = d.qaReport;

                    appDispatch({ type: 'SET_QA_REPORT', payload: qaReport });

                    if (d) {
                        setTranslationData([]);
                        setTranslationDataFlag(false);

                        let res = translationData.filter(function (item: any) {
                            return item.id === selectedRow?.id && item.language === appState.selectedLanguage;
                        });

                        temp = [];
                        temp.push({ id: id, data: d, language: appState.selectedLanguage });
                        setTranslationData(temp);
                    }
                }
            }
        }
    );


    const [deleteTermBaseDetail, { loading: deleteTermBase }] = useMutation(DELETE_TERM_BASE_DETAIL, {
        errorPolicy: 'all',
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [GetQueryLogs, { data: queryLogData, error: queryLogError }] = useLazyQuery(
        GET_QUERY_LOGS,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                if (data) {
                    let queryLog = data.getQueryLogs;

                    let dataRows = [];

                    for (let i = 0; i < queryLog.length; i++) {
                        dataRows.push({
                            id: queryLog[i].id,
                            segId: (appState.selected || queryLog[i].segmentId) ? queryLog[i].segmentId + "" : 0,
                            message: queryLog[i].content,
                            date: queryLog[i].createdAt,
                            service: queryLog[i].service,
                            user: queryLog[i].user,
                            role: queryLog[i].role,
                            link: "Translator's name",
                            isReply: 1,
                            reply: [],
                            answers: queryLog[i].answers,
                        });
                    }

                    appDispatch({ payload: dataRows.map((row) => row.segId), type: "SET_SEGMENT_ID" });
                    setQlData(dataRows);
                }
            }
        }
    );

    const [GetSegmentMachineTranslations, { data: machineTranslationData, error: machineTranslationDataError }] = useLazyQuery(
        GET_SEGMENT_MACHINE_TRANSLATION, { fetchPolicy: 'no-cache' }
    );

    const getQueryParam = (parameterName: any) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(parameterName);
    };

    let role: any = getQueryParam('role');


    const [createQueryLog, { loading: loadingPRESegments }] = useMutation(CREATE_QUERY_LOG, {
        errorPolicy: 'all',
        variables: {
            data: {
                projectId: headerData?.id,
                fileId: appState.selectedFile.id + "",
                tl: appState.selectedLanguage,
                service: appState.selectedService.code,
                segmentId: toggle.id ? toggle.id + "" : null,
                // segmentId: appState.selected ? appState.selectedRow.id + "" : null,
                content: qlInput,
                role: role,
            }
        },
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [createTermBase, { loading: loadingAddTermBase }] = useMutation(CREATE_SINGLE_TERM_BASE, {
        errorPolicy: 'all',

        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [answerQueryLog, { loading: loadingAnswerQueryLog }] = useMutation(ANSWER_QUERY_LOG, {
        errorPolicy: 'all',
        variables: {
            data: {
                questionId: commentId,
                answer: qlInput,
                service: selectedService,
                role: role,
            }
        },
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    useEffect(() => {
        const topic = '?topic=' + encodeURIComponent('public-querylog/answer');
        const hubUrl = process.env.REACT_APP_MERCURE_ENDPOINT + '/.well-known/mercure';
        const eventSource = new EventSource(hubUrl + topic);
        eventSource.onmessage = function (event) {
            setQueryLogRecall((prevState: boolean) => !prevState);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedRow) {
                let res = translationData.filter(function (item: any) {
                    if (item.id !== selectedRow.id || item.language != appState.selectedLanguage)
                        return false;
                    return true;
                });

                let objectWithUnassignedTbId = res[0]?.data?.tBTranslation?.find((item: any) => !item.tbId);

                if ((res && res[0]) && (appState.isConfirmed == 0) && !objectWithUnassignedTbId) {
                    // Handle the case where conditions are met
                } else if (appState.isConfirmed !== 1) {
                    let sl = selectedRow?.sl;
                    let tl = selectedRow?.tl;
                    let source = selectedRow?.source;

                    setTranslationDataFlag(true);

                    if (tl && source) {
                        await GetSegmentTranslations({
                            variables: {
                                segmentId: appState.selectedRow.id,
                                sourceText: source,
                                projectId: headerData?.id,
                                tl: appState.selectedLanguage,
                                sl: headerData?.sl?.code,
                                engines: parseEngines(),
                                isTrackOn: actionState.trackChanges
                            }
                        });

                        if (appState.isConfirmed == 2) {
                            appDispatch({ type: "CONFIRM_BIT_UPDATE", payload: 0 });
                        }
                    }
                }
            }
        };

        fetchData();
    }, [selectedRow, appState.selectedLanguage, appState.isConfirmed, recall]);

    useEffect(() => {
        if (tbRowData.source !== "" && tbRowData.target !== "") {
            addRow("");
        }
    }, [tbRowData]);

    useEffect(() => {
        const fetchQueryLogs = async () => {
            if (appState.selectedRow) {
                let tl: any = appState.selectedRow?.tl;

                if (tl) {
                    await GetQueryLogs({
                        variables: {
                            projectId: headerData?.id,
                            fileId: appState.selectedFile.id + "",
                            tl: appState.selectedLanguage,
                        }
                    });
                }
            }
        };

        fetchQueryLogs();
    }, [appState.selectedRow, appState.selectedLanguage, queryLogRecall]);

    useEffect(() => {
        if (appState.selectedRow) {

            let tl: any = appState.selectedRow?.tl;

            if (tl && engine) {
                GetSegmentMachineTranslations({
                    variables: {
                        sourceSegmentId: appState.selectedRow.id,
                        tl: appState.selectedLanguage,
                        engines: [{ name: engine }]
                    }
                });
            }
        }
    }, [appState.selectedRow, appState.selectedLanguage, engine]);

    useEffect(() => {
        if (machineTranslationData) {
            const translatedText = machineTranslationData.getSegmentMachineTranslations[0].translatedText;

            if (translatedText) {
                const updatedData = translationData.map((el: any) => {
                    const updatedMachineTranslation = el.data.machineTranslation.map((item: any) => {
                        if (item.engine === engine && item.translatedText === "") {
                            return {
                                ...item,
                                translatedText: translatedText,
                            };
                        }
                        return item;
                    });

                    return {
                        ...el,
                        data: {
                            ...el.data,
                            machineTranslation: updatedMachineTranslation,
                        },
                    };
                });
                setTranslationData(updatedData);
            } else {
                setHidden([...hidden, engine])
            }
            setLoading(false);
        }
    }, [machineTranslationData]);

    useEffect(() => {
        setValue(toggle.seg);
        setQlInput("");
        setEdit(0);
    }, [toggle.seg]);

    useEffect(() => {
        setSelectedService(appState.selectedService.code)
    }, [appState.selectedService.code]);

    useEffect(() => {
        const temp = appState.addNewTerm;
        if (temp.flag !== "") {
            setExpanded(true);
        }
        if (temp.flag === 's') {
            setTbSource(temp.text);
        }
        else if (temp.flag === 't') {
            setTbTarget(temp.text)
        }
    }, [appState.addNewTerm.text, appState.addNewTerm.expanded]);

    const handleChangeIcon = () => {
        setExpanded((prevExpanded: any) => !prevExpanded);
    };

    const parseEngines = () => {
        let engineResult = headerData.engines.find((itm: any) => (itm.sl == headerData?.sl?.code && itm.tl == appState.selectedLanguage));
        let engines = engineResult ? engineResult.engines : [];
        let parsedEngines = [];

        for (let i = 0; i < engines.length; i++) {
            parsedEngines.push({ name: engines[i] });
        }

        return parsedEngines;
    };

    const handleAlertClick = (newState: SnackbarOrigin) => {
        setOpen(true);
        setState({ ...newState })
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleTbSourceMismatchClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setTbSourceMismatch(false);
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: '#494949',
            border: '1px solid #E0E0E0',
            fontSize: 12,
            margin: '2px 0'
        },
        arrow: {
            "&::before": {
                backgroundColor: theme.palette.common.white,
                border: '1px solid #E0E0E0',
            }
        },
    }))(Tooltip);

    const handleInputChange = (e: any) => {
        setQlInput(e.target.value);
        //(qlInput, "Query Log Input Change")
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
        setToggle({ ...toggle, seg: newValue })
    };

    const addRow = (flag: any) => {
        const table: any = document?.getElementById("tb");
        if (table && (flag === "" && tbRowData.source !== "" && tbRowData.target !== "") || (tbSource !== "" && tbTarget !== "" && appState.selectedRow.source.includes(tbSource))) {
            let temp = translationData;

            let tempArr: any = [];

            temp.forEach((item: any) => {
                if (item.id === selectedRow.id) {

                    createTermBase({
                        variables: {
                            data: {
                                projectId: headerData?.id,
                                sl: headerData?.sl?.code,
                                tl: appState.selectedLanguage,
                                sourceTerm: flag === "" ? tbRowData.source : tbSource,
                                targetTerm: flag === "" ? tbRowData.target : tbTarget,
                                comment: flag === "" ? tbRowData.comment : tbComment,
                            }
                        }
                    });

                    // let data = JSON.stringify({
                    //     contributor: "Azam Alvi",
                    //     from: "Test TB",
                    //     lastModified: "2022-11-11 10:55:09",
                    //     sourceText: flag === "" ? tbRowData.source : tbSource,
                    //     matchRate: "100%",
                    //     translatedText: flag === "" ? tbRowData.target : tbTarget,
                    //     comment: flag === "" ? tbRowData.comment : tbComment,
                    // });

                    // let tbData = JSON.stringify(item?.data?.tBTranslation);

                    // let addTbRow: any = tbData.replace(']', ',' + data + ']');
                    // addTbRow = addTbRow.replace('[,{', '[{');

                    // let row: any = JSON.parse(addTbRow);

                    // let d = item;

                    // if (d.data.tBTranslation) {
                    //     d.data = {
                    //         machineTranslation: item.data.machineTranslation,
                    //         tMTranslation: item.data.tMTranslation,
                    //         tBTranslation: row
                    //     };
                    // }
                    // tempArr.push(d);
                }
            });
            setTranslationData(tempArr);
            setTbSource("");
            setTbTarget("");
            setTbComment("");
            setTimeout(() => {
                setRecall(!recall);
            }, 500);
        }
        else {
            setTbSourceMismatch(true);
        }
    };

    const handleClick = (e: any, row: any) => {
        if (row && e.detail === 2) {
            appDispatch({
                type: "TM",
                payload: { ...row, withTags: true, isTrackChanges: actionState.trackChanges },
            });
            // TM, TB, MT Double click will reset
            setTimeout(() => {
                appDispatch({ type: "RESET_HISTORY" })
            }, 200);
        }
    };

    const handleAdd = () => {

        if (isEdit === 0) {
            createQueryLog();
            setToggle({ ...toggle, id: 0 })
        }
        else if (isEdit === 1) {
            answerQueryLog();
        }

        setQlInput("");
        setEdit(0);
        setTimeout(() => {
            setQueryLogRecall(!queryLogRecall);
        }, 500);
    }

    const handleReply = (id: any) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        let temp =
            setTimeout(() => {

                document.getElementById("qlText")?.focus();
                clearTimeout(temp);
            }, 500);

        setCommentId(id);
        setEdit(1);
        setQlInput("");
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setselectedValue((event.target as HTMLInputElement).value);
    };

    const copyLink = () => {
        const link = window.location.origin + `/questions?id=${headerData?.id}`;

        navigator?.clipboard?.writeText(link).then(function () {
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        })
    }

    function addTbScroll() {
        document?.querySelector('#tbId')?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    if (error && error?.message) {
        return <div>Error in segment Translation
            {error?.message}
        </div>
    }

    const handleTbDelete = () => {
        deleteTermBaseDetail({
            variables: { id: removeTbId }, // Assuming your mutation takes the id as a variable
        })
            .then((response) => {
                // Check the response for success, and update the state if needed
                if (response.data && response.data.deleteTermBaseDetail.status === 'ok') {
                    // Deletion was successful, update the state to reflect the changes
                    const updatedTranslationData = translationData.map((item: any) => {
                        if (item.id === selectedRow.id) {
                            const updatedTBTranslation = item.data.tBTranslation.filter((row: any) => row.tbId !== removeTbId);
                            return { ...item, data: { ...item.data, tBTranslation: updatedTBTranslation } };
                        }
                        return item;
                    });

                    setTranslationData(updatedTranslationData);
                } else {
                    console.error('Failed to delete tBTranslation:', response.data?.deleteTermBaseDetail.error);
                }
            })
            .catch((error) => {
                // Handle any errors that occur during the mutation
                console.error('Error deleting tBTranslation:', error);
            });
        setShowConfirm(false);
    }

    const handleNo = () => {
        setShowConfirm(false);
    };

    const getSegmentCount = (id: any) => {
        const selectedSegment = appState.data.find((row: any) => row.id == id);
        if (selectedSegment && selectedSegment.key) {
            return selectedSegment.key
        } else {
            return 0;
        }
    }


    return (
        <Box id="segmentBox" className={scrollValue && scrollValue < 64 ? classes.sideBarStyles : `${classes.sideBarStyles} ${classes.sidebarPosition}`}>
            {/*{JSON.stringify(appState)}*/}
            {/*appState.isConfirmed = {appState.isConfirmed}*/}
            <Paper id="parent" className={classes.boxWrap} style={{ height: expand ? `calc(100% + 320px)` : '100%' }} elevation={1}>
                {appState.selectedService.isAssigned === 1 ?
                    <Box p={3}>
                        <AppBar
                            className={classes.segmentTabBar}
                            position="static"
                            color="default"
                        >
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    value="tm|tb"
                                    label={
                                        <Box
                                            color="#00000099"
                                            fontSize="14px"
                                            fontFamily="Roboto Medium"
                                        >
                                            {t('segmentDataBox.tm')} | {t('segmentDataBox.tb')}
                                        </Box>
                                    }
                                    wrapped
                                    {...a11yProps("tm|tb")}
                                />
                                {/* <Tab
                                value="tb"
                                label={
                                    <Box
                                        color="#00000099"
                                        fontSize="14px"
                                        fontFamily="Roboto Medium"
                                    >
                                        {t('segmentDataBox.tb')}
                                    </Box>
                                }
                                {...a11yProps("tb")}
                            /> */}
                                <Tab
                                    value="query"
                                    label={
                                        <Box
                                            color="#00000099"
                                            fontSize="14px"
                                            fontFamily="Roboto Medium"
                                        >
                                            {t('segmentDataBox.ql')}
                                        </Box>
                                    }
                                    {...a11yProps("query")}
                                />
                                <Tab
                                    value="mt"
                                    label={
                                        <Box
                                            color="#00000099"
                                            fontSize="14px"
                                            fontFamily="Roboto Medium"
                                        >
                                            {t('segmentDataBox.cmt')}
                                        </Box>
                                    }
                                    {...a11yProps("mt")}
                                />
                            </Tabs>
                        </AppBar>

                        <TabPanel value={value} index="tm|tb">
                            <>
                                <Box mb='6px' mt='6px'>
                                    <Typography id="tabHeading" className={`${classes.tabHeading} panelFont`}>
                                        {t('segmentDataBox.translationMemory')}
                                    </Typography>
                                </Box>
                                <Box className={classes.tmTbBox}>
                                    <TableContainer>
                                        {/*{translationData && translationData.length>0 && JSON.stringify(translationData)}*/}
                                        {translationData &&
                                            translationData.map((item: any, index: any) => {
                                                if (item.id === selectedRow.id && item.language == appState.selectedLanguage) {
                                                    return <Box key={index} maxHeight={'366px'}>
                                                        <Table className={classes.tableStyles}>
                                                            {/*parseInt(row?.matchRate.replace('%', '')) >= 70*/}
                                                            {item?.data?.tMTranslation.some((row: any) => 1 == 1) ? (
                                                                item?.data?.tMTranslation && item?.data?.tMTranslation.map((row: any, index: any) =>
                                                                    <span key={index}>
                                                                        {/*{parseInt(row?.matchRate.replace('%', '')) >= 70 ?*/}
                                                                        {row?.matchRate ?

                                                                            <TableBody className={classes.tableBody}>
                                                                                <TableRow
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'flex-start'
                                                                                    }}
                                                                                    onClick={(e) => handleClick(e, row)}
                                                                                >
                                                                                    <TableCell className={classes.statusCell}>
                                                                                        {row.matchRate == 'cMT' || row.matchRate == 'MT' ?
                                                                                            <>
                                                                                                <Avatar
                                                                                                    className={`${classes.textStatus} ${row.matchRate == 'MT' ? 'at_primary' : ''}`}
                                                                                                    variant="square">
                                                                                                    {row.matchRate == 'cMT' ? 'MT' : row.matchRate}
                                                                                                </Avatar>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {parseInt(row.matchRate.replace('%', '')) >= 95 ?
                                                                                                    <Avatar
                                                                                                        className={`${classes.textStatus} bg_success`}
                                                                                                        variant="square">
                                                                                                        {row.matchRate}
                                                                                                    </Avatar>
                                                                                                    :
                                                                                                    <Avatar
                                                                                                        className={`${classes.textStatus} bg_status_light`}
                                                                                                        variant="square">
                                                                                                        {row.matchRate}
                                                                                                    </Avatar>
                                                                                                }
                                                                                            </>
                                                                                        }

                                                                                    </TableCell>

                                                                                    <TableCell className={classes.translationCell}>
                                                                                        <Typography className={`${classes.textStyles} panelFont`}>
                                                                                            <div
                                                                                                className={classes.sourceText}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: renderTags(row.sourceText),
                                                                                                }}
                                                                                            />
                                                                                        </Typography>

                                                                                        <Box>
                                                                                            <LightTooltip
                                                                                                title={t('segmentDataBox.pasteMatches') as any}
                                                                                                placement="bottom"
                                                                                                arrow
                                                                                            >
                                                                                                <Box
                                                                                                    className={`${classes.textStyles} panelFont`}>
                                                                                                    <div className={classes.boldTarget}
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: renderTags(row.translatedText),
                                                                                                        }}
                                                                                                    />
                                                                                                </Box>
                                                                                            </LightTooltip></Box>

                                                                                        <Box className={classes.metaStyles}>
                                                                                            <Box className={classes.metaText}>
                                                                                                {row.lastModified}
                                                                                            </Box>

                                                                                            <LightTooltip
                                                                                                title={
                                                                                                    <React.Fragment>
                                                                                                        <Typography
                                                                                                            className={classes.tooltipStyles}><b>{t('segmentDataBox.origin')}:</b> {row?.tmName}</Typography>
                                                                                                        <Typography
                                                                                                            className={classes.tooltipStyles}><b>{t('segmentDataBox.updatedOn')}:</b> {row?.lastModified}</Typography>
                                                                                                        <Typography
                                                                                                            className={classes.tooltipStyles}><b>{t('segmentDataBox.updatedBy')}:</b> {row?.contributor}</Typography>
                                                                                                        <Typography
                                                                                                            className={classes.tooltipStyles}><b>{t('segmentDataBox.createdOn')}:</b> {row?.lastModified}</Typography>
                                                                                                        <Typography
                                                                                                            className={classes.tooltipStyles}><b>{t('segmentDataBox.createdBy')}:</b> {row?.from}</Typography>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                placement="bottom-start"
                                                                                                arrow
                                                                                            >
                                                                                                <Box className={classes.metaText}>
                                                                                                    {row?.contributor}
                                                                                                </Box>
                                                                                            </LightTooltip>
                                                                                        </Box>

                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>

                                                                            : ''}
                                                                    </span>
                                                                ))
                                                                : (<TableBody className={classes.tableBody}>
                                                                    <TableRow className={classes.tmRecord}>
                                                                        <TableCell className={classes.tmRecordCell}>
                                                                            No record found
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>)}
                                                        </Table>
                                                    </Box>
                                                }
                                            }
                                            )}
                                    </TableContainer>

                                    <Box mt={4} mb={2}>
                                        <Accordion className={classes.addTerm} expanded={expanded}>
                                            <AccordionSummary
                                                expandIcon={expanded ?
                                                    <div onClick={handleChangeIcon}>
                                                        <Box>
                                                            <ExpandMoreIcon fontSize="small" />
                                                            <RemoveIcon fontSize="small" className={classes.iconStyles} />
                                                        </Box>
                                                    </div>
                                                    :
                                                    <div onClick={handleChangeIcon}>
                                                        <Box>
                                                            <AddIcon fontSize="small" className={classes.iconStyles} />
                                                            <ExpandMoreIcon fontSize="small" />

                                                        </Box>
                                                    </div>
                                                }

                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Box>
                                                    <Typography className={classes.tabHeading}>
                                                        {t('segmentDataBox.termBase')}
                                                    </Typography>
                                                </Box>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box
                                                    className={classes.tbFields}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    position="relative"
                                                    mb={1}
                                                >
                                                    <Box
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        flexDirection={'column'}
                                                        mt={1}
                                                    >
                                                        <Box display={'flex'} alignItems={'center'} mb={'16px'}>
                                                            <Typography className={classes.feildLabel}>
                                                                {t('segmentDataBox.sourceTerm')}
                                                            </Typography>
                                                            <TextField
                                                                id="tbSource"
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder={t('segmentDataBox.sourceTerm') as any}
                                                                value={tbSource}
                                                                onChange={(e) => setTbSource(e.target.value)}
                                                                className={classes.tbTextFeild}
                                                            />
                                                        </Box>

                                                        <Box display={'flex'} alignItems={'center'} mb={'16px'}>
                                                            <Typography className={classes.feildLabel}>
                                                                {t('segmentDataBox.targetTerm')}
                                                            </Typography>
                                                            <TextField
                                                                id="tbTarget"
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder={t('segmentDataBox.targetTerm') as any}
                                                                value={tbTarget}
                                                                onChange={(e) => setTbTarget(e.target.value)}
                                                                className={classes.tbTextFeild}
                                                            />
                                                        </Box>


                                                        <Box display={'flex'} alignItems={'center'} mb={'16px'}>
                                                            <Typography className={classes.feildLabel}>
                                                                {t('segmentDataBox.comment')}
                                                            </Typography>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder={t('segmentDataBox.comment') as any}
                                                                value={tbComment}
                                                                onChange={(e) => setTbComment(e.target.value)}
                                                                className={classes.tbTextFeild}
                                                            />
                                                        </Box>

                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                addRow("flag");
                                                                addTbScroll()
                                                            }}
                                                            disableElevation
                                                            className={classes.tbAddBtn}
                                                            disabled={(tbSource === "" || tbTarget === "") ? true : false}
                                                        >
                                                            {t('segmentDataBox.add') as any}
                                                        </Button>

                                                    </Box>
                                                </Box>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>

                                    <TableContainer>
                                        {loadingAddTermBase || translationDataLoading ?
                                            <Box style={{ minHeight: 300 }} display={'flex'} alignItems={"center"} justifyContent={"center"}>
                                                <CircularProgress disableShrink />
                                            </Box> :
                                            translationData &&
                                            translationData.map((item: any, index: any) => {
                                                if (item.id === selectedRow.id && item.language == appState.selectedLanguage) {
                                                    return <Box key={index} maxHeight={'366px'}>
                                                        <Table id="tb" className={classes.tableStyles}>
                                                            {item?.data?.tBTranslation && item?.data?.tBTranslation.map((row: any, index: any) =>
                                                                <span key={index}>
                                                                    {parseInt(row?.matchRate.replace('%', '')) >= 100 ?
                                                                        <TableBody className={classes.tableBody}>
                                                                            <TableRow
                                                                                onClick={(e) => handleClick(e, row)}
                                                                            >
                                                                                <TableCell className={classes.tbCell}>
                                                                                    <Box className={classes.tbContainer}>
                                                                                        <Box id="tbId" mb={2} className={classes.tbData}>
                                                                                            <Typography
                                                                                                className={classes.tbSourceText}>
                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: renderTags(row.sourceText),
                                                                                                    }}
                                                                                                />
                                                                                            </Typography>
                                                                                            <LightTooltip
                                                                                                title={t('segmentDataBox.pasteMatches') as any}
                                                                                                placement="bottom"
                                                                                                arrow
                                                                                            >
                                                                                                <Typography
                                                                                                    className={classes.tbTargetText}>
                                                                                                    <div
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: renderTags(row.translatedText),
                                                                                                        }}
                                                                                                    />
                                                                                                </Typography>
                                                                                            </LightTooltip>

                                                                                            <Box display={'flex'}>
                                                                                                <Box
                                                                                                    className={classes.tbComment}>
                                                                                                    <LightTooltip
                                                                                                        title={
                                                                                                            <React.Fragment>
                                                                                                                <Typography
                                                                                                                    className={classes.tooltipStyles}>{row.comment}</Typography>
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                        placement="bottom-end"
                                                                                                        arrow
                                                                                                    >
                                                                                                        <MessageIcon
                                                                                                            style={{ color: row.comment !== "" ? '#3d80c3' : '#0000008a' }} className={classes.commentIcon} />
                                                                                                    </LightTooltip>
                                                                                                </Box>
                                                                                                {row.isOwner &&
                                                                                                    <Box display={'flex'}
                                                                                                        justifyContent={'center'}
                                                                                                        alignItems={'center'}>
                                                                                                        <IconButton
                                                                                                            className={classes.deleteIconButton}
                                                                                                            size="small"
                                                                                                            onClick={() => { setShowConfirm(true); setRemoveTbId(row.tbId) }}>
                                                                                                            <DeleteIcon
                                                                                                                className={classes.commentIcon} />
                                                                                                        </IconButton>
                                                                                                    </Box>
                                                                                                }
                                                                                            </Box>
                                                                                        </Box>

                                                                                        <Box className={classes.tbMeta}>
                                                                                            <Box mb={'2px'}>
                                                                                                <Typography
                                                                                                    className={classes.tooltipStyles}>{t('segmentDataBox.origin')}: {row?.tbName}</Typography>
                                                                                            </Box>

                                                                                            <Box display={'flex'}>
                                                                                                <Box mr={2}>
                                                                                                    <Typography
                                                                                                        className={classes.tooltipStyles}>{t('segmentDataBox.updatedBy')}: {row?.contributor}</Typography>
                                                                                                </Box>

                                                                                                <Typography
                                                                                                    className={classes.tooltipStyles}>{t('segmentDataBox.createdAt') as any}: {row?.lastModified}</Typography>
                                                                                            </Box>
                                                                                            {/* <Box className={classes.metaText}>
                                                                                        {row?.contributor}
                                                                                    </Box> */}
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>

                                                                        : ''}
                                                                </span>
                                                            )}
                                                        </Table>
                                                    </Box>
                                                }
                                            }
                                            )}
                                    </TableContainer>

                                    <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={tbSourceMismatch}
                                        autoHideDuration={3000} onClose={handleTbSourceMismatchClose}>
                                        <Alert className={classes.alertIcon} onClose={handleTbSourceMismatchClose} severity="success"
                                            style={{ backgroundColor: "#F77D3D" }}>
                                            {t('segmentDataBox.tbSourceNotFound')}
                                        </Alert>
                                    </Snackbar>

                                    {showConfirm && (
                                        <Box>
                                            <Dialog
                                                open={showConfirm}
                                                onClose={handleNo}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                classes={{ paper: classes.paper }}
                                                className={classes.dialogBoxWrap}
                                                BackdropProps={{
                                                    style: { backgroundColor: 'transparent', boxShadow: 'none' }
                                                }}
                                            >
                                                <DialogTitle
                                                    className={classes.dialogTitle}
                                                    id="alert-dialog-title"
                                                >
                                                    {t('qa.confirmDelete')}
                                                </DialogTitle>

                                                <DialogContent
                                                    className={classes.dialogContent}
                                                >
                                                    <DialogContentText id="alert-dialog-description">
                                                        {t('verify.areYouSure')}
                                                    </DialogContentText>
                                                </DialogContent>

                                                <DialogActions
                                                    className={classes.dialogActions}
                                                >
                                                    <Button
                                                        onClick={() => handleTbDelete()}
                                                        color="primary"
                                                        variant="contained"
                                                        autoFocus
                                                    >
                                                        {t('verify.yes')}
                                                    </Button>
                                                    <Button onClick={handleNo} autoFocus>
                                                        {t('qa.no')}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        </TabPanel>

                        <TabPanel value={value} index="mt">
                            <>
                                <TableContainer>
                                    {translationData &&
                                        translationData.map((item: any, index: any) => {
                                            if (item.id === selectedRow.id && item.language == appState.selectedLanguage) {
                                                return <span key={index}>
                                                    {
                                                        headerData.isMTEnabled ?
                                                            item?.data?.machineTranslation &&
                                                            <Box className={classes.mtBox}>
                                                                {item?.data?.machineTranslation.map((engineItem: any) => (
                                                                    <span>
                                                                        <Box mb='6px' mt="6px" mr="6px" display={'flex'} justifyContent={'space-between'} alignItems={'center'} minHeight={'44px'}>
                                                                            <Typography className={classes.tabHeading}>
                                                                                {t('common.machineTranslation')} ({engineItem.engine})
                                                                            </Typography>
                                                                            {engineItem.translatedText === "" && !loading && !hidden.includes(engineItem.engine) &&
                                                                                <IconButton size="small" id={"icon-" + engineItem.engine} onClick={() => { setEngine(engineItem.engine); setLoading(true); }}>
                                                                                    <TranslateIcon color="primary" fontSize="small" />
                                                                                </IconButton>}
                                                                            {loading && engineItem.engine === engine && !hidden.includes(engineItem.engine) && < CircularProgress id={"progress-" + engineItem.engine} size={20} />}
                                                                        </Box>
                                                                        {
                                                                            engineItem.translatedText !== "" &&
                                                                            <Table className={classes.tableStyles}>
                                                                                {/*{parseInt(engineItem.matchRate.replace('%', '')) >= 75 || 1==1 ?*/}

                                                                                <TableBody className={classes.tableBody}>
                                                                                    <TableRow
                                                                                        onClick={(e) => handleClick(e, engineItem)}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'flex-start'
                                                                                        }}
                                                                                    >


                                                                                        <TableCell className={classes.statusCell}>
                                                                                            {parseInt(engineItem.matchRate.replace('%', '')) >= 95 ?
                                                                                                <Avatar
                                                                                                    className={`${classes.textStatus} bg_success`}
                                                                                                    variant="square">
                                                                                                    {engineItem.matchRate}
                                                                                                </Avatar>
                                                                                                :
                                                                                                <Avatar
                                                                                                    className={`${classes.textStatus} bg_status_light`}
                                                                                                    variant="square">
                                                                                                    {engineItem.matchRate}
                                                                                                </Avatar>
                                                                                            }
                                                                                        </TableCell>

                                                                                        <TableCell
                                                                                            className={classes.translationCell}>
                                                                                            <div
                                                                                                className={classes.textStyles}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: renderTags(engineItem.sourceText),
                                                                                                }}
                                                                                            />

                                                                                            <LightTooltip
                                                                                                title={t('segmentDataBox.pasteMatches') as any}
                                                                                                placement="bottom"
                                                                                                arrow
                                                                                            >

                                                                                                <div
                                                                                                    className={classes.textStyles}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: renderTags(engineItem.translatedText),
                                                                                                    }}
                                                                                                />
                                                                                            </LightTooltip>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>

                                                                                {/*: ''}*/}
                                                                            </Table>
                                                                        }
                                                                        {hidden.includes(engineItem.engine) &&
                                                                            <Table className={classes.emptyTableStyles}>
                                                                                <TableBody className={classes.tableBody}>
                                                                                    <TableRow>
                                                                                        <TableCell
                                                                                            className={classes.translationCell}>
                                                                                            <Typography className={classes.textStyles}>
                                                                                                No translation found
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        }
                                                                    </span>
                                                                ))}
                                                            </Box>
                                                            :
                                                            <Box style={{ color: '#f24822' }} mt={2} className={classes.textStyles}>
                                                                {t('editorMenu.mtEnableAlert') as any}.
                                                            </Box>
                                                    }
                                                </span>
                                            }
                                        }
                                        )}
                                </TableContainer>
                            </>
                        </TabPanel>

                        <TabPanel value={value} index="query">
                            <>
                                <Box mt={3} className={classes.queryBox}>

                                    <TextField
                                        id="qlText"
                                        className={classes.queryField}
                                        placeholder={t('segmentDataBox.enterQuery') as any}
                                        multiline
                                        minRows={4}
                                        maxRows={4}
                                        variant="outlined"
                                        onChange={(e) => handleInputChange(e)}
                                        value={qlInput}
                                        inputRef={toggle.textInput}
                                    // ref={ref}
                                    />

                                    <Box pt={2} pb={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row-reverse'}>
                                        <Box>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                className={classes.addQueryBtn}
                                                disableElevation
                                                onClick={handleAdd}
                                                disabled={qlInput === "" ? true : false}
                                            >
                                                {isEdit === 0 ? t('segmentDataBox.add') : t('common.reply')}
                                            </Button>

                                            <LightTooltip title={`Copy link and share now`}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    className={classes.shareBtn}
                                                    disableElevation
                                                    onClick={() => { copyLink(); handleAlertClick({ vertical: 'bottom', horizontal: 'right' }) }}>
                                                    <span className={classes.replyIcon}>
                                                        <ReplyIcon />
                                                    </span> Share
                                                </Button>
                                            </LightTooltip>
                                        </Box>

                                        <Box>
                                            <RadioGroup className={classes.radioWrapper} aria-label="gender" name="gender1"
                                                value={selectedValue} onChange={handleRadioChange}>
                                                <FormControlLabel className={classes.radioLabel} value="all"
                                                    control={<Radio disableRipple size="small" />} label={t('segmentDataBox.all')} />
                                                <FormControlLabel className={classes.radioLabel} value="segments"
                                                    control={<Radio disableRipple size="small" />} label={t('common.segments')} />
                                            </RadioGroup>
                                        </Box>
                                    </Box>

                                    <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={open}
                                        autoHideDuration={2000} onClose={handleClose}>
                                        <Alert className={classes.alertIcon} onClose={handleClose} severity="success"
                                            style={{ backgroundColor: "#F77D3D" }}>
                                            {t('segmentDataBox.successAlert')}!
                                        </Alert>
                                    </Snackbar>

                                    <Box className={classes.sideBarScroll}>
                                        <Box className={classes.qlBox}>
                                            {qlData.map((val: any, index: number) => (
                                                selectedValue === "all" ?
                                                    <MessageBox
                                                        key={index}
                                                        segId={val?.segId}
                                                        id={val.id}
                                                        date={val.date}
                                                        message={val.message}
                                                        role={val.role}
                                                        handleReply={handleReply}
                                                        isReply={val.isReply}
                                                        reply={val.reply}
                                                        answers={val.answers}
                                                        segmentCount={getSegmentCount(val.segId)}
                                                    />
                                                    :
                                                    val.segId != 0 ?
                                                        <MessageBox
                                                            key={index}
                                                            segId={val?.segId}
                                                            id={val.id}
                                                            date={val.date}
                                                            message={val.message}
                                                            role={val.role}
                                                            handleReply={handleReply}
                                                            isReply={val.isReply}
                                                            reply={val.reply}
                                                            answers={val.answers}
                                                            segmentCount={getSegmentCount(val.segId)}
                                                        /> : ''
                                            ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        </TabPanel>
                    </Box >
                    : ""}
            </Paper >

            <CustomSnackbar
                error={gqlError}
                open={gqlErrorSnackBar}
                onClose={handleErrorSnackBarClose}
            />
        </Box >
    );
};

export default React.memo(SegmentDataBox);