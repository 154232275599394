import { gql } from '@apollo/client';

const SPLIT_SEGMENT = gql`mutation splitSegmentNew($data: SplitSegmentNewRequest){
  splitSegmentNew(data: $data){
      status
      error
  }
 
  }`;

export default SPLIT_SEGMENT;