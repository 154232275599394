import { Slider } from '@material-ui/core';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';

export const PrettoSlider = withStyles({
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 6,
        borderRadius: 4,
    },
})(Slider);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            '& .MuiToolbar-root': {
                justifyContent: 'space-between'
            }
        },
        fontFeild: {
            width: '60px',
            minWidth: '60px',
            '& .MuiOutlinedInput-input': {
                padding: '6px 6px 6px 10px !important'
            }
        },
        homeHeader: {
            '& .MuiToolbar-root': {
                justifyContent: 'flex-end',
                backgroundColor: '#ffffff !important'
            }
        },
        paper: {
            zIndex: 1,
        },
        item: {
            fontSize: '14px',
        },
        progressStatus: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        root: {
            backgroundColor: '#fbcc6b',
        },
        translatedProgress: {
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#fbcc6b",
            },
        },
        reviewProgress: {
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#76d700",
            },
        },
        approvedProgess: {
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#f6c",
            },
        },
        logoDivider: {
            borderLeft: '1px solid #E5E5E5',
            "& img": {
                maxWidth: '152px'
            }
        },
        headerMenu: {
            marginTop: 36,
            marginLeft: 40,
            '& .MuiListItem-root': {
                fontSize: '13px !important',
                padding: '10px 16px',
                '& svg': {
                    fontSize: 15,
                    marginRight: 4
                }
            },
            '& .MuiListItem-root:hover': {
                '& svg': {
                    color: '#ffffff'
                }
            }
        },
        progressTextStyle: {
            fontSize: 13,
            marginBottom: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        modalContentTitle: {
            fontSize: 14,
            fontFamily: 'Roboto Medium'
        },
        typeRadioWrap: {
            '& .MuiFormGroup-root': {
                flexDirection: 'row',
                '& .MuiSvgIcon-root': {
                    fontSize: 18
                },
                '& .MuiTypography-root': {
                    fontSize: 13
                },
                '& .Mui-checked': {
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.main
                    },
                }
            },
            '& .MuiFormControl-root': {
                '&:nth-child(2)': {
                    width: 440,
                    marginLeft: 16
                },
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: 5.5,
                    paddingBottom: 9.5,
                    backgroundColor: 'rgba(232,232,232,0.5)',
                    borderRadius: 2,
                    '&::placeholder': {
                        color: '#0000008A',
                        fontSize: 13,
                        opacity: 1
                    }
                },
                '& fieldset': {
                    border: 'none'
                }
            }
        },
        shortcutTable: {
            '& thead tr th': {
                '&:nth-child(1)': {
                    width: '50%'
                },
                '&:nth-child(2)': {
                    width: '50%'
                },
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            },
        },
        textStyle: {
            textTransform: 'initial',
        },
        logoStyle: {
            cursor: 'pointer'
        },
        refreshIcon: {
            width: '16px',
            height: '16px',
            display: 'flex',
        },
        refreshBtn: {
            width: '17px !important',
            height: '17px !important',
            '&:hover': {
                width: '17px !important',
                height: '17px !important',
            }
        },
        fileNameBtn: {
            '& .MuiButton-label': {
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'block'
            }
        },
        snackBarStyles: {
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important'
            },
            '& .MuiAlert-message': {
                color: '#ffffff !important'
            }
        },
        logoutIcon: {
            color: "#f24822",
            marginRight: "8px !important"
        },
        loadingStyle: {
            fontSize: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#3d80c3',
            marginRight: '8px'
        },
        loadingProgressStatus: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2px'
        },
        loadingSegStyle: {
            fontSize: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

    }),
);

export default useStyles;