import { client } from "src/apolloClient/client";
import { ApolloProvider } from '@apollo/client';
// import { gql } from "@apollo-boost";
import { Authentication } from "src/components/authentication/Authentication";
import { useEffect, useState } from 'react';
import Routing from './routes/Routing';

function App(props: any) {
  const [isAuthenticated, setIsAuthentication] = useState(0);
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      setIsAuthentication(1);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <Authentication history={props.history} />
      {isAuthenticated !== 0 && <Routing />}
    </ApolloProvider>
  );
}
export default App;
