import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18nLocales/en-US.json';
import zh from './i18nLocales/zh-CN.json';

const langDetectorOptions = {
    // order and from where user language should be detected
    order: ['cookie', 'localStorage', 'navigator'],
    // keys or params to lookup language from
    lookupCookie: 'locale',
    lookupLocalStorage: 'locale',
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    // only detect languages that are in the whitelist
    checkWhitelist: true,
};

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: { translation: en },
            zh: { translation: zh },
        },
        lng: "en",
        debug: process.env.REACT_APP_ENABLE_DEBUG === 'true',
        detection: langDetectorOptions,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        // backend: {
        //     // for all available options read the backend's repository readme file
        //     loadPath: '/locales/en-US.json'
        // },

    });


export default i18n;