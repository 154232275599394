import { gql } from '@apollo/client';

const GET_FILESEGMENTS_BY_LANGUAGE = gql`query GetFileSegmentsByLanguage($fileId:Int!,$projectId:String!,$tl:String!,$perPage:Int!,$currentPage:Int!,$source:String,$target:String,$caseSensitive:Boolean,$segmentFilters: SegmentFilters, $start: Int, $end: Int) {
   getFileSegmentsByLanguage(fileId:$fileId, projectId: $projectId, tl: $tl , perPage: $perPage, currentPage : $currentPage, source : $source, target : $target, caseSensitive : $caseSensitive, segmentFilters: $segmentFilters, start: $start, end: $end) {
    pagination{    
      totalPages
      currentPage
      totalRecords
    }
    searchCounts { 
        source
        target
        total
    }
    segments{
        sSegmentId
        tSegmentId
        key
        source
        target
        words
        state
        isLocked
        isPropagationOn
        tStatusType
        tStatusValue
        isAT
        verifyResult{
          level
          type
          message
        }
    }
  }
}`;


export default GET_FILESEGMENTS_BY_LANGUAGE;

