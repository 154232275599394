import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        childTitle: {
            padding: '16px 20px 16px 20px',
            borderBottom: '1px solid #dedede',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        modalBody: {
            padding: '20px',
        },
        childFooter: {
            padding: '16px 20px 16px 20px',
            borderTop: '1px solid #dedede',
            display: 'flex',
            justifyContent: 'right'
        },
        modalStyles: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '38%',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },
        textArea: {
            width: '100%',
            borderRadius: '4px',
            borderColor: '#dedede',
        },
        footerWrap: {
            '&.MuiSvgIcon-root': {
                color: 'red !important',
                fill: 'red !important'
            },
        },
        checkboxColor: {
            color: '#f77d3d',
            padding: '8px !important',
            '&.Mui-checked svg': {
                color: '#f77d3d !important',
                width: '18px !important',
                height: '18px !important',
            },
            '&.MuiIconButton-colorSecondary svg': {
                width: '18px !important',
                height: '18px !important',

            },
            '&.MuiCheckbox-indeterminate svg': {
                color: '#f77d3d !important',
            }
        },
        labelStyle: {
            fontWeight: 'bold',
        },
        checkboxLabel: {
            fontSize: '14px !important',
        },
        iconStyles: {
            padding: '8px',
            '&.MuiIconButton-root svg': {
                width: '18px',
                height: '18px',
            }
        },
        selectStyle: {
            fontSize: '14px !important'
        },
        root: {
            "& > *": {
                borderBottom: "unset",
            },
        },
        table: {
            minWidth: '100%',
            border: '1px solid #dedede',
            borderRadius: '8px'
        },
        cellStyles: {
            padding: '11px !important',
        },
        dialogStyles: {
            '&.MuiDialog-paperWidthSm': {
                minWidth: '25% !important',
                maxWidth: '25% !important',
            }
        },
        paper: {
            minWidth: "500px",
            padding: "20px",
            boxShadow: '0px 3px 5px 3px rgba(193, 199, 208, 0.2)'
        },
        dialogTitle: {
            padding: '0',
            '& h2': {
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '15px'
            }
        },
        dialogContent: {
            padding: '0'
        },
        dialogActions: {
            padding: '0'
        },
        noTerm: {
            fontSize: '14px',
        },
        dialogBoxWrap: {
            boxShadow: 'none'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'flex-end !important',
            alignItems: 'right',
            marginTop: 10
        },
        snackBarStyles: {
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important'
            }
        },
    }),
);

export default useStyles;