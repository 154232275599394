import { createTheme, responsiveFontSizes  } from '@material-ui/core/styles';
import { PaletteType } from '@material-ui/core';

let ThemeConfig = createTheme({
  
  palette: {
    
    primary: {
      light: '#4c8c4a',
      main: '#1b5e20',
      dark: '#003300',
      contrastText: '#fff'
    },
    secondary: {
        light: '#4c8c4a',
        main: '#1b5e20',
        dark: '#003300',
        contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#ff9800'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    }, 
    grey:{
      100:'#fff',
      50: '#212121'
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: 'rgba(0,0,0,0.38)',
      hint: 'rgba(0,0,0,0.38)'
    },
  },
  
  typography: {
    fontSize: 14,
    button: {
      fontSize: '0.875rem',
      textTransform: 'none',
    },
    fontFamily: [
      '"Roboto"',
      '"Arial"', 
      '"Helvetica Neue"',
      '"-apple-system"',
      '"BlinkMacSystemFont"',
      '"Apple Color Emoji"',
      '"sans-serif"',
    ].join(','),
  },
  
  overrides: {
    
    MuiCssBaseline: {
      '@global': {
        body:{
          backgroundColor:'#003300',
          '& .MuiGrid-root .MuiPaper-root':{
            backgroundColor: '#4c8c4a',
          },
          '& .MuiPopover-root .MuiMenu-paper':{
            backgroundColor: '#4c8c4a',
          },
          '& .MuiDialog-container .MuiPaper-root':{
            backgroundColor: '#4c8c4a',
          },
          '& .MuiBox-root .MuiPaper-root':{
            backgroundColor: '#4c8c4a',
          },
          '& .MuiAccordionSummary-content .MuiPaper-root':{
            backgroundColor: '#1b5e20',
          },
          '& .MuiTable-root .MuiTableHead-root .MuiTableRow-root':{
            backgroundColor: '#1b5e20 !important',
          },
          '& .MuiAccordion-root .MuiAccordionSummary-root':{
            backgroundColor: '#1b5e20',
          },
          '& .MuiModal-root .MuiBox-root':{
            backgroundColor: '#4c8c4a !important',
          },
          '& .MuiModal-root .MuiOutlinedInput-inputMarginDense':{
            backgroundColor: '#9eba9d',
            borderRadius: '5px',
            color: '#1b5e20'
          },
          '& .MuiModal-root .MuiButton-containedPrimary .MuiButton-label':{
            color: '#ffffff'
          },
          '& .MuiModal-root .MuiButton-label':{
            color: '#1b5e20'
          },
          '& .MuiModal-root .MuiLink-button':{
            color: '#ffffff'
          },
          '& .MuiModal-root textarea':{
            backgroundColor: '#9eba9d',
            color: '#1b5e20'
          },
          '& .MuiOutlinedInput-inputMarginDense':{
            backgroundColor: '#9eba9d',
            borderRadius: '2px',
            color: '#1b5e20'
          },
          '& .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused fieldset':{
            borderColor: '#1b5e20 !important',
            borderRadius: '2px',
          },
          '& .MuiTableRow-root':{
            backgroundColor: '#4c8c4a !important',
          },
          '& .MuiBox-root .boxFooter':{
            backgroundColor: '#4c8c4a'
          },
          '& .MuiBox-root .boxFooter .MuiBox-root':{
            color: '#ffffff',
            '& svg':{
              color: '#ffffff',
            }
          },
          '& .MuiAccordionDetails-root .MuiTableCell-root .MuiBox-root':{
            color: '#ffffff',
            '& svg':{
              color: '#ffffff',
            }
          },
          '& .MuiTableCell-body .MuiSvgIcon-colorPrimary':{
            color: '#F77D3D !important',
          },
          '& .MuiTableCell-body .MuiSvgIcon-colorSecondary':{
            color: '#F8C567; !important',
          },
          '& .MuiGrid-grid-xs-8 .customMenu':{
            backgroundColor: '#66bb6a !important',
          },
          '& .MuiGrid-grid-xs-8 .customMenu .menuItem':{
            color: '#ffffff !important',
          },     
          '& .MuiGrid-grid-xs-8 .customMenu .menuItem:hover':{
            backgroundColor: '#9ab999 !important'
          },
          '& .MuiTable-root .innerTable':{
            border: '1px solid #1b5e20',
          },
          '& .MuiTableCell-root .selectInput .MuiSelect-root':{
            backgroundColor: '#9eba9d !important',
            color: '#1b5e20'
          }    
        },
        '.custom_icon':{
          color:'#fff',
          '& path':{
            fill:'#fff',
            fillOpacity:1,
          }
        }
      },
    },

    MuiButton: {
      root:{
        '& svg':{
          '& *':{
            fill: '#fff',
            fillOpacity: 1
          }
        }
      },
      text: {
        color: '#1b5e20',
        root: {
          fontSize: '1rem',
        }
      },
    },

    MuiButtonBase: {
      root:{
        '& *':{
            color:'#fff'
          }
        }
    },

    MuiAppBar:{
      colorDefault: {
        color: '#fff',
        backgroundColor: '#1b5e20',
      },
      root:{
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiTableRow:{
      root:{
        transition: 'all .3s',
        '&:hover':{
          backgroundColor: '#66bb6a !important'
        },
        '&.active':{
          backgroundColor: '#66bb6a !important'
        },
      }
    },

    MuiTableCell:{
      root:{
        border: '1px solid #1b5e20 !important',
        fontSize: 13,
        padding: '10.5px 8px',
      }
    },

    MuiLinearProgress:{
      determinate:{
        backgroundColor: '#212121'
      }
    },

    MuiListItem:{
      button:{
        '&:hover':{
          backgroundColor: '#66bb6a',
          color: '#fff'
        },
      }
    },

    MuiCard:{
      root:{
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))',
        boxShadow: 'none',
        '&.MuiPaper-root':{
            backgroundColor: '#4c8c4a !important',
            color: "#1b5e20 important"
          },
      }
    },

    MuiSvgIcon:{
      root:{
        color: '#fff',
      }
    },

    MuiFormLabel: {
      asterisk: {
        color: '#ff0000',
      },
    },

  }
  
});

ThemeConfig = responsiveFontSizes(ThemeConfig);

export default ThemeConfig;