import { gql } from '@apollo/client';

const ACCEPT_REJECT_ALL_PAGES_SEGMENTS = gql`mutation AcceptRejectAllSegments($data: AcceptRejectSegmentOnAllPagesRequest){
    acceptRejectSegmentOnAllPages(data: $data){
      status
      error
  }
 
  }`;

export default ACCEPT_REJECT_ALL_PAGES_SEGMENTS;