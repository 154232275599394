import { gql } from '@apollo/client';

const DELETE_TERM_BASE_DETAIL = gql`mutation DeleteTermBaseDetail($id: ID){
    deleteTermBaseDetail(id: $id){
      status
      error
  }
 
  }`;

export default DELETE_TERM_BASE_DETAIL;