import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, Radio, RadioGroup, FormControlLabel, TextField, Button, IconButton, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "../editor-menu/EditorMenuStyles";

interface IProps {
    openSubmit: boolean,
    setOpenSubmit: any,
    setForceSubmit: any,
    showSubmitError: any
}

const SubmitModal = ({ openSubmit, setOpenSubmit, setForceSubmit, showSubmitError }: IProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit: any = () => {
        setForceSubmit(true);
        setOpenSubmit(false);
    }

    const handleModalClose = () => {
        setOpenSubmit(false);
    };

    return (
        <Modal
            open={openSubmit}
            onClose={handleModalClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="MuiModal-root"
        >
            <Box className={classes.submitModalStyles}>
                <Box borderBottom={'1px solid #dedede'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box className={classes.modalTitle} key={0}>
                        {t('header.submitTranslation')}
                    </Box>
                    <IconButton className={classes.closeButton} onClick={handleModalClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Typography className={classes.submitModalMsg}>
                    {showSubmitError}
                </Typography>

                <Box className={classes.modalFooter} key={2}>
                    <Button
                        variant="contained"
                        onClick={handleModalClose}
                        className={classes.cancelButton}

                    >
                        {t('header.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                    >
                        {t('header.yes')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default SubmitModal;