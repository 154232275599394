import { onError } from "@apollo/client/link/error";
import logout from "src/helpers/custom-hooks/Logout";

const handleErrors = ({ networkError, graphQLErrors }: any) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }: any) => {
            if (message === 'You are not authorized') {
                logout();
            } else {
                console.log("[GraphQL error]: Message", message, ",\n Location: ", locations, ",\n Path: ", path);
            }
        });
    }
    if (networkError) {
        console.log("[Network error]:", networkError);
    }
};

const errorControl = onError(handleErrors);

export { handleErrors, errorControl };