import React, { useReducer, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Card, CardContent, Snackbar } from "@material-ui/core";
import { IButton } from "./Interfaces";
import { IAppState } from "src/interfaces";
import ActionReducer, { intialState, IState } from "./ActionReducer";
import ActionWraper from "./ActionWraper";
import ButtonsList from "./ButtonsList";
import useStyles from "./EditorMenuStyles";
import MERGE_SEGMENT from "src/helpers/mutations/mergeSegment";
import { useMutation } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import { t } from "i18next";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";
interface IProps {
  appState: IAppState;
  appDispatch: Function;
  actionDispatch: Function;
  actionState: IState;
  scrollValue: any;
  headerData: any;
  setVerify: any;
  verify: any;
  fromEditorMenu: any;
  setFromEditorMenu: any;
  setNotConfirmed: Function;

}
const EditorMenu = ({ appState, appDispatch, scrollValue, actionDispatch, actionState, headerData, setVerify, verify, fromEditorMenu, setFromEditorMenu, setNotConfirmed }: IProps) => {
  const classes = useStyles();
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarState, setSnackBarState]: any = useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {
    gqlError,
    gqlErrorSnackBar,
    handleErrorSnackBarClose,
    handleError,
  } = useErrorHandler();

  const [mergeError, setMergeError] = useState("");
  const [mergeErrorSnackBar, setMergeErrorSnackBar] = useState(false);

  const { vertical, horizontal }: any = snackBarState;

  const getCurrentSegment: any = () => {
    let selected = appState.selectedRow.id;
    let selectedIndex = appState.data.findIndex((row: any) => row.id === selected);
    if ((selectedIndex + 1) !== appState.data.length) {
      let nextSegment: any = appState.data[selectedIndex + 1]?.id;
      return [parseInt(selected), parseInt(nextSegment)];
    }
    return 0;
  }

  const getQueryParam = (parameterName: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(parameterName);
  };

  const [mergeSegment, { loading: loadingMergeSegments }] = useMutation(MERGE_SEGMENT, {
    errorPolicy: 'all',
    variables: {
      data: {
        sourceSegmentId: getCurrentSegment()[0],
        key: appState.selectedRow.key,
        tl: appState.selectedLanguage,
        isTrackOn: actionState.trackChanges,
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (data.mergeSegment.error !== "") {
        setMergeError(data.mergeSegment.error);
        setMergeErrorSnackBar(true);
      }
      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }
    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });


  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.ctrlKey && e.key === 'b') {
        e.preventDefault(); // Prevent the browser's default behavior
        handleClick('BOLD');
      }
      if (e.ctrlKey && e.key === 'u') {
        e.preventDefault();
        handleClick('UNDERLINE');
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleClick = (type: string) => {
    let service = getQueryParam('type');

    if (
      type === "UNDO" || type === "REDO"
    ) {
      appDispatch({ type: type });
    }
    else if (type === "SHOW_CODE") {
      appDispatch({
        type: "SHOW_CODE", payload: false,
      });
    }
    else if (type === "CONFIRM" && appState.selected === 0) {
      // appState.selected === 0
    }
    else if (type === "MERGE") {
      if (appState.selected) {
        if (getCurrentSegment().length > 0) {
          appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
          localStorage.setItem("current_segment", JSON.stringify(appState.selectedRow.id));
          mergeSegment();
        }
        else {
          setSnackBarOpen(true);

        }
      } else {
        setSnackBarOpen(true);
      }
    }
    else if (type === "WHITE_SPACES") {
      actionDispatch({ type: type });
      // actionDispatch({ type: type, payload: appState.data });
    } else if (type === "TRACK_CHANGES") {
      if (service !== "proofreading") {
        actionDispatch({ type: type, payload: appState.data });
      }
    }
    else if (type === "REPLACE") {
      appDispatch({ type: "REPLACE", payload: appState.expandReplace ? false : true });
    }
    else {
      actionDispatch({ type: type, payload: appState.formatter });
      let x = appState.formatter.format;
      let act: any = appState.formatter.active;
      if (x.includes(type) && act.find((i: any) => { if (i.name === type) return i.active })?.active) {
        x = x.filter((i: any) => i !== type)
        act.forEach((el: any) => {
          if (el.name === type) {
            el.active = false;
          }
        });
      } else {
        x.push(type);
        act.forEach((el: any) => {
          if (el.name === 'SUPERSCRIPT' && el.active === true && type === 'SUBSCRIPT') {
            el.active = false;
          } else if (el.name === 'SUBSCRIPT' && el.active === true && type === 'SUPERSCRIPT') {
            el.active = false;
          }
        });
        act.forEach((el: any) => {
          if (el.name === type) {
            el.active = true;
          }
        });
      }
      // console.log("else   xxxx: ", x.includes(type))
      // console.log("else   actt: ", act.find((i: any) => { if (i.name === type) return i.active })?.active)
      appState.formatter.format = x;
      appState.formatter.active = act;

    }
  };

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    setMergeErrorSnackBar(false);
  };


  return (
    <Box className={scrollValue < 64 ? classes.sticky : `${classes.stickyShadow} ${classes.sticky}`}>
      {/*{appState.selectedService.isAssigned == 1 && }*/}
      {appState.selectedService.isAssigned == 1 ? ButtonsList.map((item: any, idx1: number) => {
        if (item.length > 3) {
          // return <Card className={classes.actionWrap}
          //   key={idx1}
          // >
          //   <CardContent>
          //     <ButtonGroup
          //       className={classes.buttonGroup}
          //       variant="outlined"
          //       color="default"
          //     >
          //       {item.map((btn: IButton, idx2: number) => {
          //         return <Button
          //           disabled={appState.selectedService.isAssigned == 0 ? true : false}
          //           id={"btn-id" + btn.type}
          //           className={(appState.formatter.format.includes(btn.type)
          //             && appState.formatter?.active.find((i: any) => { if (i.name === btn.type) return i })?.active) ? "btn-active" : ""}
          //           onClick={(e) => handleClick(btn.type)} key={idx2}>
          //           {btn.icon}
          //         </Button>
          //       }
          //       )}
          //     </ButtonGroup>
          //   </CardContent>
          // </Card>
        } else {
          return <ActionWraper
            actionState={actionState}
            appState={appState}
            actionDispatch={actionDispatch}
            appDispatch={appDispatch}
            headerData={headerData}
            setVerify={setVerify}
            verify={verify}
            actionButtons={item}
            btnClick={handleClick}
            key={idx1}
            fromEditorMenu={fromEditorMenu}
            setFromEditorMenu={setFromEditorMenu}
            setNotConfirmed={setNotConfirmed}
          />
        }
      }
      ) : ButtonsList.map((item: any, idx1: number) => {
        if (item.length > 3) {
          // return <Card className={classes.actionWrap}
          //   key={idx1}
          // >
          //   <CardContent>
          //     <ButtonGroup
          //       className={classes.buttonGroup}
          //       variant="outlined"
          //       color="default"
          //     >
          //       {item.map((btn: IButton, idx2: number) => {
          //         return <Button
          //           disabled={appState.selectedService.isAssigned == 0 ? true : false}
          //           id={"btn-id" + btn.type}
          //           className={(appState.formatter.format.includes(btn.type)
          //             && appState.formatter?.active.find((i: any) => { if (i.name === btn.type) return i })?.active) ? "btn-active" : ""}
          //           onClick={(e) => handleClick(btn.type)} key={idx2}>
          //           {btn.icon}
          //         </Button>
          //       }
          //       )}
          //     </ButtonGroup>
          //   </CardContent>
          // </Card>
        } else {
          return <ActionWraper
            actionState={actionState}
            appState={appState}
            actionDispatch={actionDispatch}
            appDispatch={appDispatch}
            headerData={headerData}
            setVerify={setVerify}
            verify={verify}
            actionButtons={item}
            btnClick={handleClick}
            key={idx1}
            fromEditorMenu={fromEditorMenu}
            setFromEditorMenu={setFromEditorMenu}
            setNotConfirmed={setNotConfirmed}
          />
        }
      }
      )}

      <Snackbar className={classes.snackBarStyles} anchorOrigin={{ vertical, horizontal }} open={snackBarOpen}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {!(getCurrentSegment().length > 0) && appState.selected ? t('editorMenu.lastSegMerge') : t('editorMenu.selectToMerge')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={mergeErrorSnackBar}
        autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {mergeError}
        </Alert>
      </Snackbar>

      <CustomSnackbar
        error={gqlError}
        open={gqlErrorSnackBar}
        onClose={handleErrorSnackBarClose}
      />
    </Box>
  );
};

export default React.memo(EditorMenu);