import { gql } from '@apollo/client';
const GET_VERIFY_RESULTS = gql`query GetVerifyResults($projectId: String!, $fileId: String!, $tl:String!) {
    getVerifyResults(projectId: $projectId, fileId: $fileId, tl: $tl)
     {
        id
        sourceSegment
        translationSegment
        level
        type
        message
    }
  }`;

export default GET_VERIFY_RESULTS;