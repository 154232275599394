import { gql } from '@apollo/client';

const VERIFY_SEGMENT = gql`mutation verifySegment($data: VerifySegment){
    verifySegment(data: $data){
      status
      error
    }
 
  }`;

export default VERIFY_SEGMENT;