import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            padding: 7,
            '& svg': {
                color: '#0000004D'
            }
        },
        snackBarStyles: {
            position: 'absolute',
            bottom: 16,
            left: 20,
            display: 'table',
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            padding: '2px 10px',
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important',
                width: '16px',
                height: '16px',
            },
            '& .MuiAlert-message': {
                fontSize: '12px !important'
            },
            '& .MuiAlert-action': {
                marginRight: '-4px',
                paddingLeft: '8px'
            },
            '& .MuiAlert-icon': {
                marginRight: '8px',
            }
        },
        splitModal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },

        splitModalTitle: {
            padding: '16px 20px 16px 20px',
            borderBottom: '1px solid #dedede',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        splitModalBody: {
            padding: '20px',
            maxHeight: '385px',
            overflowY: 'scroll'
        },
        splitModalFooter: {
            padding: '16px 20px 16px 20px',
            borderTop: '1px solid #dedede',
            display: 'flex',
            justifyContent: 'right'
        },
        textArea: {
            width: '100%',
            borderRadius: '4px',
            borderColor: '#dedede',
        },
        splitText: {
            width: '100%',
            outline: 'none',
            // '& fieldset': {
            //     border: '1px solid #E5E5E5',
            //     filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))'
            // },
            // '& .MuiInputBase-root': {
            //     padding: '13px 16px',

            // },
            // '& .MuiInputLabel-outlined': {
            //     transform: 'translate(16px, 13px) scale(1)',
            //     fontSize: 12,
            // },
            // '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            //     transform: 'translate(14px, -4px) scale(0.9) !important'
            // },
            // '& textarea': {
            //     fontSize: 13,
            //     padding: '13px 16px',
            // },
            // '& .MuiOutlinedInput-root.Mui-focused': {
            //     '& fieldset': {
            //         border: '1px solid #f77d3d !important',
            //         outline: 'none !important'
            //     }
            // },
            // '& .MuiOutlinedInput-root': {
            //     '&:hover fieldset': {
            //         borderColor: '#E5E5E5',
            //     },
            // },
            // '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
            //     padding: 0,
            // }
        },
        splittedChunks: {
            border: '1px solid #E5E5E5',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.07)',
            padding: '13px 16px',
            fontSize: '13px',
            marginBottom: '20px',
            '&:focus': {
                outline: '1px solid #f77d3d !important'
            }
        },
        splitHeading: {
            fontSize: '12px',
            fontWeight: 600,
            color: '#f77d3d',
            marginBottom: '2px'

        },
        snackbarContainer: {
            position: 'fixed',
        },
    }),
);

export default useStyles;