import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean,
    setOpen: Function,
    children: JSX.Element,
    title: string,
    hideCloseBtn?: boolean,
    handleSubmit?: Function
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        modalWrap: {
            '& .MuiDialogTitle-root': {
                padding: '10.5px 24px',
                '& h2': {
                    fontFamily: 'Roboto Medium'
                }
            },
            '& .MuiDialogContent-dividers': {
                borderBottom: 'none',
                padding: 0
            },
            '& .MuiDialogActions-root': {
                padding: 16
            }
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            padding: 7,
            '& svg': {
                color: '#0000004D'
            }
        },
    })
);

const ScrollDialog = ({ open, setOpen, children, title, hideCloseBtn, handleSubmit }: IProps) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        handleClose();
        if (handleSubmit) {
            handleSubmit();
        }
    }

    const { t } = useTranslation();

    return (
        <Dialog className={classes.modalWrap} open={open} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>
                {title}
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText tabIndex={-1}>
                    {children}
                </DialogContentText>
            </DialogContent>
            {!hideCloseBtn &&
                <DialogActions>
                    <Button size='small' variant="contained" onClick={submitForm} color="primary" disableElevation>
                        {t('header.sure')}
                    </Button>
                </DialogActions>
            }
        </Dialog>
    )
}

export default ScrollDialog;