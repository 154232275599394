import { gql } from '@apollo/client';

const REPLACE_SEGMENT_TRANSLATION = gql`
  mutation ReplaceSegmentTranslation(
    $projectId: String!,
    $fileId: Int!,
    $isReplaceAll: Boolean!,
    $caseSensitive: Boolean!,
    $find: String!,
    $replace: String!,
    $tl: String!,
    $isTrackOn: Boolean!,
    $perPage: Int,
    $currentPage: Int,
    $start: Int,
    $end: Int,
    $source: String,
    $target: String,
    $segmentFilters: SegmentFilters
  ) {
    replaceSegmentTranslation(data: {
      projectId: $projectId,
      fileId: $fileId,
      isReplaceAll: $isReplaceAll,
      caseSensitive: $caseSensitive,
      find: $find,
      replace: $replace,
      tl: $tl,
      isTrackOn: $isTrackOn,
      perPage: $perPage,
      currentPage: $currentPage,
      start: $start,
      end: $end,
      source: $source,
      target: $target,
      segmentFilters: $segmentFilters
    }) {
      getFileSegmentsByLanguage {
        pagination {    
          totalPages
          currentPage
          totalRecords
        }
        searchCounts { 
          source
          target
          total
        }
        segments {
          sSegmentId
          tSegmentId
          key
          source
          target
          words
          state
          isLocked
          isPropagationOn
          tStatusType
          tStatusValue
          isAT
          verifyResult {
            level
            type
            message
          }
        }
      }
      getFileProgress {
        totalSegments
        totalTranslated
        totalReviewed
        percentTranslated
        percentReviewed
      }
    }
  }
`;

export default REPLACE_SEGMENT_TRANSLATION;
