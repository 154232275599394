import { gql } from '@apollo/client';

const UPDATE_PROJECT_SETTINGS = gql`mutation updateProjectSettings($data: UpdateProjectSettings){
    updateProjectSettings(data: $data){
      status
      error
  }
 
  }`;

export default UPDATE_PROJECT_SETTINGS;