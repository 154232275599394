import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  ButtonGroup,
  MenuItem,
  Menu,
  Fade,
  Box,
  IconButton,
  Modal,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Snackbar
} from "@material-ui/core";
import { ArrowDropDownIcon } from "src/material-ui";
import { IButton } from "./Interfaces";
import { IAppState } from "src/interfaces";
import useStyles from "./EditorMenuStyles";
import { IState } from "./ActionReducer";
import LaunchIcon from "@material-ui/icons/Launch";
import CheckBoxBasic from "./CheckBoxBasic";
import CheckBoxCommon from "./CheckBoxCommon";
import CheckBoxAdvance from "./CheckBoxAdvance";
import { withStyles, Theme } from "@material-ui/core/styles";
import { useLazyQuery, useMutation } from '@apollo/client';
import SAVE_SEGMENTS from 'src/helpers/mutations/saveSegmentTranslation';
import UPDATE_PROJECT_SETTINGS from 'src/helpers/mutations/updateProjectSettings';
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import VERIFY_SEGMENT from "src/helpers/mutations/verifySegment";
import SPLIT_SEGMENT from "src/helpers/mutations/splitSegment";
import { Alert } from "@material-ui/lab";
import GET_CURRENT_USER_DETAILS from "src/helpers/queries/GetCurrentUserDetails";
import { createUnparsedSourceFile } from "typescript";
import APPLY_MACHINE_TRANSLATION_ALL_PAGES from "src/helpers/mutations/applyMachineTranslationOnAllPages";
import PROJECT_DETAIL from "src/helpers/queries/ProjectDetailsById";
import SplitSegment from "./SplitSegment";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";

interface IProps {
  actionButtons: IButton[];
  appState: IAppState;
  actionState: IState;
  btnClick: Function;
  actionDispatch: Function;
  appDispatch: Function;
  headerData: any,
  setVerify: any,
  verify: any,
  fromEditorMenu: any,
  setFromEditorMenu: any,
  setNotConfirmed: Function
}
interface IAnchor {
  [x: number]: Element | ((element: Element) => Element) | null | undefined;
}

const ActionWraper = ({
  actionButtons,
  btnClick,
  actionDispatch,
  appState,
  actionState,
  appDispatch,
  headerData,
  setVerify,
  verify,
  fromEditorMenu,
  setFromEditorMenu,
  setNotConfirmed
}: IProps) => {
  // const [app, setApp]: any = React.useState(appState);
  const classes = useStyles();
  const [anchorEl, setAnchorEl]: any = useState<IAnchor>();
  // const [state1, dispatch] = useReducer(AppReducer, initialState);
  const [open, setOpen] = useState(false);
  const [SaveSegments, { data, loading }] = useMutation(SAVE_SEGMENTS, { errorPolicy: 'all' });

  const [saveAlert, setSaveAlert] = useState('');
  const [isHover, setIsHover] = useState(false);
  const [splitModalOpen, setSplitModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(0.5);
  const [mtAlert, setMTAlert] = React.useState(false);
  const [splitAlert, setSplitAlert] = React.useState(false);
  const [splitLock, setSplitLock] = React.useState(false);

  const {
    gqlError,
    gqlErrorSnackBar,
    handleErrorSnackBarClose,
    handleError,
  } = useErrorHandler();

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarState, setSnackBarState]: any = useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal }: any = snackBarState;

  const disableBtn = ["COPY", "CLEAR", "TRANSLATE_SEGMENT", "ACCEPT_AND_MOVE", "REJECT_AND_MOVE"]

  const handleModalOpen = () => {
    setOpen(true);
    // setMenuItemClicked(true);
  };

  const getQueryParam = (parameterName: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(parameterName);
  };

  let isRO = getQueryParam('ro');
  let fileId = getQueryParam('fileId');
  let tl = getQueryParam('tl');
  let type = getQueryParam('type');


  // const [getCurrentUserDetails, { data: userData, error: userError }] = useLazyQuery(
  //   GET_CURRENT_USER_DETAILS, { fetchPolicy: 'no-cache' }
  // );

  const [GetProjectDetailById, { data: projectSettingsData, error: peojectSettingsError }] = useLazyQuery(
    PROJECT_DETAIL, { fetchPolicy: 'no-cache' }
  );
  // useEffect(() => {
  //   if (userData) {
  //     appDispatch({ type: "SET_CURRENT_USER", payload: userData?.getCurrentUserDetails });
  //   }
  // }, [userData]);

  useEffect(() => {
    appDispatch({ type: "SET_PROJECT_SETTINGS", payload: projectSettingsData?.getProjectDetailById?.settings });
  }, [projectSettingsData]);

  const handleModalClose = () => {
    setOpen(false);

    // getCurrentUserDetails();

    GetProjectDetailById({
      variables: {
        id: headerData?.id,
        readonly: isRO == "1" ? 1 : 0,
        fileId: fileId + "",
        tl: tl ? tl : "",
        service: type?.toLowerCase(),
      }
    });

    // setMenuItemClicked(false);
  };

  const [menuItemClicked, setMenuItemClicked] = useState(false);

  useEffect(() => {
    const handleBodyClick = () => {
      if (document.body.classList.contains("open")) {
        document.body.classList.remove("fixed-position");
        document.body.classList.remove("sticky-position");
        document.body.classList.remove("open");
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (menuItemClicked) {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.add("sticky-position");
        document.body.classList.add("open");
        setMenuItemClicked(true);

      }, 1);
    } else {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.remove("sticky-position");
        document.body.classList.remove("open");
        setMenuItemClicked(false);

      }, 1);
    }
  }, [menuItemClicked]);

  useEffect(() => {
    if (open) {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.add("fixed-position");
        document.body.classList.add("open");
      }, 1);
    } else {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.remove("fixed-position");
        document.body.classList.remove("open");
      }, 1);
    }
  }, [open]);

  const advance: any = localStorage.getItem("advance");
  const [applyMachineTranslationOnAllPages, { loading: loadingMachineTranslation }] = useMutation(APPLY_MACHINE_TRANSLATION_ALL_PAGES, {
    errorPolicy: 'all',
    variables: {
      data: {
        sl: headerData?.sl?.code,
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        engine: advance ? JSON.parse(advance)[1].children[0].inputValue + " Translate" : 'Google Translate',
        segmentIds: [],
        ignoreTags: false,
        isTrackOn: actionState.trackChanges,
        start: appState.data[0]?.key,
        end: appState.data[appState.data.length - 1]?.key
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }

      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const startVal = getQueryParam('start');

  const endVal = getQueryParam('end');

  let startParam: any = 0;
  let endParam: any = 0;

  if (typeof startVal != "undefined" && startVal) {
    startParam = parseInt(startVal);
  }
  if (typeof endVal != "undefined" && endVal) {
    endParam = parseInt(endVal);
  }

  const [verifySegment, { loading: loadingCopySegments }] = useMutation(VERIFY_SEGMENT, {
    errorPolicy: 'all',
    variables: {
      data: {
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        segmentIds: [],
        service: appState.selectedService.code,
        start: startParam ? startParam : 0,
        end: endParam ? endParam : 0,
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });
      setVerify(!verify);

      // if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
      //   appDispatch({
      //     type: "QUERY_EXECUTE_FILTERS",
      //     payload: 2
      //   });
      // } else {
      //   appDispatch({
      //     type: "QUERY_EXECUTE_FILTERS",
      //     payload: 1
      //   });
      // }
    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  // const [splitSegment, { loading: loadingSplitSegments }] = useMutation(SPLIT_SEGMENT, {
  //   errorPolicy: 'all',
  //   variables: {
  //     data: {
  //       segmentId: appState.selectedRow.id,
  //       tl: appState.selectedLanguage
  //     }
  //   },
  //   onCompleted: (data: any) => {
  //     // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

  //     if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
  //       appDispatch({
  //         type: "QUERY_EXECUTE_FILTERS",
  //         payload: 2
  //       });
  //     } else {
  //       appDispatch({
  //         type: "QUERY_EXECUTE_FILTERS",
  //         payload: 1
  //       });
  //     }
  //   }, onError: (error: any) => {

  //     alert(error);
  //   }
  // });

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#494949",
      border: "1px solid #E0E0E0",
      fontSize: 12,
      margin: "2px 0",
    },
  }))(Tooltip);

  const { t } = useTranslation();


  const dotCount: any = () => {
    let selectedSegment = appState.selectedRow.source;
    let dot = (selectedSegment?.match(/\./g) || []).length;
    return dot;
  }


  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    val: any
  ) => {

    const resetHistory: any = [
      'MERGE',
      'SPLIT',
      'SHOW_CODE',
      // 'PRE_TRANSLATION',
      'VERIFY',
    ];

    if (val !== "SHOW_CODE" && val !== "UNDO" && val !== "REDO" && val !== "MERGE" && val !== "SPLIT" && val !== "WHITE_SPACES" && val !== "PRE_TRANSLATION" && val !== "TRACK_CHANGES" && val !== "REPLACE" && val !== "VERIFY") {
      setMenuItemClicked(val);
    }

    if (val === "MACHINE_TRANSLATION") {
      setIsActive(1);
    }

    if (val === "VERIFY") {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
      verifySegment();
    }

    if (val === "SPLIT") {

      if (appState.selectedSegments.length > 1) {
        setSplitAlert(true);
      }
      else if (appState.selectedRow.isLocked) {
        setSplitLock(true);
      }
      else if (appState.selectedSegments.length === 1) {
        setSplitModalOpen(true);
      }
      else {
        setSnackBarOpen(true);
      }

      // if (appState.selected) {
      //   if (dotCount() > 1) {
      //     // There are more than one dot in the source
      //     // Only then you can perform split
      //     appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
      //     localStorage.setItem("current_segment", JSON.stringify(appState.selectedRow.id));
      //     splitSegment();
      //   }
      //   else {
      //     setSnackBarOpen(true);
      //   }
      // }
      // else {
      //   setSnackBarOpen(true);
      // }
    }

    if (val) {
      btnClick(val);
    }

    setAnchorEl({ ...anchorEl, [index]: event.currentTarget });

    if (resetHistory.includes(val)) {
      appDispatch({ type: "RESET_HISTORY" })
    }

  };

  const handleClose = (index: number) => {
    setAnchorEl({ ...anchorEl, [index]: null });
  };

  const confirmSelectedSegments = (flag: number) => {
    setSaveAlert('');
    let selectedRows: any;

    // Flag to confirm selected rows or data shorter than 11 rows.
    if (flag === 0) {
      selectedRows = appState.selectedSegments.length > 1
        ? appState.selectedSegments
        : [appState.selectedRow];
    } else {
      selectedRows = appState.data;
    }

    if (selectedRows.length > 0) {
      appDispatch({
        type: "CONFIRM_DATA",
        payload: selectedRows,
      });
    } else {
      setNotConfirmed(true);
    }

  }

  const confirmAllSegments = () => {
    const temp: any = [];

    const rowsToSave: any = appState.data.map((row: any) => {
      if (row.isPropagationOn === 1 && temp.some((item: any) => item.source === row.source && item.isPropagationOn === 1)) {
        row.tStatusType = 'AP';
        row.tStatusValue = '';
      } else {
        //To update row target before loader
        const rowDoc = document.getElementById("custom-editor" + row.id);
        const rowTarget = rowDoc?.innerHTML;
        row.target = rowTarget;
        temp.push(row);
      }
      return row;
    })

    if (rowsToSave.length > 0) {

      rowsToSave.forEach((row: any) => {
        if (row.isPropagationOn === 1) {
          // Find corresponding rows with the same source and isPropagationOn: 1
          const matchingRows = appState.data.filter((otherRow: any) =>
            otherRow.id !== row.id
            && otherRow.source === row.source
            && otherRow.isPropagationOn === 1
          );

          // Update matching segments target in propagation
          matchingRows.forEach((matchingRow: any) => {
            const rowDoc = document.getElementById("custom-editor" + matchingRow.id);
            const rowTarget = rowDoc?.innerHTML;
            matchingRow.target = rowTarget;
          })

          // Add matching rows to rowsToSave
          rowsToSave.push(...matchingRows);
        }
      });

      // Remove duplicate propagated ids from rowsToSave
      const uniqueRowsToSave = Array.from(new Set(rowsToSave));
      appDispatch({
        type: "CONFIRM_DATA",
        payload: uniqueRowsToSave,
      });

      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });

    } else {
      setNotConfirmed(true);
    }
  }

  const handleMenuClick = (index: number, type: string) => {
    const track = ["ACCEPT_AND_MOVE", "REJECT_AND_MOVE", "ACCEPT_CHANGES", "REJECT_CHANGES", "ACCEPT_ALL_CHANGES", "REJECT_ALL_CHANGES"];
    handleClose(index);

    const resetHistory: any = [
      'CONFIRM_SEGMENT',
      'CONFIRM_CURRENT',
      'COPY_ALL',
      'COPY_ALL_PAGES',
      'CLEAR_ALL',
      'TRANSLATE_ALL',
      "PRE_TRANSLATION",
      'ACCEPT_AND_MOVE',
      'ACCEPT_CHANGES',
      'ACCEPT_ALL_CHANGES',
      'REJECT_AND_MOVE',
      'REJECT_CHANGES',
      'REJECT_ALL_CHANGES',
    ];

    let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
    let rowVal = rowHtml.innerHTML;
    let rowState = appState.selectedRow.state;

    if (type === "CONFIRM") {
      confirmSelectedSegments(0)
    } else if (type === "CONFIRM_CURRENT" && appState.data.length > 10) {
      confirmAllSegments()
    } else if (type === "CONFIRM_CURRENT" && appState.data.length <= 10) {
      // handle confirm all segments if rows less than 11
      confirmSelectedSegments(1)
    }

    else if (track.includes(type)) {
      appDispatch({
        type: "HANDLE_TRACK_CHANGES",
        payload: { isAccept: type.startsWith("ACCEPT_"), isMove: type.endsWith("AND_MOVE"), toAll: type.endsWith("_ALL_CHANGES"), data: appState.data }
      });
      if (type === "ACCEPT_ALL_CHANGES" || type === "REJECT_ALL_CHANGES") {
        let trackVal = 1;
        if (type === "ACCEPT_ALL_CHANGES") {
          if (appState.hitTrackChanges == 1 || appState.hitTrackChanges == 2) {
            trackVal = 3;
          } else if (appState.hitTrackChanges == 3 || appState.hitTrackChanges == 0) {
            trackVal = 1;
          }
          else if (appState.hitTrackChanges == 4) {
            trackVal = 3
          }
        } else if (type === "REJECT_ALL_CHANGES") {
          if (appState.hitTrackChanges == 1 || appState.hitTrackChanges == 3) {
            trackVal = 2;
          } else if (appState.hitTrackChanges == 2 || appState.hitTrackChanges == 0) {
            trackVal = 4
          }
          else if (appState.hitTrackChanges == 4) {
            trackVal = 2
          }
        }
        appDispatch({ type, payload: trackVal });
      }
    }

    else if (type === "TRANSLATE_SEGMENT") {
      if (headerData.isMTEnabled) {

        let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);

        appDispatch({
          type: "ADD_HISTORY",
          payload: {
            id: appState.selectedRow.id,
            history: rowHtml.innerHTML,
            state: appState.selectedRow.state,
            tStatusType: appState.selectedRow.tStatusType,
            tStatusValue: appState.selectedRow.tStatusValue,
            isMT: 1
          },
        });

        appDispatch({
          type: "MACHINE_TRANSLATION",
          payload: appState.selectedSegments.length > 1 ? "all" : "1",
        });

      } else {
        setMTAlert(true)
      }


    }

    else if (type === "TRANSLATE_ALL") {
      if (headerData.isMTEnabled) {
        applyMachineTranslationOnAllPages();
        appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });

      } else {
        setMTAlert(true)
      }
    }

    else if (type === "PRE_TRANSLATION") {
      let copyVal = 1;
      if (appState.hitPreTranslationAllPages == 1) {
        copyVal = 2
      } else if (appState.hitPreTranslationAllPages == 2) {
        copyVal = 1
      }

      appDispatch({ type: 'PRE_TRANSLATION_ALL_PAGES', payload: copyVal });
    }

    else if (type === "COPY_ALL_PAGES") {
      let copyVal = 1;
      if (appState.hitCopyAllPages == 1) {
        copyVal = 2
      }
      else if (appState.hitCopyAllPages == 2) {
        copyVal = 1
      }
      appDispatch({ type: type, payload: copyVal });
    }

    else {
      appDispatch({
        type: type,
        payload: appState,
      });
      if (type === "COPY") {
        let rowHtml: any = document.getElementById("custom-editor" + appState.selectedRow.id);
        appDispatch({
          type: "ADD_HISTORY",
          payload: {
            id: appState.selectedRow.id,
            history: rowHtml.innerHTML,
            state: appState.selectedRow.state,
            tStatusType: appState.selectedRow.tStatusType,
            tStatusValue: appState.selectedRow.tStatusValue,
            isCopy: 1
          },
        });
      }

      if (type === "CLEAR") {
        appDispatch({
          type: "ADD_HISTORY",
          payload: {
            id: appState.selectedRow.id,
            history: rowVal == "" ? " " : rowVal,
            state: rowState,
            tStatusType: appState.selectedRow.tStatusType,
            tStatusValue: appState.selectedRow.tStatusValue,
            isClear: 1,
            isRemovePrev: 1
          },
        });
      }

    }

    if (resetHistory.includes(type)) {
      appDispatch({ type: "RESET_HISTORY" })
    }
  };

  const [updateProjectSettings, { data: verifydata, loading: verifyLoading }] = useMutation(UPDATE_PROJECT_SETTINGS, { errorPolicy: 'all' });

  useEffect(() => {
    if (appState.isActionStateChanged != 0) {
      updateProjectSettings({
        variables: {
          data: {
            projectId: headerData?.id,
            settings: [
              {
                title: 'Basic',
                checked: actionState.basic.find((itm: any) => itm.checked === false) ? false : true,
                value: 'basic',
                children: actionState.basic
              }, {
                title: 'Common',
                checked: actionState.common.find((itm: any) => itm.checked === false) ? false : true,
                value: 'common',
                children: actionState.common
              }, {
                title: 'Advance',
                checked: actionState.advance.find((itm: any) => itm.checked === false) ? false : true,
                value: 'advance',
                children: actionState.advance
              }, {
                title: 'Exclude Segments',
                checked: actionState.excludeSegments.find((itm: any) => itm.checked === false) ? false : true,
                value: 'excludeSegments',
                children: actionState.excludeSegments
              }
            ]

          }
        }
      })
    }

    // actionState.basic, actionState.common, actionState.advance, actionState.excludeSegments,

    // VERIFY_USER_SETTINGS
  }, [appState.isActionStateChanged]);

  useEffect(() => {
    if (saveAlert !== '') {
      // alert(saveAlert)
    }
  }, [saveAlert]);

  if (data?.saveSegmentTranslation?.status === 'ok' && !loading) {
    if (saveAlert === '')
      setSaveAlert(data?.saveSegmentTranslation?.status);
  }

  function escapeRegExp(string: any) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  const [state, setState] = useState([
    {
      name: t('filters.segmentFiltering'),
      status: true,
    },
  ]);

  useEffect(() => {
    setState([
      {
        name: t('filters.segmentFiltering'),
        status: true,
      }
    ]);
  }, [t]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const check = event.target.checked;
    const data: any = { name: name, status: check };
    // if (name === t('filters.allSegments') && event.target.checked === false) {
    //   setState([{ name: t('filters.segmentFiltering'), status: true }]);
    // }

    if (name === t('filters.allSegments')) {
      setState([{ name: t('filters.allSegments'), status: true }]);
    }
    else if (name !== t('filters.allSegments') && event.target.checked === true) {
      state.push(data);
      setState((prev) => {
        const newState = prev.filter((prev) => prev.name !== t('filters.allSegments'));
        return newState;
      });
    } else if (event.target.checked === false) {
      setState((prev) => {
        const newState = prev.filter((prev) => prev.name !== data.name);
        return newState;
      });
      if (state.length === 1)
        setState([{ name: t('filters.allSegments'), status: true }]);
    }
  };

  const isTag = (text: string) => {
    return /^(\{[a-zA-Z\d]+:\})|(\{:[a-zA-Z\d]+\})|(\{:[a-zA-Z\d]+:\})$/ig.test(text);
  }

  useEffect(() => {
    const filters: any = state.map((item: any) => item.name);
    const dataArray = appState.data;
    const idArray: any = [];
    // !== t('filters.empty')
    if (filters[0] !== t('filters.allSegments') && !filters.includes(t('filters.empty')) && !filters.includes(t('filters.segmentFiltering'))) {
      dataArray.forEach((row: any, index: number) => {
        const val = row.tStatusValue ? JSON.parse(row.tStatusValue) : {};
        const match = parseInt(val?.matchRate?.replace('%', ''));
        const segmentHTML = document.getElementById('custom-editor' + row.id)?.innerHTML;
        //  Empty segments
        if (((filters.some((elem: any) => elem === t('filters.empty')) && row.target === "")
          //  Segment targets same as source
          || (filters.some((elem: any) => elem === t('common.targetSame')) && row.target === row.source)
          //  Segment targets that contains tags i.e. {1:}, {:2} etc
          || (filters.some((elem: any) => elem === t('filters.containsTags')) && (isTag(row.source) || isTag(row.target)))
          //  Segment targets that 100% matches TM
          || (filters.some((elem: any) => elem === t('filters.hundredMatches')) && (row.tStatusType === 'TM' && match === 100))
          //  Segment targets that matches TM below 100%
          || (filters.some((elem: any) => elem === t('filters.fuzzyMatches')) && (row.tStatusType === 'TM' && match > 79 && match < 100))
          //  Context Matches 
          //  if(waitOverForAPI) contextMatches = true
          //  Segment targets that matches MT 
          || (filters.some((elem: any) => elem === t('filters.autoTranslate')) && row.tStatusType === 'MT')
          //  Translated | Reviewed | Rejected | Approved | Draft
          || (filters.some((name: any) => name.toLowerCase() === row.state) && row.isLocked === false)
          //  Unconfirmed segemnt units | Draft or Untranslated
          || (filters.some((name: any) => name === t('filters.unconfirmed')) && (row.state === 'initial' || row.state === 'draft'))
          //  Segment units without any state
          || (filters.some((name: any) => name === t('filters.notTranslated')) && row.state === 'initial')
          //  Segment targets with revision marks
          || (filters.some((name: any) => name === t('common.trackChanges')) && (segmentHTML?.includes('<del>') || segmentHTML?.includes('<ins>')))
          //  Locked segment units 
          || (filters.some((elem: any) => elem === t('filters.locked')) && row.isLocked)
          //  Unlocked segment units 
          || (filters.some((elem: any) => elem === t('filters.unlocked')) && !row.isLocked)
          //  Error/Warning
          || (filters.some((elem: any) => elem === t('filters.errorWarning')) && appState.segments.find((el: any) => el.sSegmentId === row.id).verifyResult.length > 0)
        )
          && !idArray.find((item: any) => item === row.id)
        ) {
          idArray.push(row.id);
        }
      })
      dataArray.forEach((row: any) => {
        let editorHTML = document.getElementById("row" + row.id);
        if (editorHTML)
          if (idArray.some((elem: any) => elem === row.id))
            editorHTML.style.display = "table-row";
          else
            editorHTML.style.display = "none";
      });
    } else dataArray.forEach((row: any) => {
      let editorHTML = document.getElementById("row" + row.id);
      if (editorHTML) editorHTML.style.display = "table-row";
    });

    let isEmpty = filters.includes(t('filters.empty')) ? true : false;
    let targetSameAsSource = filters.includes(t('common.targetSame')) ? true : false;
    let isLocked = filters.includes(t('filters.locked')) ? true : false;
    let isUnLocked = filters.includes(t('filters.unlocked')) ? true : false;
    let isTranslated = filters.includes(t('common.translated')) ? true : false;
    let isReviewed = filters.includes(t('common.reviewed')) ? true : false;
    let isRejected = filters.includes(t('filters.rejected')) ? true : false;
    let isUnconfirmed = filters.includes(t('filters.unconfirmed')) ? true : false;
    let isNotTranslated = filters.includes(t('filters.notTranslated')) ? true : false;
    let isDraft = filters.includes(t('filters.draft')) ? true : false;
    let isApproved = filters.includes(t('filters.approved')) ? true : false;
    let isAT = filters.includes(t('filters.autoTranslate')) ? true : false;
    let isContainTags = filters.includes(t('filters.containsTags')) ? true : false;
    let isTrackChanges = filters.includes(t('common.trackChanges')) ? true : false;
    let isHundredMatches = filters.includes(t('filters.hundredMatches')) ? true : false;
    let fuzzyMatches = filters.includes(t('filters.fuzzyMatches')) ? true : false;
    let contextMatches = filters.includes(t('filters.contextMatches')) ? true : false;
    let errorWarning = filters.includes(t('filters.errorWarning')) ? true : false;

    // { name: 'filters.hundredMatches', type: '100%_MATCHES' },
    // { name: 'filters.fuzzyMatches', type: 'FUZZY_MATCHES' },
    // { name: 'filters.contextMatches', type: 'CONTEXT_MATCHES' },
    appDispatch({
      type: "SET_SEGMENT_FILTERS",
      payload: {
        isEmpty: isEmpty,
        targetSameAsSource: targetSameAsSource,
        isLocked: isLocked,
        isUnLocked: isUnLocked,
        isTranslated: isTranslated,
        isReviewed: isReviewed,
        isRejected: isRejected,
        isUnconfirmed: isUnconfirmed,
        isNotTranslated: isNotTranslated,
        isDraft: isDraft,
        isApproved: isApproved,
        isAT: isAT,
        isContainTags: isContainTags,
        isTrackChanges: isTrackChanges,
        hundredPercentMatch: isHundredMatches,
        fuzzyMatch: fuzzyMatches,
        contextMatch: contextMatches,
        isErrorWarning: errorWarning,
      }
    });

    if (appState.hitSearchFilters != 1) {
      appDispatch({
        type: "SEARCH_HIT_FILTERS",
        payload: 1
      });
    } else {
      appDispatch({
        type: "SEARCH_HIT_FILTERS",
        payload: 2
      });
    }
  }, [state]);

  const handleTooltip = (item: any) => {
    if (isHover && item.length === 1) return true;
  };
  const tooltipTitle = (itemType: any) => {
    let title;
    switch (itemType) {
      case "MERGE":
        title = "common.mergeSegments";
        break;
      case "SPLIT":
        title = "editorMenu.splitSegments";
        break;
      case "SHOW_CODE":
        title = "editorMenu.insertTagShort"
        break;
      case "UNDO":
        title = "editorMenu.undoLastAction";
        break;
      case "REDO":
        title = "editorMenu.redoLastAction";
        break;
      case "WHITE_SPACES":
        title = "editorMenu.showWhiteSpace";
        break;
      case "VERIFY":
        title = "common.verifyDoc";
        break;
      case "ACCEPT":
        title = "editorMenu.acceptAndMove";
        break;
      case "REJECT":
        title = "editorMenu.rejectAndMove";
        break;
      default:
        title = "";
    }
    return title;
  };

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    setMTAlert(false);
    setSplitAlert(false);
    setSplitLock(false);
  };

  const isTrackChangeDisabled = () => {
    const { selectedService } = appState;
    const { trackChanges } = actionState;

    if ((selectedService.isAssigned === 0 && selectedService.code === 'proofreading') || trackChanges === false) {
      return true;
    }

    return (selectedService.isAssigned === 1 && selectedService.code !== 'proofreading');
  };

  const determineActionIconClass = () => {
    const { trackChanges } = actionState;

    if (!trackChanges) {
      return classes.disabledActionIcon;
    } else {
      return classes.actionIcon;
    }
  };

  const checkInnerHtmlNotEmpty = () => {
    let isNotEmpty = true;
    appState.selectedSegments.forEach((seg: any) => {
      const element: any = document.getElementById("custom-editor" + seg.id);
      if (element && element.textContent.trim() !== '') {
        isNotEmpty = false;
      }
    })
    return isNotEmpty;
  }


  const isButtonDisabled = (type: any) => {
    const { selected } = appState;
    const isSelected = appState.selectedSegments.length === 0;
    // if ((disableBtn.includes(type) && isSelected) || (type === "CONFIRM" && checkInnerHtmlNotEmpty())) {
    if (((disableBtn.includes(type) && selected === 0)
      || (type === "CONFIRM" && isSelected)
      || (type === "COPY" && !appState.selectedSegments.some((item: any) => item.isLocked === false))
      || (type === "CLEAR" && !appState.selectedSegments.some((item: any) => item.isLocked === false))
      || (type === "COPY_ALL" && !appState.data.some((item: any) => item.isLocked === false))
      || (type === "CLEAR_ALL" && !appState.data.some((item: any) => item.isLocked === false)
      ))) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Card className={classes.actionWrap}>

        <CardContent>
          {actionButtons.map((item: IButton, index) => (
            <Box key={index}>
              <ButtonGroup disableElevation variant="outlined" color="default">
                <Button
                  // disabled={(item.type === 'ACCEPT' || item.type === 'REJECT') && !actionState.trackChanges ? true : false}
                  disabled={appState.selectedService.isAssigned == 0 || (item.type === "UNDO" && appState.undoHistory.length === 0)
                    || (item.type === "REDO" && appState.redoHistory.length === 0) ? true : false}
                  onMouseLeave={() => setIsHover(false)}
                  id={
                    item.type === "WHITE_SPACES"
                      ? "toggleSpace"
                      : "actionWraper-" + index.toString()
                  }
                  disableTouchRipple

                  className={`
                    ${actionButtons.length > 1
                      ? index === 0
                        ? classes.firstBtn
                        : classes.secondBtn
                      : classes.singleBtn
                    }
                                      ${item.type === "WHITE_SPACES" &&
                      actionState.showWhiteSpace
                      ? classes.activeBtn
                      : ""
                    }
                                      ${item.type === "SHOW_CODE" &&
                      appState.showCode
                      ? classes.activeBtn
                      : ""
                    }
                                      ${item.type === "TRACK_CHANGES" &&
                      actionState.trackChanges
                      ? classes.activeBtn
                      : ""
                    }
                                      ${item.type === "REPLACE" &&
                      appState.expandReplace
                      ? classes.activeBtn
                      : ""
                    }

                      `}
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    handleClick(e, index, item.type)
                  }
                >
                  <LightTooltip
                    title={t(tooltipTitle(item.type)) as any}
                    placement="bottom-start"
                    open={handleTooltip(item)}
                  >
                    <Box style={{
                      opacity: appState.selectedService.isAssigned == 0 || (item.type === "UNDO" && appState.undoHistory.length === 0)
                        || (item.type === "REDO" && appState.redoHistory.length === 0) ? 0.5 : 1
                    }} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      {item.icon}
                      <Typography variant="body2">
                        {" "}
                        {item.type === "ALL_SEGMENTS" ? state.length > 0 ? t(state[0].name) : t('filters.segmentFiltering') : t(item.name)}
                      </Typography>
                      {item.options && (
                        <ArrowDropDownIcon
                        // className={(item.type === "ACCEPT" || item.type === "REJECT") ? determineActionIconClass() : undefined}
                        />
                      )}
                    </Box>
                  </LightTooltip>
                </Button>
              </ButtonGroup>
              {
                // item.type === "WHITE_SPACES" && (
                //   <>
                //     {/* <Button id='toggleSpace'
                //                  // onClick={(e:React.MouseEvent<HTMLElement>) => toggleSpaceEvent(e,index,item.type)} // 
                //                   >toggleSpace</Button> */}
                //     <Button id="parseTagBtn">Pars Tag</Button>
                //     <Button id="convertTagBtn">Convert Tag</Button>
                //   </>
                // )
              }

              {
                item.type === "VERIFY" && (
                  <>
                    <IconButton
                      disabled={appState.selectedService.isAssigned == 0 ? true : false}

                      onClick={handleModalOpen}
                      size={"small"}
                      style={{ position: "absolute", bottom: "0", right: "0" }}
                    >
                      <LaunchIcon className={classes.iconBtn} />
                    </IconButton>
                  </>
                )
              }

              {anchorEl && item.options && (
                <Menu
                  id="UserMenu"
                  className={classes.headerMenu}
                  anchorEl={anchorEl[index]}
                  getContentAnchorEl={null}
                  keepMounted
                  // disableScrollLock={true}
                  open={Boolean(anchorEl[index])}
                  onClose={() => handleClose(index)}
                  TransitionComponent={Fade}
                  anchorOrigin={
                    item.type !== "ALL_SEGMENTS"
                      ? {
                        vertical: "bottom",
                        horizontal: "left",
                      }
                      : {
                        vertical: "bottom",
                        horizontal: "right",
                      }
                  }
                  transformOrigin={
                    item.type !== "ALL_SEGMENTS"
                      ? {
                        vertical: "top",
                        horizontal: "left",
                      }
                      : {
                        vertical: "top",
                        horizontal: "right",
                      }
                  }
                  PaperProps={
                    item.type !== "ALL_SEGMENTS"
                      ? {
                        style: {
                          minWidth: 200,
                        },
                      }
                      : {
                        style: {
                          minWidth: 200,
                          height: "305px",
                          overflowY: "scroll",
                          paddingLeft: "15px",
                        },
                      }
                  }
                >
                  {item.options.map((option: any) => (
                    <Box>
                      {item.type === "ALL_SEGMENTS" ? (
                        <Box display={"flex"} flexDirection={"row"}>
                          <FormControlLabel
                            className={classes.labelStyle}
                            control={
                              <Checkbox
                                checked={state.some((item) => {
                                  if (item.name === t(option.name)) return true;
                                })}
                                onChange={(e) => handleChange(e, t(option.name))}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                className={classes.checkboxColor}
                              />
                            }
                            label={
                              window?.navigator.userAgent.indexOf("Mac") !== -1
                                ? t(option.name).replace("Ctrl", "Cmd")
                                : t(option.name)
                            }
                          />
                        </Box>
                      ) : (
                        <MenuItem
                          onClick={() => handleMenuClick(index, option.type)}
                          disabled={isButtonDisabled(option.type)}
                        >
                          {window?.navigator.userAgent.indexOf("Mac") !== -1
                            ? t(option.name).replace("Ctrl", "Cmd")
                            : t(option.name)}
                        </MenuItem>
                      )}
                    </Box>
                  ))}
                </Menu>
              )}
            </Box>
          ))}

          <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="MuiModal-root"

          >
            <Box className={classes.parentModalStyles}>
              <Box borderBottom={'1px solid #dedede'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box className={classes.modalTitle} key={0}>
                  {t('verify.listOfChecks')}
                </Box>
                <IconButton className={classes.closeButton} onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box className={classes.modalBody} key={1}>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CheckBoxBasic actionState={actionState} appState={appState} appDispatch={appDispatch} />
                  <CheckBoxCommon actionState={actionState} appState={appState} appDispatch={appDispatch} projectId={headerData?.id} />
                  <CheckBoxAdvance headerData={headerData} actionState={actionState} appState={appState} appDispatch={appDispatch} />

                </Box>
              </Box>
              <Box className={classes.modalFooter} key={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalClose}
                >
                  {t('verify.close')}
                </Button>
              </Box>
            </Box>
          </Modal>
        </CardContent>
      </Card>

      {splitModalOpen &&
        <SplitSegment splitModalOpen={splitModalOpen} setSplitModalOpen={setSplitModalOpen} appState={appState} appDispatch={appDispatch} />
      }
      <Snackbar className={classes.snackBarStyles} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={snackBarOpen}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {!(dotCount() > 1) && appState.selected ? t('editorMenu.noSplit') : t('editorMenu.selectToSplit')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.mtEnableAlert')} anchorOrigin={{ vertical, horizontal }} open={mtAlert}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('editorMenu.mtEnableAlert')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.splitAlert')} anchorOrigin={{ vertical, horizontal }} open={splitAlert}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('editorMenu.splitAlert')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.splitLocked')} anchorOrigin={{ vertical, horizontal }} open={splitLock}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('editorMenu.splitLocked')}
        </Alert>
      </Snackbar>

      <CustomSnackbar
        error={gqlError}
        open={gqlErrorSnackBar}
        onClose={handleErrorSnackBarClose}
      />
    </>
  );
};

export default React.memo(ActionWraper);
