import { gql } from '@apollo/client';
const CONCORDANCE_SEARCH = gql`query ConcordanceSearch($sl: String!, $tl: String!, $searchTerm:String!,  $type:String!, $projectId:String!) {
    concordanceSearch(sl: $sl, tl: $tl, searchTerm: $searchTerm, type: $type, projectId: $projectId)
     {
        tmName
        sourceText
        translatedText
        from
        contributor
        createdAt
        lastModified
    }
  
  }`;

export default CONCORDANCE_SEARCH;