import React, { useEffect, useState } from "react";
import { IconButton, Typography } from "@material-ui/core";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Box,
    Tab,
    Tabs,
} from "@material-ui/core";
import { a11yProps } from "src/material-ui";
import * as Icons from "src/material-ui";
import HistoryTab from "./HistoryTab";
import VerifyTab from "./VerifyTab";
import SearchTab from "./SearchTab";
import useStyles from "./BottomBarStyles";
import QAReport from "./QAReport";
import { IAppState } from "src/interfaces";

import { useLazyQuery } from "@apollo/client";
import SEGMENT_HISTORY from "src/helpers/queries/GetSegmentHistory";
import { useTranslation } from "react-i18next";
import SearchIcon from '@material-ui/icons/Search';
import HistoryIcon from '@material-ui/icons/History';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import WarningIcon from '@material-ui/icons/Warning';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import Comments from "./Comments";

interface IProps {
    appState: IAppState;
    appDispatch: Function;
    expanded: any;
    setExpanded: any;
    headerData: any;
    verify: any;
    setcurretPage: Function;
    setTempPage: Function;
}

const BottomBar = ({ appState, appDispatch, expanded, setExpanded, headerData, verify, setcurretPage, setTempPage }: IProps) => {
    const classes = useStyles();
    const [value, setValue] = useState("search");
    const { t } = useTranslation();

    const [GetSegmentHistory, { data, loading: loadingHistoryTab, error }] = useLazyQuery(
        SEGMENT_HISTORY, { fetchPolicy: 'no-cache' }
    );

    useEffect(() => {
        let tl = appState.selectedLanguage;
        let sourceSegmentId = appState.selectedRow?.sSegmentId;

        if (sourceSegmentId && expanded && value === "history") {
            GetSegmentHistory({
                variables: {
                    sourceSegmentId: sourceSegmentId,
                    tl: tl
                }
            });
        }

        // if (sourceSegmentId && expanded === true)

    }, [expanded, appState.selectedRow, appState.selectedLanguage, appState.hitHistory, value]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        if (!expanded) {
            setExpanded(true);
        } else {
            event.stopPropagation();
        }

        appDispatch({ type: 'TOGGLE_CONCORDANCE', payload: newValue === 'search' });
        setValue(newValue);
    };

    useEffect(() => {
        if (appState.concordSearch.expanded) {
            setValue("search");
            setExpanded(true);
        }
    }, [appState.concordSearch.text, appState.concordSearch.expanded]);

    if (error) {
        return <div>Error in History {error?.message}</div>
    }

    const getTabStyle = (tabValue: any) => {
        return {
            color: value === tabValue ? '#F77D3D' : '#00000099',
        };
    };

    const getTabLabelStyle = (tabValue: any) => {
        return {
            fontSize: '14px',
            fontFamily: 'Roboto Medium',
            marginLeft: '4px',
            color: value === tabValue ? '#F77D3D' : '#00000099',
        };
    };

    return (
        <Box className={classes.footerWrap}>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                className={classes.btmBarStyles}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordionSummary}
                >
                    <IconButton>
                        {expanded ? (
                            <Icons.ExpandMoreIcon className={classes.expandIcon} />
                        ) : (
                            <Icons.ExpandLessIcon className={classes.expandIcon} />
                        )}
                    </IconButton>
                    <AppBar position="static" color="default" style={{ backgroundColor: 'transparent' }}>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={value}
                            onChange={handleChange}
                        // TabIndicatorProps={{
                        //     style: { backgroundColor: 'orange' }
                        // }}
                        >
                            <Tab
                                value="search"
                                label={
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={getTabStyle('search')}>
                                        <SearchIcon fontSize="small" style={{ color: getTabStyle('search').color }} />
                                        <span style={getTabLabelStyle('search')}>
                                            {t('concordanceSearch.title')}
                                        </span>
                                    </Box>
                                }
                                {...a11yProps("search")}
                            />
                            <Tab
                                value="history"
                                label={
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={getTabStyle('history')}>
                                        <HistoryIcon fontSize="small" style={{ color: getTabStyle('history').color }} />
                                        <span style={getTabLabelStyle('history')}>
                                            {t('history.title')}
                                        </span>
                                    </Box>
                                }
                                wrapped
                                {...a11yProps("history")}
                            />
                            <Tab
                                value="verify"
                                label={
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={getTabStyle('verify')}>
                                        <WarningIcon fontSize="small" style={{ color: getTabStyle('verify').color }} />
                                        <span style={getTabLabelStyle('verify')}>
                                            {t('verifyResult.title')}
                                        </span>
                                    </Box>
                                }
                                {...a11yProps("verify")}
                            />
                            {appState.selectedService.code == "proofreading" && (
                                <Tab
                                    value="qaReport"
                                    label={
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={getTabStyle('qaReport')}>
                                            <PlaylistAddCheckOutlinedIcon fontSize="small" style={{ color: getTabStyle('qaReport').color }} />
                                            <span style={getTabLabelStyle('qaReport')}>
                                                {t('qa.title')}
                                            </span>
                                        </Box>
                                    }
                                    {...a11yProps("qa_report")}
                                />
                            )}

                            {/* make it conditional for projects with comments only */}
                            <Tab
                                value="comments"
                                label={
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={getTabStyle('comments')}>
                                        <ChatBubbleIcon fontSize="small" style={{ color: getTabStyle('comments').color }} />
                                        <span style={getTabLabelStyle('comments')}>
                                            {t('comments.title')}
                                        </span>
                                    </Box>
                                }
                                {...a11yProps("comments")}
                            />
                        </Tabs>
                    </AppBar>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Box width="100%" height={250} overflow="auto" pt={0}>
                        <SearchTab
                            value={value}
                            appState={appState}
                            appDispatch={appDispatch}
                            headerData={headerData}
                        />
                        <HistoryTab
                            value={value}
                            data={data}
                            source={appState?.selectedRow?.source}
                            loadingHistoryTab={loadingHistoryTab}
                        />
                        <VerifyTab
                            headerData={headerData}
                            appState={appState}
                            verify={verify}
                            value={value}
                            appDispatch={appDispatch}
                            expanded={expanded}
                            setcurretPage={setcurretPage}
                            setTempPage={setTempPage}
                        />
                        <QAReport
                            value={value}
                            appState={appState}
                            appDispatch={appDispatch}
                            headerData={headerData}
                            expanded={expanded}
                        />
                        <Comments
                            value={value}
                            appState={appState}
                            appDispatch={appDispatch}
                            headerData={headerData}
                            expanded={expanded}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(BottomBar);


