import { gql } from '@apollo/client';

const ANSWER_QUERY_LOG = gql`mutation AnswerQueryLog($data: AnswerQueryLogRequest){
    answerQueryLog(data: $data){
      status
      error
  }
 
  }`;

export default ANSWER_QUERY_LOG;