import { gql } from '@apollo/client';
const GET_SEGMENT_REPORTS = gql`query GetSegmentReports($sourceSegmentId: String!, $tl:String!) {
    getSegmentReports(sourceSegmentId: $sourceSegmentId, tl: $tl)
     {
        id
        translation
        suggestedRevision
        errorCategory
        errorSeverity
    }
  
  }`;


export default GET_SEGMENT_REPORTS;