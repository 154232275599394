import { gql } from '@apollo/client';
const SEGMENT_TRANSLATIONS = gql`query GetSegmentTranslations($segmentId:Int! , $tl:String!, $sl:String, $projectId: String, $engines: [Engine], $isTrackOn: Boolean) {
    getSegmentTranslations(segmentId: $segmentId, tl: $tl, sl: $sl, projectId: $projectId ,engines: $engines, isTrackOn: $isTrackOn) {
    machineTranslation{
        sourceText
        translatedText
        matchRate
        engine
    }
    tMTranslation{
            tmName
            sourceText
            translatedText
            matchRate
       
            from
            contributor
            lastModified
        
    }
    tBTranslation{
            tbId
            tbName
            sourceText
            translatedText
            matchRate
            from
            comment
            contributor
            lastModified
            isOwner
    }
  }
  }`;



export default SEGMENT_TRANSLATIONS;

