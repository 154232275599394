import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import TranslationTable from "./TranslationTable";
import SegmentDataBox from "./SegmentDataBox";
import { IAppState } from "src/interfaces";
import BottomBar from "src/components/bottom-bar/BottomBar";
import AppReducer, { initialState } from "../../AppReducer";
import useStyles from "../../pages/home/HomeStyles";

interface IProps {
	appDispatch: Function,
	actionDispatch: Function,
	appState: IAppState,
	toggle: any,
	setToggle: Function,
	totalPages: any,
	currentPage: any,
	setcurretPage: any,
	tempPage: any,
	setTempPage: any,
	handlePage: any,
	tbRowData: any,
	setTbRowData: any,
	scrollValue: any,
	headerData: any,
	rowsPerPage: any,
	setRowsPerPage: any,
	projectId: any,
	expanded: any,
	setExpanded: any,
	actionState: any,
	verify: any,
	fromEditorMenu: any,
	setFromEditorMenu: any,
	notConfirmed: any,
	setNotConfirmed: Function
}

const HomeLayout = ({ headerData, appDispatch, actionDispatch, appState, toggle, setToggle, totalPages,
	currentPage, handlePage, tbRowData, setTbRowData, scrollValue, tempPage, setTempPage, setcurretPage, rowsPerPage,
	setRowsPerPage, projectId, expanded, setExpanded, actionState, verify, fromEditorMenu, setFromEditorMenu, notConfirmed, setNotConfirmed }: IProps) => {

	useEffect(() => {
		setTimeout(() => {
			appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
		}, 500);

		appDispatch({ type: "QUERY_EXECUTE_FILTERS", payload: true });
		appDispatch({ type: "UNSET_SELECTED", })
		setcurretPage(tempPage);

	}, [tempPage]);

	const classes = useStyles({});

	// const [expanded, setExpanded] = useState(false);

	return (
		<Box px={3}>
			<Grid container spacing={4}>
				<Grid item xs={8} style={{ padding: '16px 16px 0 16px', marginBottom: expanded ? '0' : '0' }}>

					{/*QE: {appState.searchFilters?.queryExecute?'true':'false'}*/}

					<TranslationTable
						appState={appState}
						appDispatch={appDispatch}
						actionDispatch={actionDispatch}
						toggle={toggle}
						setToggle={setToggle}
						totalPages={totalPages}
						currentPage={currentPage}
						handlePage={handlePage}
						setTbRowData={setTbRowData}
						headerData={headerData}
						setcurretPage={setcurretPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						projectId={projectId}
						setTempPage={setTempPage}
						actionState={actionState}
						fromEditorMenu={fromEditorMenu}
						setFromEditorMenu={setFromEditorMenu}
						notConfirmed={notConfirmed}
						setNotConfirmed={setNotConfirmed}
					/>

				</Grid>
				<Grid item xs={4} style={{ padding: '16px 16px 0 16px' }}>
					<SegmentDataBox
						selectedRow={appState.selectedRow}
						appDispatch={appDispatch}
						appState={appState}
						toggle={toggle}
						setToggle={setToggle}
						tbRowData={tbRowData}
						headerData={headerData}
						scrollValue={scrollValue}
						expand={expanded}
						actionState={actionState}
					/>
					{/* <CommentBox /> */}
					{/* <StyleGuideBox /> */}
					{/* <QAReport /> */}
				</Grid>
				<Grid item xs={8} className={classes.bottomGrid} style={{
					bottom: 0,
					position: 'fixed',
					left: 0,
					right: 0,
				}}>
					{appState.selectedService.isAssigned === 1 ?
						<BottomBar
							appState={appState}
							appDispatch={appDispatch}
							expanded={expanded}
							setExpanded={setExpanded}
							headerData={headerData}
							verify={verify}
							setcurretPage={setcurretPage}
							setTempPage={setTempPage}
						/>
						: ''}
				</Grid>

			</Grid>
		</Box>
	);
}

export default React.memo(HomeLayout);