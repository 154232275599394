import { trackChanges } from "src/helpers";
import { ISegment } from "src/interfaces";

export interface IState {
    trackChanges: boolean;
    replace: boolean;
    basic: any;
    common: any;
    advance: any;
    excludeSegments: any;
    showWhiteSpace: any;
}
interface IAction {
    type: string,
    payload?: any,
}

export const intialState: IState = {
    trackChanges: false,
    replace: false,
    showWhiteSpace: false,
    basic: [
        {
            title: "Inconsistency in Target",
            value: "inconsistency_target",
            checked: true,
            children: [
                {
                    title: "Ignore case",
                    value: "ignore_case_t",
                    checked: true,
                },
                {
                    title: "Ignore tags",
                    value: "ignore_tags_t",
                    checked: true,
                },
                {
                    title: "Ignore end punctuation",
                    value: "ignore_punctuation_t",
                    checked: false,
                },
            ],
        },
        {
            title: "Inconsistency in Source",
            value: "inconsistency_source",
            checked: true,
            children: [
                {
                    title: "Ignore case",
                    value: "ignore_case",
                    checked: true,
                },
                {
                    title: "Ignore tags",
                    value: "ignore_tags",
                    checked: true,
                },
                {
                    title: "Ignore end punctuation",
                    value: "ignore_punctuation",
                    checked: false,
                },
            ],
        },
        {
            title: "Empty Translation",
            value: "empty_translation",
            checked: true,
        },
        {
            title: "Target Same as Source",
            value: "target_same_source",
            checked: true,
        },
    ],
    common: [
        {
            title: "Punctuation Mismatch",
            value: "punctuation_mismatch",
            checked: true,
            children: [
                {
                    title: "Check incorrect punctuation",
                    value: "incorrect_punctuation",
                    checked: false,
                },
                {
                    title: "Check multiple punctuation",
                    value: "multiple_punctuation",
                    checked: true,
                },
                {
                    title: "Check extra spaces before punctuation (, . ! ? ; :)",
                    value: "extra_spaces_punctuation",
                    checked: true,
                },
                {
                    title: "Check missing space after punctuation (, . ! ? ; :)",
                    value: "missing_spaces_punctuation",
                    checked: true,
                },
                {
                    title: "Check trailing space",
                    value: "trailing_space",
                    checked: true,
                },
                {
                    title: "Check bracket mismatch",
                    value: "bracket_mismatch",
                    checked: true,
                },
                {
                    title: "Check punctuation mismatch (Source -> Target)",
                    value: "source_target_mismatch",
                    checked: true,
                },
            ],
        },
        {
            title: "Tag Mismatch",
            value: "tag_mismatch",
            checked: true,
        },
        {
            title: "URL Mismatch",
            value: "url_missmatch",
            checked: true,
        },
        {
            title: "Camel Case Mismatch",
            value: "camel_missmatch",
            checked: true,
        },
        {
            title: "All Uppercase Mismatch",
            value: "uppercase_missmatch",
            checked: false,
        },
        {
            title: "Alphanumeric Mismatch",
            value: "alphanumeric_missmatch",
            checked: true,
        },
        {
            title: "Numeric Mismatch",
            value: "numeric_missmatch",
            checked: true,
        },
        {
            title: "Variable/Placeholder Mismatch",
            value: "variable_missmatch",
            checked: true,
            inputValue: "\\{\\d+}",
        },
        {
            title: "Key Term Mismatch",
            value: "keyterm_missmatch",
            checked: false,
            children: [],
        },
        {
            title: "Spaces Check",
            value: "spaces_check",
            checked: true,
            children: [
                {
                    title: "Check multiple spaces",
                    value: "multiple_spaces",
                    checked: true,
                },
                {
                    title: "Check zero width spaces (Non-printing characters)",
                    value: "zero_spaces",
                    checked: true,
                },
            ],
        },
        {
            title: "Camel Case Mismatch",
            value: "repeated_words",
            checked: true,
        },
        {
            title: "Camel Case Mismatch",
            value: "forbidden_words",
            checked: false,
            inputValue: "",
        },
        {
            title: "Camel Case Mismatch",
            value: "not_confirmed",
            checked: true,
        },
        {
            title: "Camel Case Mismatch",
            value: "fuzzy_match",
            checked: true,
        },
    ],
    advance: [
        {
            title: "Spell Check",
            value: "spell_check",
            checked: false,
        },
        {
            title: "Machine Translation Check",
            value: "translation",
            checked: false,
            children: [
                {
                    title: "Engine",
                    value: "engine",
                    inputValue: "Google",
                },
                {
                    title: "Similarity threshold (%, 50% ~ 100%)",
                    value: "similarity",
                    inputValue: "90",
                },
                {
                    title: "Sample percentage (%, 1% ~ 100%)",
                    value: "percentage",
                    inputValue: "10",
                },
                {
                    title: "Show diff",
                    value: "showDiff",
                    checked: false,
                }
            ],
        },
    ],
    excludeSegments: [
        {
            title: "Locked",
            value: "locked",
            checked: true,
        },
        {
            title: "100% Match",
            value: "100_matched",
            checked: true,
        },
        {
            title: "Context Matches",
            value: "context_match",
            checked: true,
        },
        {
            title: "Reviewed",
            value: "reviewed",
            checked: false,
        },
    ],
};

// const executeCommand = (commandId: string, state: IState) => {
//     if (document) {
//         const selection: any = document.getSelection();
//         if (selection.anchorNode) {
//             const range = selection?.getRangeAt(0);
//             if (range) {
//                 const insElement = document.createElement('ins');
//                 insElement.appendChild(range.cloneContents());
//                 if (state.trackChanges) {
//                     range.deleteContents();
//                     range.insertNode(insElement);
//                 }
//                 document.execCommand(commandId);
//             }
//         }
//     }

//     return { ...state };
// };

const executeCommand = (commandId: string, state: IState) => {
    if (document) {
        const selection: any = document.getSelection();
        if (selection.anchorNode) {
            document.execCommand(commandId);
            const range = selection?.getRangeAt(0);
            if (range) {
                if (state.trackChanges) {
                    const insElement = document.createElement('ins');
                    insElement.appendChild(range.cloneContents());
                    range.deleteContents();
                    range.insertNode(insElement);
                }
            }
        }
    }

    return { ...state };
};

const toggleTrackChanges = (state: IState, action: IAction) => {
    let track = !state.trackChanges;
    if (action.payload) {
        if (track) {
            action.payload.forEach((el: ISegment) => {
                let row = document.getElementById('custom-editor' + el.id);
                row?.addEventListener('beforeinput', trackChanges);
            });
        } else {
            action.payload.forEach((el: ISegment) => {
                let row = document.getElementById('custom-editor' + el.id);
                row?.removeEventListener('beforeinput', trackChanges);
            });
        }
    }
    return track;
};

const reinitializeTrackChanges = (state: IState, action: IAction) => {
    let track = state.trackChanges;
    if (action.payload) {
        if (track) {

            action.payload.forEach((el: any) => {
                let row = document.getElementById('custom-editor' + el.id);
                row?.addEventListener('beforeinput', trackChanges);
            });

        }
    }
    return track;
};

const toggleReplace = (state: IState, action: IAction) => {
    let replace = !state.replace;
    state.replace = action?.payload;
    return replace;
};

const insTagAction = (parent: any, act: IAction) => {
    let rowIns: any = parent.querySelectorAll('ins,u');
    let { type } = act;
    if (rowIns) {
        let insArr = [...rowIns];
        insArr.forEach(item => {
            if (type === "ACCEPT_CHANGES" || type === "ACCEPT_ALL_CHANGES") {
                item.outerHTML = item.innerHTML;
            } else {
                item.remove()
            }
        });
    }
}

const acceptReject = (state: IState, action: IAction) => {
    let row = document.querySelectorAll<HTMLElement>('.MuiTableRow-root.active td:nth-child(4)')[0];
    insTagAction(row, action)
    return state;
}

const acceptRejectAll = (state: IState, action: IAction) => {
    const { payload } = action;
    if (payload) {
        const { data } = payload;
        data.forEach((el: ISegment) => {
            let row = document.getElementById('custom-editor' + el.id);
            insTagAction(row, action)
        });
    }
    return state;
}

const whiteSpaces = (state: IState, action: IAction) => {
    let toggle = !state.showWhiteSpace;
    const data = action.payload;
    if (data) {
        if (toggle) {
            data.forEach((el: ISegment) => {
                let targetDoc: any = document.getElementById('custom-editor' + el.id);
                let sourceDoc: any = document.getElementById('source-td' + el.id);

                if (sourceDoc) {
                    var nestedDiv = sourceDoc.querySelector('div');
                    if (nestedDiv) {
                        nestedDiv.innerHTML = nestedDiv?.innerHTML?.replace(/\ /g, "•").replace(/\&nbsp;/g, "•");
                    }
                }

                if (targetDoc || sourceDoc) {
                    targetDoc.innerHTML = targetDoc?.innerHTML?.replace(/\ /g, "•").replace(/\&nbsp;/g, "•");
                    if (targetDoc.innerHTML == '•') {
                        targetDoc.innerHTML = '';
                    }
                }
            });
        } else {

            data.forEach((el: ISegment) => {
                let targetDoc: any = document.getElementById('custom-editor' + el.id);
                let sourceDoc: any = document.getElementById('source-td' + el.id);
                if (targetDoc || sourceDoc) {
                    targetDoc.innerHTML = targetDoc.innerHTML.replace(/•/g, " ");

                    if (sourceDoc) {
                        var nestedDiv = sourceDoc.querySelector('div');
                        if (nestedDiv) {
                            nestedDiv.innerHTML = nestedDiv?.innerHTML?.replace(/•/g, " ");
                        }
                    }
                    // sourceDoc.innerHTML = sourceDoc.innerHTML.replace(/•/g, " ");
                }
            });

        }
    }
    return toggle;
}

const ActionReducer = (state: IState, action: IAction) => {
    switch (action.type) {
        case 'TRACK_CHANGES':
            return {
                ...state,
                trackChanges: toggleTrackChanges(state, action)
            };
        case 'REINITIALIZE_TRACK_CHANGES':
            return {
                ...state,
                trackChanges: reinitializeTrackChanges(state, action)
            };
        case 'ACCEPT_CHANGES':
            return acceptReject(state, action);
        case 'ACCEPT_ALL_CHANGES':
            return acceptRejectAll(state, action);
        case 'REJECT_CHANGES':
            return acceptReject(state, action)
        case 'REJECT_ALL_CHANGES':
            return acceptRejectAll(state, action)
        case 'BOLD':
            return executeCommand('bold', state)
        case 'ITALIC':
            return executeCommand('italic', state)
        case 'STRIKE_THROUGH':
            return executeCommand('strikethrough', state)
        case 'UNDERLINE':
            return executeCommand('underline', state)
        case 'SUPERSCRIPT':
            return executeCommand('superscript', state)
        case 'SUBSCRIPT':
            return executeCommand('subscript', state)
        case 'REPLACE':
            return {
                ...state,
                replace: toggleReplace(state, action)
            };
        case 'WHITE_SPACES':
            return {
                ...state,
                showWhiteSpace: !state.showWhiteSpace
            };
        default:
            return state;
    }
}

export default ActionReducer;