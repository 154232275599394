import React, { useState, useCallback, useEffect } from "react";
import {
    Box,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    Modal,
    TextField,
    InputLabel,
    TextareaAutosize,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Link,
    Typography,
    Snackbar,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./CheckBoxStyles";
import { IState } from "./ActionReducer";
import {
    TableCell,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IAppState } from "../../interfaces/types/SegmentType";
import IMPORT_KEY_TERM_MISMATCH from "src/helpers/mutations/importKeyTermMismatch";
import { useMutation } from "@apollo/client";
import Dropzone from "react-dropzone-uploader";
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import GET_CURRENT_USER_DETAILS from "src/helpers/queries/GetCurrentUserDetails";
import { useLazyQuery } from '@apollo/client';
import { Alert } from "@material-ui/lab";
import PROJECT_DETAIL from "src/helpers/queries/ProjectDetailsById";
import { handleErrors } from "src/helpers/errorHandler";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";

interface IProps {
    actionState: IState;
    appState: IAppState;
    appDispatch: any;
    projectId: any;
}
export default function CheckBoxCommon({ actionState, appState, appDispatch, projectId }: IProps) {
    const classes = useStyles();
    const [checked, setChecked]: any = useState([]);
    const [punctuationChild, setPunctuationChild]: any = useState([]);
    const [spacesChild, setSpacesChild]: any = useState([]);
    const [forbidden, setForbidden]: any = useState([]);
    const [variable, setVariable]: any = useState([]);
    const [isAllChecked, setIsAllChecked]: any = useState(0);
    // setExpnad Identifies which settings option to open
    const [expand, setExpand] = useState("");
    const [open, setOpen] = useState(false);
    const [openRow, setOpenRow] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [disable, setDisable] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [rows, setRows]: any = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageRows, setCurrentPageRows] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const [importLoader, setImportLoader]: any = useState(false);

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarState, setSnackBarState]: any = useState({
        vertical: 'bottom',
        horizontal: 'right',
    });

    const { vertical, horizontal }: any = snackBarState;

    const getQueryParam = (parameterName: any) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(parameterName);
    };

    let isRO = getQueryParam('ro');
    let fileId = getQueryParam('fileId');
    let tl = getQueryParam('tl');
    let type = getQueryParam('type');

    const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const [importKeyTermMismatch, { loading: loadingImportKeyTermMismatchg }] = useMutation(IMPORT_KEY_TERM_MISMATCH, {
        errorPolicy: 'all',
        variables: {
            data: {
                fileUrl: "",
            }
        },
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    useEffect(() => {
        let temp: any = [];
        let punctuation: any = [];
        let space: any = [];
        let keyterm: any = [];
        let forbid = "";
        let vMissmatch = "";
        let commonArray: any = actionState.common;
        let commonFound: any = appState?.projectSettings;

        if (commonFound) {
            let common: any = JSON.parse(commonFound);
            common = common.find((itm: any) => itm.value == 'common');

            commonArray = common ? common.children : [];
            actionState.common = commonArray;
        }

        commonArray.forEach((parent: any) => {
            if (parent.checked === true) {
                temp.push(parent.value);
                if (parent.value === "punctuation_mismatch") {
                    parent.children.forEach((child: any) => {
                        if (child.checked === true) {
                            punctuation.push(child.value);
                        }
                    });
                } else if (parent.value === "spaces_check") {
                    parent.children.forEach((child: any) => {
                        if (child.checked === true) {
                            space.push(child.value);
                        }
                    });
                }
            }
            if (parent.value === "keyterm_missmatch") {
                parent?.children?.forEach((row: any) => {
                    keyterm.push(row);
                });
            }
            else if (parent.value === "variable_missmatch") {
                vMissmatch = parent.inputValue;
            } else if (parent.value === "forbidden_words") {
                forbid = parent.inputValue;
            }
        });

        setChecked(temp);
        setPunctuationChild(punctuation);
        setSpacesChild(space);
        setForbidden(forbid);
        setRows(keyterm);
        setVariable(vMissmatch);
    }, []);

    useEffect(() => {
        if (checked.length < 1) setIsAllChecked(0);
        else setIsAllChecked(1);

        setCurrentPage(1);
        setTotalPages(Math.ceil(rows?.length / 20));

    }, [checked, punctuationChild, variable, forbidden, rows]);

    useEffect(() => {
        // rows.filter
        const start_index = 0;
        const end_index = 20;

        const page_rows: any = rows?.slice(start_index, end_index);
        setCurrentPageRows(page_rows);
    }, [rows]);

    const handleNo = () => {
        setShowConfirm(false);
    };

    const handleChild = (
        event: React.ChangeEvent<HTMLInputElement>,
        name: any
    ) => {
        if (event.target.checked) {
            actionState.common.forEach((parent: any) => {
                if (parent.children)
                    parent.children.forEach((child: any) => {
                        if (child.value === name) {
                            if (parent.checked === false) {
                                parent.checked = true;
                                setChecked([...checked, parent.value]);
                            }
                            child.checked = true;
                            if (parent.value === "spaces_check")
                                setSpacesChild([...spacesChild, child.value]);
                            else setPunctuationChild([...punctuationChild, child.value]);
                        }
                    });
            });
        } else {
            actionState.common.forEach((parent: any) => {
                if (parent.children)
                    parent.children.forEach((child: any) => {
                        if (child.value === name) {
                            child.checked = false;
                            if (parent.value === "spaces_check") {
                                setSpacesChild(
                                    spacesChild.filter((item: any) => item !== name)
                                );
                            } else
                                setPunctuationChild(
                                    punctuationChild.filter((item: any) => item !== name)
                                );
                        }
                    });
            });
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged == false ? true : false });
    };

    const handleRemoveClick = (id: any) => {
        const list: any = [...rows];
        const updatedList = list.filter((row: any, i: any) => i !== id);
        const currentKeyTerms = actionState.common[8].children;
        const updatedKeyTerms = currentKeyTerms.filter((row: any, i: any) => i !== id);
        actionState.common[8].children = updatedKeyTerms;
        setRows(updatedList);
        setShowConfirm(false);

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleClearAll = () => {
        let i: any;
        const list = [...rows];
        list.splice(i);
        actionState.common[8].children = [];
        setRows(list);
        setShowConfirm(false);

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleConfirm = () => {
        setShowConfirm(true);
        setClearAll(false);
    };

    const handleConfirmAll = () => {
        setShowConfirm(true);
        setClearAll(true);
    };

    const handleInputChange = (e: any, index: any) => {
        setDisable(false);
        const { name, value }: any = e.target;
        const list: any = [...rows];
        list[index][name] = value;
        setRows(list);
    };

    const handleSave = () => {
        const result = rows.filter((row: any) => row.title.trim() !== "" || row.value.trim() !== "");

        let filters = [];
        for (let k = 0; k < result.length; k++) {
            filters.push({ title: result[k].title, value: result[k].value });
        }

        actionState.common[8].children = filters;
        setRows(result);
        setEdit(!isEdit);
        setDisable(true);
        setOpenRow(true);

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleAdd = () => {
        let uid = rows && rows.length > 0 ? rows[rows.length - 1].id + 1 : 1;
        setRows((prevRows: Array<{ id: number, title: string, value: string }>): any => [
            ...prevRows,
            { id: uid, title: "", value: "" }
        ]);
        setEdit(true);
        setClearAll(false);
        setShowConfirm(false);
    };
    const handleOpen = (name: any) => {
        setExpand(name);
        setOpen(true);
    };

    const handleClose = () => {
        if (expand === "VO" || expand === "FO") {
            let actionStateVal = 1;

            if (appState.isActionStateChanged == 0) {
                actionStateVal = 1;
            } else if (appState.isActionStateChanged == 1) {
                actionStateVal = 2;
            } else if (appState.isActionStateChanged == 2) {
                actionStateVal = 1;
            }
            appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
        } else {
            const result = actionState.common[8].children;
            setRows(result);
        }


        setOpen(false);
    };
    const handleVariable = (event: any) => {
        actionState.common[7].inputValue = event.target.value;
        setVariable(event.target.value);
    };
    const handleForbidden = (event: any) => {
        actionState.common[11].inputValue = event.target.value;
        setForbidden(event.target.value);
    };
    const handleChangeAll = (event: any) => {
        let temp = checked;
        let punctuation = punctuationChild;
        let space = spacesChild;
        let forbid: any;
        let vMissmatch: any;
        if (event.target.checked) {
            actionState.common.forEach((parent: any) => {
                parent.checked = true;
                temp.push(parent.value);
                if (parent.value === "forbidden_words") forbid = parent.inputValue;
                if (parent.value === "variable_missmatch") vMissmatch = parent.inputValue;

                if (parent.value === "punctuation_mismatch") {
                    parent.children.forEach((child: any) => {
                        child.checked = true;
                        punctuation.push(child.value);
                    });
                } else if (parent.value === "spaces_check") {
                    parent.children.forEach((child: any) => {
                        child.checked = true;
                        space.push(child.value);
                    });
                }
            });
            setChecked(temp);
            setPunctuationChild(punctuation);
            setSpacesChild(space);
            setForbidden(forbid);
            setVariable(vMissmatch);
            setIsAllChecked(1);
        } else {
            actionState.common.forEach((parent: any) => {
                parent.checked = false;
                if (parent.children) {
                    parent.children.forEach((child: any) => (child.checked = false));
                }
            });
            setChecked([]);
            setPunctuationChild([]);
            setSpacesChild([]);
            setForbidden(forbid);
            setVariable(vMissmatch);
            setIsAllChecked(0);
        }

        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
    };

    const handleCheck = (event: any, name: any) => {
        let punctuation = punctuationChild;
        let space = spacesChild;
        let forbid: any;
        let vMissmatch: any;
        if (event.target.checked) {
            actionState.common.forEach((parent: any) => {
                if (parent.value === name) {
                    parent.checked = true;
                    if (parent.value === "forbidden_words") forbid = parent.inputValue;
                    else if (parent.value === "variable_missmatch")
                        vMissmatch = parent.inputValue;
                    else if (parent.value === "punctuation_mismatch") {
                        parent.children.forEach((child: any) => {
                            child.checked = true;
                            punctuation.push(child.value);
                        });
                    } else if (parent.value === "spaces_check") {
                        parent.children.forEach((child: any) => {
                            child.checked = true;
                            space.push(child.value);
                        });
                    }
                }
            });
            setChecked([...checked, name]);
            setPunctuationChild(punctuation);
            setSpacesChild(space);
            setForbidden(forbid);
            setVariable(vMissmatch);
            setIsAllChecked(1);
        } else {
            actionState.common.forEach((parent: any) => {
                if (parent.value === name) {
                    parent.checked = false;
                    if (name === "punctuation_mismatch") {
                        parent.checked = false;
                        parent.children.forEach((child: any) => {
                            child.checked = false;
                        });
                        setPunctuationChild([]);
                    } else if (name === "spaces_check") {
                        parent.checked = false;
                        parent.children.forEach((child: any) => {
                            child.checked = false;
                        });
                        setSpacesChild([]);
                    }
                }
            });
            setChecked(checked.filter((checkedItem: any) => checkedItem !== name));
        }


        let actionStateVal = 1;

        if (appState.isActionStateChanged == 0) {
            actionStateVal = 1;
        } else if (appState.isActionStateChanged == 1) {
            actionStateVal = 2;
        } else if (appState.isActionStateChanged == 2) {
            actionStateVal = 1;
        }

        // console.log('actionStateVal',actionStateVal);

        appDispatch({ type: 'SET_ACTION_STATE_CHANGED', payload: actionStateVal });
        // appDispatch({type: 'SET_ACTION_STATE_CHANGED', payload: appState.isActionStateChanged==false?true:false});
    };
    const { t } = useTranslation();

    const common = (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            {t('verify.punctuationMis')}
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("punctuation_mismatch") ? true : false}
                            onChange={(e) => handleCheck(e, "punctuation_mismatch")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={() => handleOpen("PO")}
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>{t('verify.tagMis')}</span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("tag_mismatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "tag_mismatch")}
                    />
                }
            />
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>{t('verify.urlMis')}</span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("url_missmatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "url_missmatch")}
                    />
                }
            />
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>{t('verify.camelCaseMis')}</span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("camel_missmatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "camel_missmatch")}
                    />
                }
            />
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>{t('verify.allUppercase')}</span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("uppercase_missmatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "uppercase_missmatch")}
                    />
                }
            />
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>{t('verify.alphanumericMis')}</span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("alphanumeric_missmatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "alphanumeric_missmatch")}
                    />
                }
            />
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>{t('verify.numericMis')}</span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("numeric_missmatch") ? true : false}
                        onChange={(e: any) => handleCheck(e, "numeric_missmatch")}
                    />
                }
            />
            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            {t('verify.variableMis')}
                        </span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("variable_missmatch") ? true : false}
                            onChange={(e: any) => handleCheck(e, "variable_missmatch")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={() => handleOpen("VO")}
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
            <Box display={"flex"}>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>{t('verify.keytermMis')}</span>
                    }
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("keyterm_missmatch") ? true : false}
                            onChange={(e: any) => handleCheck(e, "keyterm_missmatch")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={() => handleOpen("GO")}
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
            <Box display={"flex"}>
                <FormControlLabel
                    label={<span className={classes.checkboxLabel}>{t('verify.spacesCheck')}</span>}
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("spaces_check") ? true : false}
                            onChange={(e: any) => handleCheck(e, "spaces_check")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={() => handleOpen("SO")}
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
            <FormControlLabel
                label={<span className={classes.checkboxLabel}>{t('verify.repeatedWords')}</span>}
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("repeated_words") ? true : false}
                        onChange={(e: any) => handleCheck(e, "repeated_words")}
                    />
                }
            />
            <Box display={"flex"}>
                <FormControlLabel
                    label={<span className={classes.checkboxLabel}>{t('verify.forbiddenWords')}</span>}
                    control={
                        <Checkbox
                            className={classes.checkboxColor}
                            checked={checked.includes("forbidden_words") ? true : false}
                            onChange={(e: any) => handleCheck(e, "forbidden_words")}
                        />
                    }
                />
                <IconButton
                    className={classes.iconStyles}
                    onClick={() => handleOpen("FO")}
                >
                    <SettingsIcon />
                </IconButton>
            </Box>
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>
                        {t('verify.translationNotConf')}
                    </span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("not_confirmed") ? true : false}
                        onChange={(e: any) => handleCheck(e, "not_confirmed")}
                    />
                }
            />
            <FormControlLabel
                label={
                    <span className={classes.checkboxLabel}>{t('verify.unedittedFuzzy')}</span>
                }
                control={
                    <Checkbox
                        className={classes.checkboxColor}
                        checked={checked.includes("fuzzy_match") ? true : false}
                        onChange={(e: any) => handleCheck(e, "fuzzy_match")}
                    />
                }
            />
        </Box>
    );

    function getFileExtension(fileName: any) {
        return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    }

    const handleImport = (values: any) => {
        setImportLoader(true);
        setErrorMsg('');
        const body = new FormData();
        // body.append("tmId", typeof tmId != "undefined" ? tmId : 0);
        body.append("tmName", values.tmName);
        body.append("overwrite", values.overwrite);
        // body.append("sourceLangId", sourceLanguageObj.id);

        let headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        };

        var invalid = false;
        values.forEach((file: any) => {
            if (file) {
                const ext = getFileExtension(file.name);
                if (ext != "xlsx" && ext != 'csv') {
                    invalid = true;
                } else {
                    // alert(file.name+""+file.extension);
                    body.append("file", file, file.name);
                }
            }
        });

        if (!invalid) {
            const req = axios.post('https://mebackendstage.marshub.com' + '/api/import-keyterm-mismatch',
                body, { headers: headers }).then((res: any) => {
                    // setLoading(false);
                    setImportLoader(false);
                    // alert('sd1');
                    setRows(res.data.keyterms)

                    if (res && res.data.errors && res.data.errors.code == 400) {

                        // setErrorText(res.data.errors.message && res.data.errors.message);
                        // dispatch(showAlert({
                        //     message: res.data.errors.message && res.data.errors.message,
                        //     messageType: 'error',
                        //     showAlertMessage: true
                        // }));
                        // setTmFiles([]);
                    } else {

                        // refresh logic here
                        // dispatch(showAlert({
                        //     message: 'TM file uploaded successfully.',
                        //     messageType: 'success',
                        //     showAlertMessage: true
                        // }));
                        // getTmData();
                        // handleModalClose();
                        // setTmFiles([]);
                        // getProjectTM();

                    }

                }).catch((err: any) => {

                });
        } else {
            // 
            setImportLoader(false);
            setErrorMsg('Invalid Extension');
            setSnackBarOpen(true);
        }

    };

    function TBDropZone() {
        const onDrop = useCallback((acceptedFiles: any) => {

            handleImport(acceptedFiles);

        }, []);

        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

        return (
            <div {...getRootProps()}>
                <input {...getInputProps()} accept=".csv, .xlsx" />
                {
                    <Button variant="contained" component="label" disabled={importLoader}>
                        {importLoader ? <CircularProgress disableShrink size={24} /> : ''} {t('verify.import')} CSV/Excel
                    </Button>
                }
            </div>
        )
    }

    const getUploadParams = ({ meta }: any) => { return { url: 'https://httpbin.org/post' } }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }: any, status: any) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files: any) => {

        console.log(files.map((f: any) => f.meta))
    }

    const handlePage = (e: any, value: number) => {
        // window.scrollTo(0, 0);
        setCurrentPage(value);
        // setcurretPage(value);

        const start_index = (value - 1) * 20;
        const end_index = value * 20;

        const page_rows = rows.slice(start_index, end_index);
        setCurrentPageRows(page_rows);
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
                <FormControlLabel
                    label={
                        <span className={classes.checkboxLabel}>
                            <b>{t('verify.common')}</b>
                        </span>
                    }
                    control={
                        <Checkbox
                            checked={isAllChecked ? true : false}
                            indeterminate={
                                checked.length !== 14 && isAllChecked ? true : false
                            }
                            onChange={handleChangeAll}
                            className={classes.checkboxColor}
                        />
                    }
                />
                {common}
            </Box>

            <Modal
                hideBackdrop={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="MuiModal-root"
            >
                <Box className={classes.modalStyles}>
                    {expand === "PO" ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.punctuationOptions')}
                        </Box>
                    ) : expand === "SO" ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.spaceOptions')}
                        </Box>
                    ) : expand === "VO" ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.variableOpt')}
                        </Box>
                    ) : expand === "FO" ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.forbiddenOpt')}
                        </Box>
                    ) : expand === "GO" ? (
                        <Box className={classes.childTitle} id="child-modal-title">
                            {t('verify.glossaryOptions')}
                        </Box>
                    ) : (
                        ""
                    )}

                    {expand === "PO" ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("incorrect_punctuation")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChild(e, "incorrect_punctuation")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkIncorrect')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("multiple_punctuation")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChild(e, "multiple_punctuation")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkMultiple')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("extra_spaces_punctuation")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleChild(e, "extra_spaces_punctuation")
                                            }
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkExtra')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("missing_spaces_punctuation")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleChild(e, "missing_spaces_punctuation")
                                            }
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkMissing')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("trailing_space")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChild(e, "trailing_space")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkTrailing')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("bracket_mismatch")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChild(e, "bracket_mismatch")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkBracketMis')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                punctuationChild.includes("source_target_mismatch")
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleChild(e, "source_target_mismatch")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkPunctuation')}
                                        </span>
                                    }
                                />
                            </Box>
                        </Box>
                    ) : expand === "SO" ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                spacesChild.includes("multiple_spaces") ? true : false
                                            }
                                            onChange={(e) => handleChild(e, "multiple_spaces")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkMiltiple')}
                                        </span>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                spacesChild.includes("zero_spaces") ? true : false
                                            }
                                            onChange={(e) => handleChild(e, "zero_spaces")}
                                            className={classes.checkboxColor}
                                        />
                                    }
                                    label={
                                        <span className={classes.checkboxLabel}>
                                            {t('verify.checkZero')}
                                        </span>
                                    }
                                />
                            </Box>
                        </Box>
                    ) : expand === "VO" ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Box mb={0.5}>
                                    <InputLabel shrink>
                                        <b>{t('verify.variableRegex')}</b>
                                    </InputLabel>
                                </Box>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    defaultValue="\{\d+}"
                                    value={variable}
                                    onChange={handleVariable}
                                />
                                <Box mt={1}>
                                    <li>
                                        <b>\{`{\\d+}`}</b>{t('verify.matchExample')}: {"{0}"}, {"{1}"}, {"{2}"}
                                        {" "}
                                        ...
                                    </li>
                                </Box>
                            </Box>
                        </Box>
                    ) : expand === "FO" ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography style={{ fontSize: "14px", fontWeight: 'bold' }}>
                                    One word per line
                                </Typography>
                                <TextareaAutosize
                                    minRows={10}
                                    maxRows={10}
                                    value={forbidden}
                                    className={classes.textArea}
                                    onChange={handleForbidden}
                                />
                            </Box>
                        </Box>
                    ) : expand === "GO" ? (
                        <Box className={classes.modalBody}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Box style={{ maxHeight: "370px", overflow: "auto" }}>
                                    <Table>
                                        <TableBody>
                                            <Box>
                                                <Table
                                                    className={classes.table}
                                                    size="small"
                                                    aria-label="a dense table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ textTransform: 'capitalize' }}>{t('verify.cTerm')}</TableCell>
                                                            <TableCell style={{ textTransform: 'capitalize' }}>{t('verify.cTranslation')}</TableCell>
                                                            <TableCell style={{ textTransform: 'capitalize' }}>{t('verify.cAction')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {rows && rows.length === 0 ?
                                                            <TableRow>
                                                                <Box p={1}>
                                                                    <Typography className={classes.noTerm}>
                                                                        No term added
                                                                    </Typography>
                                                                </Box>
                                                            </TableRow> :
                                                            <>
                                                                {currentPageRows && currentPageRows.map((row: any, i: any) => {
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell className={classes.cellStyles}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    //
                                                                                    style={{ width: "100%" }}
                                                                                    value={row.title}
                                                                                    name="title"
                                                                                    onChange={(e) =>
                                                                                        handleInputChange(e, i)
                                                                                    }
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell className={classes.cellStyles}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    //
                                                                                    style={{ width: "100%" }}
                                                                                    value={row.value}
                                                                                    name="value"
                                                                                    onChange={(e) =>
                                                                                        handleInputChange(e, i)
                                                                                    }
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="center"
                                                                                className={classes.cellStyles}
                                                                            >
                                                                                <Link
                                                                                    component="button"
                                                                                    variant="body2"
                                                                                    onClick={() => handleRemoveClick(i)}
                                                                                >
                                                                                    {t('verify.remove')}
                                                                                </Link>
                                                                            </TableCell>

                                                                            {showConfirm && (
                                                                                <Box>
                                                                                    <Dialog
                                                                                        open={showConfirm}
                                                                                        onClose={handleNo}
                                                                                        aria-labelledby="alert-dialog-title"
                                                                                        aria-describedby="alert-dialog-description"
                                                                                        classes={{ paper: classes.paper }}
                                                                                        className={classes.dialogBoxWrap}
                                                                                        BackdropProps={{
                                                                                            style: { backgroundColor: 'transparent', boxShadow: 'none' }
                                                                                        }}
                                                                                    >
                                                                                        <DialogTitle
                                                                                            className={classes.dialogTitle}
                                                                                            id="alert-dialog-title"
                                                                                        >
                                                                                            {t('verify.confirmDelete')}
                                                                                        </DialogTitle>

                                                                                        {!clearAll ? (
                                                                                            <>
                                                                                                <DialogContent
                                                                                                    className={classes.dialogContent}
                                                                                                >
                                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                                        {t('verify.areYouSure')}
                                                                                                    </DialogContentText>
                                                                                                </DialogContent>

                                                                                                <DialogActions
                                                                                                    className={classes.dialogActions}
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={() =>
                                                                                                            handleRemoveClick(row.id)
                                                                                                        }
                                                                                                        color="primary"
                                                                                                        variant="contained"
                                                                                                        autoFocus
                                                                                                    >
                                                                                                        {t('verify.yes')}
                                                                                                    </Button>
                                                                                                    <Button
                                                                                                        onClick={handleNo}
                                                                                                        autoFocus
                                                                                                    >
                                                                                                        {t('verify.no')}
                                                                                                    </Button>
                                                                                                </DialogActions>
                                                                                            </>
                                                                                        ) :


                                                                                            clearAll ? (
                                                                                                <>
                                                                                                    <DialogContent
                                                                                                        className={classes.dialogContent}
                                                                                                    >
                                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                                            {t('verify.areYouSureAll')}
                                                                                                        </DialogContentText>
                                                                                                    </DialogContent>

                                                                                                    <DialogActions
                                                                                                        className={classes.dialogActions}
                                                                                                    >
                                                                                                        <Button
                                                                                                            onClick={() => handleClearAll()}
                                                                                                            color="primary"
                                                                                                            variant="contained"
                                                                                                            autoFocus
                                                                                                        >
                                                                                                            {t('verify.yes')}
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            onClick={handleNo}
                                                                                                            autoFocus
                                                                                                        >
                                                                                                            {t('verify.no')}
                                                                                                        </Button>
                                                                                                    </DialogActions>
                                                                                                </>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                    </Dialog>
                                                                                </Box>
                                                                            )}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </>
                                                        }
                                                    </TableBody>
                                                </Table>


                                            </Box>
                                        </TableBody>
                                    </Table>

                                </Box>
                                <Box className={classes.pagination}>

                                    <Box>

                                        <>
                                            {(totalPages && totalPages > 1) ?
                                                <Pagination
                                                    count={totalPages}
                                                    page={currentPage}
                                                    onChange={handlePage}
                                                    shape="rounded" /> : <></>}

                                        </>

                                    </Box>
                                </Box>

                                <Box mt={2.5}>
                                    <Box>
                                        {isEdit ? (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Box display="flex">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleAdd}
                                                    >
                                                        {t('common.addTerm')}
                                                    </Button>
                                                    <Box ml={1}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleConfirmAll}
                                                            disabled={rows.length < 1 ? true : false}
                                                        >
                                                            {t('verify.clearAll')}
                                                        </Button>
                                                    </Box>
                                                </Box>

                                                <Box display="flex">
                                                    {rows.length !== 0 && (
                                                        <Box mr={1}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={disable}
                                                                onClick={handleSave}
                                                            >
                                                                {t('verify.save')}
                                                            </Button>
                                                        </Box>
                                                    )}

                                                    <TBDropZone />
                                                    {/*<Button variant="contained" component="label">*/}
                                                    {/*{t('verify.import')} CSV/Excel*/}
                                                    {/*<Dropzone*/}
                                                    {/*getUploadParams={getUploadParams}*/}
                                                    {/*onChangeStatus={handleChangeStatus}*/}
                                                    {/*onSubmit={handleSubmit}*/}
                                                    {/*accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                                                    {/*/>*/}
                                                    {/*/!* <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden /> *!/*/}
                                                    {/*</Button>*/}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleAdd}
                                                    >
                                                        {t('common.addTerm')}
                                                    </Button>
                                                    <Box ml={1}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleConfirmAll}
                                                            disabled={rows && rows.length < 1 ? true : false}
                                                        >
                                                            {t('verify.clearAll')}
                                                        </Button>
                                                    </Box>
                                                    {/* <Button variant='contained' onClick={handleEdit}>
                                                                            Edit
                                                                        </Button> */}
                                                </Box>

                                                <Box>
                                                    <TBDropZone />

                                                    {/*<Button variant="contained" component="label">*/}
                                                    {/*{t('verify.import')} CSV/Excel*/}
                                                    {/*<Dropzone*/}
                                                    {/*getUploadParams={getUploadParams}*/}
                                                    {/*onChangeStatus={handleChangeStatus}*/}
                                                    {/*onSubmit={handleSubmit}*/}
                                                    {/*accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                                                    {/*/>*/}
                                                    {/*/!* <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden /> *!/*/}
                                                    {/*</Button>*/}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                        : (
                            ""
                        )}

                    <Box className={classes.childFooter}>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            {t('verify.close')}
                        </Button>
                    </Box>


                    <Snackbar className={classes.snackBarStyles} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={snackBarOpen}
                        autoHideDuration={2000} onClose={handleSnackBarClose}>
                        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
                            {errorMsg}
                        </Alert>
                    </Snackbar>
                </Box>
            </Modal>

            <CustomSnackbar
                error={gqlError}
                open={gqlErrorSnackBar}
                onClose={handleErrorSnackBarClose}
            />
        </Box>
    );
}