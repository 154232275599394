import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Modal,
  Button,
  TextField,
  Select,
  Typography,
  Snackbar
} from "@material-ui/core";

import * as Icons from "src/material-ui";
import { trackChanges, useRenderTags } from "src/helpers";
import { IAppState, ISegment } from "src/interfaces";
import useStyles from "../../pages/home/HomeStyles";
import Menu from "../../components/menu/Menu";
import { withStyles, Theme } from '@material-ui/core/styles';
import { DocumentNode, useLazyQuery, useMutation } from '@apollo/client';
import SAVE_SEGMENTS from 'src/helpers/mutations/saveSegmentTranslation';
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from '@material-ui/icons/Close';
import HandleFormatButtons from '../translation-table/HandleFormatButtons'
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SaveIcon from '@material-ui/icons/Save';

// @ts-ignore
import Mark from 'mark.js';

import {
  HandleReplaceWith,
  HandleReplaceAll,
  ClickPropagationFlow,
  RightClickFunction,
  TargetKeyDown,
  MakePropArray,
  GetIcon,
  ReplaceSearch,
} from "src/components/index";
import { useTranslation } from "react-i18next";
import REPLACE_SEGMENT_TRANSLATION from "src/helpers/mutations/replaceSegmentTranslation";
import COPY_ALL_PAGES_SEGMENTS from "../../helpers/mutations/copyAllPagesSegmentTranslation";
import SEGMENT_TRANSLATIONS from "src/helpers/queries/GetSegmentTranslations";
import ACCEPT_REJECT_ALL_PAGES_SEGMENTS from "src/helpers/mutations/acceptRejectSegmentsOnAllPages";
import CREATE_PRE_TRANSLATION from "../../helpers/mutations/preTranslation";
import APPLY_MACHINE_TRANSLATION_ALL_PAGES from "src/helpers/mutations/applyMachineTranslationOnAllPages";
import CREATE_SINGLE_TERM_BASE from "../../helpers/mutations/createSingleTermBase";
import MERGE_SEGMENT from "src/helpers/mutations/mergeSegment";
import { Alert } from "@material-ui/lab";
import CONFIRM_SEGMENTS from "src/helpers/mutations/confirmSegments";
import { handleErrors } from "src/helpers/errorHandler";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";

interface IProps {
  appState: IAppState;
  appDispatch: Function;
  actionDispatch: Function;
  toggle: any;
  setToggle: Function;
  totalPages: any,
  currentPage: any,
  handlePage: any,
  setTbRowData: any,
  headerData: any,
  setcurretPage: any,
  rowsPerPage: any,
  setRowsPerPage: any,
  projectId: any,
  setTempPage: any,
  actionState: any,
  fromEditorMenu: any,
  setFromEditorMenu: any,
  notConfirmed: any,
  setNotConfirmed: Function
}

const TranslationTable = ({ appState, appDispatch, actionDispatch, toggle, setToggle, totalPages, currentPage, handlePage, setTbRowData, headerData,
  setcurretPage, rowsPerPage, setRowsPerPage, projectId, setTempPage, actionState, fromEditorMenu, setFromEditorMenu, notConfirmed, setNotConfirmed }: IProps) => {
  const classes = useStyles({});
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [propagaionData, setPropagaionData]: any = useState([]);
  const [translationData, setTranslationData]: any = useState([]);
  const [translationDataCount, setTranslationDataCount] = useState(0);
  const [selected, setSelected] = useState<string | number>(0);
  const [previousTarget, setPreviusTarget] = useState("");

  const [sourceText, setSourceText] = React.useState("");
  const [targetText, setTargetText] = React.useState("");
  const [tbComment, setTbComment]: any = useState("");
  const [tags, setTags]: any = useState(false);
  const [isReplaceAll, setIsReplaceAll]: any = useState(false);

  const [restrictSave, setRestrictSave]: any = useState(false);

  const [x, setX] = useState<any>(0);
  const [y, setY] = useState<any>(0);

  const [lock, setLock]: any = useState(false);
  const { t } = useTranslation();
  const { renderTags } = useRenderTags();

  const [value, setValue] = useState("");
  const [targetValue, setTargetValue] = useState("");

  const [flag, setFlag]: any = useState();
  const [draft, setDraft]: any = useState(false);
  const [isLocked, setIsLocked]: any = useState(false);

  // const [data, setData]: any = useState(null);
  // const [error, setError]: any = useState('');
  const [scrollTo, setScrollTo] = useState(0);

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEngineData, setIsEngineData] = useState(false);

  const [save, setSave] = useState(true);
  const [load, setLoad] = useState(false);
  const [lastFocusedRow, setLastFocusedRow] = useState<number | null>(null);
  const [changedRows, setChangedRows] = useState<number[]>([]);
  const [saveErrors, setSaveErrors] = useState<number[]>([]);

  const [pasteAlert, setPasteAlert] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarState, setSnackBarState]: any = useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal }: any = snackBarState;

  const [mtAlert, setMTAlert] = React.useState(false);

  const [firstSelectedRow, setFirstSelectedRow]: any = useState(null);

  const [toggleTag, setToggleTag] = useState(false);

  const [mergeError, setMergeError] = useState("");
  const [mergeErrorSnackBar, setMergeErrorSnackBar] = useState(false);

  const {
    gqlError,
    gqlErrorSnackBar,
    handleErrorSnackBarClose,
    handleError,
  } = useErrorHandler();

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event?.target.value));
    setcurretPage(1);
    setTimeout(() => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
    }, 500);
  };

  const [GetSegmentTranslations, { data, error }]: any = useLazyQuery(
    SEGMENT_TRANSLATIONS,
  );

  const [markOption, setMarkOption]: any = useState({
    'separateWordSearch': false,
    diacritics: true,
    accuracy: "partially",
    debug: false,
    caseSensitive: false,
    'done': function (totalMatches: any) {
      if (totalMatches) {
        setTranslationDataCount(totalMatches);
      }
      else {
        setTranslationDataCount(0);
      }
    }
  });

  const [ConfirmSegments, { loading: confirmLoading }] = useMutation(CONFIRM_SEGMENTS, {
    errorPolicy: 'all',
    onCompleted: (data: any) => {

      if (appState.isConfirmed == 1) {
        appDispatch({ type: "CONFIRM_BIT_UPDATE", payload: 2 })
      }

      if (actionState.trackChanges) {
        setTimeout(() => {
          actionDispatch({ type: "REINITIALIZE_TRACK_CHANGES", payload: translationData });
        }, 500);
      }

      appDispatch({ type: "UPDATE_HISTORY" })
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

    }, onError: (error: any) => {
      // setSaveErrors(true);
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });
      setSave(false);

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const [SaveSegments, { loading: loadingSegments }] = useMutation(SAVE_SEGMENTS, {
    errorPolicy: 'all',
    onCompleted: (data: any) => {

      const idArray: any = [];

      // Iterate over each selected segment in appState.selectedSegments
      // appState.selectedSegments.forEach((selectedSegment: any) => {
      //   propagaionData.forEach((elem: any) => {
      //     elem.forEach((el: any) => {
      //       if (el.id !== selectedSegment.id)
      //         if (el.source === selectedSegment.source && el.propagation === true)
      //           idArray.push(el.id);
      //     });
      //   });

      //   propagaionData.forEach((elem: any) => {
      //     elem.forEach((el: any) => {
      //       if (el.id === selectedSegment.id && el.propagation === true) {
      //         for (let i = 0; i < idArray.length; i++) {
      //           appState.data.forEach((item: any) => {
      //             if (item.id === idArray[i] && !item.isLocked) {
      //               if (
      //                 selectedSegment.isPropagationOn == 1 &&
      //                 item.isPropagationOn == 1 &&
      //                 selectedSegment.isConfirmed === true
      //               ) {
      //                 item.tStatusType = 'AP';
      //                 item.tStatusValue = '';
      //               }
      //               // else if (
      //               //   selectedSegment.isPropagationOn == 1 &&
      //               //   item.isPropagationOn == 1 &&
      //               //   selectedSegment.isConfirmed === false
      //               // ) {
      //               //   item.tStatusType = '';
      //               //   item.tStatusValue = '';
      //               // }
      //             }
      //           });
      //         }
      //       }
      //     });
      //   });
      // });

      // setData(data);

      // setSave(true);
      // setLoad(false);
      // setTranslationData()
      // appDispatch({type: "COPY_TRANSLATION_DATA_DATA",payload: {data: translationData}});

      if (appState.isConfirmed == 1) {
        // setTimeout(() => {
        appDispatch({ type: "CONFIRM_BIT_UPDATE", payload: 2 })
        // },1000);
      }

      if (actionState.trackChanges) {
        setTimeout(() => {
          actionDispatch({ type: "REINITIALIZE_TRACK_CHANGES", payload: translationData });
        }, 500);
      }

      appDispatch({ type: "UPDATE_HISTORY" })
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

    }, onError: (error: any) => {
      // setSaveErrors(true);
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });
      setSave(false);

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);

      // alert(error);
    }
  });

  const [copyAllPagesSegments, { loading: loadingCopySegments }] = useMutation(COPY_ALL_PAGES_SEGMENTS, {
    errorPolicy: 'all',
    variables: {
      data: {
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        isTrackOn: actionState.trackChanges,
        service: appState.selectedService.code
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }
    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const [createPreTranslation, { loading: loadingPRESegments }] = useMutation(CREATE_PRE_TRANSLATION, {
    errorPolicy: 'all',
    variables: {
      data: {
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        isTrackOn: actionState.trackChanges,
        start: appState.data[0]?.key,
        end: appState.data[appState.data.length - 1]?.key
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }


    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const [createTermBase, { loading: loadingAddTermBase }] = useMutation(CREATE_SINGLE_TERM_BASE, {
    errorPolicy: 'all',
    variables: {
      data: {
        projectId: headerData?.id,
        sl: headerData?.sl?.code,
        tl: appState.selectedLanguage,
        sourceTerm: sourceText,
        targetTerm: targetText,
        comment: tbComment,
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });


    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const advance: any = localStorage.getItem("advance");

  const [applyMachineTranslationOnAllPages, { loading: loadingMachineTranslation }] = useMutation(APPLY_MACHINE_TRANSLATION_ALL_PAGES, {
    errorPolicy: 'all',
    variables: {
      data: {
        sl: headerData?.sl?.code,
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        engine: advance ? JSON.parse(advance)[1].children[0].inputValue + " Translate" : 'Google Translate',
        segmentIds: appState.selectedSegments.length > 1 && !appState.hitMachineTranslationAllPages ? appState.selectedSegments.map((segment: any) => segment.id) : [],
        ignoreTags: tags ? false : true,
        isTrackOn: actionState.trackChanges,
        start: appState.data[0]?.key,
        end: appState.data[appState.data.length - 1]?.key
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }

      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  const getCurrentSegment: any = () => {
    let selected = appState.selectedRow.id;
    let selectedIndex = appState.data.findIndex((row: any) => row.id === selected);
    if ((selectedIndex + 1) !== appState.data.length) {
      let nextSegment: any = appState.data[selectedIndex + 1]?.id;
      return [parseInt(selected), parseInt(nextSegment)];
    }
    return 0;
  }

  const [mergeSegment, { loading: loadingMergeSegments }] = useMutation(MERGE_SEGMENT, {
    errorPolicy: 'all',
    variables: {
      data: {
        sourceSegmentId: getCurrentSegment()[0],
        key: appState.selectedRow.key,
        tl: appState.selectedLanguage,
        isTrackOn: actionState.trackChanges,
      }
    },
    onCompleted: (data: any) => {
      // appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      if (data.mergeSegment.error !== "") {
        setMergeError(data.mergeSegment.error);
        setMergeErrorSnackBar(true);
      }
      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }
    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  useEffect(() => {
    if (appState.selectedSegments.length > 1) {
      applyMachineTranslationOnAllPages();
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
    }
  }, [toggleTag])

  useEffect(() => {
    if (appState.hitCopyAllPages == 1 || appState.hitCopyAllPages == 2) {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
      copyAllPagesSegments();
    }
  }, [appState.hitCopyAllPages]);

  useEffect(() => {
    if (appState.hitPreTranslationAllPages == 1 || appState.hitPreTranslationAllPages == 2) {

      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });

      createPreTranslation();
    }
  }, [appState.hitPreTranslationAllPages]);

  // To Toggle Track Changes Button according to selected Service

  useEffect(() => {
    if ((appState.data && appState.selectedService.code && appState.selectedService.code.toLowerCase() === 'proofreading' && !actionState.trackChanges)) {
      actionDispatch({ type: "TRACK_CHANGES", payload: appState.data });
    }
  }, [appState.selectedService.isAssigned, appState.selectedService.code, appState.data])

  const [acceptRejectAllPagesSegments, { loading: loadingAcceptRejectSegments }] = useMutation(ACCEPT_REJECT_ALL_PAGES_SEGMENTS, {
    errorPolicy: 'all',
    variables: {
      data: {
        projectId: headerData?.id,
        fileId: appState.selectedFile.id,
        tl: appState.selectedLanguage,
        type: (appState.hitTrackChanges === 1 || appState.hitTrackChanges === 3) ? "accept" : "reject"
      }
    },
    onCompleted: (data: any) => {
      if (appState.searchFilters?.queryExecute === true || appState.searchFilters?.queryExecute === 1) {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 2
        });
      } else {
        appDispatch({
          type: "QUERY_EXECUTE_FILTERS",
          payload: 1
        });
      }


    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if ([1, 2, 3, 4].includes(appState.hitTrackChanges)) {
        appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
        await acceptRejectAllPagesSegments();
      }
    }
    fetchData();

  }, [appState.hitTrackChanges]);

  const [ReplaceSegmentTranslation, { loading: loadingReplace }] = useMutation(REPLACE_SEGMENT_TRANSLATION, {
    errorPolicy: 'all',
    onCompleted: (data: any) => {

      let replacedData = data.replaceSegmentTranslation.getFileSegmentsByLanguage.segments;

      if (isReplaceAll) {
        setTargetValue('');
        setReplaceValue('');
        appDispatch({ type: "FILTERS", payload: { queryExecute: false, source: value, target: '', case: caseSensitive, isEmpty: (appState.hitSearchFilters == 1) } });
      }

      appState.data.forEach((row: any) => {
        if (replacedData.find((item: any) => item.target !== row.target && item.sSegmentId === row.id)) {
          let translatedText = replacedData.find((item: any) => item.target !== row.target && item.sSegmentId === row.id).target;
          row.target = translatedText;
          row.state = "draft";
          row.isConfirmed = false;
        }
      })

      appDispatch({
        type: 'CONVERT_DATA',
        payload: appState.data,
        pagination: data.replaceSegmentTranslation.getFileSegmentsByLanguage.pagination,
        searchCounts: data.replaceSegmentTranslation.getFileSegmentsByLanguage.searchCounts,
        segments: data.replaceSegmentTranslation.getFileSegmentsByLanguage.segments,
      })


    }, onError: (error: any) => {
      appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

      {/* To handle GraphQl errors in SnackBar */ }
      handleError(error);
    }
  });

  useEffect(() => {
    const handleBodyClick = () => {
      if (document.body.classList.contains("open")) {
        document.body.classList.remove("fixed-position");
        document.body.classList.remove("open");
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.add("fixed-position");
        document.body.classList.add("open");
      }, 1);
    } else {
      setTimeout(function () {
        document.body.removeAttribute("style");
        document.body.classList.remove("fixed-position");
        document.body.classList.remove("open");
      }, 1);
    }
  }, [open]);

  const changeTab = (id: any) => {

    let temp =
      setTimeout(() => {
        const input1: any = document.getElementById('qlText');
        if (input1) {
          input1.value = '';
          input1.innerHTML = '';
          setToggle({ textInput: toggle.textInput });
        }
        setToggle({ seg: "query", id: id, textInput: toggle.textInput });
        input1?.focus();
        clearTimeout(temp);
      }, 500);

    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: '#494949',
      border: '1px solid #E0E0E0',
      fontSize: 12,
      margin: '2px 0'
    },
  }))(Tooltip);

  const LightTooltipError = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'red',
      border: '1px solid #E0E0E0',
      fontSize: 12,
      margin: '2px 0'
    },
  }))(Tooltip);

  const handleModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;

    if (userAgent.match(/firefox|fxios/i) && show) {
      $('body').removeClass("fixed-position");
      $('body').addClass("fixed-menu");

      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.top = -scrollTop + 'px';
      setScrollTo(scrollTop);
      // $('body').addClass("fixed-position");
      $('#segmentBox').css("height", "87vh");

    }
    else if (userAgent.match(/chrome|chromium|crios/i) && show) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.top = -scrollTop + 'px';
      setScrollTo(scrollTop);
      // $('body').addClass("fixed-position");
      $('#segmentBox').css("height", "87vh");
    }
    else {
      $('body').removeClass("fixed-position");
      $('body').removeClass("fixed-menu");
      $('#segmentBox').css("height", "100vh");
      window.scrollTo(0, scrollTo);
    }
  }, [show]);

  // useEffect(() => {
  //   handleBothSearches([".sourceText", ".targetText"], [value, targetValue], markOption);
  //
  //   // Filtered Search
  //   const dataArray = appState.data;
  //   const idArray: any = [];
  //
  //   if (value !== "" || targetValue !== "") {
  //     dataArray.forEach((row: any, index: number) => {
  //       const sourceHTML: any = document.getElementById('source-td' + row.id);
  //       const targetHTML: any = document.getElementById('custom-editor' + row.id);
  //
  //       const sourceElement: any = sourceHTML?.querySelector('mark');
  //       const targetElement: any = targetHTML?.querySelector('mark');
  //
  //       if ((sourceElement || targetElement) && !idArray.find((item: any) => item === row.id)) {
  //         idArray.push(row.id);
  //       }
  //
  //     })
  //     dataArray.forEach((row: any) => {
  //       let editorHTML = document.getElementById("row" + row.id);
  //       if (editorHTML)
  //         if (idArray.some((elem: any) => elem === row.id))
  //           editorHTML.style.display = "table-row";
  //         else
  //           editorHTML.style.display = "none";
  //     });
  //   } else dataArray.forEach((row: any) => {
  //     let editorHTML = document.getElementById("row" + row.id);
  //     if (editorHTML) editorHTML.style.display = "table-row";
  //   });
  //
  //
  // }, [value, targetValue, markOption.caseSensitive])

  useEffect(() => {
    setTranslationData(appState.data);
    checkPropagation();
    appDispatch({ type: "RESET_HISTORY" })
  }, [appState.data]);

  useEffect(() => {
    actionDispatch({ type: "REINITIALIZE_TRACK_CHANGES", payload: translationData });
    if (appState.searchFilters.source !== "" || appState.searchFilters.target !== "") {
      handleBothSearches([".sourceText", ".targetText"], [value, targetValue], markOption);
    }
  }, [translationData]);

  // useEffect(() => {
  //   appDispatch({
  //     type: "SELECTED",
  //     payload: { handleProp: handleInput, handleSav: onSaveSegments, menuData: menuData, currentPage: currentPage, isTrackChanges: actionState.trackChanges },
  //   });
  // }, [actionState.trackChanges]);

  useEffect(() => {
    appDispatch({
      type: "DEFAULT_SELECTION",
      payload: { handleProp: handleInput, handleSav: onSaveSegments, menuData: menuData, currentPage: currentPage, isTrackChanges: actionState.trackChanges },
    });
  }, [actionState.trackChanges]);

  useEffect(() => {
    setTranslationData([]);
    setTimeout(() => {
      setTranslationData(appState.data);
    }, 1000);

    checkPropagation();
  }, [appState.data, appState.selectedLanguage]);

  useEffect(() => {
    // console.log("-->", appState.filters);
  }, [appState.filters]);

  useEffect(() => {
    const handleClick = () => {
      setShow(false);
      setRestrictSave(false);
    };

    document.addEventListener("click", handleClick);
    // document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [show]);

  useEffect(() => {
    const temp = appState.addNewTerm;
    if (temp.flag === 's') {
      setSourceText(temp.text);
      setTargetText("");
    }
    else {
      setTargetText(temp.text);
      setSourceText("");
    }
  }, [appState.addNewTerm.text, appState.addNewTerm.flag, open]);

  useEffect(() => {
    if (appState.hitSearchFilters == 1 || appState.hitSearchFilters == 2) {
      handleSearchFilters(1);
      // handleClearFilters(1);
    }
    // console.log("-->", appState.filters);
  }, [appState.hitSearchFilters]);

  const normalizePozition = (mouseX: any, mouseY: any) => {
    let scope: any = document.querySelector("#custom-table");

    let scopeHeight = window.innerHeight - 300;

    if (window.scrollY) {
      scopeHeight = window.innerHeight - 103 + window.scrollY;
    }

    let menuHeight = 515;
    const menuWidth = 347;

    if (lock) {
      menuHeight = 50;
    }

    let {
      left: scopeOffsetX,
      top: scopeOffsetY,
    } = scope.getBoundingClientRect();

    scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX;
    scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY;

    const scopeX = mouseX - scopeOffsetX;
    const scopeY = mouseY - scopeOffsetY;
    const outOfBoundsOnX = scopeX + menuWidth > scope?.clientWidth;
    const outOfBoundsOnY = scopeY + menuHeight > scopeHeight;

    let normalizedX = mouseX;
    let normalizedY = mouseY;

    if (outOfBoundsOnX) {
      normalizedX = scopeOffsetX + scope?.clientWidth - menuWidth;
    }

    if (outOfBoundsOnY) {
      normalizedY = scopeOffsetY + scopeHeight - menuHeight;
    }
    return { normalizedX, normalizedY };
  };

  const handleContextMenu = useCallback(
    // (event) => {
    (event: { preventDefault: () => void; pageX: any; pageY: any; }) => {

      event.preventDefault();

      let cordinates: any = normalizePozition(event.pageX, event.pageY);

      // alert(event.pageY)

      setAnchorPoint({ x: cordinates.normalizedX, y: cordinates.normalizedY });

      setShow(true);

      setRestrictSave(true);

      getCursorPosition(event);
    },
    [setAnchorPoint, appState.selected, lock, restrictSave]
  );

  const cleanSave = (docContent: any) => {
    let oldContent: any = docContent?.replace(new RegExp(escapeRegExp('<div>'), 'g'), '')
      ?.replace(new RegExp(escapeRegExp('</div>'), 'g'), '')
      ?.replace(new RegExp(escapeRegExp('<br>'), 'g'), '')
      ?.replace(new RegExp(escapeRegExp('</br>'), 'g'), '');

    const parser = new DOMParser();
    const newContent = parser.parseFromString(oldContent, "text/html");

    if (newContent.nodeType !== Node.DOCUMENT_NODE) {
      throw new Error("Invalid document content");
    }
    newContent.querySelectorAll("mark").forEach((mark: any) => {
      mark.outerHTML = mark.innerHTML;
    });
    return newContent.documentElement.outerHTML;
  }

  const getQueryParam = (parameterName: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(parameterName);
  };

  const startVal = getQueryParam('start');

  const endVal = getQueryParam('end');

  let startParam: any = null;
  let endParam: any = null;

  if (typeof startVal != "undefined" && startVal) {
    startParam = parseInt(startVal);
  }
  if (typeof endVal != "undefined" && endVal) {
    endParam = parseInt(endVal);
  }

  const handleConfirmSegments = (rows: any) => {
    const rowsToConfirm = rows.map((row: any) => row.id);
    const projectId = getQueryParam('projectId');
    const targetLanguage = getQueryParam('tl');
    const service = getQueryParam('type');

    const confirmRows = async () => {
      try {
        await ConfirmSegments({
          variables: {
            data: {
              segmentIds: rowsToConfirm,
              tl: targetLanguage?.toString(),
              projectId: headerData?.id || projectId?.toString(),
              state: service === 'proofreading' ? 'reviewed' : 'translated'
            }
          }
        });
      } catch (error: any) {
      } finally {
        setLoad(false);
      }
    };

    // Call the async function directly
    confirmRows();
  }

  const onSaveSegments = (rows: ISegment | ISegment[], flag: number) => {

    // Conditionally handle confirm all or confirm 5 or more segments
    if (flag === 2) {
      handleConfirmSegments(rows);
      return;
    }

    let data: any[] = [];
    let target1;
    if (Array.isArray(rows)) {
      // For Multiple Segments Save
      rows.map((row: ISegment) => {
        let docContent: any = document?.getElementById("custom-editor" + row.id)?.innerHTML;
        let textContent: any = document?.getElementById("custom-editor" + row.id)?.textContent;
        docContent = docContent?.replace(/•+/g, (match: any) => '&nbsp;'.repeat(match.length));
        // target1 = cleanSave(docContent);

        // Remove <mark> tags
        docContent = docContent.replace(/<\/?mark[^>]*>/g, "");

        if (typeof docContent != "undefined" && docContent?.trim() == '<br/>') {
          docContent = '';
        }

        target1 = docContent;

        data.push({
          target: target1,
          tSegmentId: row.tSegmentId ?? 0,
          sSegmentId: row.sSegmentId ?? 0,
          tl: row.tl,
          state: row.state,
          isLocked: row.isLocked,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
          isPropagationOn: row.isPropagationOn,
          isAT: (row.tStatusType === 'MT' || row.tStatusType === 'cMT'),
          isConfirmed: row.isConfirmed ? true : false,
          targetPlain: textContent && textContent,
          service: appState.selectedService.code,
          start: startParam,
          end: endParam,
        });

        if (!changedRows.includes(row.id)) {
          setChangedRows([...changedRows, row.id]);
        }

      });
    } else {
      // For Single Segment Save
      let docContent = document?.getElementById("custom-editor" + rows.id)?.innerHTML || " ";
      let textContent = document?.getElementById("custom-editor" + rows.id)?.textContent;
      if (docContent || docContent === " ") {
        // target1 = cleanSave(docContent);

        // const isPropagation = propagaionData.some((innerArray: any) => {
        //   return innerArray.some((obj: any) => obj.propagation === true && obj.id === rows.id);
        // });

        // Remove <mark> tags
        docContent = docContent.replace(/<\/?mark[^>]*>/g, "");

        docContent = docContent?.replace(/•+/g, (match: any) => '&nbsp;'.repeat(match.length));

        if (typeof docContent != "undefined" && docContent?.trim() == '<br/>') {
          docContent = '';
        }
        target1 = docContent;

        data.push({
          target: target1,
          tSegmentId: rows.tSegmentId ?? 0,
          sSegmentId: rows.sSegmentId ?? 0,
          tl: rows.tl,
          state: rows.state,
          isLocked: rows.isLocked,
          tStatusType: rows.tStatusType,
          tStatusValue: rows.tStatusValue,
          isPropagationOn: rows.isPropagationOn,
          isAT: (rows.tStatusType === 'MT' || rows.tStatusType === 'cMT'),
          isConfirmed: rows.isConfirmed ? true : false,
          targetPlain: textContent,
          service: appState.selectedService.code,
          start: startParam,
          end: endParam,
        });

        if (!changedRows.includes(rows.id)) {
          setChangedRows([...changedRows, rows.id]);
        }

      }
    }
    if (data.length > 0 && (flag === 1 || target1 !== previousTarget)) {
      setSave(false);
      setLoad(true);
      // setTimeout(() => {
      //   SaveSegments({ variables: { data: data } })
      //     .then(() => {
      //       // Save is successful, reset the list of changed rows
      //       if (!Array.isArray(rows)) {
      //         setSaveErrors(prevSaveErrors => prevSaveErrors.filter(id => id !== rows.id));
      //       }
      //       setChangedRows([]);
      //     })
      //     .catch((error: any) => {
      //       console.error('Save error:', error);
      //       if (!Array.isArray(rows)) {
      //         setSaveErrors(prevSaveErrors => [...prevSaveErrors, rows.id]);
      //       }

      //     });
      //   setSave(true);
      //   setLoad(false)
      // }, 500);

      const saveData = async () => {
        try {
          await SaveSegments({ variables: { data } });
          // Save is successful, reset the list of changed rows
          if (!Array.isArray(rows)) {
            setSaveErrors(prevSaveErrors => prevSaveErrors.filter(id => id !== rows.id));
          }
          setChangedRows([]);
          setSave(true);
        } catch (error: any) {
          console.error('Save error:', error);
          if (!Array.isArray(rows)) {
            setSaveErrors(prevSaveErrors => [...prevSaveErrors, rows.id]);
          }
        } finally {
          setLoad(false);
        }
      };

      // Call the async function directly
      saveData();
    }
  };

  const checkPropagation = () => {
    let temp2: any = [];
    for (let index = 0; index < appState?.data.length; index++) {
      const element = appState.data[index];
      let propFlag = 0;
      appState.data.forEach((element2: any) => {
        if (element.source === element2.source) propFlag++;
      });
      if (propFlag > 1) {
        temp2.push({ propagation: true, id: element.id, source: element.source });
      }
    }
    let res = MakePropArray(temp2);
    setPropagaionData(res);
  };

  const handleCaseCheck = () => {
    setCaseSensitive(!caseSensitive);
    setMarkOption({ ...markOption, caseSensitive: !markOption.caseSensitive });
  };

  const checkDraft = (row: ISegment) => {
    appState.data.forEach((item: any) => {
      if (item.id === row.id) {
        item.state = "draft";
      }
    });
    // setTranslationData(appState.data);
    setDraft(!draft);
  }

  function getCurrentCursorPosition(selection: any): number {
    let currentPosition = 0;
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(range.commonAncestorContainer);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      currentPosition = preSelectionRange.toString().length;
    }
    return currentPosition;
  }

  const setCorrectCursorPosition = (rowId: any, rowHTML: any) => {
    const currentRow: any = document.getElementById('custom-editor' + rowId);
    if (currentRow && rowHTML.length === 1) {
      currentRow.innerHTML = rowHTML;
      const selection: any = window.getSelection();
      const range: any = selection.getRangeAt(0);
      selection.removeAllRanges();
      range.setStart(currentRow.firstChild, 1);
      range.setEnd(currentRow.firstChild, 1);
      selection.addRange(range);
    }
  }

  const handleInput = (
    e: any,
    row: ISegment
  ) => {
    if (row.isLocked === false) {
      let rowHTML: any;
      let rowState: any;
      let keyCode: any;
      let keyName: any;
      const currentRow: any = document.getElementById('custom-editor' + row.id);

      setSave(false);

      if (!changedRows.includes(row.id)) {
        setChangedRows([...changedRows, row.id]);
      }

      if (e !== "isForState")
        row.state = "draft";
      if (e === "clear") {
        row.state = "";
        setDraft(!draft);
      }

      row.isConfirmed = false;

      if ((e?.target?.innerHTML == '' || e?.target?.innerHTML == ' ' || e?.target?.innerHTML == '<br>')) {
        row.tStatusType = '';
        row.tStatusValue = '';
        currentRow.innerHTML = "";
      }
      if (e && e !== "clear" && e !== "isForState" && e !== "fromTM") {
        if (e?.nativeEvent?.data) {
          keyCode = e?.nativeEvent?.data?.charCodeAt(0);
          keyName = String.fromCharCode(keyCode);
        }

        if (row.tStatusType === 'TM' || row.tStatusType === 'TB' || row.tStatusType === 'MT') {
          row.tStatusType = 'c' + row.tStatusType;

          if (row.target == '' || row.target == ' ') {

            row.tStatusType = '';
            row.tStatusValue = '';
          }
          setDraft(!draft);
        } else if (row.tStatusType === 'HM') {
          row.tStatusType = 'cTM';
        }

        // if (keyCode === 32 && keyName === " " && actionState.showWhiteSpace) {
        //   rowHTML = e?.target.innerHTML.replace(/(\s+|&nbsp;)/g, '•');
        // }
        // else {
        rowHTML = e?.target.innerHTML;
        // }
      }
      else {
        // if (actionState.showWhiteSpace) {
        //   rowHTML = row.target.replace(/(\s+|&nbsp;)/g, '•');
        // }
        // else {
        rowHTML = renderTags(row.target);
        // }
      }

      rowHTML = rowHTML.replace(/<font[^>]*>(.*?)<\/font>/g, '$1').replace(/<strike[^>]*>|<\/strike>/g, '').replace(/<u[^>]*>|<\/u>/g, '');
      // rowHTML = actionState.trackChanges ? rowHTML : rowHTML.replace(/<ins[^>]*>|<\/ins>/g, '');
      rowState = row.state;
      // setCorrectCursorPosition(row.id, rowHTML);

      if (row.tStatusType === "AP") {
        row.tStatusType = "";
        row.tStatusValue = "";
      }

      // const idArray: any = [];
      // propagaionData.forEach((elem: any) => {
      //   elem.forEach((el: any) => {
      //     if (el.id !== row.id)
      //       if (el.source === row.source && el.propagation === true)
      //         idArray.push(el.id);
      //   });
      // });
      // if (idArray.length !== 0)
      //   propagaionData.forEach((elem: any) => {
      //     elem.forEach((el: any) => {
      //       if (el.id === row.id && el.propagation === true) {
      //         for (let i = 0; i < idArray.length; i++) {
      //           // if (idArray[i] !== row.id) {
      //           appState.data.forEach((item: any) => {
      //             if (item.id === idArray[i] && !item.isLocked) {
      //               let editorHTML = document.getElementById(
      //                 "custom-editor" + idArray[i]
      //               );
      //               if (editorHTML && row.isPropagationOn == 1 && item.isPropagationOn == 1) {
      //                 // console.log("rowState: ", rowState, "   ", "rowHTML: ", rowHTML)
      //                 editorHTML.innerHTML = rowHTML;
      //                 item.target = rowHTML;
      //                 item.state = rowState;
      //                 item.tStatusType = row.tStatusType;
      //                 item.tStatusValue = row.tStatusValue;
      //                 // console.log("row.id = ", item.id, ", row.target = ", item.target);
      //               }
      //             }
      //           });
      //           // }
      //         }
      //       }
      //     });
      //   });

      // if ((keyCode === 32 && keyName === " " && actionState.showWhiteSpace) || (e === "fromTM" && actionState.showWhiteSpace)) {
      //   const currentRow: any = document.getElementById('custom-editor' + row.id);
      //   if (currentRow) {
      //     const selection: any = window.getSelection();
      //     const range: any = document.createRange();
      //     const currentPosition: number = getCurrentCursorPosition(selection);
      //     currentRow.innerHTML = rowHTML;

      //     if (!(e === "fromTM" && actionState.showWhiteSpace)) {
      //       range?.setStart(currentRow.childNodes[0], currentPosition);
      //       range?.collapse(true);
      //       selection?.removeAllRanges();
      //       selection?.addRange(range);
      //     } else {
      //       // alert('sdsd');
      //       translationData.forEach((el: ISegment) => {
      //         let targetDoc: any = document.getElementById('custom-editor' + el.id);
      //         let sourceDoc: any = document.getElementById('source-td' + el.id);
      //         if (targetDoc || sourceDoc) {
      //           targetDoc.innerHTML = targetDoc?.textContent?.replace(/\ /g, "•");
      //           sourceDoc.innerHTML = sourceDoc?.textContent?.replace(/\ /g, "•");
      //         }
      //       });
      //       // actionDispatch({type: "WHITE_SPACES", payload: translationData});
      //     }
      //   }
      // }

      if (rowHTML === row.source)
        onSaveSegments(row, 1)

      if (e && e !== "clear") {
        let lastRedoH = appState.redoHistory[appState.redoHistory.length - 1];
        let lastUndoH = appState.undoHistory[appState.undoHistory.length - 1];
        let redoFound = lastRedoH && lastRedoH.history === rowHTML;
        let undoFound = lastUndoH && lastUndoH.history === rowHTML;

        //&& row.tStatusType !== "MT"

        if (!redoFound && !undoFound) {
          appDispatch({
            type: "ADD_HISTORY",
            payload: {
              id: row.id,
              history: rowHTML.replaceAll(/<img\s+alt="(.*?)"[^>]*>/g, (_: any, altValue: any) => altValue),
              state: row.state,
              tStatusType: row.tStatusType,
              tStatusValue: row.tStatusValue,
            },
          });
        }
      }
    }
  };

  const parseEngines = () => {
    let engineResult = headerData.engines.find((itm: any) => (itm.sl == headerData?.sl?.code && itm.tl == appState.selectedLanguage));
    let engines = engineResult ? engineResult.engines : [];
    let parsedEngines = [];

    for (let i = 0; i < engines.length; i++) {
      parsedEngines.push({ name: engines[i] });
    }
    return parsedEngines;
  };

  const menuData = async (exec: any) => {
    let targetDivContent: any = document?.getElementById(
      "custom-editor" + selected
    );
    let selected_data = translationData.filter((element: any) => element.id === selected);
    let para = flag === 't' ? selected_data[0]?.target : selected_data[0]?.source;
    const text: any = window.getSelection()?.toString();
    let onRightClickData = text ? text : para;
    let res: any
    if (!exec.includes("machineTranslation")) {
      res = await RightClickFunction(
        exec,
        translationData,
        targetDivContent,
        onRightClickData,
        selected,
        flag,
        checkPropagation,
        appState,
        appDispatch,
        setNotConfirmed
      );
      if (res && res.length > 0) {
        setTranslationData(res);
        let result = res.filter((row: any) => row.id === selected || appState.selectedSegments.some((item: any) => item.id === row.id));
        if (result[0]) { //  && result[0]?.target
          handleInput('isForState', result[0]);
          if (exec !== 'translated' && exec !== 'reviewed') {
            onSaveSegments(result, 1);
          }

        }
      }
    }
    else {
      const selectedRow = translationData.find((row: any) => row.id === appState.selectedRow.id);
      let tl = selectedRow?.tl;
      let source = selectedRow?.source;

      if (tl && source && !exec.includes("all") && (appState.selectedSegments.length === 1 || exec.includes("1"))) {
        setLastFocusedRow(selectedRow.id);
        GetSegmentTranslations({
          variables: {
            segmentId: appState.selectedRow.id,
            sourceText: source,
            tl: appState.selectedLanguage,
            projectId: headerData?.id,
            sl: headerData?.sl?.code,
            engines: parseEngines(),
            isTrackOn: actionState.trackChanges
          }
        });
        exec.includes("Tags") ? setTags(false) : setTags(true);
        setIsEngineData(true);
      }
      else {
        exec.includes("Tags") ? setTags(false) : setTags(true);
        setToggleTag(!toggleTag);
      }
    }
  };

  useEffect(() => {
    if (data && isEngineData) {
      // const advance: any = localStorage.getItem("advance");
      const settings = appState?.projectSettings;
      let advance: any = JSON.parse(settings);

      advance = advance.find((itm: any) => itm.value == 'advance').children[1];
      if (advance) {
        const engine = (advance && advance.children && typeof advance.children[0].inputValue != "undefined") ? advance.children[0].inputValue : 'Google';
        const machineData = data.getSegmentTranslations.machineTranslation;
        const engineData = machineData?.find((translation: any) => translation.engine === (engine + " Translate"));

        appDispatch({
          type: "TM",
          payload: { ...engineData, withTags: tags, isTrackChanges: actionState.trackChanges }
        });

        // setTimeout(()=>{
        let row: any = appState.data.find((row: any) => row.id === selected);
        onSaveSegments(row, 1)
        setIsEngineData(false);
        // },1000)

      }
    }

  }, [data, isEngineData])


  const handlePropagation = () => {
    if (selected === 0) {
      appDispatch({
        type: "SELECTED",
        payload: { handleProp: handleInput, handleSav: onSaveSegments, menuData: menuData, isTrackChanges: actionState.trackChanges, currentPage: currentPage },
      });
    }
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: ISegment
  ) => {
    handlePropagation();

    // appState.selected = row.id;

    setSelected(row.id);
    //  alert(abc)
    // setOnRightClickData(row.target);
    setIsLocked(row.isLocked);
    let element = document.getElementById("custom-editor" + row.id);
    element?.addEventListener("contextmenu", handleContextMenu);
    // element?.addEventListener("paste", function (e: any) {
    //   var text = e.clipboardData.getData("text/plain");
    //   document.execCommand("insertHTML", false, renderTags(text));
    //   e.preventDefault();
    // });
    let oldUndoH = [...appState.undoHistory];
    let findRow = oldUndoH.find((undoH) => undoH.id === row.id);
    // if (findRow === undefined) {
    appDispatch({
      type: "ADD_HISTORY",
      payload: {
        id: row.id,
        history: e.target.innerHTML,
        state: row.state,
        tStatusType: row.tStatusType,
        tStatusValue: row.tStatusValue,
      },
    });
    // }
    setFlag("t");

    // Multiple Selection
    appDispatch({
      type: 'MULTIPLE_SELECTION', payload: [row]
    });
    setFirstSelectedRow(row);
  };

  const handleFocusRowId = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: ISegment
  ) => {
    handlePropagation();
    // appState.selected = row.id;
    setSelected(row.id);

    setIsLocked(row.isLocked);

    let element = document.getElementById("row-id" + row.id);
    element?.addEventListener("contextmenu", handleContextMenu);

    let oldUndoH = [...appState.undoHistory];
    let findRow = oldUndoH.find((undoH) => undoH.id === row.id);
    if (findRow === undefined) {
      let rowHtml: any = document.getElementById("custom-editor" + row.id)
      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: row.id,
          history: rowHtml.innerHTML,
          state: row.state,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
        },
      });
    }
    setFlag("s");
  };

  const handleFocusStatusCell = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: ISegment
  ) => {
    handlePropagation();
    // appState.selected = row.id;
    setSelected(row.id);

    setIsLocked(row.isLocked);

    let element = document.getElementById("status-cell" + row.id);
    element?.addEventListener("contextmenu", handleContextMenu);

    let oldUndoH = [...appState.undoHistory];
    let findRow = oldUndoH.find((undoH) => undoH.id === row.id);
    if (findRow === undefined) {
      let rowHtml: any = document.getElementById("custom-editor" + row.id)
      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: row.id,
          history: rowHtml.innerHTML,
          state: row.state,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
        },
      });
    }
    setFlag("s");
  };

  const handleFocusInfo = (
    e: any,
    row: ISegment
  ) => {
    handlePropagation();
    appDispatch({
      type: "SET_SELECTED", payload: row.id
    });
    // appState.selected = row.id;
    setSelected(row.id);
    setIsLocked(row.isLocked);

    // let element: any = document.getElementById("info-cell" + row.id);
    // element?.addEventListener("contextmenu", handleContextMenu);

    let oldUndoH = [...appState.undoHistory];
    let findRow = oldUndoH.find((undoH) => undoH.id === row.id);
    if (findRow === undefined) {
      let rowHtml: any = document.getElementById("custom-editor" + row.id)
      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: row.id,
          history: rowHtml.innerHTML,
          state: row.state,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
        },
      });
    }
    setFlag("s");
  };

  const handleFocusSource = (
    e: any,
    row: ISegment
  ) => {
    // appState.selected = row.id;
    setFlag("s");
    setSelected(row.id);
    setIsLocked(row.isLocked);
    handlePropagation();
    // setOnRightClickData(row.source);
    let element = document.getElementById("source-td" + row.id);
    element?.addEventListener("contextmenu", handleContextMenu);
    // element?.addEventListener("paste", function (e: any) {
    //   var text = e.clipboardData.getData("text/plain");
    //   document.execCommand("insertHTML", false, renderTags(text));
    //   e.preventDefault();
    // });
    let oldUndoH = [...appState.undoHistory];
    let findRow = oldUndoH.find((undoH) => undoH.id === row.id);
    if (findRow === undefined) {
      let rowHtml: any = document.getElementById("custom-editor" + row.id);
      // alert('s');
      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: row.id,
          history: rowHtml.innerHTML,
          state: row.state,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
        },
      });
    }
  };

  const handleBothSearches = (selectors: any, values: any, options: any) => {
    const [sourceText, targetText] = selectors;
    const [value, targetValue] = values;
    let totalMatches = 0;

    handleTextMarking(sourceText, value, {
      ...options,
      done: function (count: number) {
        totalMatches += count;
      }
    });
    handleTextMarking(targetText, targetValue, {
      ...options,
      done: function (count: number) {
        totalMatches += count;
      }
    });
    setTranslationDataCount(totalMatches);
  }

  const handleTextMarking = (selector: any, value: any, options: any) => {
    var context = document.querySelectorAll(selector);
    var instance = new Mark(context);
    instance.unmark({
      done: function () {
        if (value !== "")
          instance.mark(value, options);
      }
    })
  }

  const searchTargetText = (e: any) => {
    setTargetValue(e.target.value);
  };

  const searchSourceText = (e: any) => {
    setValue(e.target.value);
  };

  const [replaceValue, setReplaceValue] = useState("");
  const replaceText = (e: any) => {
    const newValue = e.currentTarget.value;
    setReplaceValue(newValue);
  };

  function escapeRegExp(string: any) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  const start = getQueryParam('start');
  const end = getQueryParam('end');

  const handleReplaceWith = async (e: any) => {
    // if (targetValue !== "") {
    //   let replacedData: any = await HandleReplaceWith({
    //     targetValue,
    //     translationData,
    //     replaceValue,
    //     markOption,
    //     onSaveSegments
    //   });
    //   if (replacedData && replacedData.length > 0) {
    //     setTranslationData(replacedData);
    //     handleBothSearches([".sourceText", ".targetText"], [value, targetValue], markOption)
    //   }
    // }

    let startParam: any = null;
    let endParam: any = null;

    if (typeof start != "undefined" && start) {
      startParam = parseInt(start);
    }
    if (typeof end != "undefined" && end) {
      endParam = parseInt(end);
    }

    setIsReplaceAll(false);

    let data: any = [];

    data = {
      projectId: headerData.id,
      fileId: appState.selectedFile.id,
      isReplaceAll: false,
      caseSensitive: false,
      find: targetValue,
      tl: appState.selectedLanguage,
      replace: replaceValue,
      isTrackOn: actionState.trackChanges,
      perPage: rowsPerPage,
      currentPage: currentPage,
      start: startParam || null,
      end: endParam || null,
      source: appState.searchFilters?.source,
      target: appState.searchFilters?.target,
      segmentFilters: appState.segmentFilters,
    }

    ReplaceSegmentTranslation({ variables: data });

  };

  const handleReplaceAll = async (e: any) => {
    // if (value !== "" || targetValue !== "") {
    //   let replacedData: any = await HandleReplaceAll({
    //     targetValue,
    //     translationData,
    //     replaceValue,
    //     markOption,
    //     onSaveSegments
    //   });
    //   if (replacedData && replacedData.length > 0) {
    //     setTranslationData(replacedData);
    //     handleBothSearches([".sourceText", ".targetText"], [value, targetValue], markOption)
    //   }
    // }
    let startParam: any = null;
    let endParam: any = null;

    if (typeof start != "undefined" && start) {
      startParam = parseInt(start);
    }
    if (typeof end != "undefined" && end) {
      endParam = parseInt(end);
    }
    setIsReplaceAll(true);

    let data: any = [];

    data = {
      projectId: headerData.id,
      fileId: appState.selectedFile.id,
      isReplaceAll: true,
      caseSensitive: false,
      find: targetValue,
      tl: appState.selectedLanguage,
      replace: replaceValue,
      isTrackOn: actionState.trackChanges,
      perPage: rowsPerPage,
      currentPage: currentPage,
      start: startParam || null,
      end: endParam || null,
      source: appState.searchFilters?.source,
      target: appState.searchFilters?.target,
      segmentFilters: appState.segmentFilters,
    }


    const result = await ReplaceSegmentTranslation({ variables: data });

    if (result && result.data && result.data.replaceSegmentTranslation) {
      const replacedData = result.data.replaceSegmentTranslation.data;
      const newData = appState.data.map((row: any) => {
        const replacedItem = replacedData?.find((item: any) => item.segmentId === row.id);
        if (replacedItem) {
          const translatedText = replacedItem.translatedText;
          row.target = translatedText;
          row.state = "draft"; // Update the status directly for the row
          row.isConfirmed = false;
        }
        return row;
      });

      appDispatch({ type: "SET_DRAFT", payload: { data: newData } });
      appDispatch({ type: "FILTERS", payload: { queryExecute: false, source: value, target: "", case: caseSensitive, isEmpty: appState.hitSearchFilters === 1 } });
    }
  };

  const handleClearFilters = (e: any) => {
    setValue("");
    setTargetValue("");
    setReplaceValue("");
    appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });
    appDispatch({ type: "FILTERS", payload: { queryExecute: true, source: '', target: '', case: caseSensitive, isEmpty: (appState.hitSearchFilters == 1) } });


    // var contxt = document.querySelectorAll(".targetText");
    // var instance1 = new Mark(contxt);
    // instance1.unmark(markOption);
    //
    // var contxt = document.querySelectorAll(".sourceText");
    // var instance1 = new Mark(contxt);
    // instance1.unmark(markOption);
  };

  const handleSearchFilters = (e: any) => {
    setcurretPage(1);

    setTempPage(1);

    appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: true });

    appDispatch({ type: "FILTERS", payload: { queryExecute: true, source: value, target: targetValue, case: caseSensitive, isEmpty: (appState.hitSearchFilters == 1) } });

  };



  const style = (): any => {
    return {
      WebkitUserModify: "read-only",
    };
  };

  if (error) {
    console.log('error ==>', error?.message);

    return <div>
      Error {error?.message}
    </div>
  }

  const handleChange = (event: any, textType: any) => {
    if (textType === 's') {
      setSourceText(event.target.value);
    }
    else {
      setTargetText(event.target.value);
    }
  };

  const addRow = () => {

    createTermBase();

    setTbRowData({
      source: sourceText,
      target: targetText,
      comment: tbComment
    });
  };

  // This function will allow only tags to drop 
  const handleDrop = (event: any, row: any) => {
    let sel = window.getSelection()?.toString();
    if (sel !== "" || row.isLocked) {
      event.preventDefault();
    } else if (!row.isLocked) {
      // After the tag is dropped
      appDispatch({
        type: "TAG_DROPPED", payload: row.id
      });
    }
  };

  const getCursorPosition = (e: any) => {
    let sel = document.getSelection();
    let pos: any = sel?.anchorOffset;
    localStorage.setItem('cursor_position', pos);

    let cordinates: any = { start: sel?.anchorOffset, end: sel?.focusOffset }
    localStorage.setItem('coordinates', JSON.stringify(cordinates));

    let selectedText: any = window.getSelection();
    localStorage.setItem('selection', selectedText);
  }

  const preventInput = (event: any) => {
    if (event.ctrlKey ||
      event.altKey ||
      event.metaKey ||
      event.key === 'F1' ||
      event.key === 'F2' ||
      event.key === 'F3' ||
      event.key === 'F4') {
      TargetKeyDown(event, appDispatch, appState, flag, menuData, setOpen, setToggle, mergeSegment, getCurrentSegment, setRestrictSave,
        setSnackBarOpen, setNotConfirmed, headerData, setMTAlert, changeTab, setValue, setTargetValue)
    }
    else {
      event.preventDefault();
    }
  }

  const handleCopySourceToTarget = (row: any) => {
    if (appState.selectedService.isAssigned !== 0 && !row.isLocked) {
      appDispatch({ type: 'COPY', payload: appState });

      let rowHtmlVal: any = document.getElementById("custom-editor" + appState.selectedRow.id);

      appDispatch({
        type: "ADD_HISTORY",
        payload: {
          id: row.id,
          history: rowHtmlVal.innerHTML,
          state: row.state,
          tStatusType: row.tStatusType,
          tStatusValue: row.tStatusValue,
          isCopy: 2
        },
      });
    }
  };

  const handleIconClick = (row: any) => {
    appDispatch({
      type: "SET_SELECTED",
      payload: row.id,
    });

    // Using a setTimeout to ensure the state is updated before calling the function
    setTimeout(() => handleCopySourceToTarget(row), 0);
  };

  const handlePasteText = (e: any) => {
    if (actionState.trackChanges) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');

      const selection: any = document.getSelection();
      const range = selection.getRangeAt(0);

      if (!selection.isCollapsed) {
        const selectedText = range.extractContents();
        const isAlreadyMarked = selectedText.querySelector('ins') !== null;

        if (!isAlreadyMarked) {
          const deletedText = document.createElement("del");
          deletedText.appendChild(selectedText);
          range.deleteContents();
          range.insertNode(deletedText);
        }

        const pastedNode = document.createElement("ins");
        pastedNode.innerHTML = renderTags(text);
        range.insertNode(pastedNode);
      }
      else {
        // No selection, insert the pasted text with <ins> tags
        const modifiedText = `<ins>${renderTags(text)}</ins>`;
        document.execCommand('insertHTML', false, modifiedText);
      }
    }
  }

  function CustomNoRowsOverlay() {
    return (
      <div className={classes.noRecordFoundContainer}>
        <svg
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Sorry! No Record Found</Box>
      </div>
    );
  }

  const getLanguageStyle = (str: string) => {
    const rightLang = ['arc', 'dv', 'fa', 'ha', 'he',
      'khw', 'ks', 'ku', 'ps', 'ur', 'yi', 'ar']
    const lang = str.split("-");
    if (rightLang.find((item: any) => item === lang[0])) {
      const styl: any = { direction: 'rtl', textAlign: 'right' };
      return styl;
    } else {
      const styl: any = { direction: 'ltr', textAlign: 'left' };
      return styl;
    }
  }

  const getSegmentTitle: any = (id: any) => {
    const matchedSeg = appState.segments.find((row: any) => row.sSegmentId === id);

    if (matchedSeg) {
      const messageList = matchedSeg.verifyResult.map((msg: any) => {
        if (msg.message) return <li style={{ marginLeft: '-2em', listStylePosition: "outside", padding: '0 !important', margin: '0 !important' }} key={msg.message}>{msg.message}</li>;
        return null;
      });

      if (messageList.length > 0) {
        return (
          <span>
            <ul style={{ padding: '0 !important', margin: '0 !important' }}>
              {messageList}
            </ul>
          </span>
        );
      }
    }
    return null;
  }

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    setNotConfirmed(false);
    setPasteAlert(false);
    setMTAlert(false);
    setMergeErrorSnackBar(false);
  };

  const handleTrackChange = (event: any, id: any) => {
    if ((event.key === "Backspace" || ((event.ctrlKey || event.metaKey) && event.key === "d")) && actionState.trackChanges) {
      const segment: any = document.getElementById("custom-editor" + id);
      if (segment) {
        setTimeout(() => {
          const delTagsWithImg = segment.querySelectorAll('del img');
          delTagsWithImg.forEach((img: any) => {
            const div = document.createElement('div');
            div.className = 'delClass';
            if (img.parentNode) {
              img.parentNode.insertBefore(div, img);
              div.appendChild(img);
            }
          });

          handleInput(event, appState.selectedRow)
        }, 200);
      }
    }
  };

  const multipleSelection = (e: any, row: any) => {
    let temp: any = [...appState.selectedSegments];

    if (e.ctrlKey) {
      const isRowSelected = temp.some((item: any) => item.id === row.id);
      if (isRowSelected) {
        temp = temp.filter((el: any) => el.id !== row.id);
      } else {
        temp.push(row);
      }
    } else if (e.shiftKey && firstSelectedRow) {
      temp = []
      const firstSelectedIndex = appState.data.findIndex((item: any) => item.id === firstSelectedRow.id);
      const endIndex = appState.data.findIndex((item: any) => item.id === row.id);

      if (firstSelectedIndex >= 0 && endIndex >= 0) {
        const start = Math.min(firstSelectedIndex, endIndex);
        const end = Math.max(firstSelectedIndex, endIndex);

        for (let i = start; i <= end; i++) {
          const selectedRow = appState.data[i];
          if (!temp.some((item: any) => item.id === selectedRow.id)) {
            temp.push(selectedRow);
          }
        }
      }
    } else {
      temp = [row];
    }

    appDispatch({
      type: 'MULTIPLE_SELECTION',
      payload: temp,
    });

    if (!e.ctrlKey) {
      setFirstSelectedRow(row);
    }
  };

  {/* To mark out XML tags in source: */ }
  const markXMLTags = (source: any) => {

    let updatedSource = source.replace(/&lt;p&gt;/g, '<span style="color:#b4b4b4">&lt;p&gt;</span>');
    updatedSource = updatedSource.replace(/&lt;(\/|&sol;)p&gt;/g, '<span style="color:#b4b4b4">&lt;\/p&gt;</span>');
    updatedSource = updatedSource.replace(/&lt;strong&gt;/g, '<span style="color:#b4b4b4">&lt;strong&gt;</span>');
    updatedSource = updatedSource.replace(/&lt;(\/|&sol;)strong&gt;/g, '<span style="color:#b4b4b4">&lt;\/strong&gt;</span>');
    updatedSource = updatedSource.replace(/&lt;br&gt;/g, '<span style="color:#b4b4b4">&lt;br&gt;</span>');
    updatedSource = updatedSource.replace(/&lt;(\/|&sol;)br&gt;/g, '<span style="color:#b4b4b4">&lt;\/br&gt;</span>');
    return updatedSource;
  }

  {/* To remove XML tags in target: */ }
  const renderXMLTags = (target: any) => {

    let updatedTarget = target.replace(/&lt;p&gt;/g, '');
    updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)p&gt;/g, '');
    updatedTarget = updatedTarget.replace(/&lt;strong&gt;/g, '');
    updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)strong&gt;/g, '');
    updatedTarget = updatedTarget.replace(/&lt;br&gt;/g, '');
    updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)br&gt;/g, '');

    return updatedTarget.trim();
  }


  return (
    <Box id="table-id">

      {show ? (
        <Menu
          id="context-menu"
          x={x} y={y}
          menuData={menuData}
          flag={flag}
          isLocked={isLocked}
          appDispatch={appDispatch}
          appState={appState}
          anchorPoint={anchorPoint}
          show={show}
          setOpen={setOpen}
          setToggle={setToggle}
          setRestrictSave={setRestrictSave}
          setLoad={setLoad}
          mergeSegment={mergeSegment}
          getCurrentSegment={getCurrentSegment}
          setPasteAlert={setPasteAlert}
          headerData={headerData}
          setMTAlert={setMTAlert}
        />
      ) : (
        ""
      )}
      <Paper className={classes.boxWrapTranslation} elevation={1}>
        {ReplaceSearch(
          classes,
          value,
          searchSourceText,
          searchTargetText,
          targetValue,
          replaceText,
          handleReplaceWith,
          handleCaseCheck,
          handleReplaceAll,
          handleClearFilters,
          handleSearchFilters,
          translationDataCount,
          markOption,
          appState,
          replaceValue,
          handleBothSearches,
          caseSensitive,
          loadingReplace
        )}

        <Divider />


        {appState?.selectedFile?.mtConverted == 0 &&
          <Box style={{
            background: "#cde5f8",
            padding: "15px",
            color: "#4949ce",
            textAlign: "center",
            textTransform: "uppercase"
          }}>
            Machine Translation in progress ...
          </Box>}

        <Box p={3}>
          {appState.translationDataLoading ? <Box style={{ minHeight: 300 }} display={'flex'} alignItems={"center"} justifyContent={"center"}><CircularProgress disableShrink /></Box> : <>
            <TableContainer className={classes.tableContainer}>
              <Table
                id="translation-table"
                className={`${classes.translationTable} custom-table ${actionState.showWhiteSpace ? 'space' : ''}`}
                aria-label="simple table"
              >
                <TableBody id="custom-table">
                  {/*{translationData && JSON.stringify(translationData[0].target)}*/}

                  {translationData && translationData.length == 1 && translationData[0].source == "" && <CustomNoRowsOverlay />}

                  {translationData &&
                    translationData.map((row: ISegment, ind: any) => (
                      <>
                        {row.source != "" ? <TableRow
                          onBlur={() => appDispatch({ type: "SET_HISTORY", payload: row.id })}
                          key={row.id}
                          className={`${appState.selectedSegments.some((item: any) => item.id === row.id) ? "active" : ""}`}
                          id={"row" + row?.id}
                        // onClick={() => appState.selected = row.id}
                        >
                          <TableCell
                            contentEditable={appState.selectedService.isAssigned === 1 ? true : false}
                            suppressContentEditableWarning={true}
                            id={"row-id" + row.id}
                            onFocus={(e: any) => { e.preventDefault(); handleFocusRowId(e, row); setPreviusTarget(row.target) }}
                            onMouseOver={() => { setLock(row.isLocked) }}
                            onClick={(e: any) => {
                              if (!e.ctrlKey) {
                                appDispatch({
                                  type: "SET_SELECTED", payload: row.id
                                });
                              }
                              handlePropagation();
                              setPreviusTarget(row.target);
                              multipleSelection(e, row)
                            }}
                            onKeyDown={(e: any) => preventInput(e)}
                            onMouseDown={() => { document.getElementById("row-id" + row.id)!.style.caretColor = "transparent" }}
                            className={classes.hideCursor}
                            onContextMenu={() => {
                              appDispatch({
                                type: "SET_SELECTED", payload: row.id
                              });
                              handlePropagation();
                              setPreviusTarget(row.target)
                            }}
                          >
                            <Box
                              style={style()}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="baseline"
                            >
                              <Box textAlign={'center'} className={classes.segId}>{row.key}</Box>


                              {row.isPropagationOn !== 2 && <Box marginLeft={"4px"} >
                                <IconButton
                                  style={{ padding: "8px" }}
                                  onClick={(e) => {

                                    // const updatedObjects = translationData.map((obj: any) => ({
                                    //   ...obj,
                                    //   isPropagationOn: obj.id == row.id ? (obj.isPropagationOn == 1 ? 0 : 1) : obj.isPropagationOn,
                                    // }));

                                    // // Update the state with the new array of objects
                                    // setTranslationData(updatedObjects);
                                    appDispatch({
                                      type: "UPDATE_PROPAGATION", payload: row.id
                                    });
                                    // row.isPropagationOn = row.isPropagationOn == 1 ? 0 : 1;

                                    // onSaveSegments(row, 1)
                                  }}
                                >
                                  <Icons.LinkOutlinedIcon
                                    color={
                                      row.isPropagationOn == 1
                                        ? "primary"
                                        : "disabled"
                                    }
                                    className="font_14"
                                  />
                                </IconButton>
                              </Box>}

                            </Box>
                          </TableCell>

                          <TableCell
                            className="sourceText"
                            id={"source-td" + row.id}
                            contentEditable={appState.selectedService.isAssigned === 1 ? true : false}
                            suppressContentEditableWarning={true}
                            onFocus={(e: any) => handleFocusSource(e, row)}
                            onDrop={(e) => e.preventDefault()}
                            onKeyDown={(e: any) => preventInput(e)}
                            onInput={(e: any) => handleInput(e, row)}
                            onClick={(e: any) => {
                              if (!e.ctrlKey) {
                                appDispatch({
                                  type: "SET_SELECTED", payload: row.id
                                });
                              }
                              handlePropagation();
                              multipleSelection(e, row)
                            }}
                            onContextMenu={() => {
                              appDispatch({
                                type: "SET_SELECTED", payload: row.id
                              });
                              handlePropagation();
                              setPreviusTarget(row.target)
                            }}
                          >
                            <div
                              style={style()}
                              dangerouslySetInnerHTML={{
                                __html: markXMLTags(renderTags(row.source)),
                              }}
                            />
                            <a
                              id={"copy" + row?.id}
                              onClick={() => { handleIconClick(row) }}>
                              <Icons.LogoutIcon
                                className={`${classes.sourceIcon} custom_icon`}
                                style={{ opacity: appState.selectedService.isAssigned === 0 ? 0.5 : 1 }}
                              />
                            </a>
                          </TableCell>

                          <TableCell
                            contentEditable={appState.selectedService.isAssigned === 1 ? true : false}
                            suppressContentEditableWarning={true}
                            id={"status-cell" + row.id}
                            onFocus={(e: any) => { e.preventDefault(); handleFocusStatusCell(e, row); setPreviusTarget(row.target) }}
                            onMouseOver={() => { setLock(row.isLocked) }}
                            onClick={(e: any) => {
                              if (!e.ctrlKey) {
                                appDispatch({
                                  type: "SET_SELECTED", payload: row.id
                                });
                              }
                              handlePropagation();
                              setPreviusTarget(row.target);
                              multipleSelection(e, row);
                            }}
                            onKeyDown={(e: any) => preventInput(e)}
                            className={classes.hideCursor}
                            onContextMenu={() => {
                              appDispatch({
                                type: "SET_SELECTED", payload: row.id
                              });
                              handlePropagation();
                              setPreviusTarget(row.target)
                            }}
                          >
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={style()}>
                              <Box>
                                {row.isLocked === true
                                  ? GetIcon(classes, "lock", "")
                                  :
                                  GetIcon(classes, row.state, row?.tStatusValue)}
                              </Box>

                              <Box>
                                {/* Conditionally render the loading icon */}
                                {load && row.id === lastFocusedRow && changedRows.includes(row.id) ? (
                                  <LightTooltip placement="bottom" title={'Saving...'}>
                                    <CircularProgress
                                      variant="indeterminate"
                                      disableShrink
                                      className={classes.top}
                                      classes={{
                                        circle: classes.circle,
                                      }}
                                      size={20}
                                      thickness={4}
                                    />
                                  </LightTooltip>
                                ) : (
                                  row.tStatusType === 'MT' || row.tStatusType === 'cMT' ? (
                                    <LightTooltip placement="bottom" title={headerData && headerData.engines.find((itm: any) => (itm.sl == headerData?.sl?.code && itm.tl == appState.selectedLanguage)).engines[0]}>
                                      <div>
                                        {GetIcon(classes, row?.tStatusType, row?.tStatusValue)}
                                      </div>
                                    </LightTooltip>
                                  ) : (
                                    <LightTooltip placement="bottom" title={row?.tStatusValue && JSON.parse(row?.tStatusValue)?.from}>
                                      <div>
                                        {GetIcon(classes, row?.tStatusType, row?.tStatusValue)}
                                      </div>
                                    </LightTooltip>
                                  )
                                )}
                              </Box>

                              <Box className={classes.loading}>

                                {(changedRows.includes(row.id) && !save && row.id === appState.selected && row.id !== lastFocusedRow) ? (

                                  <LightTooltip placement="bottom" title={'Unsaved'}>
                                    <MoreHorizIcon style={{ color: "#f77d3d" }} />
                                  </LightTooltip>

                                  // (load && row.id === lastFocusedRow) ? (
                                ) :
                                  (load && row.id === lastFocusedRow && !changedRows.includes(row.id)) ? (
                                    <LightTooltip placement="bottom" title={'Saving...'}>
                                      <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        className={classes.top}
                                        classes={{
                                          circle: classes.circle,
                                        }}
                                        size={20}
                                        thickness={4}
                                      />
                                    </LightTooltip>
                                  ) :
                                    saveErrors.includes(row.id) ? (
                                      <div
                                        onClick={() => onSaveSegments(row, 0)}
                                        style={{ cursor: 'pointer' }}
                                        title={'Save'}
                                      >
                                        <SaveIcon style={{ color: "#f77d3d", cursor: 'pointer' }} />
                                      </div>)
                                      : null
                                }

                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell
                            className="targetText"
                            id={"custom-editor" + row.id}
                            style={getLanguageStyle(row.tl)}
                            suppressContentEditableWarning={true}
                            contentEditable={appState.selectedService.isAssigned === 1 ? true : false}
                            onFocus={(e: any) => { handleFocus(e, row); setPreviusTarget(row.target) }}
                            onDrop={(e) => handleDrop(e, row)}
                            onKeyDown={(e: any) => {
                              handleTrackChange(e, row.id); setLastFocusedRow(row.id);
                              TargetKeyDown(e, appDispatch, appState, flag, menuData, setOpen, setToggle, mergeSegment, getCurrentSegment, setRestrictSave,
                                setSnackBarOpen, setNotConfirmed, headerData, setMTAlert, changeTab, setValue, setTargetValue)
                            }}
                            onClick={(e: any) => {
                              appDispatch({
                                type: "SET_SELECTED", payload: row.id
                              });
                              {/*appState.selected = row.id;*/ }
                              {/*handlePropagation();*/ }
                              setPreviusTarget(row.target);
                            }}
                            onContextMenu={() => {
                              appDispatch({ type: "SET_SELECTED", payload: row.id });
                              {/*appState.selected = row.id;*/ }
                              handlePropagation();
                              setPreviusTarget(row.target)
                            }}
                            // onMouseEnter={() => { setCaretPos(row); }}
                            // onKeyUp={(e: any) => { e?.preventDefault(); }}
                            onBlur={async (e) => {
                              if (restrictSave || row.state === "translated" || row.state === "reviewed") {
                                e?.preventDefault();
                                setRestrictSave(true);
                              } else {
                                e?.preventDefault();
                                setRestrictSave(false);

                                await onSaveSegments(translationData[ind], 0);
                                setLastFocusedRow(row.id);
                              }
                            }}

                            onInput={(e: any) => {
                              if (row.state !== "draft") {
                                checkDraft(row);
                              }
                              handleInput(e, row)
                            }}
                            onSelect={() => HandleFormatButtons(row.id, appState)}
                            onMouseOver={() => { setLock(row.isLocked) }}
                            onPaste={(e) => handlePasteText(e)}
                          >
                            {/* {row.isLocked ?
                              <div
                                className={'disableInput'}
                                dangerouslySetInnerHTML={{
                                  __html: renderTags(row.target),
                                }}
                              /> : */}
                            <span className={classes.spanStyle} style={getLanguageStyle(appState.selectedLanguage).direction == 'rtl' ? { display: 'contents' } : {}}
                              dangerouslySetInnerHTML={{
                                __html: renderXMLTags(renderTags(row.target).replace(/<meta[^>]+>/g, "")),
                              }}
                            />
                            {/* } */}
                          </TableCell>

                          <TableCell
                            contentEditable={appState.selectedService.isAssigned === 1 ? true : false}
                            suppressContentEditableWarning={true}
                            id={"info-cell" + row.id}
                            onContextMenu={(e: any) => {
                              appDispatch({
                                type: "SET_SELECTED", payload: row.id
                              });
                              handlePropagation();
                              setPreviusTarget(row.target);
                              if (appState.selectedService.isAssigned === 0) {
                                e.stopPropagation();
                                return;
                              }
                              e.stopPropagation();
                              e.preventDefault();
                              handleContextMenu(e);
                              handleFocusInfo(e, row);
                            }}
                            onKeyDown={(e: any) => preventInput(e)}
                            onMouseOver={() => { setLock(row.isLocked) }}
                            onClick={(e: any) => {
                              if (!e.ctrlKey) {
                                appDispatch({
                                  type: "SET_SELECTED", payload: row.id
                                });
                              }
                              handlePropagation();
                              setPreviusTarget(row.target);
                              handleFocusInfo(e, row);
                              multipleSelection(e, row);
                            }}
                            className={classes.hideCursor}
                          >
                            <Box
                              style={style()}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              className={classes.qaIcons}
                            >
                              <LightTooltip placement="bottom-end" title={t('translationTable.askQuestion') as any} interactive>
                                <Icons.HelpOutlineRoundedIcon
                                  style={{ opacity: appState.selectedService.isAssigned === 0 ? 0.5 : 1, color: appState.segmentId.includes(row.id + "") ? '#3d80c3' : '' }}
                                  onClick={appState.selectedService.isAssigned === 0 ? undefined : (e) => {
                                    e.stopPropagation();
                                    appDispatch({
                                      type: "SET_SELECTED", payload: row.id
                                    });
                                    handlePropagation();
                                    changeTab(row.id)
                                  }}
                                />
                              </LightTooltip>

                              {getSegmentTitle(row.id) &&

                                <LightTooltipError placement="top-start" title={getSegmentTitle(row.id)}>
                                  <Icons.WarningIcon color="primary" />
                                </LightTooltipError>
                              }
                            </Box>
                          </TableCell>
                        </TableRow > : ''}

                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {translationData && translationData.length > 0 && translationData[0].source !== "" &&
              <div className={classes.root}>
                <Box className={classes.pagination}>
                  {/* <Box visibility={'hidden'}>
                    Rows per page:0
                  </Box> */}

                  <Box>
                    <>
                      {(totalPages && totalPages > 1) ?
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePage}
                          shape="rounded" /> : <></>}
                    </>
                  </Box>

                  {/* <Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography className={classes.perPage}>
                        Rows per page:
                      </Typography>

                      <Select
                        className={classes.paginationSelect}
                        native
                        value={rowsPerPage}
                        onChange={e => { handleChangeRowsPerPage(e) }}
                        label="Select rows"
                        inputProps={{
                          name: 'Rows',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        <option value={10} >10</option>
                        <option value={25} >25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Select>
                    </Box>
                  </Box> */}
                </Box>
                {/*: <></>}*/}
              </div>}
          </>}

        </Box>
      </Paper >

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.lastSegMerge')} anchorOrigin={{ vertical, horizontal }} open={snackBarOpen}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {!(getCurrentSegment().length > 0) && appState.selected ? t('editorMenu.lastSegMerge') : t('editorMenu.selectToMerge')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.emptyCannotConfirmed')} anchorOrigin={{ vertical, horizontal }} open={notConfirmed}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('editorMenu.emptyCannotConfirmed')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('menu.pasteShortcut')} anchorOrigin={{ vertical, horizontal }} open={pasteAlert}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('menu.pasteShortcut')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={t('editorMenu.mtEnableAlert')} anchorOrigin={{ vertical, horizontal }} open={mtAlert}
        autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {t('editorMenu.mtEnableAlert')}
        </Alert>
      </Snackbar>

      <Snackbar className={classes.snackBarStyles} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={mergeErrorSnackBar}
        autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
          {mergeError}
        </Alert>
      </Snackbar>

      <CustomSnackbar
        error={gqlError}
        open={gqlErrorSnackBar}
        onClose={handleErrorSnackBarClose}
      />
    </Box >
  );
};

export default React.memo(TranslationTable);
