import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import { ScrollDialog } from 'src/material-ui';
import useStyles, { PrettoSlider } from './HeaderStyles';
import { useTranslation } from 'react-i18next';
interface IProps {
    open: boolean,
    themeType: string,
    setOpen: Function,
    setThemeType: Function,
    appDispatch: Function
}

const SettingModal = ({ open, setOpen, themeType, setThemeType, appDispatch }: IProps) => {
    const classes = useStyles();
    const [size, setSize]: any = useState(14);
    const [panelSize, setPanelSize]: any = useState(14);
    const [mode, setMode] = React.useState(themeType);
    const { i18n } = useTranslation();
    const [laguage, setLanguage] = React.useState("en");
    const { t } = useTranslation();

    useEffect(() => {
        if (open) {
            let themeValues: any = localStorage.getItem('themeValues');
            if (themeValues) {
                themeValues = JSON.parse(themeValues);
                const newSize = themeValues.size < 14 ? 14 : themeValues.size;
                const newPanelSize = themeValues.panelSize < 14 ? 14 : themeValues.panelSize;
                setSize(newSize);
                setPanelSize(newPanelSize);
                setMode(themeValues.mode);
            }
        }
    }, [open]);

    const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('==>', e?.target?.value);

        if (e?.target?.value) {
            setLanguage(e?.target?.value);
            i18n.changeLanguage(e.target.value);
        }
    }

    const handleSizeChange: any = (e: any) => {
        if (e.target.value > 36) {
            setSize(36);
        }
        else {
            setSize(e.target.value)
        }
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
            e.preventDefault();
        }
    }

    const handlePanelSizeChange: any = (e: any) => {
        if (e.target.value > 36) {
            setPanelSize(36);
        }
        else {
            setPanelSize(e.target.value)
        }
    }

    const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMode(e.target.value)
    }

    const handleSubmit: any = () => {
        setThemeType(mode);
        appDispatch({
            type: 'SET_FONT_SIZE',
            payload: { size, panelSize }
        })
        localStorage.setItem('themeValues', JSON.stringify({ size, panelSize, mode }));
    }

    return (
        <ScrollDialog title={t('header.settings')} open={open} setOpen={setOpen} handleSubmit={handleSubmit}>
            <Box minWidth={450} px={2.5} pt={3} pb={.75}>
                <Box display={'flex'} alignItems={'center'} mb={3}>
                    <Box>
                        <Typography component={'span'} className={classes.modalContentTitle}>{t('header.fontSize')}:</Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography component={'span'} className={classes.modalContentTitle}>{t('header.translationTable')}:</Typography>
                    </Box>

                    <Box ml={1.5} className={classes.typeRadioWrap}>
                        {/* <PrettoSlider
                            value={size}
                            onChange={handleSizeChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            max={36}
                            min={8}
                        /> */}
                        <TextField
                            size="small"
                            variant="outlined"
                            id="outlined-number"
                            type="number"
                            value={size}
                            defaultValue={14}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e: any) => handleSizeChange(e)}
                            InputProps={{
                                inputProps: {
                                    min: 14,
                                    max: 36,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.fontFeild}
                        />
                    </Box>

                    <Box ml={3}>
                        <Typography component={'span'} className={classes.modalContentTitle}>{t('header.panels')}:</Typography>
                    </Box>

                    <Box ml={1.5} className={classes.typeRadioWrap}>
                        <TextField
                            size="small"
                            variant="outlined"
                            id="outlined-number"
                            type="number"
                            value={panelSize}
                            defaultValue={14}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e: any) => handlePanelSizeChange(e)}
                            InputProps={{
                                inputProps: {
                                    min: 14,
                                    max: 36,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.fontFeild}
                        />
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box>
                        <Typography component={'span'} className={classes.modalContentTitle}>{t('header.editorMode')}:</Typography>
                    </Box>
                    <Box ml={2} className={classes.typeRadioWrap}>
                        <FormControl>
                            <RadioGroup name="mode" value={mode} onChange={handleModeChange}>
                                <FormControlLabel value="green" control={<Radio />} label={t('header.greenMode')} />
                                <FormControlLabel value="light" control={<Radio />} label={t('header.normalMode')} />
                                <FormControlLabel value="dark" control={<Radio />} label={t('header.darkMode')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                {/* <Box mt={2}>
                    <Typography component={'span'} className={classes.modalContentTitle}>{t('header.editorLanguage')}</Typography>
                    <Box className={classes.typeRadioWrap}>
                        <FormControl>
                            <RadioGroup name="laguage" value={laguage} onChange={handleLanguageChange}>
                                <FormControlLabel value="en" control={<Radio />} label="English" />
                                <FormControlLabel value="zh" control={<Radio />} label="中国人" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box> */}
            </Box>
        </ScrollDialog>
    )
}

export default SettingModal;