import { gql } from '@apollo/client';

const SAVE_QA_REPORT = gql`mutation SaveQAReport($data: QAReportRequest){
    saveQAReport(data: $data){
      status
      error
  }
 
  }`;

export default SAVE_QA_REPORT;