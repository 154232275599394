import { IAppState, IHistory, ISegment } from "./interfaces/types/SegmentType";
import { renderTags, stringToHTML } from "./helpers";
import { MakePropArray } from "./components";

// import $ from 'jquery';

interface IAction {
  type: string;
  payload: any;
  pagination?: any;
  searchCounts?: any;
  segments?: any;
}

export const initialState: IAppState = {
  undoHistory: [],
  redoHistory: [],
  data: [],
  pagination: [],
  segments: [],
  segmentId: [],
  showCode: false,
  showWhiteSpace: false,
  filters: [],
  selected: 0,
  formatter: { format: [], active: [] },
  concordSearch: { expanded: false, flag: "s", text: "" },
  addNewTerm: { expanded: false, flag: "", text: "" },
  user_type: { translate: false },
  selectedRow: {},
  searchFilters: {},
  selectedService: {},
  selectedLanguage: {},
  selectedFile: {},
  searchCounts: {},
  hitSearchFilters: 0,
  hitCopyAllPages: 0,
  hitPreTranslationAllPages: 0,
  hitMachineTranslationAllPages: 0,
  hitTrackChanges: 0,
  translationDataLoading: true,
  expandReplace: false,
  segmentFilters: {},
  currentUser: {},
  projectSettings: [],
  isConfirmed: 0,
  isActionStateChanged: 0,
  qaReport: [],
  selectedSegments: [],
  hitHistory: false
};

let focus: any;

let handlePropagation: Function;
let saveSegment: Function;
let menuData: Function;
let isTrackChanges: any;
let currentPage: any;

const setDefaultSelection = (state: IAppState, action: IAction) => {
  handlePropagation = action?.payload.handleProp;
  saveSegment = action?.payload.handleSav;
  currentPage = action?.payload.currentPage;
  menuData = action.payload.menuData;
  isTrackChanges = action?.payload.isTrackChanges;

  return { ...state };
};

const setSelected = (state: IAppState, action: IAction) => {
  handlePropagation = action?.payload.handleProp;
  saveSegment = action?.payload.handleSav;
  focus = state.selected;
  currentPage = action?.payload.currentPage;
  menuData = action.payload.menuData;
  isTrackChanges = action?.payload.isTrackChanges;
  let r: any = state.data.find((item: any) => item.id === focus);
  if (r) {
    // state.selectedSegments = [r]
    state.selectedRow = r;
  }

  return { ...state };
};

const setSelectedService = (state: IAppState, action: IAction) => {

  state.selectedService = action?.payload;

  return { ...state };
};

const setQAReport = (state: IAppState, action: IAction) => {

  state.qaReport = action?.payload;

  return { ...state };
};

const setTranslationDataLoading = (state: IAppState, action: IAction) => {

  if (!(Number(state.selected) > 0)) {
    state.selected = 0;
  }

  state.translationDataLoading = action?.payload;

  return { ...state };
};

const setExpandReplace = (state: IAppState, action: IAction) => {
  state.expandReplace = action?.payload;
  return { ...state };
};

const setActionStateChanged = (state: IAppState, action: IAction) => {
  state.isActionStateChanged = action?.payload;
  return { ...state };
};

const setSegmentFilters = (state: IAppState, action: IAction) => {

  state.segmentFilters = action?.payload;

  return { ...state };
};

const setCurrentUser = (state: IAppState, action: IAction) => {

  state.currentUser = action?.payload;

  return { ...state };
};

const setProjectSettings = (state: IAppState, action: IAction) => {

  state.projectSettings = action?.payload;

  return { ...state };
};

const updateTranslationData = (state: IAppState, action: IAction) => {

  let updatedTranslation = action?.payload.target;
  let sId = action?.payload.sId;
  let tl = action?.payload.tl;

  let updatedData: any = [];
  state.data.forEach((row: ISegment) => {

    if (row.sSegmentId == sId && row.tl == tl) {
      row.target = updatedTranslation;
    }

    updatedData.push(row);
  });

  state.data = updatedData;

  return { ...state };
};

const setFilters = (state: IAppState, action: IAction) => {

  state.searchFilters = action?.payload;

  return { ...state };
};

const setQueryExecutionFilters = (state: IAppState, action: IAction) => {

  state.searchFilters.queryExecute = action?.payload;

  return { ...state };
};

const setSelectedLanguage = (state: IAppState, action: IAction) => {

  state.selectedLanguage = action?.payload;

  return { ...state };
};

const setSelectedFile = (state: IAppState, action: IAction) => {

  state.selectedFile = action?.payload;

  return { ...state };
};

const setHitSearchFilters = (state: IAppState, action: IAction) => {

  state.hitSearchFilters = action?.payload;

  return { ...state };
};

const checkPropagation = (state: IAppState) => {
  let temp2: any = [];
  for (let index = 0; index < state?.data.length; index++) {
    const element = state.data[index];
    let propFlag = 0;
    state.data.forEach((element2: any) => {
      if (element.source === element2.source) propFlag++;
    });
    if (propFlag > 1) {
      temp2.push({ propagation: true, id: element.id, source: element.source });
    }
  }
  let res = MakePropArray(temp2);
  return res;
};

const undoRedoPropagtion = (state: IAppState, rowToSave: any) => {

  const propagaionData: any = checkPropagation(state);
  const idArray: any = [];
  const row = rowToSave;

  const seg: any = document.getElementById("custom-editor" + row.id);
  let rowHTML = seg.innerHTML;

  propagaionData.forEach((elem: any) => {
    elem.forEach((el: any) => {
      if (el.id !== row.id)
        if (el.source === row.source && el.propagation === true)
          idArray.push(el.id);
    });
  });
  if (idArray.length !== 0)
    propagaionData.forEach((elem: any) => {
      elem.forEach((el: any) => {
        if (el.id === row.id && el.propagation === true) {
          for (let i = 0; i < idArray.length; i++) {
            // if (idArray[i] !== row.id) {
            state.data.forEach((item: any) => {
              if (item.id === idArray[i] && !item.isLocked) {
                let editorHTML = document.getElementById(
                  "custom-editor" + idArray[i]
                );
                if (editorHTML && row.isPropagationOn == 1 && item.isPropagationOn == 1) {
                  editorHTML.innerHTML = rowHTML;
                  item.target = rowHTML;
                  item.state = row.state;
                  item.tStatusType = row.tStatusType;
                  item.tStatusValue = row.tStatusValue;
                }
              }
            });
            // }
          }
        }
      });
    });
}

const appendTargetChild = (t: any, id: string | number) => {
  let editor = document.getElementById("custom-editor" + id);
  if (editor) {
    editor.innerHTML = renderTags(t.replaceAll(/<img\s+alt="(.*?)"[^>]*>/g, (_: any, altValue: any) => altValue));
    // editor.innerHTML = "";
    // editor.appendChild(t);
  }
};

const setHisotry = (state: IAppState, action: IAction) => {
  let undoHistory = state.undoHistory;
  let rowId = action.payload;
  if (undoHistory.length > 0 && undoHistory[undoHistory.length - 1].id === rowId
    && undoHistory.filter((item: any) => item.id === rowId).length >= 1) {
    undoHistory.pop();
  }
  state.undoHistory = undoHistory;
  return state;
};

const addHisotry = (state: IAppState, action: IAction) => {

  focus = action.payload.id;
  let maxLimit = state.redoHistory.length + state.undoHistory.length;
  if (maxLimit > 60) {
    state.undoHistory.shift();
  }
  let historyPayload = action.payload;

  let selectedHistory = state.undoHistory.filter((it: any) => it.id == focus);

  if (selectedHistory.length == 0 && (historyPayload.history == '<div></div>' || historyPayload.history == '<span></span>')) {
    historyPayload.history = " ";
  }
  else if (selectedHistory.length > 0 && selectedHistory[selectedHistory.length - 1].history == historyPayload.history) {
    return state;
  }

  if (historyPayload.isCopy === 1 && selectedHistory.length == 0 && (historyPayload.history != " " && historyPayload.history != "") && selectedHistory.filter((it) => it.history == " ").length == 0) {
    state.undoHistory = [...state.undoHistory, {
      id: historyPayload.id,
      history: " ",
      state: "",
      tStatusType: "",
      tStatusValue: "",
    }];
  }

  // if (historyPayload.isCopy === 2 && selectedHistory.length == 1 && (historyPayload.history != " " && historyPayload.history != "") && selectedHistory.filter((it) => it.history == " ").length == 1) {
  //   state.undoHistory = [...state.undoHistory, {
  //     id: historyPayload.id,
  //     history: " ",
  //     state: "",
  //     tStatusType: "",
  //     tStatusValue: "",
  //   }];
  // }

  if (historyPayload.isRemovePrev == 1) {
    state.undoHistory.pop();
    state.undoHistory.pop();
  }

  if (historyPayload.isClear === 1 && (historyPayload.history !== " " && historyPayload.history !== "")) {
    state.undoHistory = [...state.undoHistory, {
      id: historyPayload.id,
      history: historyPayload.history,
      state: historyPayload.state,
      tStatusType: historyPayload.tStatusType,
      tStatusValue: historyPayload.tStatusValue,
    }];
    state.undoHistory = [...state.undoHistory, {
      id: historyPayload.id,
      history: " ",
      state: "",
      tStatusType: "",
      tStatusValue: "",
    }];
    return { ...state };
  }

  if (historyPayload.isMT === 1) {
    state.undoHistory = [...state.undoHistory, {
      id: historyPayload.id,
      history: " ",
      state: "",
      tStatusType: "",
      tStatusValue: "",
    }];

    return { ...state };
  }

  if (historyPayload.history == "") {
    historyPayload = {
      id: historyPayload.id,
      history: " ",
      state: historyPayload.state,
      tStatusType: historyPayload.tStatusType,
      tStatusValue: historyPayload.tStatusValue,
    };
  }

  state.undoHistory = [...state.undoHistory, historyPayload];

  return { ...state };
};

const undo = (state: IAppState) => {
  let undoHistory: IHistory[] = [...state.undoHistory];
  let redoHistory: IHistory[] = [...state.redoHistory];

  if (undoHistory.length > 0) {
    let lastUndoN: any, lastUndo: any = undoHistory.pop();
    lastUndoN = lastUndo;

    const row: any = document.getElementById("custom-editor" + lastUndo.id);

    let rowHtml = row.innerHTML;

    // Find all img tags and replace them with their alt values using regEx
    rowHtml = row.innerHTML.replaceAll(/<img\s+alt="(.*?)"[^>]*>/g, (_: any, altValue: any) => altValue);

    if (row && lastUndo.history == rowHtml) {
      lastUndoN = undoHistory.pop();
      // alert("SA")
    }

    redoHistory.push({
      id: lastUndo.id,
      history: rowHtml,
      state: state.data.find((row: any) => row.id === lastUndo.id)?.state || "",
      tStatusType: state.data.find((row: any) => row.id === lastUndo.id)?.tStatusType || "",
      tStatusValue: state.data.find((row: any) => row.id === lastUndo.id)?.tStatusValue || "",

    });
    appendTargetChild(lastUndoN.history, lastUndoN.id);

    state.data.forEach((item: any) => {
      if (item.id === lastUndoN.id) {
        item.state = lastUndoN.state;
        item.tStatusType = lastUndoN.tStatusType;
        item.tStatusValue = lastUndoN.tStatusValue;
        item.isConfirmed = false;
      }
    });

    const rowToSave: any = state.data.find((row: any) => row.id === lastUndoN.id);
    undoRedoPropagtion(state, rowToSave)
    saveSegment(rowToSave, 1);

  }

  return {
    ...state,
    undoHistory,
    redoHistory,
  };
};

const redo = (state: IAppState) => {
  let undoHistory: IHistory[] = [...state.undoHistory];
  let redoHistory: IHistory[] = [...state.redoHistory];

  if (redoHistory.length > 0) {
    let lastRedoN: any, lastRedo: any = redoHistory.pop();
    lastRedoN = lastRedo;

    const row: any = document.getElementById("custom-editor" + lastRedo.id);
    let rowHtml: any = row.innerHTML.replaceAll(/<img\s+alt="(.*?)"[^>]*>/g, (_: any, altValue: any) => altValue);

    if (row && lastRedoN.history == rowHtml) {
      lastRedoN = redoHistory.pop();
    }

    undoHistory.push({
      id: lastRedo.id,
      history: rowHtml,
      state: state.selectedRow.state,
      tStatusType: state.selectedRow.tStatusType,
      tStatusValue: state.selectedRow.tStatusValue,
    });
    appendTargetChild(lastRedoN.history, lastRedoN.id);

    state.data.forEach((item: any) => {
      if (item.id === lastRedoN.id) {
        item.state = lastRedoN.state;
        item.tStatusType = lastRedoN.tStatusType;
        item.tStatusValue = lastRedoN.tStatusValue;
        item.isConfirmed = lastRedoN.state === "translated" || lastRedoN.state === "reviewed" ? true : false;
      }
    });

    const rowToSave = state.data.find((row: any) => row.id === lastRedoN.id);
    // handlePropagation("", rowToSave);
    undoRedoPropagtion(state, rowToSave)

    saveSegment(rowToSave, 1);
  }

  return {
    ...state,
    undoHistory,
    redoHistory,
  };
};

const setFontSize = (state: IAppState, { size, panelSize }: any) => {
  if (size || panelSize) {
    state.data.forEach((row: ISegment) => {
      let editor = document.getElementById("custom-editor" + row.id)?.style;
      let sourceTd = document.getElementById("source-td" + row.id)?.style;

      let tabHeadings: any = document.getElementsByClassName("panelFont");

      if (tabHeadings) {
        for (let i = 0; i < tabHeadings.length; i++) {
          let tabHeading = tabHeadings[i] as HTMLElement;
          // alert(panelSize)
          tabHeading.style.fontSize = panelSize + "px";
        }
      }

      if (editor && sourceTd) {
        editor.fontSize = size + "px";
        sourceTd.fontSize = size + "px";
      }
    });
  }
  return state;
};

const showCode = (state: IAppState, action: IAction) => {
  let showCode = true;
  let flag = action.payload;
  if (!flag) {
    showCode = !state.showCode
  }

  if (showCode || flag) {
    state.data.forEach((row: ISegment) => {
      let editor: any = document.getElementById("custom-editor" + row.id);
      let imgTag = [...editor?.querySelectorAll("img")];
      let insTag = [...editor?.querySelectorAll("ins")];
      let delTag = [...editor?.querySelectorAll("del")];
      imgTag.forEach((element) => {
        element.outerHTML = element.getAttribute("alt");
      });
      insTag.forEach((element) => {
        element.outerHTML = `[ins:]${element.innerHTML}[:ins]`;
      });
      delTag.forEach((element) => {
        element.outerHTML = `[del:]${element.innerHTML}[:del]`;
      });
    });
  }
  else {
    state.data.forEach((row: ISegment) => {
      let editorHTML = document.getElementById("custom-editor" + row.id);
      if (editorHTML) {
        editorHTML.innerHTML = renderTags(editorHTML.innerHTML);
      }
    });
  }

  return {
    ...state,
    undoHistory: [],
    redoHistory: [],
    showCode: showCode,
  };
};

const setFocus = (segment: any) => {
  setTimeout(() => {
    const range = document.createRange();
    const selection: any = window.getSelection();
    range.selectNodeContents(segment);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }, 0);
}

function isTextWithinDel(nodesArray: any) {
  let insideDel = 0;
  for (const node of nodesArray) {
    if (node === "DEL") {
      insideDel++;
    } else if (node === "/DEL") {
      insideDel--;
    } else if (node === "#text" && insideDel === 0) {
      return false;
    }
  }
  return true;
}

function findNodesForDel(node: any, result: any) {
  if (node.nodeName === "#text" && node.data.trim() !== "") {
    result.push(node.nodeName);
  } else if (node.nodeName !== "#text") {
    result.push(node.nodeName);
  } node.childNodes.forEach((item: any) => findNodesForDel(item, result))
  if (node.nodeType === Node.ELEMENT_NODE) {
    result.push("/" + node.nodeName);
  }
  return result;
}

const copySourceToTarget = (state: IAppState, action: IAction) => {
  const data = action.payload?.data;
  let editorHTML: any;
  let rowsToSave: any = [];
  if (data) {
    data.forEach((row: ISegment) => {
      if ((row.id === focus || state.selectedSegments.some((item: any) => item.id === row.id)) && row.isLocked === false) {
        editorHTML = document.getElementById("custom-editor" + row.id);
        editorHTML.innerHTML = editorHTML.innerHTML.replace(/<br>/g, ''); // Remove <br> tags

        let result: any = [];
        const proofreadMode = isTextWithinDel(findNodesForDel(editorHTML, result)) && state.selectedService.code === "proofreading";

        // if (editorHTML && ((editorHTML.innerText.replace('\n', '') === " " || editorHTML.innerText.replace(/(<br>|&nbsp;)/g, '') === "") || proofreadMode)) {
        if (editorHTML) {

          // Uncomment if you want to render XML tags in target
          // let updatedTarget = row.source.replace(/&lt;p&gt;/g, '<p>');
          // updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)p&gt;/g, '<\/p>');
          // updatedTarget = updatedTarget.replace(/&lt;strong&gt;/g, '<strong>');
          // updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)strong&gt;/g, '<\/strong>');
          // updatedTarget = updatedTarget.replace(/&lt;br&gt;/g, '<br>');
          // updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)br&gt;/g, '<\/br');

          // Uncomment if you dont want to render XML tags in target
          let updatedTarget = row.source.replace(/&lt;p&gt;/g, '');
          updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)p&gt;/g, '');
          updatedTarget = updatedTarget.replace(/&lt;strong&gt;/g, '');
          updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)strong&gt;/g, '');
          updatedTarget = updatedTarget.replace(/&lt;br&gt;/g, '');
          updatedTarget = updatedTarget.replace(/&lt;(\/|&sol;)br&gt;/g, '').trim();

          editorHTML.innerHTML = renderTags(updatedTarget);
          row.target = row.source;

          // if (proofreadMode && isTrackChanges) {
          // editorHTML.innerHTML = editorHTML.innerHTML + "<ins>" + renderTags(row.source) + "</ins>";
          // row.target = editorHTML.innerHTML + "<ins>" + row.source + "</ins>";
          // }
          // else {
          //   editorHTML.innerHTML = isTrackChanges ? "<ins>" + renderTags(row.source) + "</ins>" : renderTags(row.source);
          //   row.target = isTrackChanges ? "<ins>" + row.source + "</ins>" : row.source;
          // }
          row.tStatusType = "";
          row.tStatusValue = "";
          row.isConfirmed = false;
          // addHisotry(state, {id: row.id, history: row.target, state: row.state})
          // state.undoHistory.push({ id: row.id, history: row.target, state: "draft" });

          rowsToSave.push(row);
          handlePropagation("", row);
        }
      }
    });
    saveSegment(rowsToSave, 1);
  }

  setFocus(editorHTML);

  return { ...state };
};

const handleIsPropagation = (state: IAppState, action: IAction) => {
  const data = action.payload?.data;
  const isPropagationOn = action.payload?.isPropagationOn;
  if (data)
    data.forEach((row: ISegment) => {
      if (row.id === focus) {

        row.isPropagationOn = isPropagationOn;
        handlePropagation("", row);

      }
    });

  // setFocus(editorHTML);
  return { ...state };
};

const copyTranslationDataToData = (state: IAppState, action: IAction) => {
  const data = action.payload?.data;
  if (data) {
    state.data = data;
  }

  return { ...state };
};

const copyAllSourceToTarget = (state: IAppState, action: IAction) => {
  const data = action.payload.data;
  let rowsToSave: any = [];
  data.forEach((row: any) => {

    if (row.isLocked === false) {
      let editorHTML = document.getElementById("custom-editor" + row.id);

      // if (editorHTML && editorHTML.textContent?.trim() === "") {
      if (editorHTML) {
        // editorHTML.innerHTML = isTrackChanges ? "<ins>" + renderTags(row.source) + "</ins>" : renderTags(row.source);
        editorHTML.innerHTML = renderTags(row.source);
        row.tStatusType = "";
        row.tStatusValue = "";
        // row.target = isTrackChanges ? "<ins>" + row.source + "</ins>" : row.source;
        row.target = row.source;
        row.state = "draft";
        row.isConfirmed = false;
      }
      rowsToSave.push(row);
    }
  });

  saveSegment(rowsToSave);

  {/* To add Loader on SaveSegment */ }
  state.selected = 0;
  state.translationDataLoading = action?.payload;

  return { ...state };
};

const copyAllPagesSourceToTarget = (state: IAppState, action: IAction) => {
  state.hitCopyAllPages = action.payload;
  // alert('sd1');??

  return { ...state };
};

const editorMachineTranslation = (state: IAppState, action: IAction) => {
  menuData("machineTranslation" + action.payload);
  return { ...state }
}

const preTranslationAllPagesSourceToTarget = (state: IAppState, action: IAction) => {
  state.hitPreTranslationAllPages = action.payload;
  // alert('sd1');??

  return { ...state };
};

const applyMachineTranslationOnAllPages = (state: IAppState, action: IAction) => {
  state.hitMachineTranslationAllPages = action.payload;
  return { ...state };
};

const acceptRejectAllSegments = (state: IAppState, action: IAction) => {
  state.hitTrackChanges = action.payload;
  // alert('sd1');??

  return { ...state };
};

const clearTranslation = (state: IAppState, action: IAction) => {
  const data = action.payload.data;
  let doc: any;
  let rowsToSave: any = [];
  data.forEach(async (row: ISegment) => {
    if ((row.id === focus || state.selectedSegments.some((item: any) => item.id === row.id)) && row.isLocked === false) {
      doc = document.getElementById("custom-editor" + row.id);
      if (doc) {
        if (isTrackChanges) {

          {/* To remove span tags to get focus after del tag */ }
          doc.innerHTML = doc.innerHTML?.replace(new RegExp(escapeRegExp('<span>'), 'g'), '')
            ?.replace(new RegExp(escapeRegExp('</span>'), 'g'), '')

          doc.innerHTML = doc.innerHTML.replace(/<ins\b[^<]*(?:(?!<\/ins>)<[^<]*)*<\/ins>/gi, "");
          doc.innerHTML = "<del contenteditable=false>" + doc.innerHTML + "</del> ";
          row.target = "<del contenteditable=false>" + doc.innerHTML + "</del> ";

          setTimeout(() => {
            const delTagsWithImg = doc.querySelectorAll('del img');
            delTagsWithImg.forEach((img: any) => {
              const div = document.createElement('div');
              div.className = 'delClass';
              if (img.parentNode) {
                img.parentNode.insertBefore(div, img);
                div.appendChild(img);
              }
            });

            handlePropagation('', state.selectedRow)
          }, 200);
        } else {
          doc.innerHTML = " ";
          row.target = "";
        }
        row.tStatusType = "";
        row.tStatusValue = "";
        row.isConfirmed = false;
        row.state = "";
        rowsToSave.push(row);
        handlePropagation("clear", row);
        // editorHTML.innerHTML = " ";
        // row.tStatusType = "";
        // row.tStatusValue = "";
        // row.target = "";
        // row.state = "draft";
        // saveSegment(row, 1);
      }
    }
  });
  saveSegment(rowsToSave, 1);
  if (doc) {
    setFocus(doc)
  }
  return { ...state };
};

const convertImgToAlt = (rowHTML: string): string => {
  // Create a temporary DOM element to manipulate the innerHTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = rowHTML;

  // Convert NodeList to an array to avoid live collection issues
  const imgTags = Array.from(tempDiv.getElementsByTagName('img'));

  // Replace all img tags with their alt values
  imgTags.forEach(img => {
    const altText = img.alt;
    img.replaceWith(document.createTextNode(altText));
  });

  return tempDiv.innerHTML;
}

const clearAllTranslation = (state: IAppState, action: IAction) => {
  const data = action.payload.data;
  let rowsToSave: any = [];
  if (data.some((item: any) => item.isLocked === false)) {
    data.forEach((row: any) => {
      if (row.isLocked === false) {
        let editorHTML: any = document.getElementById("custom-editor" + row.id);
        if (editorHTML) {
          if (isTrackChanges) {

            {/* To remove span tags to get focus after del tag */ }
            editorHTML.innerHTML = editorHTML.innerHTML?.replace(new RegExp(escapeRegExp('<span>'), 'g'), '')
              ?.replace(new RegExp(escapeRegExp('</span>'), 'g'), '')

            row.target = row.target?.replace(new RegExp(escapeRegExp('<span>'), 'g'), '')
              ?.replace(new RegExp(escapeRegExp('</span>'), 'g'), '')

            editorHTML.innerHTML = editorHTML.innerHTML.replace(/<ins\b[^<]*(?:(?!<\/ins>)<[^<]*)*<\/ins>/gi, "");
            row.target = editorHTML.innerHTML.replace(/<ins\b[^<]*(?:(?!<\/ins>)<[^<]*)*<\/ins>/gi, "");

            editorHTML.innerHTML = "<del contenteditable=false>" + editorHTML.innerHTML + "</del> ";
            row.target = "<del contenteditable=false>" + editorHTML.innerHTML.replace(/(?<!<del[^>]*.*?) (?![^>]*?<\/del>)/g, "&nbsp;") + "</del> ";

            // Convert img tags to alt values
            const updatedHTML = convertImgToAlt(editorHTML.innerHTML);
            editorHTML.innerHTML = updatedHTML;
            row.target = updatedHTML;
          }
          else {
            editorHTML.innerHTML = " ";
            row.target = "";
          }
          // editorHTML.innerHTML = '<span contenteditable class="editorjs"></span>';
          // editorHTML.innerHTML = ' ';
          row.tStatusType = "";
          row.tStatusValue = "";
          // row.target = "";
          row.isConfirmed = false;
          row.state = "";


          rowsToSave.push(row);
        }
      }
    });

    saveSegment(rowsToSave);

    {/* To add Loader on SaveSegment */ }
    state.selected = 0;
    state.translationDataLoading = action?.payload;
  }

  return { ...state };
};

const translationMatches = (state: IAppState, action: IAction) => {
  if (focus !== "") {
    const text = action.payload.translatedText;
    let from = action.payload?.__typename;
    const withTags = action.payload?.withTags;
    let flag = (from && from.includes("TM") ? "TM" : (from && from.includes("TB") ? "TB" : "MT"));
    isTrackChanges = action.payload.isTrackChanges;
    if (action.payload.matchRate === "MT") {
      flag = "MT"
    }
    state.data.forEach((row: ISegment) => {
      if (row.id === focus && row.isLocked === false) {
        let from = (flag === 'MT') ? action.payload.engine : (flag === 'TM') ? 'TM: ' + action.payload?.tmName : "TB";
        let doc: any = document.getElementById('custom-editor' + row.id);
        if (doc && from !== "TB") {
          row.target = text;
          row.tStatusType = flag;
          row.tStatusValue = JSON.stringify({ matchRate: action.payload?.matchRate, from: from });
          if (isTrackChanges) {
            doc.innerHTML = withTags ? "<ins>" + renderTags(text) + "</ins>" : "<ins>" + text.replace(/\{(\d+:|:\d+|\:\d+|\d+)\}/g, "").replace(/\s{2,}/g, " ") + "</ins>";
          } else {
            doc.innerHTML = withTags ? renderTags(text) : text.replace(/\{(\d+:|:\d+|\:\d+|\d+|:\d+:\d+|:\d+:\d+:|:\d+:)\}/g, "").replace(/\s{2,}/g, " ");
          }
        } else if (doc && from === "TB") {
          doc.innerHTML = isTrackChanges ? " " + doc.innerHTML + `<ins>${renderTags(text)}</ins>` : " " + doc.innerHTML + renderTags(text);
        }
        handlePropagation("fromTM", row);
        saveSegment(row, 1);
      }
    });
  }
  return { ...state };
};

const toggleConcordSearch = (state: IAppState, action: IAction) => {
  state.concordSearch.expanded = action.payload;
  return { ...state };
}

const concordanceSearch = (state: IAppState, action: IAction) => {

  const { text, flag } = action.payload;
  const selectedRow = state.selectedRow;

  if (selectedRow.isLocked === false) {
    state.concordSearch.expanded = true;
    state.concordSearch.flag = flag;
    state.concordSearch.text = text || "";
  }

  return { ...state };
};

const concordanceReplace = (state: IAppState, action: IAction) => {
  if (focus !== "") {
    const textToReplace = action.payload.text.toString();
    const replaceWith = action.payload.row.target.toString();
    if (textToReplace !== "") {
      let doc: any = document.getElementById("custom-editor" + focus);
      doc.innerHTML = doc?.textContent?.replace(textToReplace, replaceWith);
      state.data.forEach((element: ISegment) => {
        if (element.id === focus && element.isLocked === false) {
          element.target = doc?.textContent;
          element.state = "draft";
          if (element.tStatusType === 'TM' || element.tStatusType === 'TB' || element.tStatusType === 'MT') {
            element.tStatusType = 'c' + element.tStatusType;
          }
          handlePropagation(undefined, element);
          saveSegment(element, 1);
        }
      });
    }
  }

  return { ...state };
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const boldText = (state: IAppState) => {
  if (focus !== "") {
    state.data.forEach(element => {
      let target = element.target;
      if (element?.id === focus && element.isLocked === false) {
        let text = window?.getSelection()?.toString();
        if (text) {
          if (target.includes('<b>')) {
            let abc = target.split('<b>').join('');
            let xyz = abc.split('</b>').join('');
            element.target = xyz;
          } else {
            let replaceText = '<b>' + text + '</b>';
            element.target = target.replace(text, replaceText);
          }

        }
        else {
          if (target.includes('<b>')) {
            let abc = target.replace(new RegExp(escapeRegExp('<b>'), 'g'), '');
            let xyz = abc.replace(new RegExp(escapeRegExp('</b>'), 'g'), '');
            element.target = xyz;

          } else {
            let replaceText = '<b>' + target + '</b>';
            element.target = replaceText;
          }


        }

      }
    });

  }


  return { ...state };
}

const addNewTerm = (state: IAppState, action: IAction) => {
  if (focus !== "") {
    const text = action.payload.text;
    state.addNewTerm.expanded = !state.addNewTerm.expanded;
    if (text !== "") {
      state.addNewTerm.flag = action.payload.flag;
      state.addNewTerm.text = text;
    }
    else {
      state.addNewTerm.flag = "s";
      state.addNewTerm.text = "";
    }
  }
  return { ...state };
};

// const confirmData = (state: IAppState, action: IAction) => {
//   const rowsToSave = action.payload;
//   if (Array.isArray(rowsToSave)) {
//     rowsToSave.forEach((row: any) => {
//       if (row.isLocked === false) {
//         row.state = state.selectedService.code === 'proofreading' ? 'reviewed' : 'translated';
//         row.isConfirmed = true;
//       }
//     })
//   }
//   else {
//     if (rowsToSave.isLocked === false) {
//       rowsToSave.state = state.selectedService.code === 'proofreading' ? 'reviewed' : 'translated';
//       rowsToSave.isConfirmed = true;
//     }
//   }

//   saveSegment(rowsToSave, 1);

//   state.isConfirmed = 1;
//   return { ...state };
// };

const focusNextUntranslatedSegment = (state: any, currentSegId: any) => {

  let data = state.data;
  const currentIndex = data.findIndex((row: any) => row.id === currentSegId);
  let nextIndex = currentIndex + 1;


  while (nextIndex < data.length) {
    const nextSegment = data[nextIndex];
    if (nextSegment.state !== "translated" && nextSegment.state !== "reviewed" && !nextSegment.isLocked) {
      const nextSegmentElement = document?.getElementById("custom-editor" + nextSegment.id);
      setSelectedSegment(state, { type: 'SET_SELECTED', payload: nextSegment.id });

      setTimeout(() => {
        if (nextSegmentElement) {
          nextSegmentElement.focus();
          const range = document.createRange();
          const selection = window.getSelection();
          range.selectNodeContents(nextSegmentElement);
          range.collapse(false);
          selection?.removeAllRanges();
          selection?.addRange(range);
          focus = nextSegment.id;
        }
      }, 10);
      break;
    }
    nextIndex++;
  }


}

const isPropagatedSegment = (alreadyFoundProp: any, rowId: any, state: any, rowsLength: any) => {
  if ((state.selectedSegments.some((item: any) => item.id === rowId) || state.data.length === rowsLength) && alreadyFoundProp.some((item: any) => item.id === rowId)) {
    return true;
  } else {
    return false;
  }
}

const confirmData = (state: IAppState, action: IAction) => {
  const rowsToSave = action.payload;
  let alreadyFoundProp: any = [];
  let updateConfirm = false;
  let currentSegId = "";
  if (Array.isArray(rowsToSave)) {
    rowsToSave.forEach((row: any) => {
      if (!row.isLocked) {
        if (row.isPropagationOn === 1) {

          const currentRow: any = document.getElementById('custom-editor' + row.id);


          // Prevent AP status from appearing to first encountered propagated segments in selectedSegments
          if (!alreadyFoundProp.some((item: any) => item.source === row.source)) {
            alreadyFoundProp.push(row)
          }

          const matchingRows = state.data.filter(
            otherRow => (otherRow.isPropagationOn === 1 && row.source === otherRow.source && !otherRow.isLocked) || row.id === otherRow.id
          );

          matchingRows.forEach((matchingRow: any) => {
            matchingRow.state = state.selectedService.code === 'proofreading' ? 'reviewed' : 'translated';
            // if (!alreadyFoundProp.some((item: any) => item.id === matchingRow.id)) {
            // const rowTarget = document.getElementById("custom-editor" + row.id)?.innerHTML;
            // matchingRow.target = rowTarget;

            let editorHTML: any = document.getElementById(
              "custom-editor" + matchingRow.id
            );

            editorHTML.innerHTML = currentRow.innerHTML;
            matchingRow.target = currentRow.innerHTML;
            // matchingRow.state = row.state;
            // matchingRow.tStatusType = row.tStatusType;

            if (row.id !== matchingRow.id && isPropagatedSegment(alreadyFoundProp, row.id, state, rowsToSave.length)) {
              matchingRow.tStatusValue = row.tStatusValue;
              matchingRow.tStatusType = "AP";
            }

            // matchingRow.tStatusValue = "";
            // }
          });
        }
        else {
          row.state = state.selectedService.code === 'proofreading' ? 'reviewed' : 'translated';
          // update row target in case of loader
          if (rowsToSave.length > 10) {
            let editorHTML: any = document.getElementById(
              "custom-editor" + row.id
            );
            row.target = editorHTML.innerHTML;
          }
        }

        row.isConfirmed = true;
        updateConfirm = true;
        currentSegId = row.id;
      }
    });
  }
  else {
    if (rowsToSave.isLocked === false) {
      rowsToSave.state = state.selectedService.code === 'proofreading' ? 'reviewed' : 'translated';
      rowsToSave.isConfirmed = true;
      if (rowsToSave.isConfirmed === true) {
        updateConfirm = true;
      }
      currentSegId = rowsToSave.id;
    }
  }
  focusNextUntranslatedSegment(state, currentSegId);

  // Conditionally handle confirm all or confirm 11 or more segments
  if (rowsToSave.length > 10) {
    saveSegment(rowsToSave, 2);
    // To add Loader on ConfirmSegment
    state.selected = 0;
    state.translationDataLoading = action?.payload;
  }
  else {
    saveSegment(rowsToSave, 1);
  }

  if (updateConfirm) {
    state.isConfirmed = 1;
  }



  return { ...state };
};

const confirmBitUpdate = (state: IAppState, action: IAction) => {

  // if(state.isConfirmed == 0 || state.isConfirmed == 2) {
  state.isConfirmed = action.payload;
  // } else {
  //     state.isConfirmed = 2;
  // }


  return { ...state };
};

const moveToNextRange = (data: any, nextSegCount: number) => {
  const currentSegment: any = data.find((row: any) => row.segmentCount === nextSegCount);
  const nextSegment: any = document?.getElementById("custom-editor" + currentSegment.id);
  setTimeout(() => {
    if (nextSegment) {
      nextSegment.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(nextSegment);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
      focus = currentSegment.id;
    }
  }, 10);
}

const processDelTags = (element: HTMLElement, accept: boolean) => {
  const delElements: NodeListOf<HTMLElement> = element.querySelectorAll('del');
  delElements.forEach((delElement: HTMLElement) => {
    if (accept) {
      delElement.parentNode?.removeChild(delElement);
    } else {
      const nestedDivElements: NodeListOf<HTMLElement> = delElement.querySelectorAll('div');
      nestedDivElements.forEach((nestedDiv: HTMLElement) => {
        nestedDiv.outerHTML = nestedDiv.innerHTML;
      });
      delElement.outerHTML = delElement.innerHTML;
    }
  });
};


const handleTrackChanges = (id: any, shouldAccept: boolean) => {
  const div: any = document.getElementById("custom-editor" + id);

  // const delElementsWithDiv: any = div?.querySelectorAll('del > div');
  // if (!shouldAccept) {
  //   delElementsWithDiv.forEach((node: any) => {
  //     node.outerHTML = node?.innerHTML;
  //   });
  // }

  processDelTags(div, shouldAccept);

  ["ins", "del", "font", "u"].forEach(tag => {
    if (div) {
      let nodes = div.querySelectorAll(tag);
      while (nodes.length) {  // To handle double <ins> or <del> tags
        nodes.forEach((node: any) => {
          if (tag === 'del') {
            shouldAccept ? node?.parentNode.removeChild(node) : node.outerHTML = node?.innerHTML;
          } else if (tag === 'ins' || tag === "u" || tag === 'font') {
            shouldAccept ? node.outerHTML = node?.innerHTML : node?.parentNode.removeChild(node);
          }
        });
        nodes = div.querySelectorAll(tag); // Re-select nodes after modification
      }
    }
  });

  const updatedSegment = div?.innerHTML;
  return updatedSegment;
}

const removeSpanTags = (html: any) => {
  // Use a regular expression to remove all <span> tags and their content
  return html.replace(/<\/?span[^>]*>/gi, '');
};

const acceptRejectTrackChanges = (state: IAppState, action: IAction) => {
  const { data, isAccept, isMove, toAll } = action.payload;
  let allowed: boolean = false, rowsToSave: any = [];
  let newSegmentCount: number = 0;
  const totalSegments = state.data?.length || 0;
  const currentPage: any = state.pagination[0]?.currentPage;
  const processedRows = (currentPage - 1) * 50; // Assuming 50 rows per page

  state.data?.forEach((row: any, index: number) => {
    const adjustedIndex = index + processedRows; // Adjusting index based on current page
    const div = document.getElementById("custom-editor" + row.id);

    if ((row.id === state.selectedRow.id || state.selectedSegments.some((item: any) => item.id === row.id)) && row.isLocked === false) {
      if (!toAll && isMove && div) {
        const prevHtml = div.innerHTML;
        const newHtml = handleTrackChanges(row.id, isAccept);

        allowed = adjustedIndex + 1 < (totalSegments + ((currentPage - 1) * 50)); // Check if there is a next segment
        newSegmentCount = adjustedIndex + 2; // Since index is zero-based, next segment would be index + 1, and we need to add 1 more for zero-based correction

        if (prevHtml !== newHtml) {
          const spanHTML = removeSpanTags(newHtml);

          if (spanHTML.trim() === "") {
            row.tStatusType = '';
            row.tStatusValue = '';
          }
          rowsToSave.push(row)
        }
      }
    }

    if (!toAll && !isMove && div) {
      const prevHtml = div.innerHTML;
      const newHtml = handleTrackChanges(row.id, isAccept);
      const spanHTML = removeSpanTags(newHtml);

      if (prevHtml !== newHtml) {
        if (spanHTML.trim() === "") {
          row.tStatusType = '';
          row.tStatusValue = '';
        }
        rowsToSave.push(row);
      }
    }
  });

  if (rowsToSave.length > 0) {
    saveSegment(rowsToSave, 1);
  }
  if (isMove && allowed) {
    moveToNextRange(data, newSegmentCount);
  }
  return { ...state }
}

const unsetSelection = (state: IAppState) => {
  state.selected = 0;
  state.selectedRow = {};
  state.selectedSegments = []
  return { ...state }
}

const setDraft = (state: IAppState, action: IAction) => {
  const { data, id } = action.payload;

  const newData = state.data.map((row: any) => {
    if (row.id === id) {
      return { ...row, state: "draft" };
    } else {
      return row;
    }
  });

  return { ...state, data: newData };
}

const setSegmentId = (state: IAppState, action: IAction) => {
  const data = action.payload;

  return { ...state, segmentId: data };
}

const setSelectedSegment = (state: IAppState, action: IAction) => {

  const id = action.payload;
  state.selected = parseInt(id);

  let r: any = state.data.find((item: any) => item.id === parseInt(id));
  if (r) {
    state.selectedRow = r;
    if (state.selectedSegments.length < 2) {
      state.selectedSegments = [r]
    }
  }

  return { ...state };
}

const setTrackTM = (state: IAppState, action: IAction) => {

  isTrackChanges = action.payload;

  return { ...state };
}

const setMultipleSelection = (state: IAppState, action: IAction) => {
  state.selectedSegments = action.payload;
  return { ...state };
}

const updatePropagation = (state: IAppState, action: IAction) => {
  const rowId = action.payload;
  state.data.forEach((row: any) => {
    if (row.id === rowId) {
      row.isPropagationOn = row.isPropagationOn == 1 ? 0 : 1
      saveSegment(row, 1);
    }
  })
  return { ...state };
}

const handleTagDropped = (state: IAppState, action: IAction) => {
  const rowId = action.payload;
  state.data.forEach((row: any) => {
    if (row.id === rowId && !row.isLocked) {
      row.state = "draft";
      if (row.tStatusType === 'TM' || row.tStatusType === 'TB' || row.tStatusType === 'MT') {
        row.tStatusType = 'c' + row.tStatusType;

        if (row.target.trim == '') {
          row.tStatusType = '';
          row.tStatusValue = '';
        }
      } else if (row.tStatusType === 'HM') {
        row.tStatusType = 'cTM';
      }
    }
  })
  setSelectedSegment(state, { type: 'SET_SELECTED', payload: rowId });
  return { ...state };
}

const AppReducer = (state: IAppState, action: IAction) => {
  switch (action.type) {
    case "CONVERT_DATA":
      return {
        ...state,
        data: action.payload,
        selectedRow: action.payload[0],
        pagination: action.pagination,
        searchCounts: action.searchCounts,
        segments: action.segments,
      };
    case "CONFIRM_DATA":
      return confirmData(state, action);
    case "CONFIRM_BIT_UPDATE":
      return confirmBitUpdate(state, action);
    case "ADD_HISTORY":
      return addHisotry(state, action);
    case "SET_HISTORY":
      return setHisotry(state, action);
    case "RESET_HISTORY":
      return {
        ...state,
        undoHistory: [],
        redoHistory: [],
      };
    case "SET_FONT_SIZE":
      return setFontSize(state, action.payload);
    case "UNDO":
      return undo(state);
    case "REDO":
      return redo(state);
    case "SHOW_CODE":
      return showCode(state, action);
    case "WHITE_SPACES":
      // return showWhiteSpace(state);
      return state;
    case "BOLD":
      return boldText(state);
    case "COPY":
      return copySourceToTarget(state, action);
    case "HANDLE_PROPAGATION":
      return handleIsPropagation(state, action);
    case "COPY_ALL":
      return copyAllSourceToTarget(state, action);
    case "COPY_ALL_PAGES":
      return copyAllPagesSourceToTarget(state, action);
    case "PRE_TRANSLATION_ALL_PAGES":
      return preTranslationAllPagesSourceToTarget(state, action);
    case "MACHINE_TRANSLATION":
      return editorMachineTranslation(state, action);
    case "MACHINE_TRANSLATION_ALL_PAGES":
      return applyMachineTranslationOnAllPages(state, action);
    case "ACCEPT_ALL_CHANGES":
      return acceptRejectAllSegments(state, action);
    case "REJECT_ALL_CHANGES":
      return acceptRejectAllSegments(state, action);
    case "CLEAR":
      return clearTranslation(state, action);
    case "CLEAR_ALL":
      return clearAllTranslation(state, action);
    case "TM":
      return translationMatches(state, action);
    case "TOGGLE_CONCORDANCE":
      return toggleConcordSearch(state, action);
    case "CONCORDANCE_SEARCH":
      return concordanceSearch(state, action);
    case "CONCORDANCE_REPLACE":
      return concordanceReplace(state, action);
    case "ADD_NEW_TERM":
      return addNewTerm(state, action);
    case "SELECTED":
      return setSelected(state, action);
    case "FILTERS":
      return setFilters(state, action);
    case "QUERY_EXECUTE_FILTERS":
      return setQueryExecutionFilters(state, action);
    case "SELECTED_SERVICE":
      return setSelectedService(state, action);
    case "SELECTED_LANGUAGE":
      return setSelectedLanguage(state, action);
    case "SELECTED_FILE":
      return setSelectedFile(state, action);
    case "COPY_TRANSLATION_DATA_DATA":
      return copyTranslationDataToData(state, action);
    case "HANDLE_TRACK_CHANGES":
      return acceptRejectTrackChanges(state, action);
    case "SEARCH_HIT_FILTERS":
      return setHitSearchFilters(state, action);
    case "SET_TRANSLATION_DATA_LOADING":
      return setTranslationDataLoading(state, action);
    case "REPLACE":
      return setExpandReplace(state, action);
    case "UPDATE_TRANSLATION_DATA":
      return updateTranslationData(state, action);

    case "UPDATE_PROPAGATION":
      return updatePropagation(state, action);

    case "SET_QA_REPORT":
      return setQAReport(state, action);
    case "SET_SEGMENT_FILTERS":
      return setSegmentFilters(state, action);
    case "SET_CURRENT_USER":
      return setCurrentUser(state, action);
    case "SET_PROJECT_SETTINGS":
      return setProjectSettings(state, action);
    case "SET_ACTION_STATE_CHANGED":
      return setActionStateChanged(state, action);
    case "UNSET_SELECTED":
      return unsetSelection(state);
    case "SET_DRAFT":
      return setDraft(state, action);
    case "SET_SEGMENT_ID":
      return setSegmentId(state, action);
    case "SET_SELECTED":
      return setSelectedSegment(state, action);
    case "TM_TRACK":
      return setTrackTM(state, action);
    case "MULTIPLE_SELECTION":
      return setMultipleSelection(state, action);
    case "DEFAULT_SELECTION":
      return setDefaultSelection(state, action);
    case "TAG_DROPPED":
      return handleTagDropped(state, action);
    case "UPDATE_HISTORY":
      return {
        ...state,
        hitHistory: !state.hitHistory
      }

    default:
      return state;
  }
};

export default AppReducer;