
import { gql } from '@apollo/client';

const GET_FILE_PROGRESS = gql`query GetFileProgress($projectId:String!,$fileId:Int!,$tl:String!,$start: Int, $end: Int) {
    getFileProgress(projectId: $projectId, fileId: $fileId, tl: $tl, start: $start, end: $end){
        totalSegments
        totalTranslated
        totalReviewed
        percentTranslated
        percentReviewed
        totalSegmentsOriginal
    }
}`;
export default GET_FILE_PROGRESS;








