import { gql } from '@apollo/client';
const GET_CURRENT_USER_DETAILS = gql`query getCurrentUserDetails {
    getCurrentUserDetails {
        id
        name
        email
    }
  }`;

export default GET_CURRENT_USER_DETAILS;

