import { IAppState } from "src/interfaces";

const handleSelection = (row: any, appState: IAppState) => {
    let activate: any = [{ name: "BOLD", active: false },
    { name: "ITALIC", active: false },
    { name: "UNDERLINE", active: false },
    { name: "STRIKE_THROUGH", active: false },
    { name: "SUBSCRIPT", active: false },
    { name: "SUPERSCRIPT", active: false },]
    const parents: any = []
    const text: any = document.querySelector('#custom-editor' + row);
    const selection: any = window.getSelection();
    if (selection && selection.anchorNode && selection.focusNode) {
        const selectionStartsAtElement: any = selection.anchorNode.parentNode;
        const selectionEndsAtElement: any = selection.focusNode.parentNode;
        if (selectionStartsAtElement === text && selectionEndsAtElement === text) {
            console.log('no markup elements have been selected');
            return;
        }
        const areElementsSelectedInsideText = text.contains(selectionStartsAtElement) && text.contains(selectionEndsAtElement);
        if (areElementsSelectedInsideText) {
            let parentElementsForSelectionEnd;
            let parentElementsForSelectionStart;
            // get selection end elements
            if (selectionEndsAtElement && selectionEndsAtElement !== text) {
                parentElementsForSelectionEnd = [selectionEndsAtElement];
                let nextParentAtSelectionEnd: any = selectionEndsAtElement?.parentNode;
                while (nextParentAtSelectionEnd !== text) {
                    parentElementsForSelectionEnd.push(nextParentAtSelectionEnd);
                    nextParentAtSelectionEnd = nextParentAtSelectionEnd?.parentNode;
                }
            }
            // get selection start elements
            if (selectionStartsAtElement && selectionStartsAtElement !== text) {
                parentElementsForSelectionStart = [selectionStartsAtElement];
                let nextParentAtSelectionStart: any = selectionStartsAtElement.parentNode;
                while (nextParentAtSelectionStart !== text) {
                    parentElementsForSelectionStart.push(nextParentAtSelectionStart);
                    nextParentAtSelectionStart = nextParentAtSelectionStart.parentNode;
                }
            }

            parentElementsForSelectionStart?.forEach((item: any) => {
                let tag = item.localName
                if (!parents.includes(tag.toString())) {
                    if (tag === "b") {
                        parents.push("BOLD"); activate.forEach((i: any) => { if (i.name === "BOLD") i.active = true });
                    } else if (tag === "i") {
                        parents.push("ITALIC"); activate.forEach((i: any) => { if (i.name === "ITALIC") i.active = true });
                    } else if (tag === "u") {
                        parents.push("UNDERLINE"); activate.forEach((i: any) => { if (i.name === "UNDERLINE") i.active = true });
                    } else if (tag === "strike") {
                        parents.push("STRIKE_THROUGH"); activate.forEach((i: any) => { if (i.name === "STRIKE_THROUGH") i.active = true });
                    } else if (tag === "sub") {
                        parents.push("SUBSCRIPT"); activate.forEach((i: any) => { if (i.name === "SUBSCRIPT") i.active = true });
                    } else if (tag === "sup") {
                        parents.push("SUPERSCRIPT"); activate.forEach((i: any) => { if (i.name === "SUPERSCRIPT") i.active = true });
                    }
                }
            })
            parentElementsForSelectionEnd?.forEach((item: any) => {
                let tag = item.localName
                if (!parents.includes(tag.toString())) {
                    if (tag === "b") {
                        parents.push("BOLD"); activate.forEach((i: any) => { if (i.name === "BOLD") i.active = true });
                    } else if (tag === "i") {
                        parents.push("ITALIC"); activate.forEach((i: any) => { if (i.name === "ITALIC") i.active = true });
                    } else if (tag === "u") {
                        parents.push("UNDERLINE"); activate.forEach((i: any) => { if (i.name === "UNDERLINE") i.active = true });
                    } else if (tag === "strike") {
                        parents.push("STRIKE_THROUGH"); activate.forEach((i: any) => { if (i.name === "STRIKE_THROUGH") i.active = true });
                    } else if (tag === "sub") {
                        parents.push("SUBSCRIPT"); activate.forEach((i: any) => { if (i.name === "SUBSCRIPT") i.active = true });
                    } else if (tag === "sup") {
                        parents.push("SUPERSCRIPT"); activate.forEach((i: any) => { if (i.name === "SUPERSCRIPT") i.active = true });
                    }
                }
            })
            appState.formatter = { format: parents, active: activate };
        }
    }
};

export default handleSelection;