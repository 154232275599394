import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ProjectList from 'src/pages/projects/ProjectList';
import Home from 'src/pages/home/Home';
import CreateProject from 'src/pages/projects/CreateProject';
import HomeHeader from 'src/components/header/HomeHeader';
import AnswerQueryLog from 'src/components/layout/AnswerQueryLog';
import DefaultPage from 'src/pages/defaultPage/DefaultPage';


function Routing() {

    const withHomeHeader = (Component: any) => {
        return (props: any) => (
            <div>
                <HomeHeader {...props} />
                <Component {...props} />
            </div>
        );
    };

    const ProjectListWithHeader = withHomeHeader(ProjectList);
    const DefaultPageWithHeader = withHomeHeader(DefaultPage);
    const CreateProjectWithHeader = withHomeHeader(CreateProject);

    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/" element={<DefaultPageWithHeader />} />
                    <Route path="/projectList" element={<ProjectListWithHeader />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/create-project" element={<CreateProjectWithHeader />} />
                    <Route path="/questions" element={<AnswerQueryLog />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default Routing;