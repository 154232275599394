import { gql } from '@apollo/client';
const GET_ALL_PROJECTS = gql`query getAllProjects($page: Int) {
    getAllProjects(page: $page){
        total
        projects {
            id
            name
            sl {
                code
                name
            }
            tl {
                code
                name
            }
            files {
                filename
                url
                id
            }
            services {
                name
                code
            }
            date
            isAssigned
        }
    }
  }`;



export default GET_ALL_PROJECTS;

