// src/components/CustomSnackbar.tsx
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    snackBarStyles: {
        '& .MuiAlert-standardError': {
            backgroundColor: "#f44336",
            color: '#ffffff',
        }
    },
    alertIcon: {
        '& .MuiSvgIcon-root': {
            color: '#ffffff !important'
        }
    },
});

interface CustomSnackbarProps {
    error: string;
    open: boolean;
    onClose: (event?: React.SyntheticEvent, reason?: string) => void;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ error, open, onClose }) => {
    const classes = useStyles();

    return (
        <Snackbar
            className={classes.snackBarStyles}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
        >
            <Alert className={classes.alertIcon} onClose={onClose} severity="error">
                {error}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
