import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentBoxWrap: {
            border: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: '4px 4px 0 0',
            height: 'auto',
            padding: 0,
            cursor: 'auto',
            '& .content': {
                fontSize: 13,
                lineHeight: '22px',
            },
            '& .boxFooter': {
                // borderTop: '1px solid #E5E5E5',
                backgroundColor: theme.palette.grey[50],
                borderRadius: '4px 4px 0 0',
                cursor: 'auto',
            },
            '& .MuiAccordionSummary-content': {
                margin: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                width: '100%',
            },
        },
        answerBoxWrap: {
            borderLeft: '1px solid #E5E5E5',
            borderRight: '1px solid #E5E5E5',
            borderBottom: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: 'auto',
            padding: 0,
            borderRadius: '0 0 4px 4px',
            '& .content': {
                fontSize: 13,
                lineHeight: '22px',
            },
            '& .boxFooter': {
                // borderTop: '1px solid #E5E5E5',
                backgroundColor: theme.palette.grey[50],
                borderRadius: '4px 4px 0 0'
            },
            '& .MuiAccordionSummary-content': {
                margin: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                width: '100%',
            },
        },
        arrowIcon: {
            transition: 'transform 0.3s ease',
            color: '#4b8ac7'
        },
        rotateUp: {
            transform: 'rotate(180deg)',
        },
        questionBox: {
            padding: '13px 16px',
            cursor: 'auto',
            '& .content': {
                cursor: 'text',
                userSelect: 'text',
            },
        },
        qlAccordian: {
            width: '100%',
            cursor: 'auto',
        },
        qlAccordianDetail: {
            padding: 0,
        },
        replyCellWrap: {
            // borderTop: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: '0px 0px 0 0',
            '& .content': {
                fontSize: 13,
                lineHeight: '22px',
                padding: '13px 16px',
                display: 'block'
            },
            '& .boxFooter': {
                // borderTop: '1px solid #E5E5E5',
                backgroundColor: theme.palette.grey[50],
                borderRadius: '0'
            }
        },
        infoSmIcon: {
            fontSize: '16px !important',
            color: '#7d7d7de8',
            marginBottom: -3,
            marginRight: 2,
            cursor: 'auto',
        },
        editIcon: {
            fontSize: 16,
            marginRight: 8
        },
        deleteIcon: {
            fontSize: 16
        },
        textStatus: {
            fontSize: '12px !important',
            width: 'auto !important',
            height: 'auto !important',
            display: 'inline-block !important',
            padding: '3px 5px',
            borderRadius: '2px !important',
            cursor: 'auto',
            '&.transparent': {
                backgroundColor: '#bdbdbd !important',
                color: '#ffffff',
                border: '1px solid #C4C4C4'
            },
        },
        replyLink: {
            fontSize: '12px',
            marginLeft: '6px',
            cursor: 'pointer',
            color: '#7d7d7d',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                color: '#F77D3D',
                textDecoration: 'none',
            }
        },
        transformIcon: {
            marginRight: '3px',
            "&:hover": {
                color: "#F77D3D"
            }
        },
        replyBoxWrap: {
            border: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: 4,
            width: '80%',
            backgroundColor: '#f4f4f4',
            '& .content': {
                fontSize: 13,
                lineHeight: '22px',
                padding: '13px 16px',
            },
            '& .boxFooter': {
                borderTop: '1px solid #E5E5E5',
                backgroundColor: '#ffffff',
                borderRadius: '0 0 4px 4px'
            }
        },
        boxWrap: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: '100%',
            marginBottom: '24px'
        },
        qlCursor: {
            cursor: 'auto',
        },
        qlAccordianIcon: {
            cursor: 'pointer',
        },
        qlRole: {
            cursor: 'text',
        },
        expandIconButton: {
            padding: '0'
        }
    }),
);

export default useStyles;