import { gql } from '@apollo/client';

const CREATE_PRE_TRANSLATION = gql`mutation createPreTranslation($data: PreTranslationRequest) {
    createPreTranslation(data: $data) {
      status
      error
    }
}`;

export default CREATE_PRE_TRANSLATION;

