import { gql } from '@apollo/client';

const MERGE_SEGMENT = gql`mutation MergeSegment($data: MergeSegmentRequest){
    mergeSegment(data: $data){
      sourceSegmentId
      status
      error
  }
 
  }`;

export default MERGE_SEGMENT;