import { gql } from '@apollo/client';

const CREATE_SINGLE_TERM_BASE = gql`mutation createSingleTermBase($data: TermBaseRequest){
    createSingleTermBase(data: $data){
      status
      error
  }
 
}`;



export default CREATE_SINGLE_TERM_BASE;

