import { gql } from '@apollo/client';

const ANSWER_PUBLIC_QUERY_LOG = gql`mutation AnswerPublicQueryLog($data: AnswerPublicQueryLogRequest){
    answerPublicQueryLog(data: $data){
      status
      error
  }
 
  }`;

export default ANSWER_PUBLIC_QUERY_LOG;