import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxWrap: {
            borderRadius: '8px 8px 0 0',
            boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 7px 0px rgb(0 0 0 / 6%) !important',
            height: "100%"
        },
        boxWrapTranslation: {
            borderRadius: '8px 8px 0 0',
            boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 7px 0px rgb(0 0 0 / 6%) !important',
            minHeight: "100vh"
        },
        noRecordFoundContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '400px',
            color: '#878484',
            fontSize: '18px',
            '& svg': {
                width: '190px',
                height: '150px'
            },
            '& .ant-empty-img-1': {
                fill: '#aeb8c2',
            },
            '& .ant-empty-img-2': {
                fill: '#f5f5f7'
            },
            '& .ant-empty-img-3': {
                fill: '#dce0e6'
            },
            '& .ant-empty-img-4': {
                fill: '#fff'
            },
            '& .ant-empty-img-5': {
                fillOpacity: '0.8',
                fill: '#f5f5f5'
            }
        },
        translationTable: {
            '& tr.active': {
                backgroundColor: '#FCF5ED',
                borderLeft: '2px solid #F77D3D'
            },
            '& tr td': {
                '&:nth-child(1)': {
                    minWidth: 50
                },
                '&:nth-child(2)': {
                    width: '40%',
                    position: 'relative',
                    paddingRight: 30
                },
                '&:nth-child(3)': {
                    minWidth: 100
                },
                '&:nth-child(4)': {
                    width: '47%'
                },
                '&:nth-child(5)': {
                    minWidth: 65
                },
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            },
            '& img': {
                height: '1em',
                cursor: 'pointer',
                margin: '0 0.1em',
                verticalAlign: 'middle',
                border: 'none'
            },
            '& table': {
                width: '100% !important',
            },
        },
        tableContainer: {
            '& .space': {
                '& tr td': {
                    fontFamily: 'marseditor, Roboto !important',
                },
            },
        },
        fieldWrap: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            '& .MuiFormControl-root': {
                width: 'calc(50% - 12px)',
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: 5.5,
                    paddingBottom: 9.5,
                    backgroundColor: 'rgba(232,232,232,0.5)',
                    borderRadius: 2,
                    '&::placeholder': {
                        color: '#0000008A',
                        fontSize: 13,
                        opacity: 1
                    }
                },
                '& fieldset': {
                    border: '1px solid rgba(232,232,232,0.5)',
                    '&.': {
                    }
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& fieldset': {
                        border: '1px solid #f77d3d'
                    }
                }
            }
        },
        replaceBtn: {
            height: 34,
            minWidth: 90,
            marginLeft: 16
        },
        textStatus: {
            fontSize: '12px !important',
            width: 'auto !important',
            height: 'auto !important',
            display: 'inline-block !important',
            padding: '3px 5px',
            borderRadius: '2px !important',
            '&.transparent': {
                backgroundColor: 'transparent !important',
                color: '#C4C4C4',
                border: '1px solid #C4C4C4'
            },
        },
        segmentTabBar: {
            boxShadow: 'none !important',
            borderBottom: '2px solid #E5E5E5',
            '& .MuiTab-root': {
                minWidth: 80
            }
        },
        searchIcon: {
            '& svg': {
                width: 21,
                height: 'auto'
            }
        },
        infoIconBlue: {
            color: "#3D5882"
        },
        replaceWithField: {
            marginTop: '16px !important'
        },
        sourceIcon: {
            position: 'absolute',
            right: 9,
            bottom: 0,
            top: 0,
            margin: 'auto',
            cursor: 'pointer'
        },
        tbFields: {
            width: '100%',
            '& .MuiFormControl-root': {
                width: 'calc(50% - 4px)',
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: 5.5,
                    paddingBottom: 9.5,
                    backgroundColor: 'rgba(232,232,232,0.5)',
                    borderRadius: 2,
                    '&::placeholder': {
                        color: '#0000008A',
                        fontSize: 13,
                        opacity: 1
                    }
                },
                '& fieldset': {
                    border: '1px solid rgba(232,232,232,0.5)',
                    '&.': {
                    }
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& fieldset': {
                        border: '1px solid #f77d3d'
                    }
                }
            }
        },
        queryField: {
            width: '100%',
            '& fieldset': {
                border: '1px solid #E5E5E5',
                filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))'
            },
            '& .MuiInputBase-root': {
                padding: '13px 16px',

            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(16px, 13px) scale(1)',
                fontSize: 12,
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -4px) scale(0.9) !important'
            },
            '& textarea': {
                fontSize: 13,
                padding: '13px 16px',
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
                '& fieldset': {
                    border: '1px solid #f77d3d !important',
                    outline: 'none !important'
                }
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#E5E5E5',
                },
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
                padding: 0,
            }
        },
        searchFields: {
            width: '100%',
            '& fieldset': {
                border: '1px solid #E5E5E5',
                filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))'
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(16px, 13px) scale(1)',
                fontSize: 12,
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -4px) scale(0.9) !important'
            },
            '& textarea': {
                fontSize: 13,
                padding: '13px 16px',
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
                '& fieldset': {
                    border: '1px solid #f77d3d !important',
                    outline: 'none !important'
                }
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#e8e8e880',
                },
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
                padding: 0,
            }
        },
        addQueryBtn: {
            height: 32,
            minWidth: 70,
            padding: '4px 8px'
        },
        shareBtn: {
            height: 32,
            minWidth: 70,
            backgroundColor: '#4b8ac7',
            color: '#ffffff',
            marginLeft: '10px',
            padding: '4px 6px',
            '&:hover': {
                backgroundColor: '#146dc3'
            }
        },
        replyIcon: {
            transform: 'scaleX(-1)',
            '& svg': {
                color: '#ffffff',
                display: 'flex',
            }
        },
        sideBarScroll: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        copyLink: {
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            color: '#7f7f7f',
            '&:hover': {
                color: 'F77D3D',
                textDecoration: 'none'
            }
        },
        pdfWraper: {
            filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1))'
        },
        pdfListWrap: {
            '& p': {
                fontSize: 13,
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: -16,
                '&:last-child': {
                    marginBottom: 0,
                }
            }
        },
        qaReportTable: {
            '& thead tr th': {
                width: '33.33%'
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            '& del': {
                color: '#f24822',
                textDecoration: 'line-through'
            },
            ' & ins': {
                color: '#43a047'
            }
        },
        qaFields: {
            '& .MuiFormControl-root': {
                width: '100%',
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: 5.5,
                    paddingBottom: 9.5,
                    backgroundColor: 'rgba(232,232,232,0.5)',
                    borderRadius: 2,
                    '&::placeholder': {
                        color: '#0000008A',
                        fontSize: 13,
                        opacity: 1
                    }
                },
                '& fieldset': {
                    border: '1px solid rgba(232,232,232,0.5)',
                    '&.': {
                    }
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& fieldset': {
                        border: '1px solid #f77d3d'
                    }
                }
            }
        },
        qaSelect: {
            '& .MuiSelect-root': {
                padding: '7.5px 12px',
                zIndex: 9,
                fontSize: 12
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -3px) scale(0.75) !important'
            },
            '& fieldset': {
                backgroundColor: '#f3f3f3'
            },
            '& .MuiInputLabel-outlined': {
                fontSize: 12,
                transform: 'translate(12px, 12px) scale(1)'
            },
            '& .MuiSelect-iconOutlined': {
                fontSize: 18,
                top: 8,
                right: 2,
                zIndex: 9
            }
        },
        boxTitle: {
            fontSize: 15,
            fontFamily: 'Roboto Medium'
        },
        formDesc: {
            fontSize: 13,
            margin: '8px 0 16px'
        },

        table: {
            minWidth: '100%',
            border: '1px solid #dedede',
            borderRadius: '8px',
            '& .tableRow:last-child td, .tableRow:last-child th': {
                borderBottom: '0 !important',
            },
        },
        cellStyles: {
            padding: '11px !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderTop: 'none !important',
            borderBottom: '1px solid #e4e4e4',
        },
        dialogTitle: {
            padding: '0',
            '& h2': {
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '15px'
            }
        },
        dialogContent: {
            padding: '0'
        },
        dialogActions: {
            padding: '0'
        },
        paper: {
            minWidth: "500px",
            padding: "20px"
        },
        addRow: {
            border: '1px solid #e4e4e4',
        },
        minorSeverity: {
            color: '#a2e841 !important',
        },
        majorSeverity: {
            color: '#ffb124 !important',
        },
        criticalSeverity: {
            color: '#f24444 !important'
        },
        statusIcon: {
            color: '#F77D3D',
        },
        qaIcons: {
            cursor: 'pointer'
        },
        qlBox: {
            maxHeight: '280px',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: '-20px',
            right: '-14.1px',
            overflowY: 'scroll',

        },
        radioWrapper: {
            display: 'flex',
            flexDirection: 'row',
            '& .MuiFormControlLabel-root': {
                marginLeft: '-7px !important',
            }
        },
        radioLabel: {
            '& .MuiFormControlLabel-label': {
                fontSize: '13px',
                marginRight: '8px',
            },
            '& .MuiIconButton-root': {
                padding: '6px',
            }
        },
        snackBarStyles: {
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important'
            }
        },
        root: {
            marginBottom: '44px',
            '& > *': {
                marginTop: '24px',
                display: 'flex',
                justifyContent: 'center'
            },
        },
        iconStyle: {
            color: '#4d82b8',
            fontSize: '18px',
        },

        iconPrimary: {
            color: '#ee662c',
            fontSize: '18px',
        },

        iconWarning: {
            color: '#ffa500',
            fontSize: '18px',
        },

        iconSuccess: {
            color: '#008000',
            fontSize: '18px',
        },
        disableInput: {
            WebkitUserModify: "read-only",
        },
        metaData: {
            // borderLeft: '1px solid #e0e0e0',
            border: 'none',
            display: 'block'
        },
        // metaWrapper: {
        //     display: 'flex',
        //     justifyContent: 'flex-start',
        //     padding: 0,
        //     borderTop: 'none !important',
        //     borderBottom: 'none !important',
        //     backgroundColor: '#f4f4f4'
        // },
        metaWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
            borderTop: 'none !important',
            borderBottom: 'none !important',
            backgroundColor: '#f4f4f4'
        },
        metaText: {
            fontSize: '11px',
            fontWeight: 600,
            color: '#6f6767',
            padding: '0 8px'
        },
        tableBody: {
            display: 'grid',
            borderBottom: '1px solid #e0e0e0 !important',
        },
        tableStyles: {
            borderTop: '1px solid #e0e0e0 !important',
            borderLeft: '1px solid #e0e0e0 !important',
            borderRight: '1px solid #e0e0e0 !important',
        },
        emptyTableStyles: {
            borderTop: '1px solid #e0e0e0 !important',
            borderRight: '1px solid #e0e0e0 !important',
        },
        tooltipStyles: {
            fontSize: '12px',
            color: '#868686'
        },
        commentIcon: {
            fontSize: '16px',
            margin: '0px 8px'
        },
        tblRow: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '43% 14% 43%',
            //  height: '100%',
        },
        tblCell: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            minWidth: "43%",
            borderBottom: 'none !important',
            padding: '14px 8px !important',
        },
        tblStatusCell: {
            minWidth: "14%",
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderBottom: 'none !important',
            padding: '14px 8px !important',
        },
        deleteIconButton: {
            width: '22px',
            height: '22px'
        },
        modalStyles: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },
        modalTitle: {
            padding: '16px 20px 16px 20px',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        modalBody: {
            padding: '20px',
        },
        modalFooter: {
            padding: '16px 20px 16px 20px',
            borderTop: '1px solid #dedede',
            display: 'flex',
            justifyContent: 'right'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            padding: 7,
            '& svg': {
                color: '#0000004D'
            }
        },
        textStyles: {
            fontSize: '13px',
            color: '#494949',
            padding: '6px 8px 6px 8px'
        },
        statusCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            // minWidth: '57px',
            borderTop: 'none !important',
            borderBottom: 'none !important',
            borderRight: 'none !important',
            border: 'none !important',
            minWidth: '12%'
        },
        translationCell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            borderTop: 'none !important',
            borderBottom: 'none !important',
            borderRight: 'none !important',
            padding: '0 !important',
            minWidth: '88%'
        },
        metaStyles: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            padding: '3px 8px',
            backgroundColor: '#e7e5e5'
        },
        tabHeading: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#757575'
        },

        tbCell: {
            padding: '14px 8px 4px 8px',
            border: 'none !important',
            display: 'flex'
        },

        tbContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },

        tbData: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        tbMeta: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: 'column'
        },
        tbSourceText: {
            fontSize: '13px',
            color: '#494949',
            paddingRight: '6px',
            minWidth: '43%'
        },
        tbTargetText: {
            fontSize: '13px',
            color: '#494949',
            paddingLeft: '6px',
            minWidth: '43%'
        },
        tbComment: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },

        addTerm: {
            '&.MuiAccordion-root': {
                boxShadow: 'none !important'
            },
            '&.MuiAccordion-root.Mui-expanded': {
                boxShadow: 'none !important'
            },
            '& .MuiAccordionSummary-root': {
                padding: '0 12px 0 0 !important',
                minHeight: '0 !important'
            },
            '& .MuiAccordionSummary-content': {
                margin: '0 !important'
            },
            '& .MuiIconButton-root': {
                padding: '0 !important'
            }
        },
        iconStyles: {
            color: '#3d80c3',
            '&.noRipple': {
                // Disable the ripple effect
                pointerEvents: 'none',
            },
        },
        tbTextFeild: {
            width: '100% !important',
        },
        feildLabel: {
            width: '30%',
            fontSize: '14px',
            color: '#757575'
        },
        tbAddBtn: {
            width: 'fit-content',
            display: 'flex',
            alignSelf: 'end'
        },
        sideBarStyles: {
            // minHeight: '100%',
            position: 'sticky',
            '& .MuiPaper-root': {
                // maxWidth: 'calc(100% - 0px) !important'
                maxWidth: '100%'
            },
            top: '164px !important'
        },
        sidebarPosition: {
            top: '120px !important',
            height: '87vh !important'
        },
        bottomGrid: {
            paddingLeft: '24px !important',
            paddingRight: '19px !important',
            paddingBottom: '0!important'
        },
        hideCursor: {
            caretColor: 'transparent'
        },
        tmTbBox: {
            height: '66vh',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
                msOverflowStyle: 'none',  /* Internet Explorer and Edge */
                scrollbarWidth: 'none',  /* Firefox */
            }
        },
        mtBox: {
            height: '66vh',
            overflowY: 'scroll',
        },
        queryBox: {
            height: '66vh',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
                msOverflowStyle: 'none',  /* Internet Explorer and Edge */
                scrollbarWidth: 'none',  /* Firefox */
            }
        },
        dialogBoxWrap: {
            boxShadow: 'none'
        },
        segId: {
            width: 'max-content'
        },
        perPage: {
            fontSize: '14px',
            marginRight: '8px'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center !important',
            alignItems: 'center'
        },
        paginationSelect: {
            '& .MuiSelect-select.MuiSelect-select': {
                paddingRight: '15px',
            },
            '&.MuiInput-underline::before': {
                borderBottom: 'none !important'
            }
        },
        tmRecord: {
            padding: '20px'
        },
        tmRecordCell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            borderTop: 'none !important',
            borderBottom: 'none !important',
            borderRight: 'none !important',
            borderLeft: 'none !important',
            padding: '0 !important',
            minWidth: '88%'
        },
        sourceText: {
            '& del': {
                position: 'relative',
                backgroundColor: '#fdcfcf',
                textDecoration: 'line-through',
                textDecorationColor: 'red',
                color: '#494949',
            },
            '& ins': {
                position: 'relative',
                backgroundColor: '#d6fdd8',
                textDecoration: 'underline',
                textDecorationColor: 'green',
                color: '#494949',
            },
        },
        top: {
            color: '#F77D3D',
            animationDuration: '1500ms',
            left: 0,
            position: 'relative',
        },
        circle: {
            strokeLinecap: 'round',
            strokeDasharray: '4 8',
        },
        loading: {
            display: 'contents'
        },
        boldTarget: {
            fontWeight: "bold",
        },
        spanStyle: {
            display: 'contents',
        }
    }),
);

export default useStyles;