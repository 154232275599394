import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionWrap: {
            minWidth: 70,
            height: 70,
            display: 'inline-block',
            marginRight: 16,
            '& .MuiButtonGroup-grouped': {
                display: 'flex'
            },
            '& .MuiButtonGroup-root': {
                height: '70px !important',
            },
            '& .MuiCardContent-root': {
                padding: '0 !important',
                display: 'flex',
                justifyContent: 'center',
                '& .MuiButton-root': {
                    border: 'none',
                    minWidth: 'auto',
                    '& .MuiButton-label': {
                        display: 'flex',
                        flexDirection: 'column',
                        '& p': {
                            fontSize: 12,
                            minWidth: 36,
                            maxWidth: 71,
                            marginTop: 5,
                            lineHeight: '13px'
                        },
                        '& svg': {
                            fontSize: 20,
                        }
                    }
                }
            },
        },
        actionIcon: {
            fontSize: '14px !important',
            color: '#323232',
            marginTop: -1,
        },
        disabledActionIcon: {
            color: "#00000042",
        },
        headerMenu: {
            '& .MuiPaper-root': {
                filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2))',
                boxShadow: 'none'
            },
            '& .MuiListItem-root': {
                fontSize: '13px !important',
                padding: '10px 16px',
                '& svg': {
                    fontSize: 15,
                    marginRight: 4
                }
            },
        },
        buttonGroup: {
            display: 'flex !important',
            flexWrap: 'wrap',
            width: 95,
            padding: 10,
            alignContent: 'center',
            justifyContent: 'center',
            '&>button': {
                flex: '0 0 33.33%',
                padding: 3,
                minWidth: 'auto',
                height: 25
            }
        },
        singleBtn: {
            minWidth: '70px !important',
            padding: '8px 12px 10px',
        },
        firstBtn: {
            padding: '10px 0px 10px 10px'
        },
        secondBtn: {
            padding: '10px 10px 10px 0px'
        },
        activeBtn: {
            color: '#f77d3d',
            '& svg': {
                color: '#f77d3d',
            },
            '& path': {
                color: '#f77d3d',
                fill: '#f77d3d',
            }
        },
        iconBtn: {
            fontSize: '12px',
        },
        parentModalStyles: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },
        submitModalStyles: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },
        submitModalMsg: {
            padding: '20px',
        },
        cancelButton: {
            backgroundColor: '#c5c5c5',
            marginRight: '8px',
            '& .MuiButton-label': {
                color: '#ffffff'
            }
        },
        modalTitle: {
            padding: '16px 20px 16px 20px',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        modalBody: {
            padding: '20px',
        },
        modalFooter: {
            padding: '16px 20px 16px 20px',
            borderTop: '1px solid #dedede',
            display: 'flex',
            justifyContent: 'right'
        },
        labelStyle: {
            '& .MuiFormControlLabel-label': {
                fontSize: '13px',
            }
        },
        checkboxColor: {
            color: '#f77d3d',
            padding: '8px !important',
            '&.Mui-checked svg': {
                color: '#f77d3d !important',
                width: '18px !important',
                height: '18px !important',
            },
            '&.MuiIconButton-colorSecondary svg': {
                width: '18px !important',
                height: '18px !important',

            },
            '&.MuiCheckbox-indeterminate svg': {
                color: '#f77d3d !important',
            }
        },
        disableBtn: {
            opacity: 0.5
        },
        enableBtn: {
            opacity: '1 !important',
        },
        sticky: {
            backgroundColor: '#e5e5e5',
            position: 'sticky',
            top: 0,
            width: '100%',
            height: 'auto',
            borderBottom: '1px solid #e5e5e5',
            zIndex: 999,
            maxHeight: '103px',
            padding: '16px 24px 16px',
        },
        stickyShadow: {
            boxShadow: '0 2px 3px -2px grey'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            padding: 7,
            '& svg': {
                color: '#0000004D'
            }
        },
        snackBarStyles: {
            '& .MuiAlert-standardError': {
                backgroundColor: "#f44336",
                color: '#ffffff',
            }
        },
        alertIcon: {
            '& .MuiSvgIcon-root': {
                color: '#ffffff !important'
            }
        },
        splitModal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            backgroundColor: '#ffffff',
            border: '1px solid #dedede',
            borderRadius: '4px',
            boxShadow: '24',
        },

        splitModalTitle: {
            padding: '16px 20px 16px 20px',
            borderBottom: '1px solid #dedede',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        splitModalBody: {
            padding: '20px',
        },
        splitModalFooter: {
            padding: '16px 20px 16px 20px',
            borderTop: '1px solid #dedede',
            display: 'flex',
            justifyContent: 'right'
        },
        textArea: {
            width: '100%',
            borderRadius: '4px',
            borderColor: '#dedede',
        },
        footerWrap: {
            '&.MuiSvgIcon-root': {
                color: 'red !important',
                fill: 'red !important'
            },
        },
        splitText: {
            width: '100%',
            '& fieldset': {
                border: '1px solid #E5E5E5',
                filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.07))'
            },
            '& .MuiInputBase-root': {
                padding: '13px 16px',

            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(16px, 13px) scale(1)',
                fontSize: 12,
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -4px) scale(0.9) !important'
            },
            '& textarea': {
                fontSize: 13,
                padding: '13px 16px',
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
                '& fieldset': {
                    border: '1px solid #f77d3d !important',
                    outline: 'none !important'
                }
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#E5E5E5',
                },
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
                padding: 0,
            }
        },
        splitHeading: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#494949',
            marginBottom: '2px'

        },
        snackbarContainer: {
            position: 'fixed',
        },
    }),
);

export default useStyles;