import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    TextField,
    FormControlLabel,
    IconButton,
    Checkbox,
    Tooltip,
} from "@material-ui/core";
import * as Icons from "src/material-ui";
import { withStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress';

const ReplaceBody = (
    classes: any,
    value: any,
    searchSourceText: any,
    searchTargetText: any,
    targetValue: any,
    replaceText: any,
    handleReplaceWith: any,
    handleCaseCheck: any,
    handleReplaceAll: any,
    handleClearFilters: any,
    handleSearchFilters: any,
    translationDataCount: any,
    markOption: any,
    appState: any,
    replaceValue: any,
    handleBothSearches: any,
    caseSensitive: any,
    loadingReplace: any
) => {

    // useEffect(() => {
    //     setCaseSensitive(markOption.caseSensitive)
    // }, [markOption.caseSensitive])

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: '#494949',
            border: '1px solid #E0E0E0',
            fontSize: 12,
            margin: '2px 0'
        },
        arrow: {
            "&::before": {
                backgroundColor: theme.palette.common.white,
                border: '1px solid #E0E0E0',
            }
        },
    }))(Tooltip);
    const { t } = useTranslation();

    const disabledReplaceButtons: boolean = (
        (targetValue && replaceValue) === "" ||
        // (typeof appState.searchCounts.total === "undefined" || appState.searchCounts?.total === 0) ||
        appState.pagination.totalRecords <= 0 || loadingReplace
    );

    const notSearchButtonDisabled = () => {
        return appState.selectedService.isAssigned === 1 && (value.trim() !== "" || targetValue.trim() !== "");
    }

    return (
        <Box p={3} display="flex" justifyContent="flex-end" alignItems={'flex-start'}>
            {/*<Box className={classes.searchIcon} mr={2.75}>*/}
            {/*<Icons.ListSearchIcon className="custom_icon" />*/}
            {/*</Box>*/}
            <Box className={classes.fieldWrap} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} position="relative">
                <Box display="flex" justifyContent={'space-between'} style={{ minWidth: '100%' }}>
                    <TextField
                        className={classes.searchFields}
                        id='searchSourceText'
                        variant="outlined"
                        size="small"
                        placeholder={t("translationTable.source") as any}
                        onChange={searchSourceText}
                        // onFocus={searchSourceText}
                        value={value}
                        disabled={appState.selectedService.isAssigned === 1 ? false : true}
                    />

                    <TextField
                        className={classes.searchFields}
                        id='searchTargetText'
                        variant="outlined"
                        size="small"
                        onChange={searchTargetText}
                        // onFocus={searchTargetText}
                        placeholder={t("translationTable.target") as any}
                        value={targetValue}
                        disabled={appState.selectedService.isAssigned === 1 ? false : true}
                    />
                </Box>

                {appState.expandReplace === true ?
                    <>
                        <TextField
                            className={`${classes.replaceWithField} ${classes.searchFields}`}
                            variant="outlined"
                            size="small"
                            placeholder={t("translationTable.replaceWith") as any}
                            onChange={replaceText}
                            value={replaceValue}
                            disabled={appState.selectedService.isAssigned === 1 ? false : true}
                        />

                        <Box position="absolute" bottom={0} right={-220}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.replaceBtn}
                                disabled={disabledReplaceButtons}
                                onClick={handleReplaceWith}
                            >
                                {t("common.replace")}
                            </Button>

                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.replaceBtn}
                                disabled={disabledReplaceButtons}
                                onClick={handleReplaceAll}
                            >
                                {t("translationTable.replaceAll")}
                            </Button>


                        </Box>
                        <Box position="absolute" bottom={-10} right={-280}>
                            {loadingReplace ? <CircularProgress disableShrink /> : ''}
                        </Box>
                    </>
                    : ''
                }
            </Box>

            <Box height={48} ml={3} mt={-1} display="flex">
                <LightTooltip title={t("translationTable.caseSensitive") as any} placement="bottom">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={caseSensitive}
                                onChange={handleCaseCheck}
                                name="caseSensitive"
                                size="small"
                                disabled={appState.selectedService.isAssigned === 1 ? false : true}
                            />
                        }
                        label="Aa"
                    />
                </LightTooltip>

                <LightTooltip title={t("translationTable.searchSegments") as any} placement="bottom">
                    <IconButton onClick={handleSearchFilters} style={{ width: 50 }} disabled={notSearchButtonDisabled() ? false : true}>
                        <Icons.ListSearchIcon className="custom_icon" style={{ width: 50, color: '#ff700b', opacity: notSearchButtonDisabled() ? 1 : 0.6 }} />
                    </IconButton>
                </LightTooltip>
                {/*onMouseUp={() => handleBothSearches([".sourceText", ".targetText"], [value, targetValue], markOption)}*/}
                {/* {(value || targetValue) ? (
                    <LightTooltip title={t("translationTable.refreshSegments") as any} placement="bottom">
                        <IconButton onClick={handleSearchFilters} >
                            <Icons.CachedRoundedIcon />
                        </IconButton>
                    </LightTooltip>
                ) : (
                    <IconButton disabled>
                        <Icons.CachedRoundedIcon style={{ color: "#c5c5c5" }} />
                    </IconButton>
                )} */}
                <LightTooltip title={t("translationTable.removeAllFilters") as any} placement="bottom">
                    <IconButton onClick={handleClearFilters} style={{ width: 48 }} disabled={appState.selectedService.isAssigned === 1 ? false : true}>
                        <Icons.CleanIcon className="custom_icon" />
                    </IconButton>
                </LightTooltip>
                {appState.searchCounts && appState.searchCounts.total && (value || targetValue !== "") ? (
                    <Box p={2} color={"#7f7f7f"}>
                        {t("translationTable.found")}:
                        <span
                            style={{
                                marginLeft: "4px",
                                color: "#7f7f7f",
                                fontWeight: "bold",
                            }}
                        >
                            {appState.searchCounts?.total}
                        </span>
                    </Box>
                )
                    : (
                        <Box p={2} color={"#7f7f7f"}></Box>
                    )}
            </Box>
        </Box >
    );
};

export default ReplaceBody;