import React, { useState, useEffect } from "react";
import {
    Box,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    Snackbar,
} from "@material-ui/core";
import useStyles from "../../pages/home/HomeStyles";
import { TabPanel } from "src/material-ui";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { IAppState } from "src/interfaces";
import { useTranslation } from "react-i18next";
import { useMutation } from '@apollo/client';
import SAVE_QA_REPORT from "src/helpers/mutations/saveQAReport";
import { useLazyQuery } from "@apollo/client";
import GET_SEGMENT_REPORTS from "src/helpers/queries/GetSegmentReports";
import DELETE_QA_REPORT from "src/helpers/mutations/deleteQAReport";
import { Alert } from "@material-ui/lab";
import CustomSnackbar from "../custom-snackbar/CustomSnackbar";
import useErrorHandler from "src/helpers/custom-hooks/UseErrorHandler";

interface IProps {
    value: string;
    appState: IAppState;
    appDispatch: Function;
    headerData: any;
    expanded: any;
}

const QAReport = ({ value, appState, appDispatch, headerData, expanded }: IProps) => {
    const classes = useStyles();
    //   const [isEdit, setEdit] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isAddQuery, setIsAddQuery] = useState(false);
    const { t } = useTranslation();
    const [removeIndex, setRemoveIndex]: any = useState(null);
    const [recall, setRecall] = useState(false);
    const [snackAlert, setSnackAlert] = useState(false);
    const [snackText, setSnackText] = useState("");

    const [GetSegmentReports, { data, error }] = useLazyQuery(
        GET_SEGMENT_REPORTS, { fetchPolicy: 'no-cache' }
    );

    const [toggle, setToggle] = useState(false);

    const [category, setCategory] = useState({
        Accuracy: false,
        Language: false,
        Instruction: false,
    });

    const [severity, setSeverity] = useState({
        Minor: false,
        Major: false,
        Critical: false,
    });

    const {
        gqlError,
        gqlErrorSnackBar,
        handleErrorSnackBarClose,
        handleError,
    } = useErrorHandler();

    const [rows, setRows]: any = useState([]);

    const [saveQAReport, { loading: loadingAcceptRejectSegments }] = useMutation(SAVE_QA_REPORT, {
        errorPolicy: 'all',
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    const [deleteQAReport, { loading: deleteLoading }] = useMutation(DELETE_QA_REPORT, {
        errorPolicy: 'all',
        onCompleted: (data: any) => {

        }, onError: (error: any) => {
            appDispatch({ type: "SET_TRANSLATION_DATA_LOADING", payload: false });

            {/* To handle GraphQl errors in SnackBar */ }
            handleError(error);
        }
    });

    useEffect(() => {
        document.body.addEventListener("click", () => {
            if (document.body.classList.contains("open")) {
                document.body.classList.remove("fixed-position");
                document.body.classList.remove("open");
            }
        });
    }, []);

    useEffect(() => {
        if (!showConfirm || !toggle) {
            setTimeout(function () {
                document.body.removeAttribute("style");
                document.body.classList.remove("fixed-position");
                document.body.classList.remove("open")
            }, 1);
        }
        if (showConfirm || toggle) {
            setTimeout(function () {
                document.body.removeAttribute("style");
                document.body.classList.add("fixed-position");
                document.body.classList.add("open")
            }, 1);
        }
    }, [showConfirm, toggle]);


    //  GetSegmentReports API Call

    useEffect(() => {

        if (appState.selectedRow && expanded && value === "qaReport") {

            let tl: any = appState.selectedRow?.tl;
            let source = appState.selectedRow?.source;

            if (tl && source) {
                GetSegmentReports({
                    variables: {
                        sourceSegmentId: appState.selectedRow.id + "",
                        tl: appState.selectedLanguage,
                    }
                });
            }

        }
    }, [appState.selectedRow, appState.selectedLanguage, recall, expanded, value]);

    useEffect(() => {
        if (data) {
            let qaReport = data?.getSegmentReports;

            let dataRows = [];

            console.log(qaReport, "QAREPORT")

            for (let i = 0; i < qaReport.length; i++) {
                dataRows.push({
                    id: i + 1,
                    segmentId: appState.selectedRow.id,
                    translation: qaReport[i].translation,
                    revision: qaReport[i].suggestedRevision,
                    category: qaReport[i].errorCategory,
                    severity: qaReport[i].errorSeverity,
                    isSaved: true,
                    qaId: qaReport[i].id,
                });
            }

            if (isAddQuery) {
                const rowId = appState.data.find((item: any) => item.id === appState.selected)?.id
                const targetText = document.getElementById("custom-editor" + rowId)?.textContent;
                dataRows.push({
                    id: qaReport.length > 0 ? qaReport.length + 1 : 1,
                    translation: targetText,
                    revision: targetText,
                    category: "Accuracy",
                    severity: "Minor",
                    isSaved: false,
                    segmentId: rowId,
                });
                setTimeout(() => {
                    setIsAddQuery(false);
                }, 1000);
            }

            setRows(dataRows);
            // appDispatch({ type: 'SET_QA_REPORT', payload: qaReport });
        }
    }, [data, appState.selected]);


    const handleInputChange = (e: any, index: any) => {
        // setDisable(false);
        const { name, value }: any = e.target;
        const list: any = [...rows];
        list[index][name] = value;
        setCategory({
            ...category,
            Language: e.target.value === "Language",
            Instruction: e.target.value === "Instruction",
        });
        setSeverity({
            ...severity,
            Major: e.target.value === "Major",
            Critical: e.target.value === "Critical",
        });
        setRows(list);
    };

    const handleConfirm = (i: any) => {
        setRemoveIndex(i);
        setShowConfirm(true);
    };

    const handleNo = () => {
        setShowConfirm(false);
    };

    const handleRemoveClick = () => {

        const rowToDelete = rows[removeIndex];

        if (showConfirm) {

            deleteQAReport({
                variables: { id: rowToDelete.qaId },
            })
                .then((response) => {
                    const list = [...rows];
                    list.splice(removeIndex, 1);
                    setRows(list);
                })
                .catch((error) => {
                    // Handle any errors that occur during the mutation
                    console.error('Error deleting row:', error);
                });
        }

        // Close the confirmation dialog
        setShowConfirm(false);
    };

    const handleAdd = () => {
        let target = appState.selected;
        let targetDiv = document.getElementById("custom-editor" + target);
        let targetText = targetDiv?.textContent;
        // rows.forEach((row:any)=>if(row.id === ))
        // if ((rows.length !== 0 || appState.selected))
        setRows([
            ...rows,
            {
                // id: appState.data.find((item: any) => item.id === target)
                //     ?.segmentCount,
                id: rows.length + 1,
                translation: targetText,
                revision: targetText,
                category: "Accuracy",
                severity: "Minor",
                isSaved: false,
                segmentId: appState.data.find((item: any) => item.id === target)?.id,
            },
        ]);
        // setEdit(true);
        setIsAddQuery(true);
        setShowConfirm(false);
    };

    const handleSave = (index: any) => {
        const list: any = [...rows];
        if (list[index].segmentId === "" || list[index].segmentId === undefined) {
            setSnackText(t('qa.selectASegment') as string);
            setSnackAlert(true);
        } else if (list[index].translation.trim() === "" || list[index].translation === undefined) {
            setSnackText(t('qa.translationNotEmpty') as string);
            setSnackAlert(true);
        } else if (list[index].revision.trim() === "" || list[index].revision === undefined) {
            setSnackText(t('qa.revisionNotEmpty') as string);
            setSnackAlert(true);
        }
        else {
            let data: any = [];
            data = {
                segmentId: list[index].segmentId,
                tl: appState.selectedLanguage,
                translation: list[index].translation,
                suggestedRevision: list[index].revision,
                errorCategory: list[index].category,
                errorSeverity: list[index].severity,
            }

            saveQAReport({ variables: { data: data } });
            setIsAddQuery(false);
            setRecall(!recall);
        }
    };

    const handleToggle = () => {
        setToggle(true);
    };

    const handleClose = () => {
        setToggle(false);
    };

    const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackAlert(false);
        setSnackText("");
    };

    return (
        <TabPanel value={value} index="qaReport">
            <>
                <Box height="100%">

                    <Paper className={classes.boxWrap} elevation={1}>
                        <Box className={classes.qaFields} mb={4}>
                            <Table>
                                <TableBody>
                                    <Box>
                                        <Table
                                            className={`${classes.table} innerTable`}
                                            size="small"
                                            aria-label="a dense table"
                                        >
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('qa.no')}
                                                    </TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('common.translation')}
                                                    </TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('qa.suggestedRevision')}
                                                    </TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('qa.errorCategory')}
                                                    </TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('qa.errorSeverity')}
                                                    </TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize' }} className={classes.cellStyles}>
                                                        {t('qa.actions')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {rows.map((row: any, i: any) => {
                                                    return (
                                                        <TableRow className="tableRow">
                                                            <>
                                                                <TableCell className={classes.cellStyles}>
                                                                    <Box>{i + 1}</Box>
                                                                </TableCell>
                                                                {/* Translation */}
                                                                <TableCell className={classes.cellStyles}>
                                                                    {row.isSaved === false ? (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            style={{ width: "100%" }}
                                                                            value={row.translation}
                                                                            name="translation"
                                                                            onChange={(e) =>
                                                                                handleInputChange(e, i)
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Box maxWidth={"440px"}>
                                                                            {row.translation}
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                {/* Rivision */}
                                                                <TableCell className={classes.cellStyles}>
                                                                    {row.isSaved === false ? (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            style={{ width: "100%" }}
                                                                            //
                                                                            value={row.revision}
                                                                            name="revision"
                                                                            onChange={(e) =>
                                                                                handleInputChange(e, i)
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Box maxWidth={"440px"}>
                                                                            {row.revision}
                                                                        </Box>
                                                                    )}
                                                                </TableCell>

                                                                {/* Category */}
                                                                <TableCell className={classes.cellStyles}>
                                                                    {row.isSaved === false ? (
                                                                        <FormControl
                                                                            className={classes.qaSelect}
                                                                            variant="outlined"
                                                                        >
                                                                            <Select
                                                                                name="category"
                                                                                onOpen={handleToggle}
                                                                                onClose={handleClose}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(e, i)
                                                                                }
                                                                                value={row.category}
                                                                                className="selectInput"
                                                                            >
                                                                                <MenuItem value="Accuracy" selected>
                                                                                    {t('qa.accuracy')}
                                                                                </MenuItem>
                                                                                <MenuItem value="Language">
                                                                                    {t('qa.language')}
                                                                                </MenuItem>
                                                                                <MenuItem value="Instruction">
                                                                                    {t('qa.instruction')}
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row.category
                                                                    )}
                                                                </TableCell>

                                                                {/* Error severity*/}
                                                                <TableCell className={classes.cellStyles}>
                                                                    {row.isSaved === false ? (
                                                                        <FormControl
                                                                            className={classes.qaSelect}
                                                                            variant="outlined"
                                                                        >
                                                                            <Select
                                                                                name="severity"
                                                                                onOpen={handleToggle}
                                                                                onClose={handleClose}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(e, i)
                                                                                }
                                                                                value={row.severity}
                                                                                className="selectInput"
                                                                            >
                                                                                <MenuItem value="Minor">
                                                                                    {t('qa.minor')}
                                                                                </MenuItem>
                                                                                <MenuItem value="Major">
                                                                                    {t('qa.major')}
                                                                                </MenuItem>
                                                                                <MenuItem value="Critical">
                                                                                    {t('qa.critical')}
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    ) : row.severity === "Minor" ? (
                                                                        <Box className={classes.minorSeverity}>
                                                                            {row.severity}
                                                                        </Box>
                                                                    ) : row.severity === "Major" ? (
                                                                        <Box className={classes.majorSeverity}>
                                                                            {row.severity}
                                                                        </Box>
                                                                    ) : row.severity === "Critical" ? (
                                                                        <Box className={classes.criticalSeverity}>
                                                                            {row.severity}
                                                                        </Box>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </TableCell>

                                                                {/* Icon save or delete */}
                                                                <TableCell
                                                                    align="center"
                                                                    className={classes.cellStyles}
                                                                >
                                                                    {row.isSaved === false ? (
                                                                        <Box mr={1}>
                                                                            <IconButton
                                                                                size="medium"
                                                                                onClick={() => handleSave(i)}
                                                                            >
                                                                                <SaveIcon
                                                                                    fontSize="small"
                                                                                    style={{ color: "#212121" }}
                                                                                />
                                                                            </IconButton>
                                                                        </Box>
                                                                    ) : (
                                                                        // delete icon
                                                                        <IconButton
                                                                            size="medium"
                                                                            onClick={() => handleConfirm(i)}
                                                                        >
                                                                            <DeleteIcon
                                                                                fontSize="small"
                                                                                style={{ color: "#212121" }}
                                                                            />
                                                                        </IconButton>
                                                                    )}
                                                                </TableCell>
                                                            </>
                                                        </TableRow>
                                                    );
                                                })}

                                                {showConfirm && (
                                                    <Box>
                                                        <Dialog
                                                            open={showConfirm}
                                                            onClose={handleNo}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                            classes={{ paper: classes.paper }}
                                                            className={classes.dialogBoxWrap}
                                                            BackdropProps={{
                                                                style: { backgroundColor: 'transparent', boxShadow: 'none' }
                                                            }}
                                                        >
                                                            <DialogTitle
                                                                className={classes.dialogTitle}
                                                                id="alert-dialog-title"
                                                            >
                                                                {t('qa.confirmDelete')}
                                                            </DialogTitle>

                                                            <DialogContent
                                                                className={classes.dialogContent}
                                                            >
                                                                <DialogContentText id="alert-dialog-description">
                                                                    {t('verify.areYouSure')}
                                                                </DialogContentText>
                                                            </DialogContent>

                                                            <DialogActions
                                                                className={classes.dialogActions}
                                                            >
                                                                <Button
                                                                    onClick={() => handleRemoveClick()}
                                                                    color="primary"
                                                                    variant="contained"
                                                                    autoFocus
                                                                >
                                                                    {t('verify.yes')}
                                                                </Button>
                                                                <Button onClick={handleNo} autoFocus>
                                                                    {t('qa.no')}
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Box>
                                                )}


                                            </TableBody>

                                            <Box
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Box display="flex">
                                                    <IconButton onClick={handleAdd}>
                                                        <AddIcon
                                                            style={{ color: "#4b8ac7" }}
                                                            fontSize={"medium"}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Table>
                                    </Box>
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                </Box>
                <Snackbar className={classes.snackBarStyles} key={snackText} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackAlert}
                    autoHideDuration={2000} onClose={handleSnackBarClose}>
                    <Alert className={classes.alertIcon} onClose={handleSnackBarClose} severity="error">
                        {snackText}
                    </Alert>
                </Snackbar>

                <CustomSnackbar
                    error={gqlError}
                    open={gqlErrorSnackBar}
                    onClose={handleErrorSnackBarClose}
                />
            </>
        </TabPanel>
    );
};

export default React.memo(QAReport);


